import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import DashboardDeveloper from './../Templates/DashboardDeveloper';
import DashboardInvestor from './../Templates/DashboardInvestor';
import Document from './../../../App/Components/Utils/Document';
import User from './../Utils';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        Dashboard.contextType = Context;
        this.state = {

        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', true);
        this.context.updateContext('pageSubHeading', 'At a glance summary of your PropCrowdy account');
        Document.updateDocumentHead({title: (this.context.layout === 'user.buy' ? 'PropBuy' : 'PropCrowdy') + ' - Dashboard'});
        User.syncUser(undefined, this.context).then(() => {}).catch(() => {});
        if (this.context.user && this.context.user.payment_info && (!this.context.user.payment_info.completed || this.context.user.payment_info.completed == 0)){
            this.props.history.push({pathname: '/account/profile', search: this.state.redirectSearch});
        }
    }

    render() {
        return this.context.user.customer_type === 'developer' ? (
            <DashboardDeveloper />
        ) : (
            <DashboardInvestor />
        );
    }
}
