import "./index.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const GetInsightModal = ({ showModal, toggleModal }) => {
  const history = useHistory();

  const modalContainer = document.querySelector(".custom_modal");

  const handleWindowClick = (event) => {
    if (event.target === modalContainer) {
      toggleModal();
    }
  };

  const [email, setEmail] = useState("");
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = () => {
    const validated = validateEmail(email);
    if (validated) {
      alert("email submitted");
      setEmail("");
      toggleModal();
    } else {
      alert("make sure your email is correct");
    }
  };

  return (
    <div
      onClick={(e) => handleWindowClick(e)}
      className={`custom_modal ${showModal ? "show_modal" : ""}`}
    >
      <div className="custom_modal_content">
        <div className="page_index">
          <div className="contents">
            <div className="titles">
              <p className="main_title">Get Insight</p>
              <p className="sub_title">Let's have your email</p>
            </div>
            <p className="center_text">Please what's your email address?</p>
            <label id="email">Email Address</label>
            {/* <input /> */}
            <input
              type="email"
              placeholder="Email Address"
              name="amount"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              // onChange={handleAmountChange}
            />
            <p className="validation_warning_text">
              This field cannot be empty
            </p>
          </div>
          {/* <form onSubmit={handleGetStarted} className="modal_form"> */}

          <button
            onClick={handleSubmit}
            className="custom_modal_continue_button"
          >
            Send Insight
          </button>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default GetInsightModal;
