import React from 'react';

export default class Loading extends React.Component {
    render() {
        return (
            <div className="loading-animation-container">
                <div className="loading-animation">
                    <div className="lds-roller">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        );
    }
}