import "../../assets/stylings/dealroom_styling.css";

import dealroomFormPicture from "../../../App/assets/images/dealroom_form_picture.png";
import propertyImage from "../../../User/assets/images/property_image.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Api from "../../../App/Components/Utils/Api";

const DealRoomMain = () => {
  //  fetchList(update) {
  //   if (!this.state.fetching) {
  //     if (!update) {
  //       this.setState({ fetching: true });
  //     }
  //     Api.fetch("opportunity.list", undefined, {
  //       type: this.state.type,
  //       amount: this.state.amount,
  //       return: this.state.return,
  //       tenor: this.state.tenor,
  //       search: this.state.term,
  //       offset: (this.state.page - 1) * this.state.limit,
  //       limit: this.state.limit,
  //     })
  //       .then(({ status, responseJSON }) => {
  //         this.setState({ fetching: false });
  //         if (status === 200) {
  //           this.setState({
  //             list: responseJSON.data || [],
  //             total: responseJSON.total,
  //           });
  //         }
  //       })
  //       .catch((reason) => {
  //         this.setState({ fetching: false });
  //         console.debug(reason);
  //       });
  //   }
  // }

  const history = useHistory();
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    Api.fetch("opportunity.list")
      .then((result) => {
        const data = result?.data?.data;
        if (data) {
          setProjectList(data);
        }
      })
      .catch((error) => {});
  }, []);

  const handleOnViewDocuments = (
    slug,
    companyName,
    companyDescription,
    projectTitle,
    documents,
    media
  ) => {
    localStorage.setItem(
      "more_infos",
      JSON.stringify({
        companyName: companyName,
        companyDescription: companyDescription,
        projectTitle: projectTitle,
        projectDocuments: documents,
        projectPictures: media,
      })
    );

    history.push(`/deal-room/${slug}`);
  };

  return (
    <div className="dealroom_container ">
      <div className="dealroom_upper_part">
        <div className="dealroom_propcrowdy_texts">
          <h3 className="intro_title">Welcome to PropCrowdy</h3>
          <h4 className="title">Deal Room</h4>
          <div className="description">
            <p>
              Our deal room provides you all the documentary evidence you need
              to know about an investment.
            </p>
            <p>
              As part of our due-diligence verification we compile all necessary
              documents associated with each opportunity on our platform.
            </p>
          </div>
        </div>
        <img src={dealroomFormPicture} alt="dealroom form" />
      </div>
      <div className="dealroom_lower_part">
        <h4 className="projects_title">Projects</h4>
        <ul className="project_listing_container">
          {projectList?.map((project) => {
            return (
              <li key={project.id} className="property">
                <img src={propertyImage} alt="property" />
                <div className="property_texts">
                  <h6 className="property_name">{project.title}</h6>
                  <p className="property_owner">{project.company.name}</p>
                  {/* <a
                    style={{ textDecoration: "none" }}
                    href={`/deal-room/${project.id}`}
                  > */}
                  <button
                    id="view_document_button"
                    onClick={() =>
                      handleOnViewDocuments(
                        project.slug,
                        project.company.name,
                        project.company.description,
                        project.title,
                        project.deal_document,
                        project.media
                      )
                    }
                    className="property_view_button"
                  >
                    View Documents
                  </button>
                  {/* </a> */}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DealRoomMain;
