import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Resource from './../Templates/Layouts/Resource';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';
import UI from './../../../App/Components/Utils/UI';

export default class EmailPhoneMarketingAdd extends React.Component {
    constructor(props) {
        super(props);
        EmailPhoneMarketingAdd.contextType = Context;
        this.state = {
            email: '',
            phoneNumber: '',
            type: '0',
            description: '',
            message: '',
            error: {
                email: [],
                phone_number: [],
                type: [],
                message: []
            },
            submitting: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Real Estate Email & Phone Database'});
    }

    reset() {
        this.setState({
            email: '',
            phoneNumber: '',
            type: '0',
            description: '',
            message: '',
            error: {
                email: [],
                phone_number: [],
                type: [],
                message: []
            },
        });
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if (typeof this.state.email !== 'string' || this.state.email.trim() === '') {
            error.email.push('Please provide your email address');
        } else if (!(typeof this.state.email === 'string' && this.state.email.match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi))) {
            error.email.push('Please provide a valid email');
        }

        if (typeof this.state.phoneNumber !== 'string' || this.state.phoneNumber.trim() === '') {
            error.phone_number.push('Please provide a phone number');
        } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
            error.phone_number.push('Please provide a valid phone number');
        }

        if (['sms', 'email', 'sms-email'].indexOf(this.state.type)) {
            error.type.push('Please select a type');
        }

        if (typeof this.state.description !== 'string' || this.state.description.trim() === '') {
            error.message.push('Please type your message');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        Api.fetch('marketing.add', undefined, {
            email: this.state.email,
            phone_number: this.state.phoneNumber,
            type: this.state.type,
            message: this.state.description
        }).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                this.reset();
                UI.alert({
                    title: 'Request Sent',
                    content: responseJSON.message || 'Your request for our Real Estate Email and Phone Database marketing quote has been received. We will send a quote to the provided email address shortly.'}, this.context);
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...this.state.error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <Resource heading="Email Phone Database" title="Real Estate Email & Phone Database">
                <main id="main" className="resource email-phone-marketing add">
                    <form className="steps" onSubmit={(e) => {
                        e.preventDefault();
                        this.submit();
                    }}>
                        {this.state.submitting && <Loading />}
                        <img src="/assets/images/vectors/icons/offer.svg" alt="Offer" className="icon" />
                        <div className="title">Real Estate Email & Phone Database</div>
                        <div className="message">{this.state.message}</div>
                        <div className="step">
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="field">
                                            <label htmlFor="email-phone-marketing-email">Email *</label>
                                            <input id="email-phone-marketing-email" type="email" name="email" value={this.state.email} placeholder="Email" className="form-control" onChange={(e) => {this.setState({email: e.target.value})}} required />
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="field">
                                            <label htmlFor="email-phone-marketing-phone-number">Phone Number *</label>
                                            <input id="email-phone-marketing-phone-number" type="text" name="phone_number" value={this.state.phoneNumber} placeholder="Phone Number" className="form-control" onChange={(e) => {this.setState({phoneNumber: e.target.value})}} required />
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.phone_number.join('<br />')}} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="field">
                                            <label htmlFor="email-phone-marketing-type">Type *</label>
                                            <select id="mortgage-calculator-type" name="type" value={this.state.type} placeholder="Type" className="form-control" onChange={(e) => {this.setState({type: e.target.value})}} required>
                                                <option value="0" disabled>Type</option>
                                                <option value="sms">SMS</option>
                                                <option value="email">Email</option>
                                                <option value="sms-email">SMS & Email</option>
                                            </select>
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.type.join('<br />')}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="field">
                                            <label htmlFor="email-phone-marketing-message">Message *</label>
                                            <textarea id="email-phone-marketing-message" name="message" value={this.state.description} placeholder="Message" className="form-control" onChange={(e) => {this.setState({description: e.target.value})}} />
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.message.join('<br />')}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit">
                                    <button type="submit">Send Me A Quote</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
            </Resource>
        );
    }
}
