import "./../../../assets/style.css";
import React from "react";
import { Context } from "./../../../../App/Components/Context";
import Loading from "./../../../../App/Components/Templates/Loading";
import { PaystackButton } from "react-paystack";
import $ from "jquery";
import Api from "./../../../../App/Components/Utils/Api";
import UI from "./../../../../App/Components/Utils/UI";
import Hook from "./../../Utils/Hook";
import { RavePaymentButton, RaveProvider } from "react-ravepayment";
import Util from "./../../Utils";
import { withRouter } from "react-router-dom";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    Payment.contextType = Context;
    this.state = {
      loading: false,
      paymentModalAmount: "",
    };
  }

  closePaymentModal() {
    this.context.updateContext("paymentModalType", undefined);
    this.context.updateContext("paymentModalTypeId", undefined);
    this.context.updateContext("paymentModalScheduleId", null);
    this.context.updateContext("paymentModalTypeTitle", undefined);
    this.context.updateContext("paymentModalTitle", undefined);
    this.context.updateContext("paymentModalAmount", undefined);
    this.context.updateContext("paymentModalData", undefined);
    this.context.updateContext("paymentModalMethods", undefined);
    $("#payment-modal").modal("hide");
  }

  render() {
    return (
      <div id="payment-modal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {this.state.loading && <Loading />}
            <button
              type="button"
              className="close-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="heading">{this.context.paymentModalTypeTitle}</div>
            {this.context.paymentModalAmount ? (
              <>
                <div className="title">{this.context.paymentModalTitle}</div>
                <div className="amount">
                  ₦
                  {parseInt(this.context.paymentModalAmount || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
                <div className="methods">
                  {this.context.settings.payment_options &&
                  this.context.settings.payment_options.transfer &&
                  this.context.settings.payment_options.transfer === 1 &&
                  (this.context.paymentModalMethods || ["wallet"]).indexOf(
                    "bank"
                  ) !== -1 ? (
                    <div className="method bank">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          this.context.updateContext(
                            "paymentProofModalType",
                            this.context.paymentModalType
                          );
                          this.context.updateContext(
                            "paymentProofModalTypeId",
                            this.context.paymentModalTypeId
                          );
                          this.context.updateContext(
                            "paymentProofModalScheduleId",
                            this.context.paymentModalScheduleId
                          );
                          this.context.updateContext(
                            "paymentProofModalTypeTitle",
                            this.context.paymentModalTypeTitle
                          );
                          this.context.updateContext(
                            "paymentProofModalTitle",
                            this.context.paymentModalTitle
                          );
                          this.context.updateContext(
                            "paymentProofModalAmount",
                            this.context.paymentModalAmount
                          );
                          this.context.updateContext(
                            "paymentProofModalData",
                            this.context.paymentModalData
                          );
                          $("#payment-proof-modal").modal("show");
                          this.closePaymentModal();
                        }}
                      >
                        <img
                          src="/assets/images/bank.png"
                          alt="Rave"
                          className="icon"
                        />
                        <span className="title">
                          Bank Transfer <sub>Recommended</sub>
                          <span style={{display:'block', fontSize:'0.9rem', color:'red'}}>Payment Confirmation is within 12hours </span>
                        </span>
                      </button>
                    </div>
                  ) : null}
                  {this.context.settings.payment_options &&
                  this.context.settings.payment_options.wallet &&
                  this.context.settings.payment_options.wallet === 1 &&
                  (this.context.paymentModalMethods || ["wallet"]).indexOf(
                    "wallet"
                  ) !== -1 ? (
                    <div className="method wallet">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          let type, typeId, data;
                          try {
                            type = this.context.paymentModalType;
                            typeId = this.context.paymentModalTypeId;
                            if (
                              this.context.paymentModalData &&
                              this.context.paymentModalData.constructor.name ===
                                "Array"
                            ) {
                              data = [...this.context.paymentModalData];
                            } else if (
                              this.context.paymentModalData &&
                              this.context.paymentModalData.constructor.name ===
                                "Object"
                            ) {
                              data = { ...this.context.paymentModalData };
                            } else {
                              data = this.context.paymentModalData;
                            }
                          } catch (e) {}
                          this.setState({ loading: true });
                          Api.fetch("wallet.pay", undefined, {
                            amount: parseInt(this.context.paymentModalAmount),
                            reference:
                              "PC-" +
                              this.context.paymentModalType +
                              "-" +
                              this.context.paymentModalTypeId +
                              "-" +
                              (this.context.user ? this.context.user.id : 0) +
                              "-" +
                              new Date().getTime(),
                            type: this.context.paymentModalType,
                            type_id: this.context.paymentModalTypeId,
                            schedule_id: this.context.paymentModalScheduleId,
                            user_id: this.context.user
                              ? this.context.user.id
                              : 0,
                          })
                            .then(({ status, responseJSON }) => {
                              this.setState({ loading: false });
                              if (status === 200) {
                                if (responseJSON.status) {
                                  Api.fetch(
                                    "payment.callback.wallet",
                                    undefined,
                                    {
                                      reference:
                                        "PC-" +
                                        this.context.paymentModalType +
                                        "-" +
                                        this.context.paymentModalTypeId +
                                        "-" +
                                        (this.context.user
                                          ? this.context.user.id
                                          : 0) +
                                        "-" +
                                        new Date().getTime(),
                                      type: this.context.paymentModalType,
                                      type_id: this.context.paymentModalTypeId,
                                      schedule_id:
                                        this.context.paymentModalScheduleId,
                                      user_id: this.context.user
                                        ? this.context.user.id
                                        : 0,
                                    }
                                  )
                                    .then(() => {
                                      Hook.fire("payment", undefined, [
                                        type,
                                        typeId,
                                        data,
                                      ]);
                                    })
                                    .catch((reason) => {
                                      Hook.fire("payment", undefined, [
                                        type,
                                        typeId,
                                        data,
                                      ]);
                                      // console.debug(reason);
                                    });
                                  this.closePaymentModal();
                                  UI.alert(
                                    {
                                      title: "Payment Completed",
                                      content:
                                        "Your payment has been processed successfully",
                                      buttons: [
                                        {
                                          type: "positive",
                                          title: "View Transactions",
                                          onClick: () => {
                                            this.props.history.push({
                                              pathname: "/wallet/transactions",
                                            });
                                          },
                                        },
                                      ],
                                    },
                                    this.context
                                  );
                                } else {
                                  UI.alert(
                                    {
                                      type: "error",
                                      title: "Payment not completed",
                                      content: responseJSON.message,
                                    },
                                    this.context
                                  );
                                }
                              }
                            })
                            .catch((reason) => {
                              this.setState({ loading: false });
                              let { responseJSON } =
                                typeof reason === "object" ? reason : {};
                              UI.alert(
                                {
                                  type: "error",
                                  title: "Error Occurred",
                                  content:
                                    (responseJSON && responseJSON.message) ||
                                    "Payment was not completed! Please check the internet and try again",
                                },
                                this.context
                              );
                              // console.debug(reason);
                            });
                        }}
                      >
                        <img
                          src="/assets/images/wallet.png"
                          alt="Rave"
                          className="icon"
                        />
                        <span className="title">Pay with Wallet</span>
                      </button>
                    </div>
                  ) : null}
                  {this.context.settings.payment_options &&
                  this.context.settings.payment_options.paystack &&
                  this.context.settings.payment_options.paystack === 1 &&
                  (this.context.paymentModalMethods || ["wallet"]).indexOf(
                    "paystack"
                  ) !== -1 ? (
                    <div className="method paystack">
                      <PaystackButton
                        {...{
                          reference:
                            "PC-" +
                            this.context.paymentModalType +
                            "-" +
                            this.context.paymentModalTypeId +
                            "-" +
                            (this.context.user ? this.context.user.id : 0) +
                            "-" +
                            new Date().getTime(),
                          email: this.context.user
                            ? this.context.user.email
                            : "",
                          amount: parseInt(
                            parseFloat(this.context.paymentModalAmount) * 100
                          ),
                          publicKey:
                            this.context.settings &&
                            this.context.settings.paystack &&
                            this.context.settings.paystack.public_key,
                          channels: ["card", "bank", "ussd", "qr"],
                          metadata: {
                            custom_fields: [
                              {
                                display_name: "Type",
                                variable_name: "type",
                                value: this.context.paymentModalType,
                              },
                              {
                                display_name: "Type ID",
                                variable_name: "type_id",
                                value: this.context.paymentModalTypeId,
                              },
                              {
                                display_name: "Schedule ID",
                                variable_name: "schedule_id",
                                value: this.context.paymentModalScheduleId,
                              },
                              {
                                display_name: "User ID",
                                variable_name: "user_id",
                                value: this.context.user
                                  ? this.context.user.id
                                  : 0,
                              },
                            ],
                          },
                          onSuccess: (reference) => {
                            let type, typeId, data;
                            try {
                              type = this.context.paymentModalType;
                              typeId = this.context.paymentModalTypeId;
                              if (
                                this.context.paymentModalData &&
                                this.context.paymentModalData.constructor
                                  .name === "Array"
                              ) {
                                data = [...this.context.paymentModalData];
                              } else if (
                                this.context.paymentModalData &&
                                this.context.paymentModalData.constructor
                                  .name === "Object"
                              ) {
                                data = { ...this.context.paymentModalData };
                              } else {
                                data = this.context.paymentModalData;
                              }
                            } catch (e) {}
                            Api.fetch("payment.callback.paystack", undefined, {
                              reference: reference.reference,
                              type: this.context.paymentModalType,
                              type_id: this.context.paymentModalTypeId,
                              schedule_id: this.context.paymentModalScheduleId,
                              user_id: this.context.user
                                ? this.context.user.id
                                : 0,
                            })
                              .then(() => {
                                Hook.fire("payment", undefined, [
                                  type,
                                  typeId,
                                  data,
                                ]);
                              })
                              .catch((reason) => {
                                Hook.fire("payment", undefined, [
                                  type,
                                  typeId,
                                  data,
                                ]);
                                // console.debug(reason);
                              });
                            this.closePaymentModal();
                            UI.alert(
                              {
                                title: "Payment Completed",
                                content:
                                  "Your payment is complete. You will be notified once it's confirmed",
                              },
                              this.context
                            );
                          },
                        }}
                      >
                        <img
                          src="/assets/images/paystack.png"
                          alt="Paystack"
                          className="icon"
                        />
                        <span className="title">Pay with Paystack</span>
                      </PaystackButton>
                    </div>
                  ) : null}
                  {this.context.settings.payment_options &&
                  this.context.settings.payment_options.rave &&
                  this.context.settings.payment_options.rave === 1 &&
                  (this.context.paymentModalMethods || ["wallet"]).indexOf(
                    "rave"
                  ) !== -1 ? (
                    <div className="method rave">
                      <RaveProvider
                        {...{
                          txref:
                            "PC-" +
                            this.context.paymentModalType +
                            "-" +
                            this.context.paymentModalTypeId +
                            "-" +
                            (this.context.user ? this.context.user.id : 0) +
                            "-" +
                            new Date().getTime(),
                          customer_email: this.context.user
                            ? this.context.user.email
                            : "",
                          customer_phone: this.context.user
                            ? this.context.user.phone_number
                            : 0,
                          custom_logo:
                            Util.baseUrl + "/assets/images/paystack.png",
                          amount: parseInt(this.context.paymentModalAmount),
                          PBFPubKey:
                            this.context.settings &&
                            this.context.settings.rave &&
                            this.context.settings.rave.public_key,
                          production: !(
                            !process.env.NODE_ENV ||
                            process.env.NODE_ENV === "development"
                          ),
                          metadata: [
                            {
                              metaname: "type",
                              metavalue: this.context.paymentModalType,
                            },
                            {
                              metaname: "type_id",
                              metavalue: this.context.paymentModalTypeId,
                            },
                            {
                              metaname: "schedule_id",
                              metavalue: this.context.paymentModalScheduleId,
                            },
                            {
                              metaname: "user_id",
                              metavalue: this.context.user
                                ? this.context.user.id
                                : 0,
                            },
                          ],
                          onSuccess: (reference) => {
                            let type, typeId, data;
                            try {
                              type = this.context.paymentModalType;
                              typeId = this.context.paymentModalTypeId;
                              if (
                                this.context.paymentModalData &&
                                this.context.paymentModalData.constructor
                                  .name === "Array"
                              ) {
                                data = [...this.context.paymentModalData];
                              } else if (
                                this.context.paymentModalData &&
                                this.context.paymentModalData.constructor
                                  .name === "Object"
                              ) {
                                data = { ...this.context.paymentModalData };
                              } else {
                                data = this.context.paymentModalData;
                              }
                            } catch (e) {}
                            Api.fetch("payment.callback.rave", undefined, {
                              reference: reference,
                              type: this.context.paymentModalType,
                              type_id: this.context.paymentModalTypeId,
                              schedule_id: this.context.paymentModalScheduleId,
                              user_id: this.context.user
                                ? this.context.user.id
                                : 0,
                            })
                              .then(() => {
                                Hook.fire("payment", undefined, [
                                  type,
                                  typeId,
                                  data,
                                ]);
                              })
                              .catch((reason) => {
                                Hook.fire("payment", undefined, [
                                  type,
                                  typeId,
                                  data,
                                ]);
                                // console.debug(reason);
                              });
                            this.closePaymentModal();
                            UI.alert(
                              {
                                title: "Payment Completed",
                                content:
                                  "Your payment is complete. You will be d notified once it's confirmed",
                              },
                              this.context
                            );
                          },
                        }}
                      >
                        <RavePaymentButton>
                          <img
                            src="/assets/images/rave.png"
                            alt="Rave"
                            className="icon"
                          />
                          <span className="title">Pay with Rave</span>
                        </RavePaymentButton>
                      </RaveProvider>
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.context.updateContext(
                    "paymentModalAmount",
                    this.state.paymentModalAmount
                  );
                }}
              >
                <div className="amount">
                  <input
                    type="number"
                    name="amount"
                    value={this.state.paymentModalAmount}
                    min="10"
                    step=".01"
                    placeholder="Enter Amount"
                    className="form-control input"
                    onChange={(e) => {
                      this.setState({ paymentModalAmount: e.target.value });
                    }}
                    required
                  />
                  <i className="icon las la-naira primary" />
                </div>
                <div className="submit">
                  <input type="submit" value="OK" />
                </div>
              </form>
            )}
            <div className="gateway-warning">
              There is a transaction fee for online gateways
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Payment);
