import React from "react";
import { Link } from "react-router-dom";
import { Context } from "./../Context";
import Loading from "./../Templates/Loading";
import Util from "./../../../App/Components/Utils";
import Document from "./../Utils/Document";
import Api from "./../Utils/Api";

export default class Knowledge extends React.Component {
  constructor(props) {
    super(props);
    Knowledge.contextType = Context;
    let tabSegment =
      window.location.pathname.split("/")[1] === "buy"
        ? window.location.pathname.split("/")[3]
        : window.location.pathname.split("/")[2];
    this.state = {
      tab:
        ["developer", "investor", "buyer"].indexOf(tabSegment) !== -1
          ? tabSegment
          : window.location.pathname.split("/")[1] === "buy"
          ? "buyer"
          : "investor",
      section: (
        window.location.pathname.split("/")[1] === "buy"
          ? window.location.pathname.split("/")[4]
          : window.location.pathname.split("/")[3]
      )
        ? "content"
        : "list",
      investorKnowledgeList: [],
      investorKnowledgeListIndex: 0,
      fetchingInvestorKnowledgeList: false,
      developerKnowledgeList: [],
      developerKnowledgeListIndex: 0,
      fetchingDeveloperKnowledgeList: false,
      buyerKnowledgeList: [],
      buyerKnowledgeListIndex: 0,
      fetchingBuyerKnowledgeList: false,
    };
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      window.location.pathname.split("/")[1] === "buy"
        ? "default.buy"
        : "default"
    );
    Document.updateDocumentHead({
      title:
        (window.location.pathname.split("/")[1] === "buy"
          ? "PropBuy"
          : "PropCrowdy") + " - Knowledge Base",
    });
    this.fetchInvestorKnowledgeList();
    this.fetchDeveloperKnowledgeList();
    this.fetchBuyerKnowledgeList();
  }

  fetchInvestorKnowledgeList() {
    this.setState({ fetchingInvestorKnowledgeList: true });
    Api.fetch("knowledge.list", undefined, {
      investor: 1,
      type: "investor",
      platform:
        window.location.pathname.split("/")[1] === "buy"
          ? "propbuy"
          : "propcrowdy",
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingInvestorKnowledgeList: false });
        if (status === 200) {
          this.setState(
            { investorKnowledgeList: responseJSON.data || [] },
            () => {
              if (this.state.investorKnowledgeList[0]) {
                let list = this.state.investorKnowledgeList;
                for (let i in list) {
                  if (
                    list.hasOwnProperty(i) &&
                    list[i].slug ===
                      (window.location.pathname.split("/")[1] === "buy"
                        ? window.location.pathname.split("/")[4]
                        : window.location.pathname.split("/")[3])
                  ) {
                    this.setState({ investorKnowledgeListIndex: i });
                    this.fetchInvestorKnowledge(
                      window.location.pathname.split("/")[1] === "buy"
                        ? window.location.pathname.split("/")[4]
                        : window.location.pathname.split("/")[3]
                    );
                    return;
                  }
                }
                if (this.state.investorKnowledgeList[0]) {
                  this.setState({ investorKnowledgeListIndex: 0 });
                  this.fetchInvestorKnowledge(
                    this.state.investorKnowledgeList[0].slug
                  );
                }
              }
            }
          );
        }
      })
      .catch((reason) => {
        this.setState({ fetchingInvestorKnowledgeList: false });
        console.debug(reason);
      });
  }

  fetchDeveloperKnowledgeList() {
    this.setState({ fetchingDeveloperKnowledgeList: true });
    Api.fetch("knowledge.list", undefined, {
      developer: 1,
      type: "developer",
      platform:
        window.location.pathname.split("/")[1] === "buy"
          ? "propbuy"
          : "propcrowdy",
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingDeveloperKnowledgeList: false });
        if (status === 200) {
          this.setState(
            { developerKnowledgeList: responseJSON.data || [] },
            () => {
              let list = this.state.developerKnowledgeList;
              for (let i in list) {
                if (
                  list.hasOwnProperty(i) &&
                  list[i].slug ===
                    (window.location.pathname.split("/")[1] === "buy"
                      ? window.location.pathname.split("/")[4]
                      : window.location.pathname.split("/")[3])
                ) {
                  this.setState({ developerKnowledgeListIndex: i });
                  this.fetchDeveloperKnowledge(
                    window.location.pathname.split("/")[1] === "buy"
                      ? window.location.pathname.split("/")[4]
                      : window.location.pathname.split("/")[3]
                  );
                  return;
                }
              }
              if (this.state.developerKnowledgeList[0]) {
                this.setState({ developerKnowledgeListIndex: 0 });
                this.fetchDeveloperKnowledge(
                  this.state.developerKnowledgeList[0].slug
                );
              }
            }
          );
        }
      })
      .catch((reason) => {
        this.setState({ fetchingDeveloperKnowledgeList: false });
        console.debug(reason);
      });
  }

  fetchBuyerKnowledgeList() {
    this.setState({ fetchingBuyerKnowledgeList: true });
    Api.fetch("knowledge.list", undefined, {
      probuyer: 1,
      type: "propbuyer",
      platform:
        window.location.pathname.split("/")[1] === "buy"
          ? "propbuy"
          : "propcrowdy",
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingBuyerKnowledgeList: false });
        if (status === 200) {
          this.setState({ buyerKnowledgeList: responseJSON.data || [] }, () => {
            let list = this.state.buyerKnowledgeList;
            for (let i in list) {
              if (
                list.hasOwnProperty(i) &&
                list[i].slug ===
                  (window.location.pathname.split("/")[1] === "buy"
                    ? window.location.pathname.split("/")[4]
                    : window.location.pathname.split("/")[3])
              ) {
                this.setState({ buyerKnowledgeListIndex: i });
                this.fetchBuyerKnowledge(
                  window.location.pathname.split("/")[1] === "buy"
                    ? window.location.pathname.split("/")[4]
                    : window.location.pathname.split("/")[3]
                );
                return;
              }
            }
            if (this.state.buyerKnowledgeList[0]) {
              this.setState({ buyerKnowledgeListIndex: 0 });
              this.fetchBuyerKnowledge(this.state.buyerKnowledgeList[0].slug);
            }
          });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingBuyerKnowledgeList: false });
        console.debug(reason);
      });
  }

  fetchInvestorKnowledge(slug) {
    let list = this.state.investorKnowledgeList;
    let knowledge;
    for (let i in list) {
      if (list.hasOwnProperty(i) && list[i].slug === slug) {
        knowledge = list[i];
        break;
      }
    }
    if (knowledge) {
      this.setState({ investorKnowledgeList: list });
      Api.fetch("knowledge.view", undefined, { slug: slug })
        .then(({ status, responseJSON }) => {
          if (status === 200) {
            knowledge = responseJSON.data || knowledge;
            this.setState({ investorKnowledgeList: list });
          }
        })
        .catch((reason) => {
          console.debug(reason);
        });
    }
  }

  fetchDeveloperKnowledge(slug) {
    let list = this.state.developerKnowledgeList;
    let knowledge;
    for (let i in list) {
      if (list.hasOwnProperty(i) && list[i].slug === slug) {
        knowledge = list[i];
        break;
      }
    }
    if (knowledge) {
      this.setState({ developerKnowledgeList: list });
      Api.fetch("knowledge.view", undefined, { slug: slug })
        .then(({ status, responseJSON }) => {
          if (status === 200) {
            knowledge = responseJSON.data || knowledge;
            this.setState({ developerKnowledgeList: list });
          }
        })
        .catch((reason) => {
          console.debug(reason);
        });
    }
  }

  fetchBuyerKnowledge(slug) {
    let list = this.state.buyerKnowledgeList;
    let knowledge;
    for (let i in list) {
      if (list.hasOwnProperty(i) && list[i].slug === slug) {
        knowledge = list[i];
        break;
      }
    }
    if (knowledge) {
      this.setState({ buyerKnowledgeList: list });
      Api.fetch("knowledge.view", undefined, { slug: slug })
        .then(({ status, responseJSON }) => {
          if (status === 200) {
            knowledge = responseJSON.data || knowledge;
            this.setState({ buyerKnowledgeList: list });
          }
        })
        .catch((reason) => {
          console.debug(reason);
        });
    }
  }

  render() {
    return (
      <main id="main" className="p-0">
        <section id="top-cover">
          <div className="container">
            <div className="title">Knowledge Base</div>
            {window.location.pathname.split("/")[1] === "buy" ? (
              <div className="description">
                Learn how to use our investment platform
              </div>
            ) : (
              <div className="description">
                Learn how to use our investment platform
              </div>
            )}
            <div className="links">
              <Link
                to={{
                  pathname:
                    (window.location.pathname.split("/")[1] === "buy"
                      ? "/buy"
                      : "") + "/terms",
                }}
                className="link"
              >
                View Terms & Conditions
              </Link>
            </div>
          </div>
        </section>
        <section id="knowledge-base" className={this.state.section}>
          <div className="container">
            {window.location.pathname.split("/")[1] !== "buy" ? (
              <div className="tabs">
                <Link
                  to={{
                    pathname:
                      (window.location.pathname.split("/")[1] === "buy"
                        ? "/buy"
                        : "") +
                      (this.state.investorKnowledgeList[
                        this.state.investorKnowledgeListIndex
                      ]
                        ? "/knowledge/investor/" +
                          this.state.investorKnowledgeList[
                            this.state.investorKnowledgeListIndex
                          ].slug
                        : "/knowledge/investor"),
                    state: this.state,
                  }}
                  className={
                    "tab" + (this.state.tab === "investor" ? " active" : "")
                  }
                  onClick={() => {
                    this.setState({ tab: "investor" });
                  }}
                >
                  For Investors
                </Link>
                <Link
                  to={{
                    pathname:
                      (window.location.pathname.split("/")[1] === "buy"
                        ? "/buy"
                        : "") +
                      (this.state.developerKnowledgeList[
                        this.state.developerKnowledgeListIndex
                      ]
                        ? "/knowledge/developer/" +
                          this.state.developerKnowledgeList[
                            this.state.developerKnowledgeListIndex
                          ].slug
                        : "/knowledge/developer"),
                    state: this.state,
                  }}
                  className={
                    "tab" + (this.state.tab === "developer" ? " active" : "")
                  }
                  onClick={() => {
                    this.setState({ tab: "developer" });
                  }}
                >
                  For Property Developers
                </Link>
                {/*<Link to={{pathname: (window.location.pathname.split('/')[1] === 'buy' ? '/buy' : '') + (this.state.buyerKnowledgeList[this.state.buyerKnowledgeListIndex] ? ('/knowledge/buyer/' + this.state.buyerKnowledgeList[this.state.buyerKnowledgeListIndex].slug) : '/knowledge/buyer'), state: this.state}} className={'tab' + (this.state.tab === 'buyer' ? ' active' : '')} onClick={() => {this.setState({tab: 'buyer'})}}>For Property Buyers</Link>*/}
              </div>
            ) : null}
            <div className="section-nav">
              <Link
                to={{
                  pathname:
                    window.location.pathname.split("/")[1] === "buy"
                      ? "/buy/knowledge/" +
                        window.location.pathname.split("/")[3]
                      : "/knowledge/" + window.location.pathname.split("/")[2],
                  state: this.state,
                }}
                className="close"
                onClick={() => {
                  this.setState({ section: "list" });
                }}
              >
                <i className="icon las la-angle-left" />
                Go Back to List
              </Link>
            </div>
            {this.state.tab === "investor" && (
              <div className={"tab-content " + this.state.section}>
                <div className="nav-container">
                  <div className="links nav active-switch propagate row">
                    {this.state.investorKnowledgeList.map(
                      (knowledge, index) => (
                        <div key={index} className="col-lg-3">
                          <div
                            className={
                              "nav-item" +
                              ((!(window.location.pathname.split("/")[1] ===
                              "buy"
                                ? window.location.pathname.split("/")[4]
                                : window.location.pathname.split("/")[3]) &&
                                !index) ||
                              decodeURIComponent(
                                window.location.pathname.split("/")[1] === "buy"
                                  ? window.location.pathname.split("/")[4]
                                  : window.location.pathname.split("/")[3]
                              ).replace(/[^A-Za-z0-9]+/gi, "-") ===
                                knowledge.slug.replace(/[^A-Za-z0-9]+/gi, "-")
                                ? " active"
                                : "")
                            }
                            onClick={() => {
                              this.setState({
                                section: "content",
                                investorKnowledgeListIndex: index,
                              });
                              this.fetchinvestorKnowledge(knowledge.slug);
                            }}
                          >
                            <Link
                              to={{
                                pathname:
                                  (window.location.pathname.split("/")[1] ===
                                  "buy"
                                    ? "/buy"
                                    : "") +
                                  "/knowledge/investor/" +
                                  knowledge.slug,
                                state: this.state,
                              }}
                              className="image"
                            >
                              <img
                                src={
                                  knowledge.cover_image
                                    ? knowledge.cover_image.url
                                    : "/assets/images/image-placeholder.png"
                                }
                                alt={knowledge.title}
                              />
                            </Link>
                            <Link
                              to={{
                                pathname:
                                  (window.location.pathname.split("/")[1] ===
                                  "buy"
                                    ? "/buy"
                                    : "") +
                                  "/knowledge/investor/" +
                                  knowledge.slug,
                                state: this.state,
                              }}
                              className="title"
                            >
                              {knowledge.title}
                            </Link>
                            <div className="description">
                              {Util.decodeHTMLEntities(
                                knowledge.description.replace(/<\/?.*?>/gi, "")
                              ).substring(0, 100) +
                                (Util.decodeHTMLEntities(
                                  knowledge.description.replace(
                                    /<\/?.*?>/gi,
                                    ""
                                  )
                                ).length > 100
                                  ? "..."
                                  : "")}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {this.state.fetchinginvestorKnowledgeList && <Loading />}
                <div className="heading">
                  {this.state.investorKnowledgeList[
                    this.state.investorKnowledgeListIndex
                  ] &&
                    this.state.investorKnowledgeList[
                      this.state.investorKnowledgeListIndex
                    ].title}
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.investorKnowledgeList[
                        this.state.investorKnowledgeListIndex
                      ] &&
                      this.state.investorKnowledgeList[
                        this.state.investorKnowledgeListIndex
                      ].description,
                  }}
                />
              </div>
            )}
            {this.state.tab === "developer" && (
              <div className={"tab-content " + this.state.section}>
                <div className="nav-container">
                  <div className="links nav active-switch propagate row">
                    {this.state.developerKnowledgeList.map(
                      (knowledge, index) => (
                        <div key={index} className="col-lg-3">
                          <div
                            className={
                              "nav-item" +
                              ((!(window.location.pathname.split("/")[1] ===
                              "buy"
                                ? window.location.pathname.split("/")[4]
                                : window.location.pathname.split("/")[3]) &&
                                !index) ||
                              decodeURIComponent(
                                window.location.pathname.split("/")[1] === "buy"
                                  ? window.location.pathname.split("/")[4]
                                  : window.location.pathname.split("/")[3]
                              ).replace(/[^A-Za-z0-9]+/gi, "-") ===
                                knowledge.slug.replace(/[^A-Za-z0-9]+/gi, "-")
                                ? " active"
                                : "")
                            }
                            onClick={() => {
                              this.setState({
                                section: "content",
                                developerKnowledgeListIndex: index,
                              });
                              this.fetchDeveloperKnowledge(knowledge.slug);
                            }}
                          >
                            <Link
                              to={{
                                pathname:
                                  (window.location.pathname.split("/")[1] ===
                                  "buy"
                                    ? "/buy"
                                    : "") +
                                  "/knowledge/developer/" +
                                  knowledge.slug,
                                state: this.state,
                              }}
                              className="image"
                            >
                              <img
                                src={
                                  knowledge.cover_image
                                    ? knowledge.cover_image.url
                                    : "/assets/images/image-placeholder.png"
                                }
                                alt={knowledge.title}
                              />
                            </Link>
                            <Link
                              to={{
                                pathname:
                                  (window.location.pathname.split("/")[1] ===
                                  "buy"
                                    ? "/buy"
                                    : "") +
                                  "/knowledge/developer/" +
                                  knowledge.slug,
                                state: this.state,
                              }}
                              className="title"
                            >
                              {knowledge.title}
                            </Link>
                            <div className="description">
                              {Util.decodeHTMLEntities(
                                knowledge.description.replace(/<\/?.*?>/gi, "")
                              ).substring(0, 100) +
                                (Util.decodeHTMLEntities(
                                  knowledge.description.replace(
                                    /<\/?.*?>/gi,
                                    ""
                                  )
                                ).length > 100
                                  ? "..."
                                  : "")}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {this.state.fetchingDeveloperKnowledgeList && <Loading />}
                <div className="heading">
                  {this.state.developerKnowledgeList[
                    this.state.developerKnowledgeListIndex
                  ] &&
                    this.state.developerKnowledgeList[
                      this.state.developerKnowledgeListIndex
                    ].title}
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.developerKnowledgeList[
                        this.state.developerKnowledgeListIndex
                      ] &&
                      this.state.developerKnowledgeList[
                        this.state.developerKnowledgeListIndex
                      ].description,
                  }}
                />
              </div>
            )}
            {this.state.tab === "buyer" && (
              <div className={"tab-content " + this.state.section}>
                <div className="nav-container">
                  <div className="links nav active-switch propagate row">
                    {this.state.buyerKnowledgeList.map((knowledge, index) => (
                      <div key={index} className="col-lg-3">
                        <div
                          className={
                            "nav-item" +
                            ((!(window.location.pathname.split("/")[1] === "buy"
                              ? window.location.pathname.split("/")[4]
                              : window.location.pathname.split("/")[3]) &&
                              !index) ||
                            decodeURIComponent(
                              window.location.pathname.split("/")[1] === "buy"
                                ? window.location.pathname.split("/")[4]
                                : window.location.pathname.split("/")[3]
                            ).replace(/[^A-Za-z0-9]+/gi, "-") ===
                              knowledge.slug.replace(/[^A-Za-z0-9]+/gi, "-")
                              ? " active"
                              : "")
                          }
                          onClick={() => {
                            this.setState({
                              section: "content",
                              buyerKnowledgeListIndex: index,
                            });
                            this.fetchBuyerKnowledge(knowledge.slug);
                          }}
                        >
                          <Link
                            to={{
                              pathname:
                                (window.location.pathname.split("/")[1] ===
                                "buy"
                                  ? "/buy"
                                  : "") +
                                "/knowledge/buyer/" +
                                knowledge.slug,
                              state: this.state,
                            }}
                            className="image"
                          >
                            <img
                              src={
                                knowledge.cover_image
                                  ? knowledge.cover_image.url
                                  : "/assets/images/image-placeholder.png"
                              }
                              alt={knowledge.title}
                            />
                          </Link>
                          <Link
                            to={{
                              pathname:
                                (window.location.pathname.split("/")[1] ===
                                "buy"
                                  ? "/buy"
                                  : "") +
                                "/knowledge/buyer/" +
                                knowledge.slug,
                              state: this.state,
                            }}
                            className="title"
                          >
                            {knowledge.title}
                          </Link>
                          <div className="description">
                            {Util.decodeHTMLEntities(
                              knowledge.description.replace(/<\/?.*?>/gi, "")
                            ).substring(0, 100) +
                              (Util.decodeHTMLEntities(
                                knowledge.description.replace(/<\/?.*?>/gi, "")
                              ).length > 100
                                ? "..."
                                : "")}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {this.state.fetchingBuyerKnowledgeList && <Loading />}
                <div className="heading">
                  {this.state.buyerKnowledgeList[
                    this.state.buyerKnowledgeListIndex
                  ] &&
                    this.state.buyerKnowledgeList[
                      this.state.buyerKnowledgeListIndex
                    ].title}
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.buyerKnowledgeList[
                        this.state.buyerKnowledgeListIndex
                      ] &&
                      this.state.buyerKnowledgeList[
                        this.state.buyerKnowledgeListIndex
                      ].description,
                  }}
                />
              </div>
            )}
            {!this.state.fetchingInvestorKnowledgeList && (
              <div className="section-nav">
                <Link
                  to={{
                    pathname:
                      window.location.pathname.split("/")[1] === "buy"
                        ? "/buy/knowledge/" +
                          window.location.pathname.split("/")[3]
                        : "/knowledge/" +
                          window.location.pathname.split("/")[2],
                    state: this.state,
                  }}
                  className="close"
                  onClick={() => {
                    this.setState({ section: "list" });
                  }}
                >
                  <i className="icon las la-angle-left" />
                  Go Back to List
                </Link>
              </div>
            )}
          </div>
        </section>
      </main>
    );
  }
}
