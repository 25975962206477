import React from "react";
import "../../assets/styling/zero_carbon_styles.css";
import zeroEmission from "../../../App/assets/images/vectors/zero_emission_icon.svg";
import playButton from "../../../App/assets/images/vectors/icons/playButtonDownloaded.svg";
import zeroEmissionGraph from "../../../App/assets/images/vectors/zero_emission_graph.svg";
import { Link } from "react-router-dom";
import GetInsightModal from "../miscellaneous-components/GetInsightModal";
import { useState } from "react";

const ZeroCarbon = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="overall_container">
      <section className="section_container">
        <div className="texts_part">
          <p className="initializing_text">
            Sustainability is the core value that drives us at PropCrowdy
          </p>
          <h1 className="main_title">
            Working with you to create a carbon-free future for everyone.
          </h1>
          <p className="sub_title">
            Combating climate challenge involves a mix of complex policy,
            PropCrowdy uses technology to shape the manner and ways real estate
            developers build, by promoting green and carbon policies for every
            developer funded on the portal. PropCrowdy is committed to doing its
            part.
          </p>
        </div>
        <div className="image_part">
          <img src={zeroEmission} alt="" className="zero_emission_image" />
        </div>
      </section>
      <section className="section_container reversed">
        <div className="texts_part" style={{ maxWidth: "500px" }}>
          <h3 className="minor_title">Net-Zero With Real Estate</h3>
          <p className="sub_title">
            PropCrowdy as a matter of policy would require real estate owners,
            Property owners crowd funding on its portal to invest in climate
            change by playing their role in developing buildings and properties
            that promotes and pushes the carbon zero initiative even further.{" "}
          </p>
          <div className="buttons_container">
            <button onClick={toggleModal} className="generic_button red_button">
              Get More Insight
            </button>
            {/* <Link
              to={{ pathname: "https://youtu.be/v1kzee2z-YA" }}
              className="green_play_button"
            > */}
            <a
              href="https://youtu.be/v1kzee2z-YA"
              className="green_play_button"
            >
              <img
                src={playButton}
                alt="why go green"
                style={{ marginRight: "11px" }}
                sizes={40}
                className="green_button_image"
              />
              Why Go Green
            </a>
            {/* </Link> */}
          </div>
          <GetInsightModal
            showModal={showModal}
            // setShowModal= {this.setState()}
            toggleModal={toggleModal}
          />
        </div>
        <div className="image_part">
          <img src={zeroEmissionGraph} alt="" />
        </div>
      </section>
      <div className="common_text">
        We want to make it easy to be more sustainable. That’s why our products
        and technology help and encourage people build better and understand
        their impact and actions.
      </div>
    </div>
  );
};

export default ZeroCarbon;
