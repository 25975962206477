import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Api from './../../../App/Components/Utils/Api';

export default class View extends React.Component {
    constructor(props) {
        super(props);
        View.contextType = Context;
        this.state = {

        }
    }

    static fetchAnnouncement(context) {
        Api.fetch('announcement').then(({status, responseJSON}) => {
            if(status === 200) {
                context.updateContext('announcement', responseJSON.data);
            }
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    static clearAnnouncement(context) {
        context.updateContext('announcement', null);
    }

    render() {
        return this.context.announcement ? (
            <div className="announcement">
                <img src="/assets/images/vectors/icons/announcement.svg" alt="Announcement" className="icon"/>
                <div className="body">
                    <div className="title">{this.context.announcement.title || 'Announcement Board'}:</div>
                    {/*eslint-disable-next-line jsx-a11y/anchor-has-content*/}
                    <div className="content"
                         dangerouslySetInnerHTML={{__html: this.context.announcement.content + (this.context.announcement.link ? '<a href={this.context.announcement.link} target="_blank" rel="noopener noreferrer" className="link las la-link" />' : '')}}>
                    </div>
                </div>
            </div>
        ) : null;
    }
}
