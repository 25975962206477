import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Resource from './../Templates/Layouts/Resource';
import Loading from './../../../App/Components/Templates/Loading';
import _ from 'lodash';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';
import UI from './../../../App/Components/Utils/UI';

export default class VirtualTourAdd extends React.Component {
    constructor(props) {
        super(props);
        VirtualTourAdd.contextType = Context;
        this.state = {
            roomIndex: 0,
            email: '',
            phoneNumber: '',
            websiteUrl: '',
            rooms: [{name: '', images: ['', '', '', '', '', '']}],
            'rooms.0.images.0DisplayName': '',
            'rooms.0.images.0UploadProgress': 0,
            'rooms.0.images.0Url': '/assets/images/image-placeholder.png',
            'rooms.0.images.1DisplayName': '',
            'rooms.0.images.1UploadProgress': 0,
            'rooms.0.images.1Url': '/assets/images/image-placeholder.png',
            'rooms.0.images.2DisplayName': '',
            'rooms.0.images.2UploadProgress': 0,
            'rooms.0.images.2Url': '/assets/images/image-placeholder.png',
            'rooms.0.images.3DisplayName': '',
            'rooms.0.images.3UploadProgress': 0,
            'rooms.0.images.3Url': '/assets/images/image-placeholder.png',
            'rooms.0.images.4DisplayName': '',
            'rooms.0.images.4UploadProgress': 0,
            'rooms.0.images.4Url': '/assets/images/image-placeholder.png',
            'rooms.0.images.5DisplayName': '',
            'rooms.0.images.5UploadProgress': 0,
            'rooms.0.images.5Url': '/assets/images/image-placeholder.png',
            message: '',
            error: {
                email: [],
                phone_number: [],
                website_url: [],
                'rooms.0.name': [],
                'rooms.0.images.0': [],
                'rooms.0.images.1': [],
                'rooms.0.images.2': [],
                'rooms.0.images.3': [],
                'rooms.0.images.4': [],
                'rooms.0.images.5': [],
            },
            submitting: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Virtual Tour Creator'});
    }

    reset() {
        this.setState({
            roomIndex: 0,
            email: '',
            phoneNumber: '',
            websiteUrl: '',
            rooms: [{name: '', images: ['', '', '', '', '', '']}],
            'rooms.0.images.0DisplayName': '',
            'rooms.0.images.0UploadProgress': 0,
            'rooms.0.images.0photoUrl': '',
            'rooms.0.images.1DisplayName': '',
            'rooms.0.images.1UploadProgress': 0,
            'rooms.0.images.1photoUrl': '',
            'rooms.0.images.2DisplayName': '',
            'rooms.0.images.2UploadProgress': 0,
            'rooms.0.images.2photoUrl': '',
            'rooms.0.images.3DisplayName': '',
            'rooms.0.images.3UploadProgress': 0,
            'rooms.0.images.3photoUrl': '',
            'rooms.0.images.4DisplayName': '',
            'rooms.0.images.4UploadProgress': 0,
            'rooms.0.images.4photoUrl': '',
            'rooms.0.images.5DisplayName': '',
            'rooms.0.images.5UploadProgress': 0,
            'rooms.0.images.5photoUrl': '',
            message: '',
            error: {
                email: [],
                phone_number: [],
                website_url: [],
                'rooms.0.name': [],
                'rooms.0.images.0': [],
                'rooms.0.images.1': [],
                'rooms.0.images.2': [],
                'rooms.0.images.3': [],
                'rooms.0.images.4': [],
                'rooms.0.images.5': [],
            },
            submitting: false
        })
    }

    addRoom() {
        let rooms = this.state.rooms;
        let error = this.state.error;
        error['rooms.' + this.state.rooms.length + '.name'] = [];
        error['rooms.' + this.state.rooms.length + '.images.0'] = [];
        error['rooms.' + this.state.rooms.length + '.images.1'] = [];
        error['rooms.' + this.state.rooms.length + '.images.2'] = [];
        error['rooms.' + this.state.rooms.length + '.images.3'] = [];
        error['rooms.' + this.state.rooms.length + '.images.4'] = [];
        error['rooms.' + this.state.rooms.length + '.images.5'] = [];
        rooms.push({name: '', images: ['', '', '', '', '', '']});
        this.setState({rooms: rooms, roomIndex: rooms.length - 1, error: error});
    }

    updateRoom(index, type, value) {
        let rooms = this.state.rooms;
        rooms[index][type] = value;
        this.setState({rooms: rooms});
    }

    removeRoom(index) {
        let state = this.state;
        for(let i = index; i < this.state.rooms.length - 1; i++) {
            for(let j = 0; j < 6; j++) {
                this['rooms.' + i + '.images.' + j + 'FileInput'] = this['rooms.' + (i + 1) + '.images.' + j + 'FileInput']
                state['rooms.' + i + '.images.' + j + 'DisplayName'] = this.state['rooms.' + (i + 1) + '.images.' + j + 'DisplayName'];
                state['rooms.' + i + '.images.' + j + 'UploadProgress'] = this.state['rooms.' + (i + 1) + '.images.' + j + 'UploadProgress'];
                state['rooms.' + i + '.images.' + j + 'Url'] = this.state['rooms.' + (i + 1) + '.images.' + j + 'Url'];
                state.error['rooms.' + i + '.images'] = state.error['rooms.' + (i + 1) + '.images.' + j];
            }
            state.error['rooms.' + i + '.name'] = state.error['rooms.' + (i + 1) + '.name'];
        }
        for(let j = 0; j < 6; j++) {
            delete this['rooms.' + (this.state.rooms.length - 1) + '.images.' + j + 'FileInput'];
            delete state['rooms.' + (this.state.rooms.length - 1) + '.images.' + j + 'DisplayName'];
            delete state['rooms.' + (this.state.rooms.length - 1) + '.images.' + j + 'UploadProgress'];
            delete state['rooms.' + (this.state.rooms.length - 1) + '.images.' + j + 'Url'];
            delete state.error['rooms.' + (this.state.rooms.length - 1) + '.images.' + j];
        }
        delete state.error['rooms.' + (this.state.rooms.length - 1) + '.name'];
        state.roomIndex--
        state.rooms.splice(index, 1);
        this.setState({state});
    }

    uploadFile(fileHandle, key, callback) {
        let setKey = (value) => {
            let state = this.state;
            _.set(state, key, value);
            this.setState(state, () => {
                if(typeof callback === 'function') {
                    callback.apply();
                }
            });
        }
        let formData = new FormData();
        if (fileHandle.files.length) {
            this.setState({[key + 'UploadProgress']: 0.1, [key + 'DisplayName']: 'Uploading... (0%)', [key + 'Url']: URL.createObjectURL(fileHandle.files[0]), message: ''}, () => {
                setKey('');
            });
            fileHandle.disabled = true;
            formData.append('file', fileHandle.files[0]);
            Api.fetch('file.upload', undefined, formData, undefined, (progress) => {
                this.setState({[key + 'UploadProgress']: progress, [key + 'DisplayName']: 'Uploading... (' + Math.ceil(progress * 100) + '%)'});
            }).then(({status, responseJSON}) => {
                fileHandle.disabled = false;
                if(status === 200) {
                    this.setState({[key + 'DisplayName']: responseJSON.original_name, [key + 'UploadProgress']: 0}, () => {
                        setKey(responseJSON.name, () => {
                            this.validateSteps([this.state.step]).then(() => {}).catch(() => {});
                        });
                    });
                } else {
                    this.setState({[key + 'DisplayName']: '', [key + 'UploadProgress']: 0, [key + 'Url']: '/assets/images/image-placeholder.png', message: responseJSON.message || ''});
                }
            }).catch((reason) => {
                this.setState({[key + 'DisplayName']: '', [key + 'UploadProgress']: 0, [key + 'Url']: '/assets/images/image-placeholder.png'});
                fileHandle.disabled = false;
                let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                if ([403, 422].indexOf(status) !== -1) {
                    let error = {};
                    if(responseJSON.error && responseJSON.error.file) {
                        error[key => key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)] = responseJSON.error['file'];
                    }
                    this.setState({error: {...this.state.error, ...error}, message: responseJSON.message || ''});
                } else {
                    console.debug(reason);
                    this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
                }
            });
        } else {
            this.setState({[key + 'DisplayName']: '', [key + 'Url']: '/assets/images/image-placeholder.png'}, () => {
                setKey('');
            });
        }
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if (typeof this.state.email !== 'string' || this.state.email.trim() === '') {
            error.email.push('Please provide your email address');
        } else if (!(typeof this.state.email === 'string' && this.state.email.match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi))) {
            error.email.push('Please provide a valid email');
        }

        if (typeof this.state.phoneNumber !== 'string' || this.state.phoneNumber.trim() === '') {
            error.phone_number.push('Please provide a phone number');
        } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
            error.phone_number.push('Please provide a valid phone number');
        }

        if (typeof this.state.websiteUrl !== 'string' || this.state.websiteUrl.trim() === '') {
            error.website_url.push('Please provide a company\'s Linkedin URL');
        } else if (!this.state.websiteUrl.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi)) {
            error.website_url.push('Please use a valid URL');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        Api.fetch('tour.add', undefined, {
            email: this.state.email,
            phone_number: this.state.phoneNumber,
            website_url: this.state.websiteUrl,
            rooms: this.state.rooms
        }, {'Accept': 'application/json'}).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                this.reset();
                UI.alert({
                    title: 'Request Sent',
                    content: responseJSON.message || 'Your request for the Virtual Tour quote has been received. We will send a quote to the provided email address shortly.'
                }, this.context);
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...this.state.error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <Resource heading="Virtual Tour Creator" title="Virtual Tour Creator">
                <main id="main" className="resource virtual-tour add">
                    <div className="container">
                        <form className="steps" onSubmit={(e) => {
                            e.preventDefault();
                            this.submit();
                        }}>
                            {this.state.submitting && <Loading />}
                            <img src="/assets/images/vectors/icons/offer.svg" alt="Offer" className="icon" />
                            <div className="title">Virtual Tour Creator</div>
                            <div className="message">{this.state.message}</div>
                            <div className="step">
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="virtual-tour-email">Email *</label>
                                                <input id="virtual-tour-email" type="email" name="email" value={this.state.email} placeholder="Email" className="form-control" onChange={(e) => {this.setState({email: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="virtual-tour-phone-number">Phone Number *</label>
                                                <input id="virtual-tour-phone-number" type="text" name="phone_number" value={this.state.phoneNumber} placeholder="Phone Number" className="form-control" onChange={(e) => {this.setState({phoneNumber: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.phone_number.join('<br />')}} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="virtual-tour-website-url">Website URL *</label>
                                                <input id="virtual-tour-website-url" type="url" name="website_url" value={this.state.websiteUrl} placeholder="Website URL" className="form-control" onChange={(e) => {this.setState({websiteUrl: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.website_url.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="rooms">
                                                <div className="tabs">
                                                    {this.state.rooms.map((room, index) => (
                                                        <button key={index} type="button" className={'tab' + (index === this.state.roomIndex ? ' active' : '')} onClick={() => {this.setState({roomIndex: index})}}>{room.name || 'New Room'}</button>
                                                    ))}
                                                    <button type="button" className="add" onClick={() => {this.addRoom()}}><i className="icon las la-plus-circle" /></button>
                                                </div>
                                                <div className="rooms">
                                                    {this.state.rooms.length ? (
                                                        <div className="room">
                                                            <div className="field no-label">
                                                                <button type="button" className="remove"><i className="icon las la-minus-circle" onClick={() => {this.removeRoom(this.state.roomIndex)}} /></button>
                                                            </div>
                                                            <div className="field">
                                                                <label htmlFor={'virtual-tour-room-' + this.state.roomIndex + '-name'}>Name *</label>
                                                                <input id={'virtual-tour-room-' + this.state.roomIndex + '-name'} type="text" name="room[][title]" value={this.state.rooms[this.state.roomIndex].name} placeholder="Name" className="form-control" onChange={(e) => {this.updateRoom(this.state.roomIndex, 'name', e.target.value)}} required />
                                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                                                            </div>
                                                            <div className="images">
                                                                {this.state.rooms[this.state.roomIndex].images.map((image, index) => (
                                                                    <div key={index} className="field">
                                                                        <label htmlFor={'virtual-tour-room-' + this.state.roomIndex + '-image-' + index}>{this.state['rooms.' + this.state.roomIndex + '.images.' + index + 'DisplayName']}</label>
                                                                        <input type="hidden" name="room[][images][]" value={this.state['rooms.' + this.state.roomIndex + '.images.' + index]} />
                                                                        <input ref={(roomImagesFileInput) => {this['rooms.' + this.state.roomIndex + '.images.' + index + 'FileInput'] = roomImagesFileInput}} type="file" name="rooms[][images_file][]" className="display-none" accept="image/x-png, image/jpeg" onChange={(e) => {this.uploadFile(e.target, 'rooms.' + this.state.roomIndex + '.images.' + index)}} />
                                                                        <div className={'image' + (this.state['rooms.' + this.state.roomIndex + '.images.' + index + 'UploadProgress'] ? ' uploading' : '')} style={{backgroundImage: 'url("' + this.state['rooms.' + this.state.roomIndex + '.images.' + index + 'Url'] + '")'}} onClick={() => {this['rooms.' + this.state.roomIndex + '.images.' + index + 'FileInput'].click()}}>
                                                                            <div className="progress" style={{height: 'calc(100% - ' + (this.state.rooms[this.state.roomIndex].images[index] ? 100 : Math.ceil(this.state['rooms.' + this.state.roomIndex + '.images.' + index + 'UploadProgress'] * 100)) + '%)'}} />
                                                                        </div>
                                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error['rooms.' + this.state.roomIndex + '.images.' + index].join('<br />')}} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit">
                                        <button type="submit">Send Me A Quote</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </main>
            </Resource>
        );
    }
}
