import './../../assets/style.css';
import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from './../../../App/Components/Context';
import $ from 'jquery';
import Hook from './../../../App/Components/Utils/Hook';
import User from './../../../User/Components/Utils';

export default class View extends React.Component {
    constructor(props) {
        super(props);
        View.contextType = Context;
        this.state = {

        }
    }

    componentDidMount() {
        Hook.register('payment', this.onPayment.bind(this));
    }

    componentWillUnmount() {
        Hook.unregister('payment', this.onPayment.bind(this));
    }

    onPayment(result, type, typeId, data) {
        User.syncUser(this.context.user, this.context).then(() => {}).catch(() => {});
    }

    render() {
        return this.context.user ? (
            <div className="wallet wallet-balance">
                <div className="title">
                    <span className="left">My Wallet</span>
                    <div className="right">Credit Balance</div>
                </div>
                <div className="balance">₦{parseInt(this.context.user.wallet_balance || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                <div className="divider">
                    <i className="icon las la-wallet" />
                </div>
                <div className="actions">
                    <div className="links">
                        <Link to={{pathname: '/wallet/transactions'}} className="link" onClick={() => {$('#wallet-modal').modal('hide')}}>View Transactions</Link>
                    </div>
                    <div className="buttons">
                        <button className="button" onClick={(e) => {
                            e.preventDefault();
                            this.context.updateContext('paymentModalType', 'FUNDWALLET');
                            this.context.updateContext('paymentModalTypeId', this.context.user.id);
                            this.context.updateContext('paymentModalScheduleId', null);
                            this.context.updateContext('paymentModalTypeTitle', 'Fund Wallet');
                            this.context.updateContext('paymentModalTitle', 'Fund your PropCrowdy Wallet using either of the options below');
                            this.context.updateContext('paymentModalAmount', null);
                            this.context.updateContext('paymentModalData', this.context.user);
                            this.context.updateContext('paymentModalMethods', ['paystack', 'rave']);
                            $('#payment-modal').modal('show');
                        }}>
                            Fund
                            <i className="icon las la-file-import" />
                        </button>
                        <button className="button" disabled>
                            Withdraw
                            <i className="icon las la-file-export" />
                        </button>
                    </div>
                </div>
            </div>
        ) : null;
    }
}
