import './../../assets/style.css';
import React from 'react';
import Loading from './../../../App/Components/Templates/Loading';
import Api from './../../../App/Components/Utils/Api';
import UI from './../../../App/Components/Utils/UI';
import {Context} from './../../../App/Components/Context';

export default class AgencyEdit extends React.Component {
    constructor(props) {
        super(props);
        AgencyEdit.contextType = Context;
        this.state = {
            information: '',
            email: '',
            phoneNumber: '',
            rcNumber: '',
            regulatoryJurisdictionId: '0',
            regulatoryJurisdictionNumber: '',
            message: '',
            error: {
                information: [],
                email: [],
                phone_number: [],
                rc_number: [],
                regulatory_jurisdiction_id: [],
                regulatory_jurisdiction_number: [],
            },
            submitting: false
        }
    }

    componentDidMount() {
        this.setState({
            information: (this.context.user.agency_details && this.context.user.agency_details.information) || '',
            email: (this.context.user.agency_details && this.context.user.agency_details.email) || '',
            phoneNumber: (this.context.user.agency_details && this.context.user.agency_details.phone_number) || '',
            rcNumber: (this.context.user.agency_details && this.context.user.agency_details.rc_number) || '',
            regulatoryJurisdiction: (this.context.user.agency_details && this.context.user.agency_details.regulatory_jurisdiction) || '0',
            regulatoryJurisdictionNumber: (this.context.user.agency_details && this.context.user.agency_details.regulatory_jurisdiction_number) || '',
        });
    }

    saveAgencyDetails() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if (typeof this.state.information !== 'string' || this.state.information.trim() === '') {
            error.information.push('Please provide the agency information');
        }

        if (typeof this.state.email !== 'string' || this.state.email.trim() === '') {
            error.email.push('Please provide the agency email address');
        } else if (!(typeof this.state.email === 'string' && this.state.email.match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi))) {
            error.email.push('Please provide a valid email');
        }

        if (typeof this.state.phoneNumber !== 'string' || this.state.phoneNumber.trim() === '') {
            error.phone_number.push('Please provide the agency phone number');
        } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
            error.phone_number.push('Please provide a valid phone number');
        }

        if (!parseInt(this.state.rcNumber)) {
            error.rc_number.push('Please provide the RC number');
        }

        if (!parseInt(this.state.regulatoryJurisdictionId)) {
            error.regulatory_jurisdiction_id.push('Please provide the regulatory jurisdiction');
        }

        if (!parseInt(this.state.regulatoryJurisdictionNumber)) {
            error.regulatory_jurisdiction_number.push('Please provide the regulatory jurisdiction number');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        let data = {
            customer_id: this.context.user.id,
            information: this.state.information,
            email: this.state.email,
            phone_number: this.state.phoneNumber,
            rc_number: this.state.rcNumber,
            regulatory_jurisdiction_id: this.state.regulatoryJurisdictionId,
            regulatory_jurisdiction_number: this.state.regulatoryJurisdictionNumber
        }

        Api.fetch('developer.agency.edit', undefined, data).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                UI.alert({title: 'Success', content: 'Agency Details saved successfully'}, this.context);
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...this.state.error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <form className="user account" onSubmit={(e) => {e.preventDefault(); this.saveAgencyDetails()}}>
                {this.state.submitting && <Loading />}
                <div className="message">{this.state.message}</div>
                <div className="sub-heading first">Agency Details</div>
                <div className="field">
                    <div className="row">
                        <div className="col-xl-6">
                            <label htmlFor="account-agency-information">Agency Information</label>
                            <div className="info">Enter company details</div>
                        </div>
                        <div className="col-xl-6">
                            <div className="info">About Agency*</div>
                            <textarea id="account-agency-information" name="information" value={this.state.information} placeholder="Information" className="form-control" onChange={(e) => {this.setState({information: e.target.value})}} />
                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.information.join('<br />')}} />
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="row">
                        <div className="col-xl-6">
                            <label htmlFor="account-agency-email">Email Address</label>
                            <div className="info">This is the mail we send transaction notifications and other updates</div>
                        </div>
                        <div className="col-xl-6">
                            <div className="info">Email*</div>
                            <input id="account-agency-email" type="email" name="email" value={this.state.email} placeholder="Email Address" className="form-control" onChange={(e) => {this.setState({email: e.target.value})}} required />
                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="row">
                        <div className="col-xl-6">
                            <label htmlFor="account-agency-phone-number">Phone Number</label>
                            <div className="info">This is the number we send SMS verification messages</div>
                        </div>
                        <div className="col-xl-6">
                            <div className="info">Phone Number</div>
                            <input id="account-agency-phone-number" type="text" name="phone_number" value={this.state.phoneNumber} pattern="^\+?[0-9\s]{8,15}" placeholder="Phone Number" className="form-control" onChange={(e) => {this.setState({phoneNumber: e.target.value})}} required />
                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.phone_number.join('<br />')}} />
                        </div>
                    </div>
                </div>
                <div className="sub-heading">Income</div>
                <div className="field">
                    <div className="row">
                        <div className="col-xl-6">
                            <label htmlFor="account-agency-rc-number">RC Number</label>
                            <div className="info">Company Registration</div>
                        </div>
                        <div className="col-xl-6">
                            <div className="info">Registration Number</div>
                            <input id="account-agency-rc-number" type="text" name="rc_number" value={this.state.rcNumber} placeholder="Number" className="form-control" onChange={(e) => {this.setState({rcNumber: e.target.value})}} required />
                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.rc_number.join('<br />')}} />
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="row">
                        <div className="col-xl-6">
                            <label htmlFor="account-proof-of-annual-income">Regulatory Jurisdiction</label>
                            <div className="info">Enter License Number</div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="info">Jurisdiction</div>
                                    <select id="account-agency-regulatory-jurisdiction-id" name="regulatory_jurisdiction_id" value={this.state.regulatoryJurisdictionId} placeholder="Jurisdiction" className="form-control" onChange={(e) => {this.setState({regulatoryJurisdictionId: e.target.value})}} required>
                                        <option value="0" disabled>Jurisdiction</option>
                                        {((this.context.constants && this.context.constants.developer && this.context.constants.developer.regulatoryJurisdictions) || []).map((jurisdiction, index) => (
                                            <option key={index} value={jurisdiction.id}>{jurisdiction.name}</option>
                                        ))}
                                    </select>
                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.regulatory_jurisdiction_id.join('<br />')}} />
                                </div>
                                <div className="col-lg-6">
                                    <div className="info">Number</div>
                                    <input id="account-agency-regulatory-jurisdiction-number" type="text" name="regulatory_jurisdiction_number" value={this.state.regulatoryJurisdictionNumber} placeholder="Number" className="form-control" onChange={(e) => {this.setState({regulatoryJurisdictionNumber: e.target.value})}} required />
                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.regulatory_jurisdiction_number.join('<br />')}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit">
                    <div className="row">
                        <div className="col-xl-6" />
                        <div className="col-xl-6">
                            <input type="submit" value="Save" />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}
