import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import WalletView from './../View';

export default class Wallet extends React.Component {
    constructor(props) {
        super(props);
        Wallet.contextType = Context;
        this.state = {

        }
    }

    render() {
        return (
            <div id="wallet-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <WalletView />
                    </div>
                </div>
            </div>
        );
    }
}
