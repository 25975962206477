import React from "react";

export default class Blank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        id="layout"
        className={
          "blank" +
          (this.props.htmlClasses ? " " + this.props.htmlClasses.join(" ") : "")
        }
      >
        {this.props.children}
      </div>
    );
  }
}
