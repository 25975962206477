import "./../../assets/style.css";
import "./../../assets/stylings/signing_styles.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Loading from "./../../../App/Components/Templates/Loading";
import Document from "./../../../App/Components/Utils/Document";
import Api from "./../../../App/Components/Utils/Api";
import UI from "./../../../App/Components/Utils/UI";
import User from "./../Utils";
/*import validate from 'deep-email-validator'*/

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    Register.contextType = Context;
    this.state = {
      redirectPathname:
        (this.props.location.state &&
          this.props.location.state.redirectPathname) ||
        "/dashboard",
      redirectSearch:
        (this.props.location.state &&
          this.props.location.state.redirectSearch) ||
        "",
      showPassword: false,
      showPasswordConfirm: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      phoneNumber: "",
      customerType: "retail_investor",
      referral_code: "",
      message:
        (this.props.location.state && this.props.location.state.message) || "",
      error: {
        first_name: [],
        last_name: [],
        email: [],
        password: [],
        password_confirmation: [],
        phone_number: [],
        customer_type: [],
      },
      submitting: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('ref');
    this.setState({referral_code:myParam})
    this.context.updateContext(
      "layout",
      this.context.layout === "defaultPictured" ||
        this.context.layout === "defaultPictured"
        ? "defaultPictured"
        : "defaultPictured"
    );
    Document.updateDocumentHead({ title: "PropCrowdy - Register" });
    if (this.context.user) {
      this.props.history.replace(
        this.context.user.platform === "propbuy" ? "/buy" : "/"
      );
    } else if (
      this.state.redirectPathname === "/dashboard" &&
      (this.context.layout === "defaultPictured" ||
        this.context.layout === "defaultPictured")
    ) {
      this.setState({ redirectPathname: "/property/my-list" });
    }
  }

  facebookLogin(result) {
    if (result.status !== "unknown") {
      this.socialLogin({
        firstName: result.name.split(" ")[0],
        lastName: result.name.split(" ")[1],
        email: result.email ? result.email : result.id + "@facebook.com",
        username: result.username ? result.username : "facebook_" + result.id,
        image: result.picture.data ? result.picture.data.url : "",
        gender: result.gender,
        id: result.id,
        type: "facebook",
        token: result.accessToken,
      });
    }
  }

  googleLogin(response) {
    if (response.error !== "popup_closed_by_user") {
      this.socialLogin({
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email
          ? response.profileObj.email
          : response.profileObj.googleId + "@google.com",
        username: response.profileObj.username
          ? response.profileObj.username
          : "google_" + response.profileObj.googleId,
        image: response.profileObj.imageUrl,
        gender: response.profileObj.gender,
        id: response.profileObj.googleId,
        type: "google",
        token: response.accessToken,
      });
    }
  }

  socialLogin(user) {
    this.setState({ submitting: true, message: "" });
    Api.fetch("user.auth.social", undefined, {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      username: user.username,
      phone_number: user.phoneNumber,
      gender: user.gender,
      password: user.password,
      password_confirmation: user.passwordConfirmation,
      customer_type: user.customerType,
      image: user.image,
      id: user.id,
      type: user.type,
      token: user.token,
      partner:this.context.partner??''
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          let user = responseJSON.data.user;
          if (user.phone_number && user.customer_type) {
            user["token"] = responseJSON.data.token;
            user["platform"] =
              this.context.layout === "defaultPictured" ||
              this.context.layout === "defaultPictured"
                ? "propbuy"
                : "propcrowdy";
            User.login(user, this.context);
            this.props.history.push({
              pathname: this.state.redirectPathname,
              search: this.state.redirectSearch,
            });
          } else {
            this.context.updateContext("verificationEmail", user.email);
            this.context.updateContext("verificationIsSocial", true);
            this.props.history.push({
              pathname: "/verify",
              search: "?requested=1",
              state: {
                redirectPathname: this.state.redirectPathname,
                search: this.state.redirectSearch,
              },
            });
          }
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          let errors = [];
          let error = responseJSON.error || {};
          for (let i in error) {
            if (error.hasOwnProperty(i)) {
              errors = [...errors, ...error[i]];
            }
          }
          UI.alert(
            {
              type: "error",
              title: "Error Occurred",
              subTitle: "Could not authenticate with " + user.type,
              content: errors.join("<br />"),
            },
            this.context
          );
        } else {
          // console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  async submit() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (
      typeof this.state.firstName !== "string" ||
      this.state.firstName.trim() === ""
    ) {
      error.first_name.push("Please provide your first name");
    } else if (!this.state.firstName.trim().match(/^[a-zA-Z-]{2,}$/gi)) {
      error.first_name.push("Please provide a valid first name");
    }

    if (
      typeof this.state.lastName !== "string" ||
      this.state.lastName.trim() === ""
    ) {
      error.last_name.push("Please provide your last name");
    } else if (!this.state.lastName.trim().match(/^[a-zA-Z-]{2,}$/gi)) {
      error.last_name.push("Please provide a valid last name");
    }

    if (
      typeof this.state.email !== "string" ||
      this.state.email.trim() === ""
    ) {
      error.email.push("Please provide your email address");
    } else if (
      !(
        typeof this.state.email === "string" &&
        this.state.email.match(
          /^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi
        )
      )
    ) {
      error.email.push("Please provide a valid email");
    }
    // let resValidate = await validate(this.state.email)
    // if (resValidate.valid === false){
    //     error.email.push('Please provide a valid email');
    // }
    if (
      typeof this.state.password !== "string" ||
      this.state.password.trim() === ""
    ) {
      error.password.push("Please provide a password");
    } else if (this.state.password.trim().length < 10) {
      error.password.push("Password must contain at least 10 characters");
    } else if (
      !this.state.password.match(/[a-z]/) ||
      !this.state.password.match(/[A-Z]/) ||
      !this.state.password.match(/[0-9]/) ||
      !this.state.password.match(/[^a-zA-Z0-9\s]/)
    ) {
      error.password.push(
        "Password must contain at least a uppercase and lowercase alphabet, a number, and a symbol"
      );
    }

    if (this.state.password !== this.state.passwordConfirmation) {
      error.password_confirmation.push("Password confirmation does not match");
    }

    if (
      typeof this.state.phoneNumber !== "string" ||
      this.state.phoneNumber.trim() === ""
    ) {
      error.phone_number.push("Please provide a phone number");
    } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
      error.phone_number.push("Please provide a valid phone number");
    }

    if (
      typeof this.state.customerType !== "string" ||
      this.state.customerType.trim() === ""
    ) {
      error.customer_type.push("Please select a customer type");
    } else {
      let customerTypes = [];
      (
        (this.context.constants &&
          this.context.constants.user &&
          this.context.constants.user.customer_type) ||
        []
      ).map((type) => customerTypes.push(type.value));
      if (customerTypes.indexOf(this.state.customerType) === -1) {
        error.customer_type.push("Please provide a valid customer type");
      }
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }

    this.setState({ submitting: true });

    Api.fetch("user.auth.register", undefined, {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone_number: this.state.phoneNumber,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
      customer_type: this.state.customerType,
      referrer_id:
        this.state.customerType === "retail_investor"
          ? this.state.referral_code
          : "",
      partner:this.context.partner??''
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          let user = responseJSON.data.user;
          user["token"] = responseJSON.data.token;
          user["platform"] =
            this.context.layout === "defaultPictured" ||
            this.context.layout === "defaultPictured"
              ? "propbuy"
              : "propcrowdy";
          User.login(user, this.context);
          this.props.history.push({
            pathname: this.state.redirectPathname,
            search: this.state.redirectSearch,
          });
        } else if (status === 201 && responseJSON.need_verification) {
          this.context.updateContext(
            "verificationEmail",
            responseJSON.data.user.email
          );
          this.context.updateContext("verificationIsSocial", false);
          this.props.history.push({
            pathname: "/verify",
            search: "?requested=1",
            state: {
              redirectPathname: this.state.redirectPathname,
              search: this.state.redirectSearch,
            },
          });
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          this.setState({
            error: { ...error, ...responseJSON.error },
            message: responseJSON.message || "",
          });
        } else {
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  render() {
    return (
      <>
        {/* <main id="main" className="auth signup">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 split-left">
                <div className="content">
                  <div className="heading">Create your account</div>
                  <div className="sub-heading">
                    Password must be at least 10 characters long.
                  </div>
                  <img
                    src="/assets/images/vectors/laptop.svg"
                    alt="Security"
                    className="image"
                  />
                </div>
              </div>
              <div className="col-xl-6 split-right">
                <form
                  className="auth signup"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submit().then();
                  }}
                >
                  {this.state.submitting && <Loading />}
                  <img
                    src="/assets/images/vectors/icons/lock.svg"
                    alt="Lock"
                    className="icon"
                  />
                  <div className="message">{this.state.message}</div>
                  <div className="row">
                    <div className="col-xl-6">
                      <label htmlFor="register-first-name" className="stacked">
                        First Name
                      </label>
                      <input
                        id="register-first-name"
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.first_name.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="register-last-name" className="stacked">
                        Last Name
                      </label>
                      <input
                        id="register-last-name"
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.last_name.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="register-email" className="stacked">
                        Email Address
                      </label>
                      <input
                        id="register-email"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        autoComplete="username"
                      />
                      <img
                        src="/assets/images/icons/at.png"
                        alt="At"
                        className="icon"
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.email.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="col-xl-6">
                      <label
                        htmlFor="register-phone-number"
                        className="stacked"
                      >
                        Phone Number
                      </label>
                      <input
                        id="register-phone-number"
                        type="text"
                        name="phone_number"
                        placeholder="Phone Number"
                        pattern="^\+?[0-9\s]{8,15}"
                        onChange={(e) => {
                          this.setState({ phoneNumber: e.target.value });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.phone_number.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="register-password" className="stacked">
                        Password
                      </label>
                      <input
                        id="register-password"
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                        autoComplete="new-password"
                      />
                      <img
                        src={
                          this.state.showPassword
                            ? "/assets/images/icons/eye-opened.png"
                            : "/assets/images/icons/eye-closed.png"
                        }
                        alt="Eye"
                        className="icon"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.password.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="col-xl-6">
                      <label
                        htmlFor="register-password-confirm"
                        className="stacked"
                      >
                        Confirm Password
                      </label>
                      <input
                        id="register-password-confirm"
                        type={
                          this.state.showPasswordConfirm ? "text" : "password"
                        }
                        name="password_confirmation"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          this.setState({
                            passwordConfirmation: e.target.value,
                          });
                        }}
                        autoComplete="new-password"
                      />
                      <img
                        src={
                          this.state.showPasswordConfirm
                            ? "/assets/images/icons/eye-opened.png"
                            : "/assets/images/icons/eye-closed.png"
                        }
                        alt="Eye"
                        className="icon"
                        onClick={() => {
                          this.setState({
                            showPasswordConfirm:
                              !this.state.showPasswordConfirm,
                          });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.error.password_confirmation.join(
                              "<br />"
                            ),
                        }}
                      />
                    </div>
                    {this.state.customerType === "retail_investor" ? (
                      <div className="col-xl-6">
                        <label htmlFor="referral" className="stacked">
                          Referral Code
                        </label>
                        <input
                          id="referral"
                          type="text"
                          name="referrer"
                          placeholder="Referral Code"
                          onChange={(e) => {
                            this.setState({ referral_code: e.target.value });
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="col-xl-12">
                      {(
                        (this.context.constants &&
                          this.context.constants.user &&
                          this.context.constants.user.customer_type) ||
                        []
                      ).map((customerType, index) => (
                        <div key={index} className="radio">
                          <input
                            id={"register-customer-" + customerType.value}
                            type="radio"
                            name="customer_type"
                            value={customerType.value}
                            checked={
                              this.state.customerType === customerType.value
                            }
                            onChange={(e) => {
                              this.setState({ customerType: e.target.value });
                            }}
                            required
                          />
                          <label
                            htmlFor={"register-customer-" + customerType.value}
                          >
                            {customerType.display}
                          </label>
                        </div>
                      ))}
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.customer_type.join("<br />"),
                        }}
                      />
                    </div>
                  </div>
                  <div className="submit">
                    <button type="submit">Create your account</button>
                  </div>
                </form>
                <div className="bottom">
                  <div className="social-logins">
                    <FacebookLogin
                      appId={
                        this.context.settings && this.context.settings.facebook
                          ? this.context.settings.facebook.app_id
                          : "1920986124792619"
                      }
                      fields="name,email,picture"
                      callback={(result) => {
                        this.facebookLogin(result);
                      }}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="facebook"
                        >
                          <img
                            src="/assets/images/icons/facebook-light.png"
                            alt="Facebook"
                            className="icon"
                          />
                          <span className="text">Sign up with Facebook</span>
                        </button>
                      )}
                    />
                    <GoogleLogin
                      clientId={
                        this.context.settings && this.context.settings.google
                          ? this.context.settings.google.client_id
                          : "685037057672-4n9bec8k6he23uacrcgeaf0eflhn9aj6.apps.googleusercontent.com"
                      }
                      onSuccess={(response) => {
                        this.googleLogin(response);
                      }}
                      onFailure={(e) => {
                        console.debug(e);
                      }}
                      cookiePolicy={"single_host_origin"}
                      render={(renderProps) => (
                        <button
                          onClick={() => {
                            renderProps.onClick();
                          }}
                          className="google"
                        >
                          <img
                            src="/assets/images/icons/google-light.png"
                            alt="Google"
                            className="icon"
                          />
                          <span className="text">Sign up with Google</span>
                        </button>
                      )}
                    />
                  </div>
                  <div className="info divider-bottom">
                    Already have an account?{" "}
                    <Link
                      to={{
                        pathname: "/login",
                        state: {
                          redirectPathname: this.state.redirectPathname,
                          search: this.state.redirectSearch,
                        },
                      }}
                      className="link"
                    >
                      Login to access account
                    </Link>
                  </div>
                  <div className="info">
                    By clicking on Create your account, you agree to our{" "}
                    <Link
                      to={{ pathname: "/terms" }}
                      target="_blank"
                      className="link"
                    >
                      terms and conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={{ pathname: "/privacy" }}
                      target="_blank"
                      className="link"
                    >
                      privacy policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main> */}
        <section className="register_page">
          <main className="register_main">
            <p className="red_top_text">Get Started With PropCrowdy</p>
            <h2 className="main_title">Create An Account</h2>
            <p className="sub_title_text">
              Now You can invest with confidence, knowing that your investment
              is safe.
            </p>
            <form
              className="form_main"
              onSubmit={(e) => {
                e.preventDefault();
                this.submit().then();
              }}
            >
              {this.state.submitting && <Loading />}
              <div className="input_container double">
                <div className="container_half">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    className="generic_input"
                    onChange={(e) => {
                      this.setState({ firstName: e.target.value });
                    }}
                  />
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.error.first_name.join("<br />"),
                    }}
                  />
                </div>

                <div className="container_half">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    className="generic_input"
                    onChange={(e) => {
                      this.setState({ lastName: e.target.value });
                    }}
                  />
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.error.last_name.join("<br />"),
                    }}
                  />
                </div>
              </div>
              <div className="input_container">
                <label htmlFor="phone_num">Phone Number</label>
                <input
                  id="phone_num"
                  type="text"
                  placeholder="Phone Number"
                  className="generic_input"
                  pattern="^\+?[0-9\s]{8,15}"
                  onChange={(e) => {
                    this.setState({ phoneNumber: e.target.value });
                  }}
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.phone_number.join("<br />"),
                  }}
                />
              </div>
              <div className="input_container">
                <label htmlFor="register-email">Email</label>
                <input
                  id="register-email"
                  type="email"
                  placeholder="Email"
                  className="generic_input"
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  autoComplete="username"
                />
                {/* <img
                  src="/assets/images/icons/at.png"
                  alt="At"
                  className="icon"
                /> */}
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.email.join("<br />"),
                  }}
                />
              </div>

              <h4>Secure Your Account</h4>
              <div className="input_container double">
                <div className="container_half">
                  <div style={{ position: "relative" }}>
                    <label htmlFor="register-password">Password</label>
                    <input
                      id="register-password"
                      type={this.state.showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="generic_input"
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                      autoComplete="new-password"
                    />
                    <img
                      src={
                        this.state.showPassword
                          ? "/assets/images/icons/eye_opened_icon.svg"
                          : "/assets/images/icons/eye_closed_icon.svg"
                      }
                      alt="Eye"
                      className="icon"
                      onClick={() => {
                        this.setState({
                          showPassword: !this.state.showPassword,
                        });
                      }}
                    />
                  </div>
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.error.password.join("<br />"),
                    }}
                  />
                </div>

                <div className="container_half">
                  <div style={{ position: "relative" }}>
                    <label htmlFor="retype_password">Retype Password</label>
                    <input
                      id="retype_password"
                      type={
                        this.state.showPasswordConfirm ? "text" : "password"
                      }
                      placeholder="Retype Password"
                      className="generic_input"
                      onChange={(e) => {
                        this.setState({
                          passwordConfirmation: e.target.value,
                        });
                      }}
                      autoComplete="new-password"
                    />
                    <img
                      src={
                        this.state.showPasswordConfirm
                          ? "/assets/images/icons/eye_opened_icon.svg"
                          : "/assets/images/icons/eye_closed_icon.svg"
                      }
                      alt="Eye"
                      className="icon"
                      onClick={() => {
                        this.setState({
                          showPasswordConfirm: !this.state.showPasswordConfirm,
                        });
                      }}
                    />
                  </div>

                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.error.password_confirmation.join("<br />"),
                    }}
                  />
                </div>
              </div>

              {this.state.customerType === "retail_investor" ? (
                <div className="input_container">
                  <label htmlFor="referral_code">Referral Code</label>
                  <input
                    id="referral_code"
                    type="text"
                    placeholder="Referral Code"
                    className="generic_input"
                    onChange={(e) => {
                      this.setState({ referral_code: e.target.value });
                    }}
                  />
                </div>
              ) : null}

              <div className="input_container">
                <label htmlFor="">User Type</label>
                <select
                  value={this.state.customerType}
                  name="customer_type"
                  className="generic_input"
                  style={{
                    color: "",
                  }}
                  onChange={(e) => {
                    this.setState((prev) => ({
                      ...prev,
                      customerType: e.target.value,
                    }));
                  }}
                >
                  <option value="" disabled>
                    Select User Type
                  </option>
                  {(
                    (this.context.constants &&
                      this.context.constants.user &&
                      this.context.constants.user.customer_type) ||
                    []
                  ).map((customerType, index) => (
                    <option
                      key={index}
                      value={customerType.value}
                      style={{ paddingBottom: "5px" }}
                    >
                      {customerType.display}
                    </option>
                  ))}
                </select>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.customer_type.join("<br />"),
                  }}
                />
              </div>
              <div className="message">{this.state.message}</div>
              <button className="generic_button" type="submit">
                Create My Account
              </button>
            </form>
            <div className="already_have_an_account">
              <p>Already have an account?</p>{" "}
              {/* <button className="goto_other_signing">Login to account </button> */}
              <Link
                to={{
                  pathname: "/login",
                  state: {
                    redirectPathname: this.state.redirectPathname,
                    search: this.state.redirectSearch,
                  },
                }}
                className="goto_other_signing"
              >
                Login to access account
              </Link>
            </div>

            {/* <div></div> */}
          </main>
          {/* <div className="image_part"></div> */}
        </section>
      </>
    );
  }
}
