import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import Loading from './../../../../App/Components/Templates/Loading';
import $ from 'jquery';
import Api from './../../../../App/Components/Utils/Api';
import UI from './../../../../App/Components/Utils/UI';
import Hook from './../../../../App/Components/Utils/Hook';
import {withRouter} from "react-router-dom";

class ReviewAdd extends React.Component {
    constructor(props) {
        super(props);
        ReviewAdd.contextType = Context;
        this.state = {
            ratings: {
                1: {title: 'Could be better', icon: 'angry'},
                2: {title: 'Below Average', icon: 'frown'},
                3: {title: 'Normal', icon: 'meh'},
                4: {title: 'Good', icon: 'smile'},
                5: {title: 'Excellent', icon: 'grin'}
            },
            rating: '3',
            comment: '',
            submitting: false,
            message: '',
            error: {
                rating: [],
                review: [],
            }
        }
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if(this.context.user) {
            if (typeof this.state.comment !== 'string' || this.state.comment.trim() === '') {
                error.review.push('Please add a comment');
            }

            if (!parseInt(this.state.rating) || this.state.rating < 1 || this.state.rating > 5) {
                error.rating.push('Please set a valid rating');
            }

            this.setState({error: error});

            for(let i in error) {
                if(error.hasOwnProperty(i)) {
                    if(error[i].length) {
                        return false;
                    }
                }
            }

            this.setState({submitting: true});
            Api.fetch('developer.company.review.add', undefined, {review: this.state.comment, rating: this.state.rating, company_id: this.context.reviewAddCompany.id}).then(({status, responseJSON}) => {
                this.setState({submitting: false});
                if(status === 200) {
                    this.setState({comment: '', rating: 3}, () => {
                        $('#review-modal').modal('hide');
                        UI.alert({title: 'Success', content: 'Your review has been added successfully.'}, this.context);
                        Hook.fire('review.added', undefined, ['company', this.context.reviewAddCompany.id, {...this.context.reviewAddCompany}]);
                    });
                } else {
                    this.setState({message: responseJSON.message || ''});
                }
            }).catch((reason) => {
                this.setState({submitting: false});
                let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                if ([403, 422].indexOf(status) !== -1) {
                    this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
                } else {
                    console.debug(reason);
                    this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
                }
            });
        } else {
            UI.alert({
                title: 'Login First!',
                content: 'You must be logged in to add review',
                buttons: [{
                    type: 'positive',
                    title: 'Login',
                    onClick: () => {
                        this.props.history.push({pathname: '/login', state: {message: 'Please login first', redirectPathname: '/company/' + this.context.reviewAddCompany.slug}});
                    },
                }, {
                    type: 'neutral',
                    title: 'Register',
                    onClick: () => {
                        this.props.history.push({pathname: '/register', state: {message: 'Please register first', redirectPathname: '/company/' + this.context.reviewAddCompany.slug}});
                    },
                }]
            }, this.context);
        }
    }

    render() {
        return (
            <div id="review-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <form id="review-modal-form" className="review add" onSubmit={(e) => {
                            e.preventDefault();
                            this.submit();
                        }}>
                            {this.state.submitting && <Loading />}
                            <div className="heading">Add Review</div>
                            <div className="rating">
                                <div className={'face face-' + this.state.rating}>
                                    <i className={'icon las la-' + this.state.ratings[this.state.rating].icon} />
                                    <div className="title">{this.state.ratings[this.state.rating].title}</div>
                                </div>
                                <input id="review-rating" type="range" name="rating" min="1" max="5" step="1" value={this.state.rating} placeholder="Rating" className="form-control" onChange={(e) => {this.setState({rating: e.target.value})}} />
                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.rating.join('<br />')}} />
                            </div>
                            <div className="form-groups">
                                <div className="form-group">
                                    <textarea id="review-comment" name="review" value={this.state.comment} placeholder="Add Comment" className="form-control" rows="3" maxLength="100" onChange={(e) => {this.setState({comment: e.target.value})}} />
                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.review.join('<br />')}} />
                                </div>
                            </div>
                            <div className="message">{this.state.message}</div>
                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ReviewAdd);