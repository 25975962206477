import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import {Link} from 'react-router-dom';
import Resource from './../Templates/Layouts/Resource';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';

export default class ArticleResearchPaperList extends React.Component {
    constructor(props) {
        super(props);
        ArticleResearchPaperList.contextType = Context;
        this.state = {
            term: null,
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Articles and Research Papers'});
        let param = Api.queryStringToObj(this.props.location.search);
        this.setState({term: param.term}, () => {
            this.fetchList();
        });
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchList() {
        if(!this.state.fetching) {
            let term = this.state.term;
            this.setState({fetching: true});
            Api.fetch('research.list', undefined, {
                search: this.state.term,
                offset: this.state.offset,
                limit: this.state.limit
            }).then(({status, responseJSON}) => {
                if(status === 200) {
                    this.setState({
                        list: [...this.state.list, ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: this.state.offset + (responseJSON.data || []).length
                    });
                }
                this.setState({fetching: false}, () => {
                    if(this.state.term !== term) {
                        this.resetList(() => this.fetchList());
                    }
                });
            }).catch((reason) => {
                this.setState({fetching: false}, () => {
                    if(this.state.term !== term) {
                        this.resetList(() => this.fetchList());
                    }
                });
                console.debug(reason);
            });
        }
    }

    render() {
        return (
            <Resource heading="Articles & Research" title="Articles and Research Papers">
                <main id="main" className="resource articles-research-papers">
                    <div className="container">
                        <div className="filters">
                            <input type="search" value={this.state.term} placeholder="Search" className="form-control" onChange={(e) => {this.setState({term: e.target.value}, () => {this.resetList(() => this.fetchList())});}} />
                            <select name="type" onChange={(e) => {this.setState({page: 1, type: e.target.value}, () => {this.resetList(() => this.fetchList())})}} value={this.state.type}>
                                <option value="0" disabled>Sort By</option>
                                <option value="top">Top Viewed</option>
                                <option value="downloads">Most Downloaded</option>
                            </select>
                        </div>
                        <div className="list">
                            {!this.state.fetching && !this.state.list.length && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="message">No result</span>
                                </div>
                            )}
                            <div className="row">
                                {this.state.list.map((item, index) => (
                                    <div key={index} className="col-xl-4">
                                        <div className="item">
                                            <Link to={{pathname: '/resource/article-research-paper/' + item.slug, state: {item: item}}} className="cover">
                                                <img src={item.book_cover ? item.book_cover.url : '/assets/images/image-placeholder.png'} alt={item.title} className="image" />
                                            </Link>
                                            <div className="details">
                                                {/*<div className="user">*/}
                                                {/*    <img src={item.publisher ? item.publisher.photo.url : '/assets/images/vectors/avatar.svg'} alt={item.title} className="avatar" />*/}
                                                {/*</div>*/}
                                                <Link to={{pathname: '/resource/article-research-paper/' + item.slug, state: {item: item}}} className="title">{item.title}</Link>
                                                {/*<div className="downloads">*/}
                                                {/*    {item.download_count || 0} {item.download_count > 1 ? 'Downloads' : 'Download'}*/}
                                                {/*</div>*/}
                                                {/*<div className="rating">*/}
                                                {/*    <div className="stars">*/}
                                                {/*        {Array.from({length: 5}).map((el, i) => (<i key={i} className={'icon star las la-star' + (i < item.rating ? ' shaded' : '')} />))}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                                {this.state.fetching && <Loading />}
                            </div>
                            {!this.state.fetching && this.state.list.length < this.state.total && (
                                <div className="list-load-more">
                                    <button onClick={() => {this.fetchList()}}>Load More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </Resource>
        );
    }
}
