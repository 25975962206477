import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Resource from './../Templates/Layouts/Resource';
import Loading from './../../../App/Components/Templates/Loading';
import DatePicker from 'react-datepicker';
import Document from './../../../App/Components/Utils/Document';
import Time from './../../../App/Components/Utils/Time';

export default class MortgageCalculator extends React.Component {
    constructor(props) {
        super(props);
        MortgageCalculator.contextType = Context;
        this.state = {
            monthlyPrincipalInterest: '',
            total360Payments: '',
            payOffDate: '',
            loanAmount: '',
            interestRate: '',
            loanTerm: '',
            startDate: '',
            propertyTax: '',
            PMI: '',
            message: '',
            error: {
                loan_amount: [],
                interest_rate: [],
                loan_term: [],
                start_date: [],
                property_tax: [],
                pmi: [],
            },
            submitting: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Mortgage Calculator'});
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({monthlyPrincipalInterest: '', total360Payments: '', payOffDate: '', message: ''});
        if (!parseFloat(this.state.loanAmount)) {
            error.loan_amount.push('Please provide the loan amount');
        } else if (this.state.loanAmount <= 0) {
            error.loan_amount.push('Please provide a valid loan amount');
        }

        if (!parseFloat(this.state.interestRate)) {
            error.interest_rate.push('Please provide the interest rate');
        } else if (this.state.interestRate <= 0) {
            error.interest_rate.push('Please provide a valid interest rate');
        }

        if (!parseFloat(this.state.loanTerm)) {
            error.loan_term.push('Please provide the loan term');
        } else if (this.state.loanTerm <= 0) {
            error.loan_term.push('Please provide a valid loan term');
        }

        if (!parseInt(this.state.startDate)) {
            error.start_date.push('Please select the start date');
        }

        // if (!parseFloat(this.state.propertyTax)) {
        //     error.property_tax.push('Please provide the property tax');
        // }

        // if (!parseFloat(this.state.pmi)) {
        //     error.pmi.push('Please provide the PMI');
        // }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        const paymentsPerYear = 12
        let monthlyPayment, endDate, monthlyPrincipalInterest, total360Payments, payOffDate;
        let principal = parseFloat(this.state.loanAmount);
        if(parseFloat(this.state.propertyTax)) {
            principal += (parseFloat(this.state.propertyTax) * parseFloat(this.state.loanTerm));
        }
        if(parseFloat(this.state.pmi)) {
            principal += (parseFloat(this.state.propertyTax) * parseFloat(this.state.pmi));
        }
        monthlyPayment = ((((parseFloat(this.state.interestRate) / 100) / paymentsPerYear) * principal) / (1 - (Math.pow (1 + ((parseFloat(this.state.interestRate) / 100) / paymentsPerYear), (parseFloat(this.state.loanTerm) * -paymentsPerYear)))));
        endDate = parseInt(this.state.startDate) + (parseFloat(this.state.loanTerm) * 31540000000)

        monthlyPrincipalInterest = '₦ ' + monthlyPayment.toFixed(2);
        total360Payments = '₦ ' + (monthlyPayment * parseFloat(this.state.loanTerm) * paymentsPerYear).toFixed(2);
        payOffDate = Time.months[new Date(endDate).getMonth()]+ ', ' + new Date(endDate).getFullYear();
        this.setState({
            monthlyPrincipalInterest: monthlyPrincipalInterest,
            total360Payments: total360Payments,
            payOffDate: payOffDate,
            submitting: false
        });
    }

    render() {
        return (
            <Resource heading="Mortgage Calculator" title="Mortgage Calculator">
                <main id="main" className="resource mortgage-calculator">
                    <div className="container">
                        <form className="steps" onSubmit={(e) => {
                            e.preventDefault();
                            this.submit();
                        }}>
                            {this.state.submitting && <Loading />}
                            <img src="/assets/images/vectors/icons/offer.svg" alt="Offer" className="icon" />
                            <div className="title">Calculator</div>
                            <div className="message">{this.state.message}</div>
                            <div className="step">
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="mortgage-calculator-loan-amount">Loan Amount *</label>
                                                <input id="mortgage-calculator-loan-amount" type="number" name="loan_amount" value={this.state.loanAmount} min="0" step="0.01" placeholder="Loan Amount" className="form-control" onChange={(e) => {this.setState({loanAmount: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.loan_amount.join('<br />')}} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="mortgage-calculator-interest-rate">Interest Rate (%) *</label>
                                                <input id="mortgage-calculator-interest-rate" type="number" name="interest_rate" value={this.state.interestRate} min="0" max="100" step="0.01" placeholder="Interest Rate" className="form-control" onChange={(e) => {this.setState({interestRate: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.interest_rate.join('<br />')}} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="mortgage-calculator-loan-term">Loan Term (in years) *</label>
                                                <input id="mortgage-calculator-loan-term" type="number" name="loan_term" value={this.state.loanTerm} min="0" placeholder="Loan Term" className="form-control" onChange={(e) => {this.setState({loanTerm: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.loan_term.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="mortgage-calculator-start-date">Start Date *</label>
                                                <DatePicker id="mortgage-calculator-start-date" name="start_date" selected={this.state.startDate} onChange={(date) => {this.setState({startDate: new Date(date).getTime()})}} dateFormat="Y-M-d" placeholderText="Start Date" className="date-picker" autoComplete="off" required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.start_date.join('<br />')}} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="mortgage-calculator-property-tax">Property Tax (annual)</label>
                                                <input id="mortgage-calculator-property-tax" type="number" name="property_tax" value={this.state.propertyTax} min="0" step="0.01" placeholder="Property Tax" className="form-control" onChange={(e) => {this.setState({propertyTax: e.target.value})}} />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.property_tax.join('<br />')}} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field">
                                                <label htmlFor="mortgage-calculator-pmi">PMI (annual)</label>
                                                <input id="mortgage-calculator-pmi" type="number" name="pmi" value={this.state.pmi} placeholder="PMI" min="0" step="0.01" className="form-control" onChange={(e) => {this.setState({pmi: e.target.value})}} />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.pmi.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4" />
                                        <div className="col-md-4">
                                            <div className="submit">
                                                <button type="submit">Calculate</button>
                                            </div>
                                        </div>
                                        <div className="col-md-4" />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="result">
                            <div className="title">Mortgage Summary</div>
                            <div className="data">
                                <div className="datum">
                                    <div className="value">{this.state.monthlyPrincipalInterest || '-'}</div>
                                    <div className="prop">Monthly Principal and Interest</div>
                                </div>
                                <div className="datum">
                                    <div className="value">{this.state.total360Payments || '-'}</div>
                                    <div className="prop">Total 360 Payments</div>
                                </div>
                                <div className="datum">
                                    <div className="value">{this.state.payOffDate || '-'}</div>
                                    <div className="prop">Pay-Off Date</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Resource>
        );
    }
}
