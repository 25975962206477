import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';

export default class List extends React.Component {
    constructor(props) {
        super(props);
        List.contextType = Context;
        this.state = {
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'user.buy' ? 'user.buy' : 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Your ' + (this.context.layout === 'user.buy' ? 'PropBuy' : 'PropCrowdy') +' Notifications');
        Document.updateDocumentHead({title: (this.context.layout === 'user.buy' ? 'PropBuy' : 'PropCrowdy') + ' - Notifications'});
        this.fetchList();
        List.fetchUnreadList(this.context);
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'new.notification') {
                this.fetchList(true);
            }
        }
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }
            Api.fetch('notification.list', undefined, {
                offset: update ? 0 : this.state.offset,
                limit: update ? Math.max(this.state.list.length, this.state.limit) : this.state.limit
            }).then(({status, responseJSON}) => {
                if(status === 200) {
                    this.setState({
                        list: [...(update ? [] : this.state.list), ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: (update ? 0 : this.state.offset) + (responseJSON.data || []).length
                    });
                }
                (responseJSON.data || []).map((notification) => {
                    if(!parseInt(notification.read)) {
                        this.markNotificationsRead(notification.id);
                    }
                    return true;
                })
                this.setState({fetching: false});
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    static fetchUnreadList(context) {
        Api.fetch('notification.list', undefined, {type: 'unread', unread: 1, read: 0, limit: 5}).then(({status, responseJSON}) => {
            if(status === 200) {
                context.updateContext('unreadNotifications', responseJSON.data || []);
            }
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    static clearUnreadList(context) {
        context.updateContext('unreadNotifications', []);
    }

    markNotificationsRead(id) {
        Api.fetch('notification.read', undefined, {customer_id: this.context.user.id, id: id}).then(() => {
            List.fetchUnreadList(this.context);
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    hideNotifications(index) {
        let list = this.state.list;
        Api.fetch('notification.delete', undefined, {id: this.state.list[index].id}).then(() => {}).catch((reason) => {console.debug(reason)});
        list.splice(index, 1);
        this.setState({list: list, total: this.state.total - 1});
    }

    render() {
        return (
            <main id="main" className="opportunity notifications admin listing">
                <div className="container">
                    <div className="list">
                        {!this.state.fetching && !this.state.list.length && (
                            <div className="list-empty">
                                <i className="icon las la-info-circle" />
                                <span className="message">No notification</span>
                            </div>
                        )}
                        <div className="items">
                            {this.state.list.map((notifications, index) => (
                                <div key={index} className="item notification">
                                    <div className="overview">
                                        <div className="left">
                                            <img src="/assets/images/vectors/icons/cash.svg" alt="Notifications" className="icon" />
                                        </div>
                                        <div className="middle">
                                            <div className="top">
                                                <div className="title">{notifications.title}</div>
                                                <div className="time">{Time.timeToFriendly(notifications.created_at, 0)}</div>
                                            </div>
                                            <div className="middle hide-mobile">
                                                <div className="title">Notification</div>
                                            </div>
                                            <div className="bottom">
                                                <button className="toggle-details">
                                                    <span className="title">Details</span>
                                                    <i className="icon las la-angle-down" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="top hide-mobile">
                                                <div className="title">Hide Notification</div>
                                            </div>
                                            <div className="bottom hide-mobile">
                                                <div className="actions">
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        UI.alert({
                                                            title: 'Confirmation',
                                                            content: 'Are you sure you want to remove this notification?',
                                                            buttons: [{
                                                                type: 'positive',
                                                                title: 'Yes',
                                                                onClick: () => {
                                                                    this.hideNotifications(index);
                                                                },
                                                            }, {
                                                                type: 'neutral',
                                                                title: 'No'
                                                            }]
                                                        }, this.context);
                                                    }} className="green">Hide</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details" dangerouslySetInnerHTML={{__html: notifications.body}} />
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        UI.alert({
                                            title: 'Confirmation',
                                            content: 'Are you sure you want to remove this notification?',
                                            buttons: [{
                                                type: 'positive',
                                                title: 'Yes',
                                                onClick: () => {
                                                    this.hideNotifications(index);
                                                },
                                            }, {
                                                type: 'neutral',
                                                title: 'No'
                                            }]
                                        }, this.context);
                                    }} className="close-button show-mobile">
                                        <i className="icon las la-times" />
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                            {this.state.fetching && <Loading />}
                        </div>
                        {!this.state.fetching && this.state.list.length < this.state.total && (
                            <div className="list-load-more">
                                <button onClick={() => {this.fetchList()}}>Load More</button>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}
