import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import ReactPaginate from "react-paginate";
import OpportunityListItem from "./../Templates/OpportunityListItem";
import Loading from "./../../../App/Components/Templates/Loading";
import Document from "./../../../App/Components/Utils/Document";
import Hook from "./../../../App/Components/Utils/Hook";
import Api from "./../../../App/Components/Utils/Api";
import noticeBanner from "../../../App/assets/images/banner-notice.gif";
import { Link } from "react-router-dom";
import Opportunities from "./Opportunities";

export default class List extends React.Component {
  constructor(props) {
    super(props);
    List.contextType = Context;
    this.state = {
      type: "0",
      amount: null,
      return: null,
      tenor: null,
      term: null,
      page:
        Api.queryStringToObj(this.props?.history?.location?.search).page || 1,
      // 1,
      limit:
        Api.queryStringToObj(this.props?.history?.location?.search).limit || 24,
      // 24,

      list: [],
      total: 0,
      fetching: false,
    };
  }

  componentDidMount() {
    this.context.updateContext("layout", "default");
    // console.log("this is the props inside the List component: ", this.props);
    Document.updateDocumentHead({ title: "PropCrowdy - Discover" });
    let param = Api.queryStringToObj(this.props?.location?.search);
    // console.log(
    //   "checking it out: ",
    //   Api.queryStringToObj(this.props?.location?.search)
    // );
    // console.log(
    //   "checking it out the main text: ",
    //   this.props?.location?.search
    // );
    // console.log("checking it out the main text props: ", this.props);
    this.setState(
      {
        amount: param.amount,
        return: param.return,
        tenor: param.tenor,
        term: param.term,
      },
      () => {
        this.fetchList();
      }
    );
    Hook.register("push.on.message", this.pushOnMessage.bind(this));
  }

  componentWillUnmount() {
    Hook.unregister("push.on.message", this.pushOnMessage.bind(this));
  }

  pushOnMessage(result, message) {
    if (typeof message == "object") {
      if (
        [
          "investment.payment.successful",
          "pool.investment.payment.successful",
        ].indexOf(message.type) !== -1
      ) {
        this.fetchList(true);
      }
    }
  }

  fetchList(update) {
    if (!this.state.fetching) {
      if (!update) {
        this.setState({ fetching: true });
      }
      Api.fetch("opportunity.list", undefined, {
        type: this.state.type,
        amount: this.state.amount,
        return: this.state.return,
        tenor: this.state.tenor,
        search: this.state.term,
        offset: (this.state.page - 1) * this.state.limit,
        limit: this.state.limit,
      })
        .then(({ status, responseJSON }) => {
          this.setState({ fetching: false });
          if (status === 200) {
            this.setState({
              list: responseJSON.data || [],
              total: responseJSON.total,
            });
          }
        })
        .catch((reason) => {
          this.setState({ fetching: false });
          console.debug(reason);
        });
    }
  }

  render() {
    return (
      <main id="main" className="opportunity opportunities discover">
        <div className="bread-crumb">
          {this.state.list && (
            <div className="container">
              <Link to={{ pathname: "/" }} className="link">
                Home
              </Link>
              <i className="icon arrow las la-angle-right" />
              <Link to={{ pathname: "/discover" }} className="link">
                {/* {this.state.article.title} */}
                Opportunities
              </Link>
            </div>
          )}
        </div>
        <div className="container">
          <div className="heading">Browse Opportunities</div>
          <div
            style={{
              maxWidth: "351px",
              textAlign: "center",
              fontFamily: "ApercuProMediumNormal",
              margin: "0 auto",
            }}
            className="sub-heading"
          >
            Real Estate Companies in Nigeria raising money for property
            development.
          </div>
          {/* <div className="filters">
            <select
              name="type"
              onChange={(e) => {
                this.setState({ page: 1, type: e.target.value }, () => {
                  this.fetchList();
                });
              }}
              value={this.state.type}
            >
              <option value="0" disabled>
                Sort by Minimum Investment
              </option>
              <option value="low-high">Lowest Minimum Investment</option>
              <option value="high-low">Highest Minimum Investment</option>
            </select>
          </div> */}
          <div className="list">
            {this.state.fetching && <Loading />}
            {!this.state.fetching && !this.state.list.length && (
              <div className="list-empty">
                <i className="icon las la-info-circle" />
                <span className="message">No result</span>
              </div>
            )}
            <div className="listing_container">
              {this.state.list.map((opportunity, index) => (
                <div key={index} id="opportunity_display_card">
                  <OpportunityListItem opportunity={opportunity} />
                </div>
              ))}
            </div>
          </div>
          <div className="pagination-container">
            {this.state.total ? (
              <div className="limits">
                {/*<label>Show:</label>*/}
                {/*<Link to={{pathname: '#'}} className={'limit' + (this.state.limit === 24 ? ' active' : '')} onClick={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    this.setState({page: 1, limit: 24}, () => {*/}
                {/*        this.fetchList();*/}
                {/*    });*/}
                {/*}}>24</Link>*/}
                {/*<Link to={{pathname: '#'}} className={'limit' + (this.state.limit === 36 ? ' active' : '')} onClick={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    this.setState({page: 1, limit: 36}, () => {*/}
                {/*        this.fetchList();*/}
                {/*    });*/}
                {/*}}>36</Link>*/}
                {/*<Link to={{pathname: '#'}} className={'limit' + (this.state.limit === 48 ? ' active' : '')} onClick={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    this.setState({page: 1, limit: 48}, () => {*/}
                {/*        this.fetchList();*/}
                {/*    });*/}
                {/*}}>48</Link>*/}
              </div>
            ) : null}
            <ReactPaginate
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(this.state.total / this.state.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={({ selected }) => {
                this.setState({ page: selected + 1 }, () => {
                  this.fetchList();
                });
              }}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link icon las la-angle-left"}
              nextLinkClassName={"page-link icon las la-angle-right"}
              activeClassName={"active"}
              hrefBuilder={(page) => "#"}
              initialPage={this.state.page - 1}
            />
          </div>
        </div>
      </main>
    );
  }
}
