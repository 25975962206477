import './../assets/style.css';
import React from 'react';
import {Context} from './../../App/Components/Context';

export default class Notify extends React.Component {
    constructor(props) {
        super(props);
        Notify.contextType = Context;
        this.state = {

        }
    }

    render() {
        return (
            <div id="notification-notify-container" />
        );
    }
}
