import "./../../../assets/style.css";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Context } from "./../../../../App/Components/Context";
import Announcement from "./../../../../Announcement/Components/Templates/View";
import $ from "jquery";
import Time from "./../../../../App/Components/Utils/Time";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    User.contextType = Context;
    this.state = {
      isMobile: window.innerWidth <= 768,
      isZoomScreen: window.innerWidth > 1200 && window.innerWidth <= 1366,
    };
  }

  componentDidMount() {
    if (this.context.user) {
      this.context.updateContext(
        "adminHeaderHeight",
        this.adminHeader.offsetHeight
      );
    } else {
      this.props.history &&
        this.props.history.replace({
          pathname: "/login",
          state: { message: "Please login first" },
        });
    }
    window.addEventListener("resize", this.onResize.bind(this));
    if (
      this.context.user &&
      this.context.user.payment_info &&
      !this.context.user.payment_info.completed
    ) {
      this.props.history &&
        this.props.history.replace({
          pathname: "/account/profile",
          search: this.state.redirectSearch,
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  onResize() {
    this.setState({
      isMobile: window.innerWidth <= 768,
      isZoomScreen: window.innerWidth > 1200 && window.innerWidth <= 1366,
    });
    if (this.adminHeader) {
      this.context.updateContext(
        "adminHeaderHeight",
        this.adminHeader.offsetHeight
      );
    }
  }

  header() {
    return (
      <header
        ref={(adminHeader) => {
          this.adminHeader = adminHeader;
        }}
        id="admin-header"
      >
        <div className="container">
          <div id="admin-header-content">
            {/* <div className="heading">
              {this.context.user.customer_type === "developer" ||
              (this.context.user.customer_type !== "developer" &&
                (parseInt(
                  this.context.user.investment_stat &&
                    this.context.user.investment_stat.allAmountInvested
                ) ||
                  parseInt(
                    this.context.user.pool_funding_stat &&
                      this.context.user.pool_funding_stat.allAmountInvested
                  )))
                ? !this.state.isMobile && (
                    <div className="tab-headings">
                      <Link
                        to={{ pathname: "/dashboard" }}
                        className={
                          "tab-heading" +
                          (this.context.layout !== "user.buy" ? " active" : "")
                        }
                      >
                        <span className="title">
                          <span className="suffix"> Dashboard</span>
                        </span>
                        <span className="description">
                          Manageeeee Fundraising Transactions
                        </span>
                      </Link>
                    </div>
                  )
                : this.context.pageShowWelcome && (
                    <>
                      <div className="welcome">Welcome Back!</div>
                      <div className="username">
                        {this.context.user.first_name +
                          " " +
                          this.context.user.last_name}
                      </div>
                    </>
                  )}
              {this.context.user.customer_type !== "developer" &&
              !this.state.isMobile ? (
                <div className="actions">
                  <button
                    className="action"
                    onClick={() => {
                      $("#wallet-modal").modal("show");
                    }}
                  >
                    <i className="icon las la-wallet" />
                    <span className="title">Wallet</span>
                  </button>
                  <Link to={{ pathname: "/discover" }} className="action">
                    <span className="title">Start Funding</span>
                    <i className="icon las la-arrow-right" />
                  </Link>
                </div>
              ) : null}

              <div className="sub-heading">{this.context.pageSubHeading}</div>
            </div> */}
            <p>Welcome Back!</p>
            <div className="info">
              <div className="account">
                <div className="user">
                  <img
                    className="avatar"
                    src={
                      this.context.user.photo
                        ? this.context.user.photo.url
                        : "/assets/images/vectors/avatar.svg"
                    }
                    alt={
                      this.context.user.first_name +
                      " " +
                      this.context.user.last_name
                    }
                  />
                  <div className="auth">
                    <Link to={{ pathname: "/logout" }} className="link logout">
                      Logout
                    </Link>
                    <div className="type">
                      {(
                        (this.context.constants &&
                          this.context.constants.user &&
                          this.context.constants.user.customer_type) ||
                        []
                      ).reduce((types, type) => {
                        types[type.value] = type.display;
                        return types;
                      }, {})[this.context.user.customer_type] ||
                        String(this.context.user.customer_type)
                          .replace(/[^A-Za-z0-9]/gi, " ")
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.substring(1)
                          )
                          .join(" ")}
                    </div>
                    {(this.context.user.customer_type === "developer" ||
                      (this.context.user.customer_type !== "developer" &&
                        (parseInt(
                          this.context.user.investment_stat &&
                            this.context.user.investment_stat.allAmountInvested
                        ) ||
                          parseInt(
                            this.context.user.pool_funding_stat &&
                              this.context.user.pool_funding_stat
                                .allAmountInvested
                          )))) &&
                    this.state.isMobile ? (
                      <>
                        {this.context.layout === "user.buy" ? (
                          <Link
                            to={{ pathname: "/dashboard" }}
                            className="link propcrowdy"
                          >
                            Dashboard
                          </Link>
                        ) : (
                          <Link
                            to={{ pathname: "/dashboard" }}
                            className="link propcrowdy"
                          >
                            Dashboard
                          </Link>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="menu">
                  <span className="menu-item notification dropdown">
                    <button
                      id="header-notification-dropdown-menu-button"
                      type="button"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {!!(this.context.unreadNotifications || []).length && (
                        <span className="indicator" />
                      )}
                      <img
                        src="/assets/images/vectors/icons/bell.svg"
                        alt="Notifications"
                        className="icon"
                      />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="header-notification-dropdown-menu-button"
                    >
                      {!(this.context.unreadNotifications || []).length && (
                        <div className="dropdown-item message">
                          No new notification
                        </div>
                      )}
                      {(this.context.unreadNotifications || []).map(
                        (update, index) => (
                          <div key={index} className="dropdown-item">
                            <div className="title">{update.title}</div>
                            <div className="time">
                              {Time.timeToFriendly(update.created_at, 0)}
                            </div>
                          </div>
                        )
                      )}
                      <Link
                        to={{ pathname: "/notifications" }}
                        className="dropdown-item"
                      >
                        See All Notifications
                      </Link>
                    </div>
                  </span>
                  <span className="menu-item more dropdown">
                    <button
                      id="header-more-dropdown-menu-button"
                      type="button"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/images/vectors/icons/menu.svg"
                        alt="More"
                        className="icon"
                      />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="header-more-dropdown-menu-button"
                    >
                      <Link
                        to={{ pathname: "/messages" }}
                        className="dropdown-item"
                      >
                        Messages
                      </Link>
                      <Link
                        to={{ pathname: "/account" }}
                        className="dropdown-item"
                      >
                        Account
                      </Link>
                      <Link
                        to={{ pathname: "/logout" }}
                        className="dropdown-item"
                      >
                        Logout
                      </Link>
                    </div>
                  </span>
                  <span className="menu-item">
                    <button
                      type="button"
                      id="header-more-dropdown-menu-button"
                      className="nav-toggler admin-nav-toggler"
                    >
                      <img
                        src="/assets/images/vectors/icons/menu-fries.svg"
                        alt="More"
                        className="icon"
                      />
                    </button>
                  </span>
                </div>
              </div>
              {this.context.user.customer_type === "developer"
                ? !this.state.isMobile && (
                    <div className="actions">
                      {/* <button
                        className="action"
                        onClick={() => {
                          $("#wallet-modal").modal("show");
                        }}
                      >
                        <i className="icon las la-wallet" />
                        <span className="title">Wallet</span>
                      </button> */}
                      {/* <Link
                        to={{ pathname: "/opportunity/create" }}
                        className="action"
                      >
                        <span className="title">Get Funded</span>
                        <i className="icon las la-arrow-right" />
                      </Link> */}
                    </div>
                  )
                : (!this.state.isMobile ||
                    window.location.pathname.split("/")[1] === "dashboard") && (
                    <>
                      {/*
                                    <div className="stat">
                                        <div className="title">Referral Code</div>
                                        <div className="data">{this.context.user.customer_number}</div>
                                    </div>
                                    <div className="stat">
                                        <div className="title">Total Amount Invested</div>
                                        <div className="data">₦{parseInt((this.context.user.investment_stat ? this.context.user.investment_stat.allAmountInvested : 0) + (this.context.user.pool_funding_stat ? this.context.user.pool_funding_stat.allAmountInvested : 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                                        <Link to={{pathname: '/fund/history'}} className="link">
                                            <img src="/assets/images/vectors/icons/stats.svg" alt="Stat" className="icon"/>
                                            Check Details
                                        </Link>
                                    </div>*/}
                    </>
                  )}
            </div>
          </div>
          <Announcement />
        </div>
      </header>
    );
  }

  aside() {
    return (
      <aside id="aside">
        <div id="aside-content">
          <nav className="nav">
            <button className="nav-toggler admin-nav-toggler">
              <i className="icon las la-times" />
            </button>
            {this.context.layout === "user.buy" ? (
              <NavLink
                id="aside-logo"
                to={{ pathname: "/buy" }}
                className="aside-brand"
              >
                <img src="/assets/images/logo-3.png" alt="PropBuy Logo" />
              </NavLink>
            ) : (
              <NavLink
                id="aside-logo"
                to={{ pathname: "/home" }}
                className="aside-brand"
              >
                <img
                  src="/assets/images/vectors/logo-light.svg"
                  alt="PropCrowdy Logo"
                />
              </NavLink>
            )}
            <div
              style={{ color: "#fff" }}
              id="profile_info"
              className="profile_info_container"
            >
              {this.context.user.profile_picture ? (
                <img
                  src={this.context.profile_picture}
                  alt="user dp"
                  className="profile_picture"
                />
              ) : (
                <img
                  src="/assets/images/vectors/avatar.svg"
                  alt="avatar"
                  className="profile_picture"
                />
              )}
              <p className="profile_name">
                {this.context.user.first_name} {this.context.user.last_name}
              </p>
            </div>
            <ul className="nav-bar">
              {this.context.layout === "user.buy" ? (
                <>
                  {/*<li className="nav-item">*/}
                  {/*    <NavLink to={{pathname: '/property/list'}} className="nav-link">*/}
                  {/*        <img className="icon" src="/assets/images/vectors/icons/home.svg" alt="Properties"/>*/}
                  {/*        <span className="title">Properties</span>*/}
                  {/*    </NavLink>*/}
                  {/*</li>*/}
                  {this.context.user?.customer_type === "developer" ? (
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/property/my-list" }}
                        className="nav-link"
                      >
                        <i className="icon las la-warehouse" />
                        <span className="title">Properties</span>
                      </NavLink>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/property/purchases" }}
                      className="nav-link"
                    >
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/shopping-cart.svg"
                        alt="Funding History"
                      />
                      <span className="title">Purchases</span>
                    </NavLink>
                  </li>
                </>
              ) : this.context.user.customer_type === "developer" ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/dashboard" }}
                      className="nav-link"
                    >
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/home.svg"
                        alt="Dashboard"
                      />
                      <span className="title">Dashboard</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/discover" }}
                      className="nav-link"
                    >
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/opportunity.svg"
                        alt="Dashboard"
                      />
                      <span className="title">Opportunities</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/fund/raisers" }}
                      className="nav-link"
                    >
                      <i className="icon las la-money-bill-wave" />
                      <span className="title">Funding Raisers</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/fund/history" }}
                      className="nav-link"
                    >
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/shopping-cart.svg"
                        alt="Funding History"
                      />
                      <span className="title">Funding History</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/fund/updates" }}
                      className="nav-link"
                    >
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/tickets.svg"
                        alt="Funding Updates"
                      />
                      <span className="title">
                        Funding Updates
                        {!!(this.context.unreadFundingUpdates || []).length && (
                          <span className="indicator" />
                        )}
                      </span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  {this.context.user.platform === "propcrowdy" ||
                  parseInt(
                    this.context.user.investment_stat &&
                      this.context.user.investment_stat.allAmountInvested
                  ) ||
                  parseInt(
                    this.context.user.pool_funding_stat &&
                      this.context.user.pool_funding_stat.allAmountInvested
                  ) ? (
                    <>
                      <li>
                        <NavLink
                          to={{ pathname: "/dashboard" }}
                          className="nav-link"
                        >
                          <img
                            className="icon"
                            src="/assets/images/vectors/icons/home.svg"
                            alt="Dashboard"
                          />
                          <span className="title">Dashboard</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={{ pathname: "/discover" }}
                          className="nav-link"
                        >
                          <img
                            className="icon"
                            src="/assets/images/vectors/icons/opportunity.svg"
                            alt="Opportunities"
                          />
                          <span className="title">Opportunities</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={{ pathname: "/fund/history" }}
                          className="nav-link"
                        >
                          <img
                            className="icon"
                            src="/assets/images/vectors/icons/shopping-cart.svg"
                            alt="Funding History"
                          />
                          <span className="title">Funding History</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={{ pathname: "/fund/updates" }}
                          className="nav-link"
                        >
                          <img
                            className="icon"
                            src="/assets/images/vectors/icons/tickets.svg"
                            alt="Funding Updates"
                          />
                          <span className="title">
                            Funding Updates{" "}
                            {!!(this.context.unreadFundingUpdates || [])
                              .length && <span className="indicator" />}
                          </span>
                        </NavLink>
                      </li>
                    </>
                  ) : null}
                </>
              )}
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/notifications" }}
                  className="nav-link"
                >
                  <img
                    className="icon"
                    src="/assets/images/vectors/icons/bell.svg"
                    alt="Notifications"
                    style={{ filter: "grayscale() brightness(10)" }}
                  />
                  <span className="title">
                    Notifications
                    {!!(this.context.unreadNotifications || []).length && (
                      <span className="indicator" />
                    )}
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={{ pathname: "/messages" }} className="nav-link">
                  <img
                    className="icon"
                    src="/assets/images/vectors/icons/message-square.svg"
                    alt="Messages"
                  />
                  <span className="title">
                    Messages
                    {!!(this.context.unreadConversations || []).length && (
                      <span className="indicator" />
                    )}
                  </span>
                </NavLink>
              </li>
              {this.context.layout === "user.buy" ? (
                this.context.user.customer_type === "developer" ? (
                  <>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to={{pathname: '/account/agency'}} className="nav-link">*/}
                    {/*        <i className="icon las la-building" />*/}
                    {/*        <span className="title">Agency Details</span>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                  </>
                ) : (
                  <></>
                )
              ) : this.context.user.customer_type === "developer" ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/company/details" }}
                      className="nav-link"
                    >
                      <i className="icon las la-building" />
                      <span className="title">Company Details</span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <></>
              )}
              {this.context.user.customer_type !== "developer" ? (
                <>
                  <li className="nav-item">
                    <Link to={{ pathname: "/referrals" }} className="nav-link">
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/opportunity.svg"
                        alt="Referrals"
                      />
                      <span className="title">Referrals</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: "/account/payment" }}
                      className="nav-link"
                    >
                      <img
                        className="icon"
                        src="/assets/images/vectors/icons/tickets.svg"
                        alt="Financial Information"
                      />
                      <span className="title">Financial Information</span>
                    </Link>
                  </li>
                </>
              ) : null}
              <li className="nav-item">
                <NavLink to={{ pathname: "/account" }} className="nav-link">
                  <img
                    className="icon"
                    src="/assets/images/vectors/icons/account-balance.svg"
                    alt="Accounts"
                  />
                  <span className="title">Accounts</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/support/tickets" }}
                  className="nav-link"
                >
                  <img
                    className="icon"
                    src="/assets/images/vectors/icons/message-circle.svg"
                    alt="Support Centre"
                  />
                  <span className="title">Support Centre</span>
                </NavLink>
              </li>
              {this.state.isMobile ? (
                <>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={(e) => {
                        $("#wallet-modal").modal("show");
                      }}
                    >
                      <i className="icon las la-wallet" />
                      <span className="title">Wallet</span>
                    </button>
                  </li>
                  {this.context.user.customer_type === "developer" ? (
                    <li className="nav-item">
                      <Link
                        to={{ pathname: "/opportunity/create" }}
                        className="nav-link"
                      >
                        <i className="icon las la-money-check" />
                        <span className="title">Get Funded</span>
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <Link to={{ pathname: "/discover" }} className="nav-link">
                        <i className="icon las la-money-check" />
                        <span className="title">Start Funding</span>
                      </Link>
                    </li>
                  )}
                </>
              ) : null}
              <li className="nav-item">
                <NavLink to={{ pathname: "/logout" }} className="nav-link">
                  <img
                    className="icon"
                    src="/assets/images/vectors/icons/log-out.svg"
                    alt="Log Out"
                  />
                  <span className="title">Log Out</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }

  render() {
    return (
      <div
        id="layout"
        className={
          "user" +
          (this.props.htmlClasses ? " " + this.props.htmlClasses.join(" ") : "")
        }
      >
        {this.aside()}
        <div id="aside-main" style={{}}>
          {this.header()}
          <style
            dangerouslySetInnerHTML={{
              __html:
                "#layout.user #main {min-height: calc(" +
                (this.state.isZoomScreen ? 125 : 100) +
                "vh - " +
                (this.context.adminHeaderHeight || 0) +
                "px);}",
            }}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}
