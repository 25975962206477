import "../../assets/stylings/accessories_stylings/NumberOfInvestorsCard_styling.css";
import investedPeople12k from "../../assets/images/invested_people_12k.png";
import reviewerDp from "../../assets/images/reviewer_dp.png";
import { useState } from "react";
import { useEffect } from "react";

const NumberOfInvestorsCard = ({ investments }) => {
  // console.log("inside the NumberOfInvestorsCard Component: ", investments);
  // const [customers, setCustomers] = useState([]);
  const [subCustomers, setSubCustomers] = useState([]);
  useEffect(() => {
    setSubCustomers(investments.slice(0, 4));
  }, [investments]);
  return (
    <div className="number_of_investors">
      <ul className="circular_images">
        {subCustomers.map((customer, index) => {
          return customer?.customer?.photo?.preview ? (
            <li key={index} className="images">
              <img
                src={customer?.customer?.photo?.preview || reviewerDp}
                alt=""
                className="image"
              />
            </li>
          ) : (
            // <img
            //   src={reviewerDp}
            //   alt=""
            //   className="images_placeholder"
            //   key={index}
            // />
            <li key={index} className="images">
              <img
                src={
                  customer?.customer?.photo?.preview ||
                  "https://office.propcrowdy.com/storage/238/61dc372ac1688_IMG-20200702-WA0001.jpg"
                }
                alt=""
                className="image"
              />
            </li>
          );
        })}
        <div className="images empty image">{investments.length}</div>
      </ul>
      {/* <img src={investedPeople12k} alt="" className="invested_people_12k" /> */}
      <p className="description">
        <span style={{ fontFamily: "ApercuProBoldNormal" }}>
          {investments.length} people
        </span>{" "}
        already invested in this offer Don't waste this opportunity, invest now!
      </p>
    </div>
  );
};

export default NumberOfInvestorsCard;

//  {
//               customer?.customer?.photo?.preview ? <div key={index} className="images">
//               <img
//                 src={customer?.customer?.photo?.preview || reviewerDp}
//                 alt=""
//                 className="image"
//               />
//             </div> : <img src={investedPeople12k} alt="" className="invested_people_12k" />
//             }
