import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';

export default class ImageViewer extends React.Component {
    constructor(props) {
        super(props);
        ImageViewer.contextType = Context;
        this.state = {

        }
    }

    render() {
        return (
            <div id="image-viewer-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <img src={this.context.imageViewerModalSrc} alt={this.context.imageViewerModalTitle} className="image" />
                    </div>
                </div>
            </div>
        );
    }
}
