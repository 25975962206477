import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Document from './../../../App/Components/Utils/Document';
import User from './../Utils';

export default class Logout extends React.Component {
    constructor(props) {
        super(props);
        Logout.contextType = Context;
        this.state = {
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'blank');
        Document.updateDocumentHead({title: 'PropCrowdy - Logout'});
        let platform = this.context.user && this.context.user.platform;
        User.logout(this.context);
        this.props.history.replace(platform === 'propbuy' ? '/buy' : '/');
    }

    render() {
        return null;
    }
}
