import "./../../assets/style.css";
import React from "react";
import Loading from "./../../../App/Components/Templates/Loading";
import _ from "lodash";
import Api from "./../../../App/Components/Utils/Api";
import UI from "./../../../App/Components/Utils/UI";
import { Context } from "./../../../App/Components/Context";
import User from "../../../User/Components/Utils";

import toast, { Toaster } from "react-hot-toast";

const notifysuccess = (msg) => toast(msg);
const notifyfail = (msg) => toast(msg);

export default class FinancialInfo extends React.Component {
  constructor(props) {
    super(props);
    FinancialInfo.contextType = Context;
    this.state = {
      identificationTypes: [
        {
          id: "voters_card",
          name: "Voters Card",
        },
        {
          id: "national_id",
          name: "National ID card",
        },
        {
          id: "drivers_license",
          name: "Drivers License",
        },
        {
          id: "international_passport",
          name: "International passport",
        },
      ],
      residenceOption: [
        {
          id: "1",
          name: "Yes",
        },
        {
          id: "2",
          name: "No",
        },
      ],
      financialInstitutionId: "",
      financialAccountName: "",
      financialAccountNumber: "",
      annualIncome: "",
      proofOfAnnualIncome: "",
      proofOfAnnualIncomeDisplayName: "",
      proofOfAnnualIncomeUploadProgress: 0,
      proofOfAnnualIncomeUrl: "",
      bvn: "",
      identificationType: "",
      identification: "",
      identificationDisplayName: "",
      identificationUploadProgress: 0,
      identificationUrl: "",
      residence: 1,
      financialForeign: "",
      documentNumber: "",
      message: "",
      error: {
        bank_id: [],
        account_name: [],
        account_number: [],
        yearly_income: [],
        proof_of_yearly_income: [],
        bvn: [],
        identification_type: [],
        identification: [],
        financialForeign: [],
        residence: [],
        document_number: [],
      },
      submitting: false,
    };
  }

  componentDidMount() {
    this.setState({
      financialInstitutionId:
        (this.context.user.payment_info &&
          this.context.user.payment_info.bank_id) ||
        "0",
      financialAccountName:
        (this.context.user.payment_info &&
          this.context.user.payment_info.account_name) ||
        "",
      financialAccountNumber:
        (this.context.user.payment_info &&
          this.context.user.payment_info.account_number) ||
        "",
      annualIncome:
        (this.context.user.payment_info &&
          this.context.user.payment_info.yearly_income) ||
        "",
      bvn:
        (this.context.user.payment_info &&
          this.context.user.payment_info.bvn) ||
        "",
      identificationType:
        (this.context.user.payment_info &&
          this.context.user.payment_info.identification_type) ||
        "",
      identification:
        (this.context.user.payment_info &&
          this.context.user.payment_info.identification) ||
        "",
      residence:
        (this.context.user.payment_info &&
          this.context.user.payment_info.residence) ||
        1,
      financialForeign:
        (this.context.user.payment_info &&
          this.context.user.payment_info.foreign_account) ||
        "",
      document_id:
        (this.context.user.payment_info &&
          this.context.user.payment_info.document_id) ||
        "",
    });
  }

  uploadFile(fileHandle, key, callback) {
    let setKey = (value) => {
      let state = this.state;
      _.set(state, key, value);
      this.setState(state, () => {
        if (typeof callback === "function") {
          callback.apply();
        }
      });
    };
    let formData = new FormData();
    if (fileHandle.files.length) {
      this.setState(
        {
          [key + "UploadProgress"]: 0.1,
          [key + "DisplayName"]: "Uploading... (0%)",
          [key + "Url"]: URL.createObjectURL(fileHandle.files[0]),
          message: "",
        },
        () => {
          setKey("");
        }
      );
      fileHandle.disabled = true;
      formData.append("file", fileHandle.files[0]);
      Api.fetch("file.upload", undefined, formData, undefined, (progress) => {
        this.setState({
          [key + "UploadProgress"]: progress,
          [key + "DisplayName"]:
            "Uploading... (" + Math.ceil(progress * 100) + "%)",
        });
      })
        .then(({ status, responseJSON }) => {
          fileHandle.disabled = false;
          if (status === 200) {
            this.setState(
              {
                [key + "DisplayName"]: responseJSON.original_name,
                [key + "UploadProgress"]: 0,
              },
              () => {
                setKey(responseJSON.name, () => {
                  this.validateSteps([this.state.step])
                    .then(() => {})
                    .catch(() => {});
                });
              }
            );
          } else {
            this.setState({
              [key + "DisplayName"]: "",
              [key + "UploadProgress"]: 0,
              [key + "Url"]: "/assets/images/image-placeholder.png",
              message: responseJSON.message || "",
            });
          }
        })
        .catch((reason) => {
          this.setState({
            [key + "DisplayName"]: "",
            [key + "UploadProgress"]: 0,
            [key + "Url"]: "/assets/images/image-placeholder.png",
          });
          fileHandle.disabled = false;
          let { status, responseJSON } =
            typeof reason === "object" ? reason : {};
          if ([403, 422].indexOf(status) !== -1) {
            let error = {};
            if (responseJSON.error && responseJSON.error.file) {
              error[
                (key) =>
                  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
              ] = responseJSON.error["file"];
            }
            this.setState({
              error: { ...this.state.error, ...error },
              message: responseJSON.message || "",
            });
          } else {
            console.debug(reason);
            this.setState({
              message:
                (responseJSON && responseJSON.message) ||
                "Error Occurred! Please check the internet and try again",
            });
          }
        });
    } else {
      this.setState(
        {
          [key + "DisplayName"]: "",
          [key + "Url"]: "/assets/images/image-placeholder.png",
        },
        () => {
          setKey("");
        }
      );
    }
  }

  saveFinancialInfo() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (this.state.residence == 1) {
      if (!parseInt(this.state.financialInstitutionId)) {
        error.bank_id.push("Please provide the financial institution name");
      }

      if (!parseInt(this.state.bvn)) {
        error.bvn.push("Please provide your BVN");
      }
    } else if (this.state.residence == 2) {
      if (!parseInt(this.state.residence)) {
        error.residence.push("Please provide your place of residence");
      }

      if (
        typeof this.state.financialForeign !== "string" ||
        this.state.financialForeign.trim() === ""
      ) {
        error.financialForeign.push(
          "Please provide your foreign account details"
        );
      }
    }

    if (
      typeof this.state.financialAccountName !== "string" ||
      this.state.financialAccountName.trim() === ""
    ) {
      error.account_name.push("Please provide the financial account name");
    }

    if (!parseInt(this.state.annualIncome)) {
      error.yearly_income.push("Please provide your annual income");
    }

    if (
      (!this.context.user.payment_info ||
        !this.context.user.payment_info.proof_of_yearly_income ||
        this.context.user.payment_info.yearly_income !==
          this.state.annualIncome) &&
      (typeof this.state.proofOfAnnualIncome !== "string" ||
        this.state.proofOfAnnualIncome.trim() === "")
    ) {
      if (this.state.proofOfAnnualIncomeUploadProgress) {
        error.proof_of_yearly_income.push(
          "Please wait for the upload to finish"
        );
      } else {
        error.proof_of_yearly_income.push(
          "Please provide the proof of your annual income"
        );
      }
    }

    if (
      typeof this.state.identificationType !== "string" ||
      this.state.identificationType.trim() === ""
    ) {
      error.identification_type.push("Please select an identification type");
    }

    if (
      (!this.context.user.payment_info ||
        !this.context.user.payment_info.identification ||
        this.context.user.payment_info.identification !==
          this.state.identification) &&
      (typeof this.state.identification !== "string" ||
        this.state.identification.trim() === "")
    ) {
      if (this.state.identificationUploadProgress) {
        error.identification.push("Please wait for the upload to finish");
      } else {
        error.identification.push("Please provide the ID");
      }
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }

    this.setState({ submitting: true });
    let data = {
      customer_id: this.context.user.id,
      bank_id: this.state.financialInstitutionId,
      account_name: this.state.financialAccountName,
      account_number: this.state.financialAccountNumber,
      monthly_income: this.state.annualIncome / 12,
      yearly_income: this.state.annualIncome,
      bvn: this.state.bvn,
      identification_type: this.state.identificationType,
      identification: this.state.identification,
      residence: this.state.residence,
      document_number: this.state.documentNumber,
    };
    if (this.state.proofOfAnnualIncome) {
      data["proof_of_monthly_income"] = this.state.proofOfAnnualIncome;
      data["proof_of_yearly_income"] = this.state.proofOfAnnualIncome;
    }
    if (parseInt(this.state.residence) === 2) {
      data["foreign_account"] = this.state.financialForeign;
    }
    Api.fetch("payment.user.edit", undefined, data)
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200) {
          User.syncUser(this.context.user, this.context);
          UI.alert(
            {
              title: "Success",
              content: "Financial information saved successfully",
            },
            this.context
          );
          // notifysuccess(responseJSON.message);
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          this.setState({
            error: { ...this.state.error, ...responseJSON.error },
            message: responseJSON.message || "",
          });
          notifyfail(
            //    err.response.data?.errors?.email ||
            //      err.response.data.errors?.last_name ||
            //      err.response.data.errors?.phone_number ||
            //      err.response.data.errors?.first_name ||
            //      err.response.data?.message
            "An error occurred while saving."
          );
        } else {
          console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  render() {
    return (
      <>
        <Toaster />
        <form
          className="user account"
          onSubmit={(e) => {
            e.preventDefault();
            this.saveFinancialInfo();
          }}
        >
          {this.state.submitting && <Loading />}
          <div className="message">{this.state.message}</div>
          <div className="sub-heading first">Custodial Account</div>
          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-financial-institution-id">
                  Are you resident in Nigeria
                </label>
                <div className="info"></div>
              </div>
              <div className="col-xl-6">
                <div className="info">Are you resident in Nigeria</div>
                <select
                  value={this.state.residence}
                  placeholder="Are you resident in Nigeria"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ residence: e.target.value });
                  }}
                  required
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {(
                    this.state.residenceOption || this.state.residenceOption
                  ).map((res, index) => (
                    <option key={index} value={res.id}>
                      {res.name}
                    </option>
                  ))}
                </select>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.residence.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>
          {this.state.residence == 1 ? (
            <div className="field">
              <div className="row">
                <div className="col-xl-6">
                  <label htmlFor="account-financial-institution-id">
                    Financial Institution Name
                  </label>
                  <div className="info">
                    Provide your financial institution or bank name
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="info">Financial Institution / Bank Name</div>
                  <select
                    id="account-financial-institution-id"
                    name="bank_id"
                    value={this.state.financialInstitutionId}
                    placeholder="Financial Institution / Bank Name"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ financialInstitutionId: e.target.value });
                    }}
                    required
                  >
                    <option value="" disabled>
                      Financial Institution / Bank Name
                    </option>
                    {(
                      (this.context.constants &&
                        this.context.constants.payment &&
                        this.context.constants.payment.banks) ||
                      []
                    ).map((bank, index) => (
                      <option key={index} value={bank.id}>
                        {bank.name}
                      </option>
                    ))}
                  </select>
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.error.bank_id.join("<br />"),
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.residence == 2 ? (
            <div className="field">
              <div className="row">
                <div className="col-xl-6">
                  <label htmlFor="account-financial-account-name">
                    Foreign Bank Account Details
                  </label>
                  <div className="info">
                    Provide the your foreign bank account details, the details
                    should include (Bank name, Bank address, swift code, etc)
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="info">Foreign Bank Account Details</div>
                  <textarea
                    placeholder="Foreign bank account details, the details should include (Bank name, Bank address, swift code, etc)"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ financialForeign: e.target.value });
                    }}
                  >
                    {this.state.financialForeign}
                  </textarea>
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.error.financialForeign.join("<br />"),
                    }}
                  />
                </div>
                <div className="col-xl-6"></div>
                <div className="col-xl-6">
                  <div className="alert alert-warning">
                    Your investment is dominated in Naira, and as your
                    investment matures, your account will be credited in Naira.
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-financial-account-name">
                  Financial Account Name
                </label>
                <div className="info">Provide the your account name</div>
              </div>
              <div className="col-xl-6">
                <div className="info">Account Name</div>
                <input
                  id="account-financial-account-name"
                  type="text"
                  name="account_name"
                  value={this.state.financialAccountName}
                  placeholder="Account Name"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ financialAccountName: e.target.value });
                  }}
                  required
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.account_name.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-financial-account-number">
                  Financial Account Number
                </label>
                <div className="info">Provide your account number</div>
              </div>
              <div className="col-xl-6">
                <div className="info">Account Number</div>
                <input
                  id="account-financial-account-number"
                  type="text"
                  name="account_number"
                  value={this.state.financialAccountNumber}
                  placeholder="Account Number"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ financialAccountNumber: e.target.value });
                  }}
                  required
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.account_number.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sub-heading">Income</div>
          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-annual-income">Annual Income</label>
                <div className="info">Average Annual Income (₦)</div>
              </div>
              <div className="col-xl-6">
                <div className="info">
                  How much you earn on average per year in naira
                </div>
                <input
                  id="account-annual-income"
                  type="number"
                  name="yearly_income"
                  value={this.state.annualIncome}
                  placeholder="Annual Income (₦)"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ annualIncome: e.target.value });
                  }}
                  required
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.yearly_income.join("<br />"),
                  }}
                />
              </div>
              <div className="col-xl-6"></div>
              <div className="col-xl-6">
                <div className="alert alert-warning">
                  You can only invest 10% of your annual income.
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-proof-of-annual-income">
                  Proof of Annual Income
                </label>
                <div className="info">Proof of Annual Income</div>
              </div>
              <div className="col-xl-6">
                <div className="info">
                  A document showing proof of your annual income (PDF, EXCEL)
                </div>
                <input
                  id="account-proof-of-annual-income"
                  type="text"
                  name="proof_of_yearly_income_display_name"
                  value={this.state.proofOfAnnualIncomeDisplayName}
                  placeholder="Proof of Annual Income"
                  className="form-control"
                  disabled
                  onChange={(e) => {
                    this.setState({ proofOfAnnualIncome: e.target.value });
                  }}
                />
                <input
                  type="hidden"
                  name="account-proof-of-annual-income"
                  value={this.state.proofOfAnnualIncome}
                />
                <input
                  ref={(proofOfAnnualIncomeFileInput) => {
                    this.proofOfAnnualIncomeFileInput =
                      proofOfAnnualIncomeFileInput;
                  }}
                  type="file"
                  name="proof_of_yearly_income_file"
                  className="display-none"
                  onChange={(e) => {
                    this.uploadFile(e.target, "proofOfAnnualIncome");
                  }}
                />
                <button
                  type="button"
                  className="upload-button upload"
                  style={{
                    boxShadow:
                      (this.state.proofOfAnnualIncome
                        ? this.state.isSmallScreen
                          ? 110
                          : 200
                        : Math.ceil(
                            this.state.proofOfAnnualIncomeUploadProgress *
                              (this.state.isSmallScreen ? 110 : 200)
                          )) + "px 0 rgba(0, 0, 0, .5) inset",
                  }}
                  onClick={() => {
                    this.proofOfAnnualIncomeFileInput.click();
                  }}
                >
                  Choose File
                </button>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.error.proof_of_yearly_income.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sub-heading">Identification</div>
          {this.state.residence == 1 ? (
            <div className="field">
              <div className="row">
                <div className="col-xl-6">
                  <label htmlFor="account-bvn-income">BVN</label>
                  <div className="info">Your BVN </div>
                </div>
                <div className="col-xl-6">
                  <div className="info">BVN linked to your phone number</div>
                  <input
                    id="account-bvn"
                    type="text"
                    name="bvn"
                    value={this.state.bvn}
                    placeholder="BVN"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ bvn: e.target.value });
                    }}
                    required
                  />
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.error.bvn.join("<br />"),
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-identification-type">
                  Identification Type
                </label>
                <div className="info">Your ID type</div>
              </div>
              <div className="col-xl-6">
                <div className="info">Select an identification type</div>
                <select
                  id="account-identification-type"
                  name="identification_type"
                  value={this.state.identificationType}
                  placeholder="Identification Type"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ identificationType: e.target.value });
                  }}
                  required
                >
                  <option value="0" disabled>
                    Identification Type
                  </option>
                  {(
                    (this.context.constants &&
                      this.context.constants.user &&
                      this.context.constants.user.identificationType) ||
                    this.state.identificationTypes
                  ).map((identificationType, index) => (
                    <option key={index} value={identificationType.id}>
                      {identificationType.name}
                    </option>
                  ))}
                </select>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.identification_type.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="document_number">Document Number</label>
                <div className="info">Document Number</div>
              </div>
              <div className="col-xl-6">
                {this.state.identificationType === "national_id" ? (
                  <div className="info">
                    Virtual National Identification Number
                  </div>
                ) : (
                  <div className="info">Document Identification Number</div>
                )}

                <input
                  id="document_number"
                  type="text"
                  name="document_number"
                  value={this.state.documentId}
                  placeholder="Document ID"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ documentNumber: e.target.value });
                  }}
                  required
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.document_number.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="row">
              <div className="col-xl-6">
                <label htmlFor="account-proof-of-annual-income">
                  Identification
                </label>
                <div className="info">Your ID</div>
              </div>
              <div className="col-xl-6">
                <div className="info">A scanned image of your ID</div>
                <input
                  id="account-identification"
                  type="text"
                  name="identification"
                  value={this.state.identificationDisplayName}
                  // placeholder={
                  //   this.state.identificationType === "national_id"
                  //     ? "Document Number (Virtual NIN)"
                  //     : "ID"
                  // }
                  placeholder="ID"
                  className="form-control"
                  disabled
                  onChange={(e) => {
                    this.setState({ identification: e.target.value });
                  }}
                />
                <input
                  type="hidden"
                  name="account-identification"
                  value={this.state.identification}
                />
                <input
                  ref={(identificationFileInput) => {
                    this.identificationFileInput = identificationFileInput;
                  }}
                  type="file"
                  name="identification_file"
                  className="display-none"
                  onChange={(e) => {
                    this.uploadFile(e.target, "identification");
                  }}
                />
                <button
                  type="button"
                  className="upload-button upload"
                  style={{
                    boxShadow:
                      (this.state.identification
                        ? this.state.isSmallScreen
                          ? 110
                          : 200
                        : Math.ceil(
                            this.state.identificationUploadProgress *
                              (this.state.isSmallScreen ? 110 : 200)
                          )) + "px 0 rgba(0, 0, 0, .5) inset",
                  }}
                  onClick={() => {
                    this.identificationFileInput.click();
                  }}
                >
                  Choose File
                </button>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.identification.join("<br />"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="submit">
            <div className="row">
              <div className="col-xl-6" />
              <div className="col-xl-6">
                <input type="submit" value="Save" />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
