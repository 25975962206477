import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';
import User from './../Utils';

export default class Verify extends React.Component {
    constructor(props) {
        super(props);
        Verify.contextType = Context;
        this.state = {
            redirectPathname: (this.props.location.state && this.props.location.state.redirectPathname) || '/dashboard',
            redirectSearch: (this.props.location.state && this.props.location.state.redirectSearch) || '',
            requested: false,
            email: '',
            code: '',
            accessToken: '',
            phoneNumber: '',
            customerType: 'retail_investor',
            message: (this.props.location.state && this.props.location.state.message) || '',
            error: {
                email: [],
                code: [],
                access_token: [],
                phone_number: [],
                customer_type: []
            },
            submitting: false
        };
    }

    componentDidMount() {
        if(this.context.verificationEmail) {
            this.setState({email: this.context.verificationEmail, requested: true});
        }
        this.context.updateContext('layout', this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'default.buy.split' : 'default.split');
        Document.updateDocumentHead({title: 'PropCrowdy - Verify Account'});
        if(this.context.user) {
            this.props.history.replace(this.context.user.platform === 'propbuy' ? '/buy' : '/');
        } else if(this.state.redirectPathname === '/dashboard' && (this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split')) {
            this.setState({redirectPathname: '/property/my-list'});
        }
    }

    request() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if (typeof this.state.email !== 'string' || this.state.email.trim() === '') {
            error.email.push('Please provide your email address');
        } else if (!(typeof this.state.email === 'string' && this.state.email.match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi))) {
            error.email.push('Please provide a valid email');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        Api.fetch('user.auth.verify.request', undefined, {email: this.state.email}).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200 && responseJSON.status) {
                this.setState({requested: true});
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if(this.context.verificationIsSocial) {
            if (typeof this.state.phoneNumber !== 'string' || this.state.phoneNumber.trim() === '') {
                error.phone_number.push('Please provide a phone number');
            } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
                error.phone_number.push('Please provide a valid phone number');
            }

            if (typeof this.state.customerType !== 'string' || this.state.customerType.trim() === '') {
                error.customer_type.push('Please select a customer type');
            } else {
                let customerTypes = [];
                ((this.context.constants && this.context.constants.user && this.context.constants.user.customer_type) || []).map(type => customerTypes.push(type.value));
                if (customerTypes.indexOf(this.state.customerType) === -1) {
                    error.customer_type.push('Please provide a valid customer type');
                }
            }
        } else {
            if (typeof this.state.code !== 'string' || this.state.code.trim() === '') {
                error.code.push('Please provide the code');
            }
        }

        if (typeof this.state.accessToken !== 'string' || this.state.accessToken.trim() === '') {
            error.access_token.push('Please provide your personalized access pass');
        } else if(!this.state.accessToken.match(/^\d{6}$/gi)) {
            error.access_token.push('Your personalized access pass be 6 digits');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        let data = {
            access_token: this.state.accessToken,
            email: this.state.email
        };
        if(this.context.verificationIsSocial) {
            data['phone_number'] = this.state.phoneNumber;
            data['customer_type'] = this.state.customerType;
        } else {
            data['code'] = this.state.code;
        }
        this.setState({submitting: true});
        Api.fetch('user.auth.verify', undefined, data).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200 && responseJSON.status) {
                let user = responseJSON.data.user;
                user['token'] = responseJSON.data.token;
                user['platform'] = this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'propbuy' : 'propcrowdy';
                User.login(user, this.context);
                this.props.history.push({pathname: this.state.redirectPathname, search: this.state.redirectSearch});
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <main id="main" className="auth verify">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 split-left">
                            <div className="content">
                                <div className="heading">Verify your account</div>
                                <div className="sub-heading">{this.state.requested ? 'Provide a verification code sent to your email' : 'Provide your email address'}</div>
                                <img src="/assets/images/vectors/laptop.svg" alt="Security" className="image" />
                            </div>
                        </div>
                        <div className="col-xl-6 split-right">
                            {this.state.requested ? (
                                <form className="auth verify" onSubmit={(e) => {
                                    e.preventDefault();
                                    this.submit();
                                }}>
                                    {this.state.submitting && <Loading />}
                                    <img src="/assets/images/vectors/icons/lock.svg" alt="Lock" className="icon" />
                                    <div className="message">{this.state.message}</div>
                                    <div className="row">
                                        {!this.context.verificationIsSocial && (
                                            <div className="col-xl-12">
                                                <label htmlFor="verify-code" className="stacked">Enter Activation Token</label>
                                                <input id="verify-code" type="text" name="code" placeholder="Enter Activation Token" onChange={(e) => {
                                                    this.setState({code: e.target.value})
                                                }} />
                                                <div className="info">Enter the activation token sent to your email</div>
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.code.join('<br />')}} />
                                            </div>
                                        )}
                                        <div className="col-xl-12">
                                            <label htmlFor="verify-access-token" className="stacked">Create Personalized Access Pass</label>
                                            <input id="verify-access-token" type="text" name="access_token" placeholder="Create Personalized Access Pass" onChange={(e) => {this.setState({accessToken: e.target.value})}} />
                                            <div className="info">Create a 6 digit personalized access pass to secure your account</div>
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.access_token.join('<br />')}} />
                                        </div>
                                        {this.context.verificationIsSocial && (
                                            <>
                                                <div className="col-xl-12">
                                                    <label htmlFor="register-phone-number" className="stacked">Phone Number</label>
                                                    <input id="register-phone-number" type="text" name="phone_number" placeholder="Phone Number" onChange={(e) => {this.setState({phoneNumber: e.target.value})}} />
                                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.phone_number.join('<br />')}} />
                                                </div>
                                                <div className="col-xl-12">
                                                    {((this.context.constants && this.context.constants.user && this.context.constants.user.customer_type) || []).map((customerType, index) => (
                                                        <div key={index} className="radio">
                                                            <input id={'register-customer-' + customerType.value} type="radio" name="customer_type" value={customerType.value} checked={this.state.customerType === customerType.value} onChange={(e) => {this.setState({customerType: e.target.value})}} />
                                                            <label htmlFor={'register-customer-' + customerType.value}>{customerType.display}</label>
                                                        </div>
                                                    ))}
                                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.customer_type.join('<br />')}} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="submit">
                                        <button type="submit">Verify your account</button>
                                    </div>
                                </form>
                            ) : (
                                <form className="auth verify" onSubmit={(e) => {
                                    e.preventDefault();
                                    this.request();
                                }}>
                                    {this.state.submitting && <Loading />}
                                    <img src="/assets/images/vectors/icons/lock.svg" alt="Lock" className="icon" />
                                    <div className="message">{this.state.message}</div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <label htmlFor="verify-email" className="stacked">Email Address</label>
                                            <input id="verify-email" type="email" name="email" placeholder="Email Address" onChange={(e) => {this.setState({email: e.target.value})}} />
                                            <img src="/assets/images/icons/at.png" alt="At" className="icon" />
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                                        </div>
                                    </div>
                                    <div className="submit">
                                        <button type="submit">Request Code</button>
                                    </div>
                                </form>
                            )}
                            <div className="bottom">
                                {this.state.requested ? (
                                    <div className="info">
                                        Didn't get activation code?
                                        <button className="button" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({requested: false});
                                        }}>Request New Code?</button>
                                    </div>
                                ) : (
                                    <div className="info"> Already have the code? <button className="button" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({requested: true});
                                    }}>Enter to verify</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
