import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import Util from './../../../App/Components/Utils';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';

export default class TicketList extends React.Component {
    constructor(props) {
        super(props);
        TicketList.contextType = Context;
        this.state = {
            isMobile: window.innerWidth <= 992,
            isZoomScreen: window.innerWidth > 1200 && window.innerWidth <= 1366,
            headerHeight: 0,
            ticket: null,
            reply: {
                list: [],
                total: 0,
                offset: 0,
                limit: 5,
                message: '',
                attachments: [],
                error: {
                    comment: []
                },
                responseMessage: '',
                fetching: false,
                submitting: false
            },
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'user.buy' ? 'user.buy' : 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Feel free to reach out to our support staff');
        Document.updateDocumentHead({title: 'PropCrowdy - Support Ticket Conversation'});
        this.fetchReplies();
        this.fetchTicket();
        window.addEventListener('resize', this.onResize.bind(this));
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize.bind(this));
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    onResize() {
        this.setState({
            isMobile: window.innerWidth <= 992,
            isZoomScreen: window.innerWidth > 1200 && window.innerWidth <= 1366
        });
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'new.ticket.reply') {
                if(message.data && parseInt(message.data.ticket_id) === this.state.ticket.id) {
                    this.fetchReplies(true);
                }
            }
        }
    }

    fetchTicket() {
        this.setState({fetching: true});
        Api.fetch('support.ticket.view', {id: window.location.pathname.split('/')[3]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({ticket: responseJSON.data}, () => {
                    this.fetchReplies();
                });
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    fetchReplies(update) {
        if(this.state.ticket && this.state.ticket.id) {
            let reply = this.state.reply;
            reply['list'] = reply.list || [];
            reply['total'] = reply.total || 0;
            reply['offset'] = reply.offset || 0;
            reply['limit'] = reply.limit || 5;
            if(!update) {
                reply['fetching'] = true;
            }
            this.setState({reply: reply});
            let initialScrollTop = 0;
            let initialScrollHeight = 0;
            if(this.conversation) {
                initialScrollTop = this.conversation.scrollTop;
                initialScrollHeight = this.conversation.scrollHeight;
            }
            Api.fetch('support.ticket.reply.list', undefined, {
                ticket_id: this.state.ticket.id,
                offset: update ? 0 : reply.offset,
                limit: update ? Math.max(reply.list.length, reply.limit) : reply.limit
            }).then(({status, responseJSON}) => {
                this.setState({fetching: false});
                if(status === 200) {
                    reply['list'] = [...(update ? [] : reply.list), ...(responseJSON.data || [])];
                    reply['total'] = responseJSON.total || reply.total;
                    reply['offset'] = (update ? 0 : reply.offset) + (responseJSON.data || []).length;
                    this.setState({reply: reply}, () => {
                        if(this.conversation && !this.conversation.scrollTop && !update) {
                            if(reply.list.length <= reply.limit) {
                                this.conversation.scrollTop = this.conversation.scrollHeight;
                            } else {
                                this.conversation.scrollTop = this.conversation.scrollHeight - initialScrollHeight - initialScrollTop;
                            }
                        }
                    });
                }
                reply['fetching'] = false;
                this.setState({reply: reply});
            }).catch((reason) => {
                reply['fetching'] = false;
                this.setState({reply: reply});
                console.debug(reason);
            });
        }
    }

    message(message, index) {
        return (
            <div key={index || 0} className={'message' + (message.customer.id === this.context.user.id ? ' self' : '')}>
                <div className="user">
                    <img src={message.customer.photo ? message.customer.photo.url : '/assets/images/vectors/avatar.svg'} alt={message.customer.first_name} className="avatar" />
                    <div className="name">{message.customer.first_name}</div>
                </div>
                <div className="content">
                    <div className="message" dangerouslySetInnerHTML={{__html: message.description || message.comment}} />
                    <div className="details">
                        <div className="time">{Time.timeToFriendly(message.created_at, 0)}</div>
                        {message.attachment.length ? (
                            <div className="attachments">
                                <img src="/assets/images/vectors/icons/attachment.svg" alt="Attachment" className="icon" />
                                {message.attachment.length + (message.attachment.length > 1 ? ' files' : ' file')}
                                , {Util.humanFileSize(message.file_size || 0)} total
                                <button onClick={() => {window.open(Api.baseUrl + 'download-ticket-attachment?ticket_id=' + message.support_id + '&comment_id=' + message.id)}}>
                                    <i className="icon las la-download" />
                                    <span className="title">Download</span>
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }

    addAttachments(fileHandle) {
        for(let i in fileHandle.files) {
            if(fileHandle.files.hasOwnProperty(i)) {
                let reply = this.state.reply;
                let attachments = reply.attachments;
                let attachmentsLength = attachments.push({progress: 0, display: '', url: '', file: ''});
                let index = attachmentsLength - 1;
                let formData = new FormData();
                attachments[index]['progress'] = 0.1;
                attachments[index]['display'] = 'Uploading... (0%)';
                attachments[index]['url'] = URL.createObjectURL(fileHandle.files[0]);
                attachments[index]['file'] = '';
                reply.error['attachment.' + index] = [];
                this.setState({reply: reply}, () => {
                    formData.append('file', fileHandle.files[i]);
                    Api.fetch('file.upload', undefined, formData, undefined, (progress) => {
                        attachments[index]['progress'] = progress;
                        attachments[index]['display'] = 'Uploading... (' + Math.ceil(progress * 100) + '%)';
                        this.setState({reply: reply});
                    }).then(({status, responseJSON}) => {
                        attachments[index]['progress'] = 0;
                        if(status === 200) {
                            attachments[index]['display'] = responseJSON.original_name;
                            attachments[index]['file'] = responseJSON.name;
                            this.setState({reply: reply});
                        } else {
                            attachments[index]['display'] = '';
                            reply['responseMessage'] = responseJSON.message || '';
                            this.setState({reply: reply});
                        }
                    }).catch((reason) => {
                        attachments[index]['progress'] = 0;
                        attachments[index]['display'] = '';
                        let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                        if ([403, 422].indexOf(status) !== -1) {
                            if(responseJSON.error && responseJSON.error.file) {
                                reply.error['attachment.' + index] = responseJSON.error['file'];
                            }
                            reply['responseMessage'] = responseJSON.message || '';
                            this.setState({reply: reply});
                        } else {
                            console.debug(reason);
                            reply['responseMessage'] = (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again';
                            this.setState({reply: reply});
                        }
                    });
                });
            }
        }
    }

    removeAttachments(indices) {
        let reply = this.state.reply;
        let attachments = reply.attachments;
        let error = reply.error;
        let remove = (index) => {
            return new Promise((resolve, reject) => {
                for(let i = index; i < attachments.length - 1; i++) {
                    attachments[i]['progress'] = attachments[i + 1].progress;
                    attachments[i]['display'] = attachments[i + 1].display;
                    attachments[i]['url'] = attachments[i + 1].url;
                    attachments[i]['file'] = attachments[i + 1].file;
                    error['attachment.' + i] = error['attachment.' + (i + 1)];
                }
                delete error['attachment.' + (attachments.length - 1)];
                attachments.splice(attachments.length - 1, 1);
                this.setState({reply: reply}, () => {
                    resolve();
                });
            });
        };
        indices.map(async (index) => {
            return await remove(index);
        });
    }

    reply() {
        let reply = this.state.reply;
        let error = reply.error;

        reply['responseMessage'] = '';

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }

        if (typeof reply.message !== 'string' || reply.message.trim() === '') {
            error.comment.push('Please type your message');
        } else {
            for(let i in reply.attachments) {
                if(reply.attachments.hasOwnProperty(i)) {

                }
            }
        }

        this.setState({reply: reply});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        if(this.replyMessageInput) {
            this.replyMessageInput.blur()
        }

        reply['submitting'] = true;
        this.setState({reply: reply});

        let data = {customer_id: this.context.user.id, support_id: this.state.ticket.id, comment: reply.message, attachment: []}
        reply.attachments.map(attachment => data.attachment.push(attachment.file));
        Api.fetch('support.ticket.reply.add', undefined, data).then(({status, responseJSON}) => {
            reply['submitting'] = false;
            if(status === 200) {
                reply['message'] = '';
                reply['attachments'] = [];
                reply['total']++;
                reply.list.unshift({...responseJSON.data, ...{customer: this.context.user}});
                if(this.conversation) {
                    this.conversation.scrollTop = this.conversation.scrollHeight;
                    setTimeout(() => {
                        this.conversation.scrollTop = this.conversation.scrollHeight;
                    }, 100);
                }
                this.fetchReplies(true);
            } else {
                reply['responseMessage'] = responseJSON.message || '';
            }

            this.setState({reply: reply}, () => {
                if(this.replyMessageInput) {
                    this.replyMessageInput.focus();
                }
            });
        }).catch((reason) => {
            reply['submitting'] = false;
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                reply['error'] = responseJSON.error;
            } else {
                console.debug(reason);
                reply['responseMessage'] =  (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again';
            }

            if(this.replyMessageInput) {
                this.replyMessageInput.focus();
            }
            this.setState({reply: reply});
        });
    }

    render() {
        return (
            <main id="main" className="support ticket admin">
                <div className="container">
                    {this.state.ticket ? (
                        <>
                            <div className="heading">
                                {this.state.ticket.subject}
                                <div className="details">
                                    <div className="category">{this.state.ticket.category && this.state.ticket.category.name}</div>
                                    <div className="status" style={{backgroundColor: ((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.status) || []).reduce((statuses, status) => {statuses[status.value] = status.color || (status.value === 1 && '#84e0be') || (status.value === 2 && '#000') || (status.value === 3 && '#f00'); return statuses}, {})[this.state.ticket.status]}}>
                                        {((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.status) || []).reduce((statuses, status) => {statuses[status.value] = status.display; return statuses}, {})[this.state.ticket.status]}
                                    </div>
                                    <div className="priority" style={{backgroundColor: ((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.priority) || []).reduce((priorities, priority) => {priorities[priority.value] = priority.color || (priority.value === 1 && '#84e0be') || (priority.value === 2 && '#000') || (priority.value === 3 && '#f00'); return priorities}, {})[this.state.ticket.priority]}}>
                                        {((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.priority) || []).reduce((priorities, priority) => {priorities[priority.value] = priority.display; return priorities}, {})[this.state.ticket.priority]}
                                    </div>
                                </div>
                            </div>
                            <div ref={(conversation) => {this.conversation = conversation}} className="conversation" style={{height: 'calc(' + (this.state.isZoomScreen ? 125 : 100) + 'vh - ' + (this.context.adminHeaderHeight || 0) + 'px - 260px'}} onScroll={(e) => {
                                if(e.target.scrollTop === e.target.clientHeight - e.target.scrollHeight) {
                                    if (!this.state.reply.fetching && this.state.reply.list.length < this.state.reply.total) {
                                        this.fetchReplies();
                                    }
                                }
                            }}>
                                <div className="height-fill-available" />
                                {this.state.reply.list.map((reply, index) => this.message(reply, index))}
                                {this.state.reply.list.length === this.state.reply.total && (!this.state.reply.fetching || this.state.reply.list.length)? this.message(this.state.ticket, 0) : null}
                                <div className={'ajax-loading-container' + (this.state.reply.fetching ? ' active' : '')}>
                                    {this.state.reply.fetching && <Loading />}
                                </div>
                            </div>
                            <form className={'reply' + (this.state.reply.submitting ? ' submitting' : '')} onSubmit={(e) => {
                                e.preventDefault();
                                this.reply();
                            }}>
                                <div className="field">
                                    <input ref={(replyMessageInput) => {this.replyMessageInput = replyMessageInput}} type="text" name="comment" value={this.state.reply.message} placeholder="Type Message..." className="message" onChange={(e) => {
                                        let reply = this.state.reply;
                                        reply['message'] = e.target.value;
                                        this.setState({reply: reply});
                                    }} />
                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.reply.error.comment.join('<br />')}} />
                                </div>
                                <button type="button" className="attach" onClick={() => {this.attachmentFileInput.click()}}>
                                    <img src="/assets/images/vectors/icons/attachment.svg" alt="Attach" />
                                </button>
                                <input ref={(attachmentFileInput) => {this.attachmentFileInput = attachmentFileInput}} type="file" name="attachment[]" className="display-none" onChange={(e) => {
                                    this.addAttachments(e.target)
                                }} multiple />
                                <div className="files">
                                    {this.state.reply.attachments.map((attachment, index) => (
                                        <div key={index} className="file-container">
                                            <div key={index} className="file">
                                                {attachment.file || this.state.reply.error['attachment.' + index].length ? (
                                                    <button type="button" className="close-button las la-times" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.removeAttachments([index])
                                                    }} />
                                                ) : (
                                                    <div className="progress" style={{width:  Math.ceil(attachment.progress * 100)+ '%'}} />
                                                )}
                                                <div className="display">
                                                    {attachment.display}
                                                </div>
                                            </div>
                                            <div className="error" dangerouslySetInnerHTML={{__html: this.state.reply.error['attachment.' + index].join('<br />')}} />
                                        </div>
                                    ))}
                                </div>
                                <div className="response-message">{this.state.reply.responseMessage}</div>
                                <button type="submit" value="" className="submit">
                                    <img src="/assets/images/vectors/icons/send-icon.svg" alt="Send" />
                                </button>
                            </form>
                        </>
                    ) : (
                        <Loading />
                    )}
                </div>
            </main>
        );
    }
}
