import React from 'react';
import AppComponent from './Components/App';

export default class App extends React.Component {
    render() {
        return (
            <AppComponent />
        );
    }
}
