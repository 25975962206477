import './../../assets/style.css';
import React from 'react';
import {Link} from 'react-router-dom';
import Util from './../../../App/Components/Utils';
import Time from './../../../App/Components/Utils/Time';

export default class ArticleListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            article: this.props.article,
            previewArticle: this.props.previewArticle,
            nextArticle: this.props.nextArticle
        }
    }

    render() {
        let date = Time.timeToFriendly(this.state.article.created_at, 1);
        return (
            <div className="article">
                <Link to={{pathname: '/article/' + this.state.article.slug, state: {article: this.state.article, previousArticle: this.state.previousArticle}}} style={{backgroundImage: 'url("' + (this.state.article.cover_image ? this.state.article.cover_image.url : '') + '")'}} className="image" />
                <div className="details">
                    <div className="date">{date}</div>
                    <Link to={{pathname: '/article/' + this.state.article.slug}} className="title">{this.state.article.title}</Link>
                    <div className="body">{Util.decodeHTMLEntities(this.state.article.description.replace(/<\/?.*?>/gi, '')).substring(0, 100) + (Util.decodeHTMLEntities(this.state.article.description.replace(/<\/?.*?>/gi, '')).length > 100 ? '...' : '')}</div>
                    <Link to={{pathname: '/article/' + this.state.article.slug, state: {article: this.state.article, previousArticle: this.state.previousArticle}}} className="action">
                        <span className="title">Know More</span>
                        <i className="icon las la-arrow-right" />
                    </Link>
                </div>
            </div>
        );
    }
}
