import React from 'react';
import {Context} from './../Context';
import {withRouter} from 'react-router-dom';
import Wallet from './../../../Wallet/Components/Templates/Modals/Wallet';
import Investment from './../../../Investor/Components/Templates/Modals/Investment';
import Payment from './Modals/Payment';
import PaymentSchedules from './Modals/PaymentSchedules';
import PaymentProof from './Modals/PaymentProof';
import Contact from './Modals/Contact';
import ImageViewer from './Modals/ImageViewer';
import VideoViewer from './Modals/VideoViewer';
import RiskDisclosure from './Modals/RiskDiscloser';
import Alert from './Modals/Alert';
import Notify from './../../../Notification/Components/Notify';
import ReviewAdd from './../../../Developer/Components/Templates/Modals/ReviewAdd';

class Modals extends React.Component {
    constructor(props) {
        super(props);
		Modals.contextType = Context;
        this.state = {

		};
    }

    render() {
        return (
			<div id="modals">
				<Alert />
				<Wallet />
				<Investment />
				<ReviewAdd />
				<Payment />
				<PaymentSchedules />
				<PaymentProof />
				<Contact />
				<ImageViewer />
				<VideoViewer />
				<RiskDisclosure />
				<Notify />
			</div>
        );
    }
}

export default withRouter(Modals);
