import React from 'react';
import {Context} from './../Context';

export default class Cookie extends React.Component {
    constructor(props) {
        super(props);
        Cookie.contextType = Context;
    }

    static setCookie = (name, value, days, path) => {
        days = days || 365;
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + (path ? '; path=' + path : '');
    }

    static getCookie = (name) => {
        name = name + '=';
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return '';
    }

    static deleteCookie = (name) => {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    }
}
