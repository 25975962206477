import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';

export default class TicketList extends React.Component {
    constructor(props) {
        super(props);
        TicketList.contextType = Context;
        this.state = {
            subject: '',
            categoryId: '0',
            description: '',
            priority: '0',
            status: '1',
            attachments: [],
            submitting: false,
            error: {
                subject: [],
                category_id: [],
                description: [],
                priority: [],
                status: []
            }
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'user.buy' ? 'user.buy' : 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Feel free to reach out to our support staff');
        Document.updateDocumentHead({title: 'PropCrowdy - Create Ticket'});
    }

    addAttachments(fileHandle) {
        for(let i in fileHandle.files) {
            if(fileHandle.files.hasOwnProperty(i)) {
                let error = this.state.error;
                let attachments = this.state.attachments;
                let attachmentsLength = attachments.push({progress: 0, display: '', url: '', file: ''});
                let index = attachmentsLength - 1;
                let formData = new FormData();
                attachments[index]['progress'] = 0.1;
                attachments[index]['display'] = 'Uploading... (0%)';
                attachments[index]['url'] = URL.createObjectURL(fileHandle.files[0]);
                attachments[index]['file'] = '';
                error['attachment.' + index] = [];
                this.setState({attachments: attachments, error: error}, () => {
                    formData.append('file', fileHandle.files[i]);
                    Api.fetch('file.upload', undefined, formData, undefined, (progress) => {
                        attachments[index]['progress'] = progress;
                        attachments[index]['display'] = 'Uploading... (' + Math.ceil(progress * 100) + '%)';
                        this.setState({attachments: attachments});
                    }).then(({status, responseJSON}) => {
                        attachments[index]['progress'] = 0;
                        if(status === 200) {
                            attachments[index]['display'] = responseJSON.original_name;
                            attachments[index]['file'] = responseJSON.name;
                            this.setState({attachments: attachments});
                        } else {
                            attachments[index]['display'] = '';
                            this.setState({attachments: attachments, message: responseJSON.message || ''});
                        }
                    }).catch((reason) => {
                        attachments[index]['progress'] = 0;
                        attachments[index]['display'] = '';
                        let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                        if ([403, 422].indexOf(status) !== -1) {
                            if(responseJSON.error && responseJSON.error.file) {
                                error['attachment.' + index] = responseJSON.error['file'];
                            }
                            this.setState({attachments: attachments, error: error, message: responseJSON.message || ''});
                        } else {
                            console.debug(reason);
                            this.setState({attachments: attachments, message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
                        }
                    });
                });
            }
        }
    }

    removeAttachments(indices) {
        let attachments = this.state.attachments;
        let error = this.state.error;
        let remove = (index) => {
            return new Promise((resolve, reject) => {
                for(let i = index; i < attachments.length - 1; i++) {
                    attachments[i]['progress'] = attachments[i + 1].progress;
                    attachments[i]['display'] = attachments[i + 1].display;
                    attachments[i]['url'] = attachments[i + 1].url;
                    attachments[i]['file'] = attachments[i + 1].file;
                    error['attachment.' + i] = error['attachment.' + (i + 1)];
                }
                delete error['attachment.' + (attachments.length - 1)];
                attachments.splice(attachments.length - 1, 1);
                this.setState({attachments: attachments, error: error}, () => {
                    resolve();
                });
            });
        };
        indices.map(async (index) => {
            return await remove(index);
        });
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if (typeof this.state.subject !== 'string' || this.state.subject.trim() === '') {
            error.subject.push('Please provide the subject');
        }

        if (typeof this.state.description !== 'string' || this.state.description.trim() === '') {
            error.description.push('Please provide the description');
        }

        if (!parseInt(this.state.categoryId)) {
            error.category_id.push('Please select a category');
        } else {
            let categoryIds = [];
            ((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.categories) || []).map(category => categoryIds.push(category.id));
            if (categoryIds.indexOf(parseInt(this.state.categoryId)) === -1) {
                error.category_id.push('Please provide a valid category');
            }
        }

        if (!parseInt(this.state.priority)) {
            error.priority.push('Please select a priority');
        } else {
            let priorities = [];
            ((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.priority) || []).map(priority => priorities.push(priority.value));
            if (priorities.indexOf(parseInt(this.state.priority)) === -1) {
                error.priority.push('Please choose a valid priority');
            }
        }

        for(let i in this.state.attachments) {
            if(this.state.attachments.hasOwnProperty(i)) {

            }
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        let data = {
            customer_id: this.context.user.id,
            subject: this.state.subject,
            description: this.state.description,
            category_id: this.state.categoryId,
            priority: this.state.priority,
            status: this.state.status,
            attachment: []
        }
        this.state.attachments.map(attachment => data.attachment.push(attachment.file));

        Api.fetch('support.ticket.add', undefined, data).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                this.props.history.push({pathname: '/support/ticket/' + responseJSON.data.id + '/' + responseJSON.data.subject.replace(/[^A-Za-z0-9]+/gi, '-'), state: {ticket: responseJSON.data}});
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...this.state.error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <main id="main" className="support ticket new">
                <div className="container">
                    <div className="box">
                        <div className="heading">New Ticket</div>
                        <div className="row">
                            <div className="col-md-8">
                                <form action="/tickets" method="post" onSubmit={(e) => {e.preventDefault(); this.submit()}}>
                                    {this.state.submitting && <Loading />}
                                    <div className="message">{this.state.message}</div>
                                    <div className="field">
                                        <label htmlFor="support-ticket-subject">Subject*</label>
                                        <input id="support-ticket-subject" type="text" name="subject" value={this.state.subject} placeholder="Subject" className="form-control" onChange={(e) => {this.setState({subject: e.target.value})}} required />
                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.subject.join('<br />')}} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="support-ticket-category">Category*</label>
                                        <select id="support-ticket-category" name="category_id" value={this.state.categoryId} placeholder="Category" className="form-control" onChange={(e) => {this.setState({categoryId: e.target.value})}} required>
                                            <option value="0" disabled>Category</option>
                                            {((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.categories) || []).map((category, index) => (
                                                <option key={index} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.category_id.join('<br />')}} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="support-ticket-priority">Priority*</label>
                                        <select id="support-ticket-priority" name="priority" value={this.state.priority} placeholder="Priority" className="form-control" onChange={(e) => {this.setState({priority: e.target.value})}} required>
                                            <option value="0" disabled>Priority</option>
                                            {((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.priority) || []).map((priority, index) => (
                                                <option key={index} value={priority.value}>{priority.display}</option>
                                            ))}
                                        </select>
                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.priority.join('<br />')}} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="support-ticket-description">Description*</label>
                                        <textarea id="support-ticket-description" name="description" value={this.state.description} placeholder="Description" className="form-control" onChange={(e) => {this.setState({description: e.target.value})}} required />
                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.description.join('<br />')}} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="support-ticket-description">Attachments</label>
                                        <button type="button" className="attach" onClick={() => {this.attachmentFileInput.click()}}>
                                            <img src="/assets/images/vectors/icons/attachment.svg" alt="Attach" />
                                            Choose file
                                        </button>
                                        <input ref={(attachmentFileInput) => {this.attachmentFileInput = attachmentFileInput}} type="file" name="attachment[]" className="display-none" onChange={(e) => {
                                            this.addAttachments(e.target)
                                        }} multiple />
                                        <div className="files">
                                            {this.state.attachments.map((attachment, index) => (
                                                <div key={index} className="file-container">
                                                    <div key={index} className="file">
                                                        {attachment.file || this.state.error['attachment.' + index].length ? (
                                                            <button type="button" className="close-button las la-times" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.removeAttachments([index])
                                                            }} />
                                                        ) : (
                                                            <div className="progress" style={{width:  Math.ceil(attachment.progress * 100)+ '%'}} />
                                                        )}
                                                        <div className="display">
                                                            {attachment.display}
                                                        </div>
                                                    </div>
                                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error['attachment.' + index].join('<br />')}} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="submit">
                                        <input id="support-ticket-submit" type="submit" value="Create" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4">
                                <div className="portal-admin-status">
                                    <div className="title">Contact Portal Admin</div>
                                    <img src="/assets/images/vectors/icons/message.svg" alt="At" className="image" />
                                    <div className="status">
                                        <span className={'indicator' + (this.context.constants && this.context.constants.support && this.context.constants.support.portalAdmin && this.context.constants.support.portalAdmin.status ? ' active' : '')} />
                                        <span className="label">{this.context.constants && this.context.constants.support && this.context.constants.support.portalAdmin && this.context.constants.support.portalAdmin.message}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
