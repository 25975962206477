import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import { Link } from "react-router-dom";
import Document from "./../../../App/Components/Utils/Document";
import Loading from "./../../../App/Components/Templates/Loading";
import $ from "jquery";
import Hook from "./../../../App/Components/Utils/Hook";
import Api from "./../../../App/Components/Utils/Api";
import UI from "./../../../App/Components/Utils/UI";
import DeveloperListItem from "./../Templates/DeveloperListItem";
import OpportunityListItem from "./../../../Opportunity/Components/Templates/OpportunityListItem";
import Time from "./../../../App/Components/Utils/Time";

export default class CompanyView extends React.Component {
  constructor(props) {
    super(props);
    CompanyView.contextType = Context;
    this.state = {
      developer: null,
      company: this.props.location.state && this.props.location.state.company,
      fetching: false,
      opportunityList: [],
      fetchingOpportunities: false,
      propertyList: [],
      fetchingProperties: false,
      reviewList:
        (this.props.location.state &&
          this.props.location.state.company &&
          this.props.location.state.company.company_reviews) ||
        [],
      topReview: null,
      reviewSort: "desc",
      fetchingReviews: false,
    };
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      this.context.layout === "default.buy" ||
        this.context.layout === "default.buy.split"
        ? "default.buy"
        : "default"
    );
    Document.updateDocumentHead({
      title:
        (this.context.layout === "default.buy" ||
        this.context.layout === "default.buy.split"
          ? "PropCrowdy"
          : "PropCrowdy") + " - Company",
    });
    Hook.register("push.on.message", this.pushOnMessage.bind(this));
    Hook.register("review.added", this.onReviewAdded.bind(this));
    if (this.state.company) {
      Document.updateDocumentHead({
        title:
          (this.context.layout === "default.buy" ||
          this.context.layout === "default.buy.split"
            ? "PropCrowdy"
            : "PropCrowdy") +
          " Company - " +
          this.state.company.name,
      });
      this.fetchCompany(true);
      this.isFollowing();
      this.fetchTopReview();
      this.fetchReviews();
      this.fetchOpportunities();
      this.fetchProperties();
      UI.slick();
    } else {
      this.fetchCompany();
    }
  }

  componentWillUnmount() {
    Hook.unregister("push.on.message", this.pushOnMessage.bind(this));
    Hook.unregister("review.added", this.onReviewAdded.bind(this));
  }

  pushOnMessage(result, message) {
    if (typeof message == "object") {
      if (
        message.type === "company.review.updated" &&
        message.data &&
        parseInt(message.data.id) === this.state.company.id
      ) {
        this.fetchTopReview();
        this.fetchReviews();
      }
    }
  }

  onReviewAdded(result, type, typeId, data) {
    if (
      type === "company" &&
      typeof data === "object" &&
      parseInt(data.id) === parseInt(this.state.company.id)
    ) {
      this.fetchCompany(true);
    }
  }

  fetchCompany(update) {
    if (!update) {
      this.setState({ fetching: true });
    }
    Api.fetch("developer.company.view", undefined, {
      slug: window.location.pathname.split("/")[2],
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetching: false });
        if (status === 200) {
          this.setState({ company: responseJSON.data }, () => {
            Document.updateDocumentHead({
              title:
                (this.context.layout === "default.buy" ||
                this.context.layout === "default.buy.split"
                  ? "PropCrowdy"
                  : "PropCrowdy") +
                " Company - " +
                this.state.company.name,
            });
            this.isFollowing();
            this.fetchTopReview();
            this.fetchReviews();
            this.fetchOpportunities();
            this.fetchProperties();
            UI.slick();
          });
        }
      })
      .catch((reason) => {
        this.setState({ fetching: false });
        // console.debug(reason);
      });
  }

  fetchOpportunities() {
    Api.fetch("developer.company.opportunity.list", undefined, {
      slug: this.state.company.slug,
      limit: 4,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingOpportunities: false });
        if (status === 200) {
          this.setState({ opportunityList: responseJSON.data || [] });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingOpportunities: false });
        // console.debug(reason);
      });
  }

  fetchProperties() {
    Api.fetch("developer.company.property.list", undefined, {
      slug: this.state.company.slug,
      limit: 4,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingProperties: false });
        if (status === 200) {
          this.setState({ propertyList: responseJSON.data || [] });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingProperties: false });
        // console.debug(reason);
      });
  }

  fetchReviews() {
    Api.fetch("developer.company.review.list", undefined, {
      company_id: this.state.company.id,
      slug: this.state.company.slug,
      sort: this.state.reviewSort,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingReviews: false });
        if (status === 200) {
          this.setState({ reviewList: responseJSON.data || [] });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingReviews: false });
        // console.debug(reason);
      });
  }

  fetchTopReview() {
    Api.fetch("developer.company.review.list", undefined, {
      company_id: this.state.company.id,
      slug: this.state.company.slug,
      sort: "top",
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingReviews: false });
        if (status === 200) {
          this.setState({ topReview: (responseJSON.data || [])[0] });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingReviews: false });
        // console.debug(reason);
      });
  }

  share(type) {
    const url = window.location.href;
    const target =
      (this.context.layout === "default.buy" ||
      this.context.layout === "default.buy.split"
        ? "PropCrowdy"
        : "PropCrowdy") + " - Share Developer";
    const features = "width = 500, height = 500, scrollbars = yes";
    if (type === "facebook") {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?display=popup&u=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "twitter") {
      window.open(
        "https://twitter.com/share?text=" +
          this.state.company.name +
          "&url=" +
          encodeURIComponent(url) +
          "&href=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "linkedin") {
      window.open(
        "https://www.linkedin.com/cws/share?url=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "pinterest") {
      window.open(
        "https://pinterest.com/pin/create/button/?url=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "whatsapp") {
      window.open(
        "https://api.whatsapp.com/send?text=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "telegram") {
      window.open(
        "https://telegram.me/share/url?url=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "email") {
      window.open(
        "mailto:?subject=" +
          this.state.company.name +
          "&body=" +
          encodeURIComponent(url),
        "_self",
        features
      );
    }
  }

  isFollowing() {
    let company = this.state.company;
    Api.fetch("developer.company.following", undefined, {
      company_slug: company.slug,
    })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          company["is_following"] = responseJSON.follow ? 1 : 0;
          this.setState({ company: company });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
  }

  follow() {
    let company = this.state.company;
    Api.fetch("developer.company.follow", undefined, {
      company_slug: company.slug,
    })
      .then(() => {})
      .catch((reason) => {
        // console.debug(reason);
      });
    company["is_following"] = 1;
    this.setState({ company: company });
  }

  unfollow() {
    let company = this.state.company;
    Api.fetch("developer.company.unfollow", undefined, {
      company_slug: company.slug,
    })
      .then(() => {})
      .catch((reason) => {
        // console.debug(reason);
      });
    company["is_following"] = 0;
    this.setState({ company: company });
  }

  render() {
    return (
      <main id="main" className="developer company view">
        <div className="bread-crumb">
          {this.state.company && (
            <div className="container">
              <Link
                to={{
                  pathname:
                    "/opportunity/" + window.location.pathname.split("/")[2],
                }}
                className="link"
              >
                Opportunity
              </Link>
              <i className="icon arrow las la-angle-right" />
              <Link
                to={{
                  pathname:
                    "/company/" + window.location.pathname.split("/")[2],
                }}
                className="link"
              >
                {this.state.company.name}
              </Link>
            </div>
          )}
        </div>
        {this.state.company ? (
          <div className="container">
            <div className="body">
              <div className="share">
                <button
                  className="lab la-facebook"
                  onClick={() => this.share("facebook")}
                />
                <button
                  className="lab la-twitter"
                  onClick={() => this.share("twitter")}
                />
                <button
                  className="lab la-linkedin"
                  onClick={() => this.share("linkedin")}
                />
                {/*<button className="lab la-pinterest" onClick={() => this.share('pinterest')} />*/}
                <button
                  className="lab la-whatsapp"
                  onClick={() => this.share("whatsapp")}
                />
                <button
                  className="lab la-telegram"
                  onClick={() => this.share("telegram")}
                />
                <button
                  className="lab la-email"
                  onClick={() => this.share("email")}
                />
              </div>
              <div className="content">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="overview">
                      <div className="logo">
                        <img
                          src={
                            this.state.company.logo
                              ? this.state.company.logo.url
                              : "/assets/images/company-logo.png"
                          }
                          alt={this.state.company.name}
                        />
                      </div>
                      <div className="details">
                        <div className="name">{this.state.company.name}</div>
                        <div className="location">
                          {this.state.company.address}
                        </div>
                        <div className="rating">
                          <div className="stars">
                            {Array.from({ length: 5 }).map((el, i) => (
                              <i
                                key={i}
                                className={
                                  "icon star las la-star" +
                                  (i < (this.state.company.rating || 0)
                                    ? " shaded"
                                    : "")
                                }
                              />
                            ))}
                          </div>
                          <div className="reviews">
                            {(this.state.company.company_reviews || []).length}{" "}
                            {(this.state.company.company_reviews || []).length >
                            1
                              ? "Reviews"
                              : "Review"}
                          </div>
                        </div>
                        <div className="review">
                          {this.state.company ? (
                            this.state.company.quote
                          ) : (
                            <em>No Quote</em>
                          )}
                        </div>
                        {this.context.user &&
                        this.state.company &&
                        this.state.company.customer_id !==
                          this.context.user.id ? (
                          <div className="actions">
                            {this.state.company.is_following === 1 ? (
                              <button
                                className="action active"
                                onClick={(e) => {
                                  if (this.context.user) {
                                    this.unfollow();
                                  } else {
                                    UI.alert(
                                      {
                                        title: "Login First",
                                        content:
                                          "You must be logged in to unfollow company",
                                        buttons: [
                                          {
                                            type: "positive",
                                            title: "Login",
                                            onClick: () => {
                                              this.props.history.push({
                                                pathname: "/login",
                                                state: {
                                                  message: "Please login first",
                                                  redirectPathname: "/home",
                                                },
                                              });
                                            },
                                          },
                                          {
                                            type: "neutral",
                                            title: "Register",
                                            onClick: () => {
                                              this.props.history.push({
                                                pathname: "/register",
                                                state: {
                                                  message:
                                                    "Please register first",
                                                  redirectPathname: "/home",
                                                },
                                              });
                                            },
                                          },
                                        ],
                                      },
                                      this.context
                                    );
                                  }
                                }}
                              >
                                Unfollow
                              </button>
                            ) : null}
                            {this.state.company.is_following === 0 ? (
                              <button
                                className="action"
                                onClick={(e) => {
                                  if (this.context.user) {
                                    this.follow();
                                  } else {
                                    UI.alert(
                                      {
                                        title: "Login First",
                                        content:
                                          "You must be logged in to follow company",
                                        buttons: [
                                          {
                                            type: "positive",
                                            title: "Login",
                                            onClick: () => {
                                              this.props.history.push({
                                                pathname: "/login",
                                                state: {
                                                  message: "Please login first",
                                                  redirectPathname: "/home",
                                                },
                                              });
                                            },
                                          },
                                          {
                                            type: "neutral",
                                            title: "Register",
                                            onClick: () => {
                                              this.props.history.push({
                                                pathname: "/register",
                                                state: {
                                                  message:
                                                    "Please register first",
                                                  redirectPathname: "/home",
                                                },
                                              });
                                            },
                                          },
                                        ],
                                      },
                                      this.context
                                    );
                                  }
                                }}
                              >
                                Follow
                              </button>
                            ) : null}
                            <button
                              className="action"
                              onClick={(e) => {
                                if (this.context.user) {
                                  this.context.updateContext(
                                    "reviewAddCompany",
                                    this.state.company,
                                    () => {
                                      $("#review-modal").modal("show");
                                    }
                                  );
                                } else {
                                  UI.alert(
                                    {
                                      title: "Login First",
                                      content:
                                        "You must be logged in to add review",
                                      buttons: [
                                        {
                                          type: "positive",
                                          title: "Login",
                                          onClick: () => {
                                            this.props.history.push({
                                              pathname: "/login",
                                              state: {
                                                message: "Please login first",
                                                redirectPathname: "/home",
                                              },
                                            });
                                          },
                                        },
                                        {
                                          type: "neutral",
                                          title: "Register",
                                          onClick: () => {
                                            this.props.history.push({
                                              pathname: "/register",
                                              state: {
                                                message:
                                                  "Please register first",
                                                redirectPathname: "/home",
                                              },
                                            });
                                          },
                                        },
                                      ],
                                    },
                                    this.context
                                  );
                                }
                              }}
                            >
                              Submit Review
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 contact-container">
                    <div className="contact">
                      <div className="label">
                        Contact{" "}
                        {this.context.layout === "default.buy" ||
                        this.context.layout === "default.buy.split"
                          ? "PropCrowdy"
                          : "PropCrowdy"}
                      </div>
                      <div className="buttons">
                        <a href="mailto:info@propcrowdy.com" className="button">
                          <i className="icon las la-envelope" />
                        </a>
                        <a href="tel:+2348107321018" className="button">
                          <i className="icon las la-phone" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="information">
                  <div className="title">Company Information</div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.company.description,
                        }}
                        className="description"
                      ></div>
                    </div>
                    <div className="col-lg-6">
                      <div className="stats">
                        {this.context.layout !== "default.buy" ? (
                          <div className="stat risk">
                            <div className="title">Investment Risk</div>
                            <div className="bar">
                              <div
                                className="fill"
                                style={{
                                  width:
                                    (this.state.company.investment_risk || 0) +
                                    "%",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="stat reputation">
                          <div className="title">Company's reputation</div>
                          <div className="bar">
                            <div
                              className="fill"
                              style={{
                                width:
                                  (this.state.company.company_reputation || 0) +
                                  "%",
                              }}
                            />
                          </div>
                        </div>
                        {this.context.layout !== "default.buy" ? (
                          <div className="stat reliability">
                            <div className="title">Investment Reliability</div>
                            <div className="bar">
                              <div
                                className="fill"
                                style={{
                                  width:
                                    (this.state.company
                                      .investment_reliability || 0) + "%",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.company.company_board_of_directors &&
                this.state.company.company_board_of_directors.length ? (
                  <div className="board">
                    <div className="title">Board of Directors</div>
                    <div className="members developers">
                      {(
                        this.state.company.company_board_of_directors || []
                      ).map((member, index) => (
                        <DeveloperListItem key={index} developer={member} />
                      ))}
                    </div>
                  </div>
                ) : null}
                {this.context.layout !== "default.buy" ? (
                  <div className="warning">
                    <div className="title">Warning to Investors</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.company.warning_to_investors,
                      }}
                      className="content"
                    ></div>
                  </div>
                ) : null}
                {this.context.layout !== "default.buy" &&
                this.state.company &&
                this.state.company.tell_story ? (
                  <div className="story">
                    <div className="title">Investment Story</div>
                    <div className="row">
                      <div className="col-xl-6">
                        {this.state.topReview ? (
                          <div className="review">
                            <span className="message">
                              “{this.state.topReview.content}”
                            </span>
                            <span className="name">
                              {" "}
                              -{" "}
                              {this.state.topReview.customer.first_name +
                                " " +
                                this.state.topReview.customer.last_name}
                            </span>
                          </div>
                        ) : null}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.company.tell_story,
                          }}
                          className="content"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className={
                    "reviews" + (this.state.reviewList.length ? "" : " empty")
                  }
                >
                  <div className="title">Reviews</div>
                  <div className="content">
                    {this.state.reviewList.length ? (
                      <div className="sort dropdown">
                        <button
                          id="reviews-sort-dropdown"
                          type="button"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {this.state.reviewSort === "desc"
                            ? "Most recent"
                            : ""}
                          {this.state.reviewSort === "top" ? "Top rating" : ""}
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="reviews-sort-dropdown"
                        >
                          <button
                            onClick={() =>
                              this.setState({ reviewSort: "desc" }, () => {
                                this.fetchReviews();
                              })
                            }
                            className="dropdown-item"
                          >
                            Most recent
                          </button>
                          <button
                            onClick={() =>
                              this.setState({ reviewSort: "top" }, () => {
                                this.fetchReviews();
                              })
                            }
                            className="dropdown-item"
                          >
                            Top Rating
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="no-review">
                        <div className="message">
                          No review yet.
                          {!this.context.user ||
                          (this.context.user &&
                            this.state.company &&
                            this.state.company.customer_id !==
                              this.context.user.id) ? (
                            <>
                              <br />
                              Be the first to add a review.
                            </>
                          ) : null}
                        </div>
                        {!this.context.user ||
                        (this.context.user &&
                          this.state.company &&
                          this.state.company.customer_id !==
                            this.context.user.id) ? (
                          <button
                            className="button"
                            onClick={() => {
                              if (this.context.user) {
                                this.context.updateContext(
                                  "reviewAddCompany",
                                  this.state.company,
                                  () => {
                                    $("#review-modal").modal("show");
                                  }
                                );
                              } else {
                                UI.alert(
                                  {
                                    title: "Login First",
                                    content:
                                      "You must be logged in to add review",
                                    buttons: [
                                      {
                                        type: "positive",
                                        title: "Login",
                                        onClick: () => {
                                          this.props.history.push({
                                            pathname: "/login",
                                            state: {
                                              message: "Please login first",
                                              redirectPathname: "/home",
                                            },
                                          });
                                        },
                                      },
                                      {
                                        type: "neutral",
                                        title: "Register",
                                        onClick: () => {
                                          this.props.history.push({
                                            pathname: "/register",
                                            state: {
                                              message: "Please register first",
                                              redirectPathname: "/home",
                                            },
                                          });
                                        },
                                      },
                                    ],
                                  },
                                  this.context
                                );
                              }
                            }}
                          >
                            Add Review
                          </button>
                        ) : null}
                      </div>
                    )}
                    {this.state.reviewList.map((review, index) => (
                      <div key={index} className="review">
                        <div className="avatar">
                          <img
                            src={
                              review.customer && review.customer.photo
                                ? review.customer.photo.url
                                : "/assets/images/vectors/avatar.svg"
                            }
                            alt={review.customer.first_name}
                          />
                        </div>
                        <div className="details">
                          <div className="name">
                            {review.customer
                              ? review.customer.first_name +
                                " " +
                                review.customer.last_name
                              : ""}
                          </div>
                          <div className="number">
                            {review.customer
                              ? review.customer.phone_number
                              : ""}
                          </div>
                        </div>
                        <div className="transactions">
                          <div className="count">
                            {review.customer
                              ? review.customer.total_transactions || 0
                              : 0}{" "}
                            {review.customer &&
                            review.customer.total_transactions > 1
                              ? "Transactions"
                              : "Transaction"}
                          </div>
                          <div className="date">
                            {Time.timeAgo(
                              review.customer &&
                                (review.customer.created_at || 0)
                            )}
                          </div>
                        </div>
                        <div className="rating">
                          <div className="stars">
                            {Array.from({ length: 5 }).map((el, i) => (
                              <i
                                key={i}
                                className={
                                  "icon star las la-star" +
                                  (i < (review.ratings || 0) ? " shaded" : "")
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {this.context.layout === "default.buy" ||
            this.context.layout === "default.buy.split" ? (
              <div className="properties"></div>
            ) : (
              <div className="opportunities">
                {this.state.opportunityList.length ? (
                  <>
                    <div className="title">Recent Investments</div>
                    <div className="sub-title">
                      Real Estates Companies in Nigeria raising money for
                      opportunity development
                    </div>
                    <div className="row">
                      {this.state.opportunityList.map((opportunity, index) => (
                        <div key={index} className="col-xl-3">
                          <OpportunityListItem opportunity={opportunity} />
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </main>
    );
  }
}
