import React from 'react';
import {Context} from './../Context';
import Document from './../Utils/Document';

export default class Risk extends React.Component {
    constructor(props) {
        super(props);
        Risk.contextType = Context;
        this.state = {

        };
    }

    componentDidMount() {
        this.context.updateContext('layout', window.location.pathname.split('/')[1] === 'buy' ? 'default.buy' : 'default');
        Document.updateDocumentHead({title: (window.location.pathname.split('/')[1] === 'buy' ? 'PropBuy' : 'PropCrowdy') + ' - Risk Disclosure'});
    }

    render() {
        return (
            <main id="main" className="p-0">
                <section id="risk-disclosure">
                    <div className="container">
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="heading">PropBuy Risk Disclosure</div>
                        ) : (
                            <div className="heading">PropCrowdy Risk Disclosure</div>
                        )}
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="content">
                                <p>This statement serves as a disclosure to the investor that there are risks involved in placing an investment through an online portal and this portal operated by PropBuy is not an exception. Should you choose to place an investment through this platform, you will have done this of your own volition and without relying on any information on this platform, other than the information availed by the Issuer. If you feel unsure or unclear about any information provided by the Issuer, you are advised to ask questions and seek out advice from an independent financial advisor, legal counsel or such other professional before proceeding to accept the investment offers on this platform and making financial commitments on the strength thereof.</p>
                                <p>Any investment(s) made in the business hosted on this platform carries a high risk and the projections are highly speculative. There is a significant chance that you may lose your money either in part or in whole on any such investments and you are advised to assess your financial position as to whether you can afford to bear such risk without suffering undue financial/economic hardship should you proceed to make an investment on this platform. Crowd funding is regulated by Security and Exchange Commission (SEC) in Nigeria</p>
                            </div>
                        ) : (
                            <div className="content">
                                <p>This statement serves as a disclosure to the investor that there are risks involved in placing an investment through an online portal and this portal operated by PropCrowdy is not an exception. Should you choose to place an investment through this platform, you will have done this of your own volition and without relying on any information on this platform, other than the information availed by the Issuer. If you feel unsure or unclear about any information provided by the Issuer, you are advised to ask questions and seek out advice from an independent financial advisor, legal counsel or such other professional before proceeding to accept the investment offers on this platform and making financial commitments on the strength thereof.</p>
                                <p>
                                    Any investment(s) made in the business hosted on this platform carries a high risk and the projections are highly speculative. There is a significant chance that you may lose your money either in part or in whole on any such investments and you are advised to assess your financial position as to whether you can afford to bear such risk without suffering undue financial/economic hardship should you proceed to make an investment on this platform. Crowd funding is regulated by Security and Exchange Commission (SEC) in Nigeria
                                    <img src="/assets/images/sec-logo.png" alt="SEC Logo" className="sec-logo" />
                                </p>
                            </div>
                        )}
                    </div>
                </section>
            </main>
        );
    }
}
