// import "./../../assets/style.css";
import "./../../assets/stylings/signing_styles.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import { Link } from "react-router-dom";
import Loading from "./../../../App/Components/Templates/Loading";
import Document from "./../../../App/Components/Utils/Document";
import Api from "./../../../App/Components/Utils/Api";

export default class Forgot extends React.Component {
  constructor(props) {
    super(props);
    Forgot.contextType = Context;
    this.state = {
      redirectPathname:
        (this.props.location.state &&
          this.props.location.state.redirectPathname) ||
        "/dashboard",
      redirectSearch:
        (this.props.location.state &&
          this.props.location.state.redirectSearch) ||
        "",
      requested: false,
      confirmed: false,
      completed: false,
      showPassword: false,
      showPasswordConfirm: false,
      email: "",
      code: "",
      password: "",
      passwordConfirmation: "",
      message:
        (this.props.location.state && this.props.location.state.message) || "",
      error: {
        email: [],
        code: [],
        password: [],
        password_confirmation: [],
      },
      submitting: false,
    };
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      this.context.layout === "defaultPictured" ||
        this.context.layout === "defaultPictured"
        ? "defaultPictured"
        : "defaultPictured"
    );
    Document.updateDocumentHead({ title: "PropCrowdy - Forgot Password" });
    if (this.context.user) {
      this.props.history.replace(
        this.context.user.platform === "propbuy" ? "/buy" : "/"
      );
    } else if (
      this.state.redirectPathname === "/dashboard" &&
      (this.context.layout === "default.buy" ||
        this.context.layout === "default.buy.split")
    ) {
      this.setState({ redirectPathname: "/property/my-list" });
    }
  }

  request() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (
      typeof this.state.email !== "string" ||
      this.state.email.trim() === ""
    ) {
      error.email.push("Please provide your email address");
    } else if (
      !(
        typeof this.state.email === "string" &&
        this.state.email.match(
          /^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi
        )
      )
    ) {
      error.email.push("Please provide a valid email");
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }

    this.setState({ submitting: true });
    Api.fetch("user.password.reset.request", undefined, {
      email: this.state.email,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          this.setState({ requested: true });
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          this.setState({
            error: { ...error, ...responseJSON.error },
            message: responseJSON.message || "",
          });
        } else {
          console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  confirm() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (typeof this.state.code !== "string" || this.state.code.trim() === "") {
      error.code.push("Please provide the reset token");
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }

    this.setState({ submitting: true });
    Api.fetch("user.password.reset.confirm", undefined, {
      code: this.state.code,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          this.setState({ confirmed: true });
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          this.setState({
            error: { ...error, ...responseJSON.error },
            message: responseJSON.message || "",
          });
        } else {
          console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  submit() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (
      typeof this.state.password !== "string" ||
      this.state.password.trim() === ""
    ) {
      error.password.push("Please provide a password");
    } else if (this.state.password.trim().length < 10) {
      error.password.push("Password must contain at least 10 characters");
    } else if (
      !this.state.password.match(/[a-z]/) ||
      !this.state.password.match(/[A-Z]/) ||
      !this.state.password.match(/[0-9]/) ||
      !this.state.password.match(/[^a-zA-Z0-9\s]/)
    ) {
      error.password.push(
        "Password must contain at least a uppercase and lowercase alphabet, a number, and a symbol"
      );
    }

    if (this.state.password !== this.state.passwordConfirmation) {
      error.password_confirmation.push("Password confirmation does not match");
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }

    this.setState({ submitting: true });
    Api.fetch("user.password.reset", undefined, {
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
      code: this.state.code,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          this.setState({ completed: true });
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          this.setState({
            error: { ...error, ...responseJSON.error },
            message: responseJSON.message || "",
          });
        } else {
          console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  render() {
    return (
      <main id="main" className=" forgot">
        <div className="container">
          <div className="row_div">
            {/* <div className="col-xl-6 split-left">
              <div className="content">
                <div className="heading">Change Password</div>
                <div className="sub-heading">
                  {!this.state.completed &&
                    !this.state.confirmed &&
                    !this.state.requested &&
                    "Recover Lost Password"}
                  {!this.state.completed &&
                    !this.state.confirmed &&
                    this.state.requested &&
                    "Enter the code sent to the email address"}
                  {!this.state.completed &&
                    this.state.confirmed &&
                    "Enter the new password"}
                  {this.state.completed &&
                    "Your password has been changed successfully"}
                </div>
                <img
                  src="/assets/images/vectors/laptop.svg"
                  alt="Security"
                  className="image"
                />
              </div>
            </div> */}
            <div className="col-xl-12 split-right">
              {!this.state.completed &&
                !this.state.confirmed &&
                !this.state.requested && (
                  <form
                    className="auth forgot"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.request();
                    }}
                  >
                    {this.state.submitting && <Loading />}
                    {/* <img
                      src="/assets/images/vectors/icons/lock.svg"
                      alt="Lock"
                      className="icon"
                    /> */}
                    <div className="message">{this.state.message}</div>
                    <div className="row_div">
                      <div style={{ marginBottom: "20px", paddingTop: "30px" }}>
                        <p className="red_top_text">
                          Get Started With PropCrowdy
                        </p>
                        <h2 className="main_title">Change Password</h2>
                        <p className="sub_title_text">Recover Lost Password</p>
                      </div>
                      <div className="input_container">
                        <label htmlFor="forgot_email" className="stacked">
                          Recovery Email
                        </label>
                        <input
                          id="forgot_email"
                          type="email"
                          name="email"
                          placeholder="Recovery Email"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          className="generic_input"
                        />
                        {/* <img
                          src="/assets/images/icons/at.png"
                          alt="At"
                          className="icon"
                        /> */}
                        <div
                          className="error"
                          dangerouslySetInnerHTML={{
                            __html: this.state.error.email.join("<br />"),
                          }}
                        />
                      </div>
                    </div>
                    <div className="submit">
                      <button
                        type="submit"
                        className="generic_button"
                        style={{ width: "100%" }}
                      >
                        Request Code
                      </button>
                    </div>
                  </form>
                )}
              {!this.state.confirmed && this.state.requested && (
                <form
                  className="auth forgot"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.confirm();
                  }}
                >
                  {this.state.submitting && <Loading />}
                  {/* <img
                    src="/assets/images/vectors/icons/lock.svg"
                    alt="Lock"
                    className="icon"
                  /> */}
                  <div className="message">{this.state.message}</div>
                  <div className="row_div">
                    <div style={{ marginBottom: "20px", paddingTop: "30px" }}>
                      <p className="red_top_text">
                        Get Started With PropCrowdy
                      </p>
                      <h2 className="main_title">Change Password</h2>
                      <p className="sub_title_text">
                        Enter the code sent to the email address
                      </p>
                    </div>
                    <div className="input_container">
                      <label htmlFor="forgot-code" className="stacked">
                        Enter Reset Token
                      </label>
                      <input
                        id="forgot-code"
                        type="text"
                        name="code"
                        placeholder="Enter Reset Token"
                        onChange={(e) => {
                          this.setState({ code: e.target.value });
                        }}
                        className="generic_input"
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.code.join("<br />"),
                        }}
                      />
                    </div>
                  </div>
                  <div className="submit">
                    <button type="submit" className="generic_button">
                      Confirm Code
                    </button>
                  </div>
                </form>
              )}
              {!this.state.completed && this.state.confirmed && (
                <form
                  className="auth forgot"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submit();
                  }}
                >
                  {this.state.submitting && <Loading />}
                  {/* <img
                    src="/assets/images/vectors/icons/lock.svg"
                    alt="Lock"
                    className="icon"
                  /> */}
                  <div className="message">{this.state.message}</div>
                  <div className="row_div">
                    <div style={{ marginBottom: "20px", paddingTop: "30px" }}>
                      <p className="red_top_text">
                        Get Started With PropCrowdy
                      </p>
                      <h2 className="main_title">Change Password</h2>
                      <p className="sub_title_text">Enter the new password</p>
                    </div>
                    <div className="input_container">
                      <label htmlFor="forgot-password" className="stacked">
                        New Password
                      </label>
                      <input
                        id="forgot-password"
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="New Password"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                        className="generic_input"
                      />
                      <img
                        src={
                          this.state.showPassword
                            ? "/assets/images/icons/eye_opened_icon.svg"
                            : "/assets/images/icons/eye_closed_icon.svg"
                        }
                        alt="Eye"
                        className="icon"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.password.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="input_container">
                      <label
                        htmlFor="forgot-password-confirmation"
                        className="stacked"
                      >
                        New Password Confirmation
                      </label>
                      <input
                        id="forgot-password-confirmation"
                        type={
                          this.state.showPasswordConfirm ? "text" : "password"
                        }
                        name="password_confirmation"
                        placeholder="New Password Confirmation"
                        onChange={(e) => {
                          this.setState({
                            passwordConfirmation: e.target.value,
                          });
                        }}
                        className="generic_input"
                      />
                      <img
                        src={
                          this.state.showPasswordConfirm
                            ? "/assets/images/icons/eye_opened_icon.svg"
                            : "/assets/images/icons/eye_closed_icon.svg"
                        }
                        alt="Eye"
                        className="icon"
                        onClick={() => {
                          this.setState({
                            showPasswordConfirm:
                              !this.state.showPasswordConfirm,
                          });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.error.password_confirmation.join(
                              "<br />"
                            ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="submit">
                    <button type="submit" className="generic_button">
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
              <div className="bottom">
                {this.state.completed && (
                  <div className="row_div">
                    <div style={{ marginBottom: "20px", paddingTop: "30px" }}>
                      <p className="red_top_text">
                        Get Started With PropCrowdy
                      </p>
                      <h2 className="main_title">Change Password</h2>
                      {/* <p className="sub_title_text">
                        Your password has been changed successfully
                      </p> */}
                    </div>
                    <div className="password_change_success_message">
                      Your password has been changed successfully.
                      <br />
                      You may now{" "}
                      <Link
                        to={{
                          pathname: "/login",
                          state: {
                            redirectPathname: this.state.redirectPathname,
                            search: this.state.redirectSearch,
                          },
                        }}
                        className="button_enter_code link"
                      >
                        sign in
                      </Link>{" "}
                      to your account
                      <br />
                      <Link
                        to={{
                          pathname: "/login",
                          state: {
                            redirectPathname: this.state.redirectPathname,
                            search: this.state.redirectSearch,
                          },
                        }}
                        className="generic_button link lg"
                        style={{ marginTop: "20px" }}
                      >
                        Sign In
                      </Link>
                    </div>
                  </div>
                )}
                {!this.state.completed &&
                  !this.state.confirmed &&
                  this.state.requested && (
                    <div className="already_have_a_code">
                      Didn't get reset code?{" "}
                      <button
                        className="button_enter_code"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ requested: false });
                        }}
                      >
                        Request New Code?
                      </button>
                    </div>
                  )}
                {!this.state.completed &&
                  !this.state.confirmed &&
                  !this.state.requested && (
                    <div className="already_have_a_code">
                      {" "}
                      Already have a code?{" "}
                      <button
                        className="button_enter_code"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ requested: true });
                        }}
                      >
                        Enter the code
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
