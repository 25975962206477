import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import { Link } from "react-router-dom";
import Loading from "./../../../App/Components/Templates/Loading";
import $ from "jquery";
import Document from "./../../../App/Components/Utils/Document";
import Hook from "./../../../App/Components/Utils/Hook";
import Api from "./../../../App/Components/Utils/Api";
import Time from "./../../../App/Components/Utils/Time";
import UI from "./../../../App/Components/Utils/UI";
import noticeBanner from "../../../App/assets/images/banner-notice.gif";
import Countdown from "react-countdown";
import MinorCardsContainer from "../Accessories/MinorCardsContainer";
import NumberOfInvestorsCard from "../Accessories/NumberOfInvestorsCard";
import OpportunitySecondSection from "../Accessories/OpportunitySecondSection";
import ContactUsBanner from "../Accessories/ContactUsBanner";

export default class OpportunityView extends React.Component {
  constructor(props) {
    super(props);
    OpportunityView.contextType = Context;
    this.state = {
      isMobile: window.innerWidth <= 992,
      opportunity:
        this.props.location.state && this.props.location.state.opportunity,
      showInvestAmountForm: false,
      showShareButton: false,
      investAmount: "",
      investments: [],
      myInvestments: [],
      myPendingInvestments: [],
      myAbortedInvestments: [],
      today: new Date(),
      starting_date: new Date(),
      closing_date: new Date(),
      error: {
        amount: [],
      },
    };
  }

  componentDidMount() {
    this.context.updateContext("layout", "default");
    Document.updateDocumentHead({
      title:
        "PropCrowdy" +
        (this.state.opportunity
          ? " Opportunity - " + this.state.opportunity.title
          : " - Opportunity"),
    });
    window.addEventListener("click", this.onClick.bind(this));
    window.addEventListener("resize", this.onResize.bind(this));
    this.init();
    Hook.register("push.on.message", this.pushOnMessage.bind(this));
    Hook.register("payment", this.onPayment.bind(this));
    // console.log(
    //   "this is the opportunity now: ",
    //   this.state.opportunity
    // );
  }

  componentDidUpdate(prevProps, x) {
    if (
      prevProps.match.params !== this.props.match.params ||
      prevProps.match.url !== this.props.match.url
    ) {
      this.setState(
        {
          //                opportunity: this.props.location.state && this.props.location.state.opportunity,
        },
        () => {
          //                this.init();
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClick.bind(this));
    window.removeEventListener("resize", this.onResize.bind(this));
    Hook.unregister("push.on.message", this.pushOnMessage.bind(this));
    Hook.unregister("payment", this.onPayment.bind(this));
  }

  init() {
    if (this.state.opportunity) {
      Document.updateDocumentHead({
        title: "PropCrowdy Opportunity - " + this.state.opportunity.title,
      });
    }
    this.fetchOpportunity();
  }

  onClick(e) {
    if (e && e.target) {
      const invest = $(e.target).closest("#main.opportunity .invest");
      if (!invest.length && !this.state.submitting) {
        this.setState({ showInvestAmountForm: false });
      }

      const share = $(e.target).closest("#main.opportunity .share");
      if (!share.length) {
        this.setState({ showShareButtons: false });
      }
    }
  }

  onResize() {
    this.setState({ isMobile: window.innerWidth <= 992 });
  }

  pushOnMessage(result, message) {
    if (typeof message == "object") {
      if (
        message.type === "investment.payment.successful" &&
        message.data &&
        parseInt(message.data.id) === this.state.opportunity.id
      ) {
        this.fetchOpportunity(true);
      }
    }
  }

  onPayment(result, type, typeId, data) {
    if (
      type === "INVESTMENT" &&
      typeof data === "object" &&
      parseInt(data.id) === parseInt(this.state.opportunity.id)
    ) {
      this.fetchOpportunity(true);
    }
  }

  fetchOpportunity(update) {
    if (!update) {
      this.setState({ fetching: true });
    }
    Api.fetch("opportunity.view", undefined, {
      slug: window.location.pathname.split("/")[2],
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetching: false });
        if (status === 200) {
          this.setState({ opportunity: responseJSON.data }, () => {
            Document.updateDocumentHead({
              title: "PropCrowdy Opportunity - " + this.state.opportunity.title,
            });
            this.listInvestments();
          });
            this.setState({
                starting_date: new Date(this.state.opportunity.start_date),
                closing_date: new Date(this.state.opportunity.closing_date),
            })
        }
      })
      .catch((reason) => {
        this.setState({ fetching: false });
        console.debug(reason);
      });
  }

  listInvestments() {
    let investments = [];
    let myInvestments = [];
    let myPendingInvestments = [];
    let myAbortedInvestments = [];
    if (this.state.opportunity) {
      (this.state.opportunity.crowdy_investments || [])
        .reverse()
        .map((investment, index) => {
          if (parseInt(investment.paid) === 1) {
            investments.push(investment);
          }
          if (
            this.context.user &&
            this.context.user.id === investment.customer_id
          ) {
            myInvestments.push(investment);
            if (parseInt(investment.paid) === 2) {
              myPendingInvestments.push(investment);
            } else if (parseInt(investment.paid) === 0) {
              myAbortedInvestments.push(investment);
            }
          }
          return true;
        });
    }
    this.setState({
      investments: investments,
      myInvestments: myInvestments,
      myPendingInvestments: myPendingInvestments,
      myAbortedInvestments: myAbortedInvestments,
    });
  }

  viewImage(src, title) {
    this.context.updateContext("imageViewerModalSrc", src);
    this.context.updateContext("imageViewerModalTitle", title);
    $("#image-viewer-modal").modal("show");
  }

  viewVideo(src, title, mimeType) {
    this.context.updateContext("videoViewerModalSrc", src);
    this.context.updateContext("videoViewerModalTitle", title);
    this.context.updateContext("videoViewerModalDescription", "");
    this.context.updateContext(
      "videoViewerModalMimeType",
      mimeType || "video/mp4"
    );
    $("#video-viewer-modal").modal("show");
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    // console.log("this is the opportunity now: ", this.props.opportunity);
    // if (completed) {
    //   // Render a complete state
    //   return <div className="countdown-timer">Closed</div>;
    // } else {
    //   // Render a countdown
    //   return (
    //     <div className="countdown-timer">
    //       {days}days {hours}hr {minutes} mins {seconds}s Left!
    //     </div>
    //   );
    // }
    if (
      this.state.today < this.state.starting_date ||
      this.state.today < this.state.closing_date ||
      this.state.opportunity.investment_status === 1
    ) {
      // Render a countdown
      return (
        <div className="countdown-timer">
          Time Left:<span id="tab"></span> {days}
          <span>Days &nbsp;</span> : {hours} <span> Hours &nbsp;</span> : &nbsp;
          {minutes}
          <span> Mins &nbsp;</span> : {seconds} <span> Secs </span>
        </div>
      );
    }
  };
  invest() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (this.context.user) {
      if (this.context.user.customer_type !== "developer") {
        if (!this.state.myPendingInvestments.length) {
          if (!this.state.myAbortedInvestments.length) {
            if (this.state.showInvestAmountForm) {
              if (
                typeof this.state.investAmount !== "string" ||
                this.state.investAmount.trim() === ""
              ) {
                error.amount.push("Please enter an amount");
              } else if (
                parseFloat(this.state.investAmount) <
                parseFloat(this.state.opportunity.min_investment)
              ) {
                error.amount.push(
                  "Minimum investment is ₦" +
                    parseInt(this.state.opportunity.min_investment || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else if (
                parseFloat(this.state.investAmount) >
                parseFloat(this.state.opportunity.max_investment)
              ) {
                error.amount.push(
                  "Maximum investment is ₦" +
                    parseInt(this.state.opportunity.max_investment || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }

              this.setState({ error: error });

              for (let i in error) {
                if (error.hasOwnProperty(i)) {
                  if (error[i].length) {
                    return false;
                  }
                }
              }

              this.setState({ submitting: true });
              Api.fetch("opportunity.investment.add", undefined, {
                crowdy_id: this.state.opportunity.id,
                amount_invested: this.state.investAmount,
                customer_id: this.context.user.id,
              })
                .then(({ status, responseJSON }) => {
                  this.setState({ submitting: false });
                  if (status === 200 && responseJSON.data) {
                    this.fetchOpportunity();
                    this.setState({ showInvestAmountForm: false }, () => {
                      this.context.updateContext(
                        "paymentModalType",
                        "INVESTMENT"
                      );
                      this.context.updateContext(
                        "paymentModalTypeId",
                        responseJSON.data.id
                      );
                      this.context.updateContext(
                        "paymentModalScheduleId",
                        null
                      );
                      this.context.updateContext(
                        "paymentModalTypeTitle",
                        "Opportunity Investment"
                      );
                      this.context.updateContext(
                        "paymentModalTitle",
                        this.state.opportunity.title
                      );
                      this.context.updateContext(
                        "paymentModalAmount",
                        responseJSON.data.amount_invested
                      );
                      this.context.updateContext(
                        "paymentModalData",
                        this.state.opportunity
                      );
                      this.context.updateContext("paymentModalMethods", [
                        "wallet",
                        "paystack",
                        "rave",
                        "bank",
                      ]);
                      $("#payment-modal").modal("show");
                    });
                  } else {
                    let error = this.state.error;
                    error["amount"] = [responseJSON.message];
                    this.setState({ error: error });
                  }
                })
                .catch((reason) => {
                  this.setState({ submitting: false });
                  let { status, responseJSON } =
                    typeof reason === "object" ? reason : {};
                  if ([403, 422].indexOf(status) !== -1) {
                    this.setState({
                      error: { ...error, ...responseJSON.error },
                      message: responseJSON.message || "",
                    });
                  } else {
                    console.debug(reason);
                    this.setState({
                      message:
                        (responseJSON && responseJSON.message) ||
                        "Error Occurred! Please check the internet and try again",
                    });
                  }
                });
            } else {
              this.setState({ showInvestAmountForm: true });
            }
          } else {
            UI.alert(
              {
                title: "Investment in Progress",
                content:
                  "You have initiated an investment but you have not made the payment.",
                buttons: [
                  {
                    type: "positive",
                    title: "Pay Now",
                    onClick: () => {
                      this.setState({ showInvestAmountForm: false }, () => {
                        this.context.updateContext(
                          "paymentModalType",
                          "INVESTMENT"
                        );
                        this.context.updateContext(
                          "paymentModalTypeId",
                          this.state.myAbortedInvestments[0].id
                        );
                        this.context.updateContext(
                          "paymentModalScheduleId",
                          null
                        );
                        this.context.updateContext(
                          "paymentModalTypeTitle",
                          "Opportunity Investment"
                        );
                        this.context.updateContext(
                          "paymentModalTitle",
                          this.state.opportunity.title
                        );
                        this.context.updateContext(
                          "paymentModalAmount",
                          this.state.myAbortedInvestments[0].amount_invested
                        );
                        this.context.updateContext(
                          "paymentModalData",
                          this.state.opportunity
                        );
                        this.context.updateContext("paymentModalMethods", [
                          "wallet",
                          "paystack",
                          "rave",
                          "bank",
                        ]);
                        $("#payment-modal").modal("show");
                      });
                    },
                  },
                  {
                    type: "negative",
                    title: "Cancel Investment",
                    onClick: () => {
                      let myAbortedInvestments =
                        this.state.myAbortedInvestments || [];
                      if (myAbortedInvestments.length) {
                        let opportunity = this.state.opportunity;
                        let investmentIndex = undefined;
                        Api.fetch("opportunity.investment.delete", {
                          id: myAbortedInvestments[0].id,
                        })
                          .then(({ status, responseJSON }) => {})
                          .catch((reason) => {
                            console.debug(reason);
                          });
                        (opportunity.crowdy_investments || []).map(
                          (investment, index) => {
                            if (
                              investment.id ===
                              this.state.myAbortedInvestments[0].id
                            ) {
                              investmentIndex = index;
                            }
                            return true;
                          }
                        );
                        myAbortedInvestments.splice(0, 1);
                        if (typeof investmentIndex !== "undefined") {
                          (opportunity.crowdy_investments || []).splice(
                            investmentIndex,
                            1
                          );
                        }
                        this.setState({
                          opportunity: opportunity,
                          myAbortedInvestments: myAbortedInvestments,
                        });
                      }
                    },
                  },
                ],
              },
              this.context
            );
          }
        } else {
          UI.alert(
            {
              title: "Transaction in progress",
              content:
                "You have an investment transaction in progress. Please wait for the payment to be confirmed.",
            },
            this.context
          );
        }
      } else {
        UI.alert(
          {
            title: "Sorry",
            content:
              "Developers cannot make an investment. Only an investor can invest.",
          },
          this.context
        );
      }
    } else {
      UI.alert(
        {
          title: "Login First",
          content: "You must be logged in to invest",
          buttons: [
            {
              type: "positive",
              title: "Login",
              onClick: () => {
                this.props.history.push({
                  pathname: "/login",
                  state: {
                    message: "Please login first",
                    redirectPathname:
                      "/opportunity/" + this.state.opportunity.slug,
                  },
                });
              },
            },
            {
              type: "neutral",
              title: "Register",
              onClick: () => {
                this.props.history.push({
                  pathname: "/register",
                  state: {
                    message: "Please register first",
                    redirectPathname:
                      "/opportunity/" + this.state.opportunity.slug,
                  },
                });
              },
            },
          ],
        },
        this.context
      );
    }
  }

  share(type) {
    const url = window.location.href;
    const target = "PropCrowdy - Share Opportunity";
    const features = "width = 500, height = 500, scrollbars = yes";
    if (type === "facebook") {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?display=popup&u=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "twitter") {
      window.open(
        "https://twitter.com/share?text=" +
          this.state.opportunity.title +
          "&url=" +
          encodeURIComponent(url) +
          "&href=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "linkedin") {
      window.open(
        "https://www.linkedin.com/cws/share?url=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "pinterest") {
      window.open(
        "https://pinterest.com/pin/create/button/?url=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "whatsapp") {
      window.open(
        "https://api.whatsapp.com/send?text=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "telegram") {
      window.open(
        "https://telegram.me/share/url?url=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "email") {
      window.open(
        "mailto:?subject=" +
          this.state.opportunity.title +
          "&body=" +
          encodeURIComponent(url),
        "_self",
        features
      );
    }
  }

  fund() {
    let percent =
      (parseFloat(this.state.opportunity.amount_raised || 0) /
        parseFloat(this.state.opportunity.amount)) *
      100;

    // let closing_date = new Date(this.state.opportunity.closing_date);
    // let today = new Date();

    return (
      <div className="fund">
        {/* <Countdown
          date={this.state.opportunity.closing_date + " 23:59:59"}
          renderer={this.renderer}
        /> */}
        <div
          style={{
            width: "100%",
            background: "#ff0000",
            color: "#fff",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "56px",
            fontFamily: "ApercuProBoldNormal",
            borderRadius: "12px 12px 0 0",
          }}
        >
          {/* {today >= closing_date ? <p>Open</p> : <p>Closed</p>} */}
          <p>
            {this.state.today < this.state.starting_date
              ? "Coming Soon"
              : this.state.today >= this.state.starting_date &&
                this.state.opportunity.investment_status === 1
              ? "Open"
              : this.state.today > this.state.starting_date &&
                this.state.opportunity.investment_status === 2
              ? "Closed"
              : this.state.today > this.state.starting_date &&
                this.state.opportunity.investment_status === 3
              ? "Cancelled"
              : this.state.today > this.state.starting_date &&
                this.state.opportunity.investment_status === 4
              ? "Completed"
              : ""}
          </p>
        </div>
        {this.context && this.context.settings && this.context.settings.investments && this.context.settings.investments.show_goal?(
        <>
          {this.state.opportunity.investment_status != 2 ? (
              <div className="goal">
            <span className="raised">
              ₦
              {parseInt(this.state.opportunity.amount_raised || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              Raised
            </span>
                <span className="amount">
              of ₦
                  {parseInt(this.state.opportunity.amount || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  goal
            </span>
              </div>
          ) : null}
          {this.state.opportunity.investment_status != 2 ? (
              <div className="bar">
                <div
                    className="fill"
                    style={{
                      width:
                          (percent && percent < 1
                              ? percent.toFixed(2)
                              : Math.floor(percent)) + "%",
                    }}
                />
              </div>
          ) : null}
        </>): null}
        {/*<div className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elitr, sed diam nonumy eirmod</div>*/}
        <div className="info" />
        {this.state.opportunity.investment_status != 2 ? (
            <>
              {this.context && this.context.settings && this.context.settings.investments && this.context.settings.investments.show_sponsors?(
                  <div className="sponsors">
                    {(this.state.investments || [])
                        .slice(0, 3)
                        .map((investment, index) => {
                          return (
                              <div key={index} className="sponsor">
                                <img
                                    src={
                                      investment.customer.photo
                                          ? investment.customer.photo.url
                                          : "/assets/images/vectors/avatar.svg"
                                    }
                                    alt={
                                      investment.customer.first_name +
                                      " " +
                                      investment.customer.last_name
                                    }
                                    className="avatar"
                                />
                                <div className="amount">
                                  Sponsored
                                  <br />₦
                                  {parseInt(investment.amount_invested || 0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                                <div className="time-ago">
                                  {Time.timeAgo(investment.created_at)}
                                </div>
                              </div>
                          );
                        })}
                  </div>):null}
            </>
        ) : null}
        <div className="invest">
          {!this.state.myPendingInvestments.length &&
            this.state.showInvestAmountForm &&
            !this.state.fetching && (
              <form
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.invest();
                }}
              >
                {this.state.submitting && <Loading />}
                <div className="message">{this.state.message}</div>
                <input
                  type="number"
                  name="amount"
                  value={this.state.investAmount}
                  placeholder="Enter Investment Amount (₦)"
                  className="form-control"
                  onChange={(e) =>
                    this.setState({ investAmount: e.target.value })
                  }
                  required
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.amount.join("<br />"),
                  }}
                />
              </form>
            )}
          {parseInt(this.state.opportunity.amount_raised || 0) <=
            parseInt(this.state.opportunity.amount || 0) &&
          (!this.context.user ||
            (this.context.user &&
              this.context.user.customer_type !== "developer")) ? (
            <button
              onClick={() => {
                if (
                  this.context.user &&
                  this.context.user.payment_info &&
                  (!this.context.user.payment_info.completed ||
                    this.context.user.payment_info.completed == 0)
                ) {
                  UI.alert(
                    {
                      title: "Financial Information Details",
                      content:
                        "You need to update your financial information to enable you invest. Go to account setting to update your profile and your financial information",
                      buttons: [
                        {
                          type: "positive",
                          title: "Goto Account Settings",
                          onClick: () => {
                            this.props.history.push({
                              pathname: "/account/payment",
                              state: {
                                message:
                                  "Please Update your financial information",
                                redirectPathname:
                                  "/opportunity/" + this.state.opportunity.slug,
                              },
                            });
                          },
                        },
                      ],
                    },
                    this.context
                  );
                } else {
                  this.invest();
                }
              }}
              title={
                this.state.myPendingInvestments.length
                  ? "You have an investment transaction in progress. Please wait for the payment to be confirmed."
                  : this.state.myAbortedInvestments.length
                  ? "You have initiated an investment."
                  : "Invest"
              }
              disabled={
                this.state.opportunity.investment_status === 2 ||
                this.state.opportunity.investment_status === 3 ||
                this.state.opportunity.investment_status === 4
                  ? true
                  : false
              }
              className={
                this.state.myPendingInvestments.length ||
                this.state.myAbortedInvestments.length ||
                this.state.fetching ||
                this.state.opportunity.investment_status === 2 ||
                this.state.opportunity.investment_status === 3 ||
                this.state.opportunity.investment_status === 4
                  ? "disabled"
                  : ""
              }
            >
              {this.state.opportunity.investment_status != 2
                ? "Invest Now"
                : "Investment Closed"}
            </button>
          ) : null}
        </div>
        <div className="share">
          {this.state.showShareButtons && (
            <div className="buttons">
              <button
                className="lab la-facebook"
                onClick={() => this.share("facebook")}
              />
              <button
                className="lab la-twitter"
                onClick={() => this.share("twitter")}
              />
              <button
                className="lab la-linkedin"
                onClick={() => this.share("linkedin")}
              />
              {/*<button className="lab la-pinterest" onClick={() => this.share('pinterest')} />*/}
              <button
                className="lab la-whatsapp"
                onClick={() => this.share("whatsapp")}
              />
              <button
                className="lab la-telegram"
                onClick={() => this.share("telegram")}
              />
              <button
                className="lab la-email"
                onClick={() => this.share("email")}
              />
            </div>
          )}
          <button
            onClick={() => {
              this.setState({ showShareButtons: true });
            }}
          >
            Share Project
          </button>
        </div>

        {/* {this.state.opportunity.company ? (
          <div className="developer">
            <button
              onClick={() => {
                if (
                  this.context &&
                  this.context.user &&
                  this.context.user.hasOwnProperty("id")
                ) {
                  this.props.history.push({
                    pathname: "/deal-room/" + this.state.opportunity.slug,
                  });
                } else {
                  UI.alert(
                    {
                      title: "Login First",
                      content: "You must be logged in to enter deal room",
                      buttons: [
                        {
                          type: "positive",
                          title: "Login",
                          onClick: () => {
                            this.props.history.push({
                              pathname: "/login",
                              state: {
                                message: "Please login first",
                                redirectPathname:
                                  "/deal-room/" + this.state.opportunity.slug,
                              },
                            });
                          },
                        },
                        {
                          type: "neutral",
                          title: "Register",
                          onClick: () => {
                            this.props.history.push({
                              pathname: "/register",
                              state: {
                                message: "Please register first",
                                redirectPathname:
                                  "/deal-room/" + this.state.opportunity.slug,
                              },
                            });
                          },
                        },
                      ],
                    },
                    this.context
                  );
                }
              }}
              className="link deal-button"
            >
              Go to Deal Room
            </button>
            <Link
              to={{
                pathname: "/company/" + this.state.opportunity.company.slug,
                state: { company: this.state.opportunity.company },
              }}
              className="link"
            >
              View All Developer Info
            </Link>
            {this.state.opportunity &&
            this.state.opportunity.project_update_link ? (
              <a
                style={{ marginTop: "7px" }}
                href={this.state.opportunity.project_update_link ?? ""}
                target="_blank"
                className="link update-link"
              >
                Project Update & Progress
              </a>
            ) : null}
          </div>
        ) : null} */}
      </div>
    );
  }

  render() {
    return (
      <main id="main" className="opportunity view">
        <div className="bread-crumb">
          {this.state.opportunity && (
            <div className="container">
              <Link to={{ pathname: "/home" }} className="link">
                Home
              </Link>
              <i className="icon arrow las la-angle-right" />
              <Link
                to={{
                  pathname: "/opportunity/" + this.state.opportunity.slug,
                }}
                className="link"
              >
                {this.state.opportunity.title}
              </Link>
            </div>
          )}
        </div>
        {this.state.opportunity ? (
          <div style={{}} className="container">
            <div style={{}} className="row">
              <div className="col-lg-8">
                <div className="heading">
                  {/* <img
                        src={
                          (this.state.opportunity.company &&
                            this.state.opportunity.company.logo &&
                            this.state.opportunity.company.logo.url) ||
                          "/assets/images/company-logo.png"
                        }
                        alt={
                          this.state.opportunity.company &&
                          this.state.opportunity.company.name
                        }
                        className="logo"
                      /> */}
                  <div className="property">
                    <div
                      style={{
                        fontFamily: "ApercuProBoldNormal",
                        fontSize: "18px",
                        lineHeight: "26px",
                        letterSpacing: "-0.02em",
                        textTransform: "capitalize",
                        color: "#ff0000",
                        marginTop: "10px",
                      }}
                      className="company_name"
                    >
                      {this.state.opportunity.company.name}
                    </div>
                    <div className="title">{this.state.opportunity.title}</div>
                    {/* <div className="location">
                          {this.state.opportunity.location}
                        </div> */}
                  </div>
                </div>
                <div className="image-carousel-container">
                  <div className="preview">
                    <div
                      id="opportunity-image-carousel"
                      className="carousel slide image-carousel"
                      data-interval="0"
                    >
                      <div className="carousel-inner">
                        {this.state.opportunity.property_images.map(
                          (image, index) => (
                            <div
                              key={index}
                              className={
                                "carousel-item" + (index ? "" : " active")
                              }
                            >
                              <img
                                className="d-block w-100 image"
                                src={image.url}
                                alt={this.state.opportunity.title}
                                data-large-img-url={image.url}
                                onClick={() => {
                                  this.viewImage(image.url);
                                }}
                              />
                            </div>
                          )
                        )}
                        {this.state.opportunity.video ? (
                          <div className="carousel-item">
                            <img
                              className="d-block w-100 image"
                              src={
                                this.state.opportunity.video.preview ||
                                "/assets/images/video-placeholder.jpg"
                              }
                              alt={this.state.opportunity.title}
                              data-large-img-url={
                                this.state.opportunity.video.preview ||
                                "/assets/images/video-placeholder.jpg"
                              }
                              onClick={() => {
                                this.viewVideo(
                                  this.state.opportunity.video.url,
                                  "",
                                  this.state.property.video.mime_type
                                );
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="controls">
                    <button className="left-arrow arrow">
                      <i className="icon las la-angle-left" />
                    </button>
                    <div className="thumbs">
                      {this.state.opportunity.property_images.map(
                        (image, index) => (
                          <img
                            key={index}
                            src={image.url}
                            alt={this.state.opportunity.title}
                            data-slide-to={index}
                            className={"thumb" + (index ? "" : " active")}
                          />
                        )
                      )}
                      {this.state.opportunity.video ? (
                        <img
                          src={
                            this.state.opportunity.thumbnail ||
                            "/assets/images/video-placeholder.jpg"
                          }
                          alt={this.state.opportunity.title}
                          data-slide-to={
                            this.state.opportunity.property_images.length
                          }
                          className="thumb"
                        />
                      ) : null}
                    </div>
                    <button className="right-arrow arrow">
                      <i className="icon las la-angle-right" />
                    </button>
                  </div>
                </div>

                <MinorCardsContainer opportunity={this.state.opportunity} />

                {this.state.isMobile && this.fund()}
                {/*<div style={{width:'100%', textAlign:'center'}}>
                                    <img src={noticeBanner}/>
                                </div>*/}
                {/* <div className="details">
                  <div className="detail">
                    <div className="title">Company Information</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          this.state.opportunity.company &&
                          this.state.opportunity.company.description,
                      }}
                      className="content"
                    ></div>
                  </div>
                  <div className="detail">
                    <div className="title">Investor Warning</div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: this.state.opportunity.investors_warning ? (
                          this.state.opportunity.investors_warning
                        ) : (
                          <em>No Data</em>
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="return">
                    <div className="detail">
                      <div className="title">Wait Period Before ROI</div>
                      <div className="content">
                        {this.state.opportunity.tenor} Months
                      </div>
                    </div>
                    <div className="detail">
                      <div className="title">ROI Percentage</div>
                      <div className="content">
                        {this.state.opportunity.roi_percentage}%
                      </div>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="title">Description</div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: this.state.opportunity.description ? (
                          this.state.opportunity.description
                        ) : (
                          <em>No Data</em>
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="detail">
                    <div className="title">Liquidity of the securities</div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: this.state.opportunity.liquidity_of_security ? (
                          this.state.opportunity.liquidity_of_security
                        ) : (
                          <em>No Data</em>
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="detail">
                    <div className="title">
                      Risk of Investment non-performance
                    </div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: this.state.opportunity
                          .risk_of_investment_non_performance ? (
                          this.state.opportunity
                            .risk_of_investment_non_performance
                        ) : (
                          <em>No Data</em>
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="detail">
                    <div className="title">Video</div>
                    <div className="content">
                      {this.state.opportunity.youtube_video_url ? (
                        <iframe
                          height="300px"
                          style={{ width: "100%" }}
                          src={this.state.opportunity.youtube_video_url}
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                        />
                      ) : (
                        <em>No video</em>
                      )}
                    </div>
                  </div>
                  <div className="detail">
                    <div className="title">Description of Business</div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: this.state.opportunity
                          .description_of_investment_business ? (
                          this.state.opportunity
                            .description_of_investment_business
                        ) : (
                          <em>No Data</em>
                        ),
                      }}
                    ></div>
                  </div>
                </div> */}
              </div>
              <div style={{}} className="col-lg-4">
                {!this.state.isMobile && this.fund()}
                {this.context && this.context.settings && this.context.settings.investments && this.context.settings.investments.show_sponsors?(
                    <NumberOfInvestorsCard
                  investments={this.state.opportunity?.crowdy_investments}
                />):null}
              </div>
            </div>
            <div>
              <OpportunitySecondSection
                opportunity={this.state.opportunity}
                contexting={this.context}
              />
            </div>
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "150px",
              }}
              className="main_third_section"
            >
              <ContactUsBanner />
            </section>
          </div>
        ) : (
          <Loading />
        )}
      </main>
    );
  }
}
