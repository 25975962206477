import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import {Link} from 'react-router-dom';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';

export default class TicketList extends React.Component {
    constructor(props) {
        super(props);
        TicketList.contextType = Context;
        this.state = {
            term: null,
            page: Api.queryStringToObj(this.props.history.location.search).page || 1,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'user.buy' ? 'user.buy' : 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Feel free to reach out to our support staff');
        Document.updateDocumentHead({title: 'PropCrowdy - Support Tickets'});
        let param = Api.queryStringToObj(this.props.location.search);
        this.setState({search: param.search}, () => {
            this.fetchList();
            TicketList.fetchUnreadList(this.context);
            Hook.register('push.on.message', this.pushOnMessage.bind(this));
        });
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'new.ticket.message') {
                this.resetList(() => {
                    this.fetchList();
                });
            }
        }
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchList() {
        if(!this.state.fetching) {
            this.setState({fetching: true});
            Api.fetch('support.ticket.list', undefined, {
                search: this.state.term,
                offset: (this.state.page - 1) * this.state.limit,
                limit: this.state.limit
            }).then(({status, responseJSON}) => {
                this.setState({fetching: false});
                if(status === 200) {
                    this.setState({
                        list: responseJSON.data || [],
                        total: responseJSON.total
                    });
                }
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    static fetchUnreadList(context) {
        Api.fetch('ticket.list', undefined, {type: 'unread', unread: 1, read: 0, limit: 5}).then(({status, responseJSON}) => {
            if(status === 200) {
                context.updateContext('unreadTickets', responseJSON.data || []);
            }
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    static clearUnreadList(context) {
        context.updateContext('unreadTickets', []);
    }

    markTicketsRead(id) {
        Api.fetch('ticket.read', undefined, {customer_id: this.context.user.id, id: id}).then(() => {
            TicketList.fetchUnreadList(this.context);
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    render() {
        return (
            <main id="main" className="support tickets admin listing">
                <div className="container">
                    <div className="heading">Tickets</div>
                    <div className="list">
                        <div className="row">
                            <div className="col-md-6">
                                <form className="search" onSubmit={(e) => {e.preventDefault()}}>
                                    <input type="search" placeholder="Search" onChange={(e) => {
                                        this.setState({term: e.target.value}, () => {
                                            this.resetList(() => {
                                                this.fetchList();
                                            });
                                        });
                                    }} />
                                    <img src="/assets/images/vectors/icons/search-1.svg" className="icon" alt="Search" />
                                </form>
                            </div>
                            <div className="col-md-6">
                                <div className="buttons">
                                    <Link to={{pathname: '/support/new-ticket'}} className="button">
                                        <i className="icon las la-plus" />
                                        New Ticket
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {!this.state.fetching && !this.state.list.length && (
                            <div className="list-empty">
                                <i className="icon las la-info-circle" />
                                <span className="message">You have no ticket</span>
                            </div>
                        )}
                        <div className="items">
                            {this.state.list.map((ticket, index) => (
                                <div key={index} className="item ticket">
                                    <div className="overview">
                                        <div className="middle">
                                            <div className="top">
                                                <Link to={{pathname: '/support/ticket/' + ticket.id + '/' + ticket.subject.replace(/[^A-Za-z0-9]+/gi, '-'), state: {ticket: ticket}}} className="title text-uppercase">{ticket.subject}</Link>
                                                <div className="time">{Time.timeToFriendly(ticket.created_at, 0)}</div>
                                            </div>
                                            {ticket.attachment.length ? (
                                                <div className="middle">
                                                    <div className="attachments">
                                                        <img src="/assets/images/vectors/icons/attachment.svg" alt="Attachment" className="icon" />
                                                        {ticket.attachment.length + (ticket.attachment.length > 1 ? ' Attachments' : ' Attachment')}
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="bottom">
                                                <div className="type">{ticket.category.name}</div>
                                                <div className="status" style={{backgroundColor: ((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.status) || []).reduce((statuses, status) => {statuses[status.value] = status.color || (status.value === 1 && '#84e0be') || (status.value === 2 && '#000') || (status.value === 3 && '#f00'); return statuses}, {})[ticket.status]}}>
                                                    {((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.status) || []).reduce((statuses, status) => {statuses[status.value] = status.display; return statuses}, {})[ticket.status]}
                                                </div>
                                                <div className="priority" style={{backgroundColor: ((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.priority) || []).reduce((priorities, priority) => {priorities[priority.value] = priority.color || (priority.value === 1 && '#84e0be') || (priority.value === 2 && '#000') || (priority.value === 3 && '#f00'); return priorities}, {})[ticket.priority]}}>
                                                    {((this.context.constants && this.context.constants.support && this.context.constants.support.ticket && this.context.constants.support.ticket.priority) || []).reduce((priorities, priority) => {priorities[priority.value] = priority.display; return priorities}, {})[ticket.priority]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="bottom">
                                                <div className="actions">
                                                    <Link to={{pathname: '/support/ticket/' + ticket.id + '/' + ticket.subject.replace(/[^A-Za-z0-9]+/gi, '-'), state: {ticket: ticket}}} className="green">View</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details" dangerouslySetInnerHTML={{__html: ticket.description}} />
                                </div>
                            ))}
                        </div>
                        <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                            {this.state.fetching && <Loading />}
                        </div>
                        {!this.state.fetching && this.state.list.length < this.state.total && (
                            <div className="list-load-more">
                                <button onClick={() => {this.fetchList()}}>Load More</button>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}
