import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from './../Context';
import Document from './../Utils/Document';

export default class Privacy extends React.Component {
    constructor(props) {
        super(props);
        Privacy.contextType = Context;
        this.state = {

        };
    }

    componentDidMount() {
        this.context.updateContext('layout', window.location.pathname.split('/')[1] === 'buy' ? 'default.buy' : 'default');
        Document.updateDocumentHead({title: (window.location.pathname.split('/')[1] === 'buy' ? 'PropBuy' : 'PropCrowdy') + ' - Privacy Policy'});
    }

    render() {
        return (
            <main id="main" className="p-0">
                <section id="top-cover">
                    <div className="container">
                        <div className="title">Privacy Policy</div>
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="description">Your data is safe with us and we won’t use your data except with your permission</div>
                        ) : (
                            <div className="description">Your data is safe with us and we won’t use your data except with your permission</div>
                        )}
                        <div className="links">
                            <Link to={{pathname: '/terms'}} className="link">View Terms & Condition</Link>
                        </div>
                    </div>
                </section>
                <section id="privacy-policy">
                    <div className="container">
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="heading">PropBuy Privacy Policy</div>
                        ) : (
                            <div className="heading">PropCrowdy Privacy Policy</div>
                        )}
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="content">
                                <p><strong><u>Introduction</u></strong></p>
                                <p>This Privacy Policy is developed by Propbuy Nigeria in compliance with the Nigerian Data Protection Regulation 2019 and other similar regulations. It is also a demonstration of our commitment to the protection of your personal data.</p>
                                <p>We only collect data that is required for the nature of the transaction that you are involved in with us and no more. Any data, personal or otherwise, that is not directly required for the purpose of collection or that is not consented to by you will not be collected or acquired. We do not collect personal information from you unless you specifically and knowingly choose to provide such information to us.</p>
                                <p>For the limited instances where personal information is collected either automatically or volunteered by the web user, the following constitute our undertakings in handling such Personal Information.</p>
                                <p><strong><u>Data Subject’s Privacy Rights</u></strong></p>
                                <p>This Privacy Policy describes your privacy rights in relation to your personal information which Propbuy Nigeria might collect, use, store, or share when you use our services. It applies to our website and all database applications, services, tools and physical contact with the company regardless of how you access or use them.</p>
                                <p>If you have created a username, identification code, password or any other piece of information as part of our access security measures, you must treat such information as confidential, and must not disclose it to any third party. Propbuy reserves the right to disable your identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of these Conditions. If you know or suspect that anyone other than you knows your security details, you must promptly notify us.</p>
                                <p><strong><u>Data Subject’s consent</u></strong></p>
                                <p>“Data Subject” as used in this policy means a person who can be identified directly or indirectly by reference to any information supplied to the Commission. “Consent” of the data subject means any freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her in the manner specified in this policy. You accept this Privacy Policy when you give consent upon access to our platforms, or use our services, content, features, technologies or functions offered on our website, digital platforms. We may amend this Privacy Policy at any time by posting a revised version on our website, or placing such notice at conspicuous points at our office premises.</p>
                                <p> </p>
                                <p><strong><u>Collectable Personal Information</u></strong></p>
                                <p>When you use any of our services, we collect information automatically sent to us by your computer, mobile phone or other electronic access device. Such information includes but is not limited to:</p>
                                <ul>
                                    <li>Information about the computer or device used to access Propbuy’s website such as: device ID or unique identifier, device type, location information, computer and connection information, mobile network information;</li>
                                    <li>Internet Protocol (IP) address.  A computer’s IP address establishes its location on the Internet and allows communications with other computers to send it content and other information;</li>
                                    <li>The date and time (with time zone) of a website visit;</li>
                                    <li>Information about the pages you access, statistics on page views, traffic to and from the sites, referral URL, ad data, standard web log data;</li>
                                    <li>Internet traffic information associated with a specific IP address, time, or event;</li>
                                    <li>Type of technology used to access the website (such as the type of internet browser and type of operating system);</li>
                                    <li>Host names;</li>
                                    <li>Malicious software identification; and</li>
                                    <li>Caller Identification logs, which provide a caller’s apparent phone number.</li>
                                </ul>
                                <p>We may also collect information you provide us including but not limited to information on web form, survey responses account update information, email, phone number, organization you represent (where necessary) and correspondence with our support services. We may also collect information about your transactions, enquiries and your activities on our platform.</p>
                                <p>We may also use information provided by third parties like social media sites. However, Information about you provided by other sites are not controlled by us and we are therefore not liable for how they use it.</p>
                                <p><strong><u>Purpose of collecting Personal Data</u></strong></p>
                                <p>We generally do not collect your name, email, mailing address or similar identifying information without your knowledge when you visit our website to access or use information, such as press releases or publications. However, we will automatically collect and maintain certain statistical information about your visit for the following purposes:</p>
                                <ul>
                                    <li>To enable us make improvements on our website and for site management purposes:</li>
                                    <li>To track traffic on our website of visitors to the different sections of our website;</li>
                                    <li>To evaluate system performance;</li>
                                    <li>To help us make the website more useful to our visitors; and</li>
                                    <li>To analyze and maintain the safety of our website from time to time.</li>
                                </ul>
                                <p><strong><u>Technical methods used to collect and store personal information</u></strong> – <strong><u>cookies</u></strong></p>
                                <p>Cookies are small files placed on your computer’s hard drive that enables the website to identify your computer as you view different pages. Cookies allow websites and applications to store your preferences in order to present contents, options or functions that are specific to you. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only encrypted session cookies which are erased either after a predefined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from the user’s computer. They will typically store information in the form of a session identification that does not personally identify the user.</p>
                                <p><strong><u>How we protect your personal information</u></strong></p>
                                <p>We store and process your personal information on our computers in Nigeria. Where we need to transfer your data to another country, such country must have an adequate data protection law. We will seek your consent where we need to send your data to a country without an adequate data protection law. We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.</p>
                                <p><strong><u>Third Party Disclosure</u></strong></p>
                                <p>We generally do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties in some limited cases.</p>
                                <p>Where and when it is necessary, we may also provide Government Ministries, Departments, Agencies (MDA), other organs of government, private sector operators performing government functions, with information such as your name, contact details, or other details you provide us for the purpose of performing our lawful mandate to you or third parties.</p>
                                <p><strong><u> </u></strong></p>
                                <p><strong><u> </u></strong></p>
                                <p><strong><u> </u></strong></p>
                                <p><strong><u>Security of personal data</u></strong></p>
                                <p>We will always hold your information securely. To prevent unauthorized access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. <strong> </strong></p>
                                <p><strong><u>Data Confidentiality Rights and available remedies in the event of violation of the privacy policy</u></strong></p>
                                <p>Section 37 of the Constitution provides that: “The privacy of citizens, their homes, correspondence, telephone conversations and telegraphic communications is hereby guaranteed and protected”</p>
                                <p>Your information is regarded as confidential and will not be divulged to any third party except under legal and/or necessary transactional conditions. You have the right to request sight of, and copies of any and all information we keep on you, if such requests are made in compliance with the Freedom of Information Act and other relevant enactments. While we are responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with physical security protocols on our premises.</p>
                                <p>In line with Fair Information Practices we will take the following responsive action, should a data breach occur: We will notify the users via email within 7 business days; we will notify the users via in site notification also within 7 business days. We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires that not only should individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.</p>
                                <p><strong><u>Links to Other Websites and Premises</u></strong></p>
                                <p>Certain transaction processing channels may require links to other websites or organizations other than ours. Please note that Propbuy is not responsible and has no control over websites outside its domain. We do not monitor or review the content of other parties’ websites which are linked from our website or media platforms. Opinions expressed or materials appearing on such websites are not necessarily shared or endorsed by us, and Propbuy should not be regarded as the publisher of such opinions or materials. Please be aware that we are not responsible for the privacy practices, or content of these sites. We encourage our users to be aware of when they leave our site and to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through our site yourself, before disclosing any personal information to them. We will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.</p>
                                <p><strong><u>Governing Law</u></strong></p>
                                <p>This Privacy Policy is made pursuant to the Nigeria Data Protection Regulation (2019) and other relevant Nigerian laws, regulations or international conventions applicable to Nigeria. Where any provision of this Policy is deemed inconsistent with a law, regulation or convention, such provision shall be subject to the overriding law, regulation or convention.</p>
                            </div>
                        ) : (
                            <div className="content">
                                <p><strong><u>Introduction</u></strong></p>
                                <p>This Privacy Policy is developed by Propcrowdy Nigeria in compliance with the Nigerian Data Protection Regulation 2019 and other similar regulations. It is also a demonstration of our commitment to the protection of your personal data.</p>
                                <p>We only collect data that is required for the nature of the transaction that you are involved in with us and no more. Any data, personal or otherwise, that is not directly required for the purpose of collection or that is not consented to by you will not be collected or acquired.We do not collect personal information from you unless you specifically and knowingly choose to provide such information to us.</p>
                                <p>For the limited instances where personal information is collected either automatically or volunteered by the web user, the following constitute our undertakings in handling such Personal Information.</p>
                                <p><strong>Data Subject's Privacy Rights</strong></p>
                                <p>This Privacy Policy describes your privacy rights in relation to your personal information which SEC Nigeria might collect, use, store, or share when you use our services. It applies to our website and all database applications, services, tools and physical contact with the company regardless of how you access or use them.</p>
                                <p>If you have created a username, identification code, password or any other piece of information as part of our access security measures, you must treat such information as confidential, and must not disclose it to any third party. Propcrowdy reserves the right to disable your identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of these Conditions. If you know or suspect that anyone other than you knows your security details, you must promptly notify us.</p>
                                <p><strong><u>Data Subject's consent</u></strong></p>
                                <p>"Data Subject" as used in this policy means a person who can be identified directly or indirectly by reference to any information supplied to the Commission. "Consent" of the data subject means any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her in the manner specified in this policy. You accept this Privacy Policy when you give consent upon access to our platforms, or use our services, content, features, technologies or functions offered on our website, digital platforms. We may amend this Privacy Policy at any time by posting a revised version on our website, or placing such notice at conspicuous points at our office premises.</p>
                                <p> </p>
                                <p><strong><u>Collectable Personal Information</u></strong></p>
                                <p>When you use any of our services, we collect information automatically sent to us by your computer, mobile phone or other electronic access device. Such information includes but is not limited to:</p>
                                <ul>
                                    <li>Information about the computer or device used to access SEC Nigeria's website such as: device ID or unique identifier, device type, location information, computer and connection information, mobile network information;</li>
                                    <li>Internet Protocol (IP) address. A computer's IP address establishes its location on the Internet and allows communications with other computers to send it content and other information;</li>
                                    <li>The date and time (with time zone) of a website visit;</li>
                                    <li>Information about the pages you access, statistics on page views, traffic to and from the sites, referral URL, ad data, standard web log data;</li>
                                    <li>Internet traffic information associated with a specific IP address, time, or event;</li>
                                    <li>Type of technology used to access the website (such as the type of internet browser and type of operating system);</li>
                                    <li>Host names;</li>
                                    <li>Malicious software identification; and</li>
                                    <li>Caller Identification logs, which provide a caller's apparent phone number.</li>
                                </ul>
                                <p> </p>
                                <p>We may also collect information you provide us including but not limited to information on web form, survey responses account update information, email, phone number, organization you represent (where necessary) and correspondence with our support services. We may also collect information about your transactions, enquiries and your activities on our platform or premises.</p>
                                <p>We may also use information provided by third parties like social media sites. However, Information about you provided by other sites are not controlled by us and we are therefore not liable for how they use it.</p>
                                <p><strong><u>Purpose of collecting Personal Data</u></strong></p>
                                <p>We generally do not collect your name, email, mailing address or similar identifying information without your knowledge when you visit our website to access or use information, such as press releases or publications. However, we will automatically collect and maintain certain statistical information about your visit for the following purposes:</p>
                                <ul>
                                    <li>To enable us make improvements on our website and for site management purposes:</li>
                                    <li>To track traffic on our website of visitors to the different sections of our website;</li>
                                    <li>To evaluate system performance;</li>
                                    <li>To help us make the website more useful to our visitors; and</li>
                                    <li>To analyze and maintain the safety of our website from time to time.</li>
                                </ul>
                                <p><strong><u>Technical methods used to collect and store personal information</u></strong> &ndash; <strong><u>cookies</u></strong></p>
                                <p>Cookies are small files placed on your computer's hard drive that enables the website to identify your computer as you view different pages. Cookies allow websites and applications to store your preferences in order to present contents, options or functions that are specific to you. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only encrypted session cookies which are erased either after a predefined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from the user's computer. They will typically store information in the form of a session identification that does not personally identify the user.</p>
                                <p><strong><u>How we protect your personal information</u></strong></p>
                                <p>We store and process your personal information on our computers in Nigeria. Where we need to transfer your data to another country, such country must have an adequate data protection law. We will seek your consent where we need to send your data to a country without an adequate data protection law. We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.</p>
                                <p><strong><u>Third Party Disclosure</u></strong></p>
                                <p>We generally do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties in some limited cases.</p>
                                <p>Where and when it is necessary, we may also provide Government Ministries, Departments, Agencies (MDA), other organs of government, private sector operators performing government functions, with information such as your name, contact details, or other details you provide us for the purpose of performing our lawful mandate to you or third parties.</p>
                                <p><strong><u> </u></strong></p>
                                <p><strong><u> </u></strong></p>
                                <p><strong><u> </u></strong></p>
                                <p><strong><u>Security of personal data</u></strong></p>
                                <p>We will always hold your information securely. To prevent unauthorized access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. <strong> </strong></p>
                                <p><strong><u>Data Confidentiality Rights and available remedies in the event of violation of the privacy policy</u></strong></p>
                                <p>Section 37 of the Constitution provides that: "The privacy of citizens, their homes, correspondence, telephone conversations and telegraphic communications is hereby guaranteed and protected"</p>
                                <p>Your information is regarded as confidential and will not be divulged to any third party except under legal and/or necessary transactional conditions. You have the right to request sight of, and copies of any and all information we keep on you, if such requests are made in compliance with the Freedom of Information Act and other relevant enactments. While we are responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with physical security protocols on our premises.</p>
                                <p>In line with Fair Information Practices we will take the following responsive action, should a data breach occur: We will notify the users via email within 7 business days; we will notify the users via in site notification also within 7 business days. We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires that not only should individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.</p>
                                <p><strong><u>Links to Other Websites and Premises</u></strong></p>
                                <p>Certain transaction processing channels may require links to other websites or organizations other than ours. Please note that Propcrowdy is not responsible and has no control over websites outside its domain. We do not monitor or review the content of other party's websites which are linked from our website or media platforms. Opinions expressed or materials appearing on such websites are not necessarily shared or endorsed by us, and Propcrowdy should not be regarded as the publisher of such opinions or materials. Please be aware that we are not responsible for the privacy practices, or content of these sites. We encourage our users to be aware of when they leave our site and to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through our site yourself, before disclosing any personal information to them. We will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.</p>
                                <p><strong><u>Governing Law</u></strong></p>
                                <p>This Privacy Policy is made pursuant to the Nigeria Data Protection Regulation (2019) and other relevant Nigerian laws, regulations or international conventions applicable to Nigeria. Where any provision of this Policy is deemed inconsistent with a law, regulation or convention, such provision shall be subject to the overriding law, regulation or convention.</p>
                            </div>
                        )}
                    </div>
                </section>
            </main>
        );
    }
}
