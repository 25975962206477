import './../../assets/style.css';
import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from "../../../App/Components/Context";

export default class DeveloperListItem extends React.Component {
    constructor(props) {
        super(props);
        DeveloperListItem.contextType = Context;
        this.state = {
            developer: this.props.developer,
            company: this.props.company
        }
    }

    render() {
        return (
            <div className={'developer' + (this.state.company && this.state.company.slug ? ' company' : '')} style={{backgroundImage: 'url("' + (this.state.developer.photo ? this.state.developer.photo.url : (this.state.company && this.state.company.logo ? this.state.company.logo.url : '/assets/images/vectors/avatar.svg')) + '")'}}>
                {this.state.company && this.state.company.slug ? (
                    <Link to={{pathname: '/company/' + this.state.company.slug, state: {company: this.state.company}}} className="overlay">
                        <i className="icon las la-eye" />
                        <span className="title">View detail</span>
                    </Link>
                ) : null}
                <div className="details">
                    <div className="name">{this.state.developer.name || (this.state.developer.first_name + ' ' + this.state.developer.last_name) || (this.state.company ? this.state.company.name : '')}</div>
                    {this.state.company ? (
                        <>
                            <div className="position">
                                {this.state.developer.position}
                                {this.state.company && this.state.company.name ? ' with ' : ''}
                                <span className="company">{this.state.company.name}</span>
                            </div>
                            <div className="rating">
                                <div className="stars">
                                    {Array.from({length: 5}).map((el, i) => (<i key={i} className={'icon star las la-star' + (i < this.state.developer.rating ? ' shaded' : '')} />))}
                                </div>
                                <div className="reviews">{this.state.developer.reviews_count} {this.state.developer.reviews_count > 1 ? 'Reviews' : 'Review'}</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="location">Position: {this.state.developer.function || this.state.developer.function}</div>
                            <div className="location">Location: {this.state.developer.address || this.state.developer.location}</div>
                            <div className="nationality">Nationality: {this.state.developer.country || ((this.context.constants && this.context.constants.locale && this.context.constants.locale.countries) || []).reduce((countries, country) => {countries[country.id] = country.name; return countries}, {})[this.state.developer.nationality_id]}</div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
