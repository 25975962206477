import React from "react";
import { Context } from "./../Context";
import $ from "jquery";

export default class UI extends React.Component {
  constructor(props) {
    super(props);
    UI.contextType = Context;
  }

  static alert = (options, context) => {
    options = options || {};
    context.updateContext("AlertModalType", options.type || "success");
    context.updateContext("AlertModalTitle", options.title);
    context.updateContext("AlertModalSubTitle", options.subTitle);
    context.updateContext("AlertModalContent", options.content);
    context.updateContext("AlertModalButtons", options.buttons);
    // context.updateContext("AlertModalButtonsRoute", options.route);
    $("#alert-modal").modal("show");
  };

  static slick = () => {
    try {
      $(".slick-carousel").slick("unslick");
    } catch (e) {}
    try {
      $(".slick-carousel").slick();
    } catch (e) {}
  };

  static animationEntry() {
    $(".animation-entry:not(.animation-entry-complete)").each(
      (index, element) => {
        if (
          element &&
          $(element).offset().top + $(element).outerHeight() >=
            $(window).scrollTop() &&
          $(element).offset().top <= $(window).scrollTop() + $(window).height()
        ) {
          $(element).addClass("animation-entry-complete");
        }
      }
    );
  }
}
