import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import Loading from './../../../../App/Components/Templates/Loading';
import $ from 'jquery';
import Api from './../../../../App/Components/Utils/Api';
import UI from './../../../../App/Components/Utils/UI';
import {withRouter} from "react-router-dom";

class Investment extends React.Component {
    constructor(props) {
        super(props);
        Investment.contextType = Context;
        this.state = {
            amount: '',
            investing: false,
            myInvestments: [],
            myPendingInvestments: [],
            message: '',
            error: {
                amount: []
            }
        }
    }

    invest() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if(this.context.user) {
            if (this.context.user.customer_type !== 'developer') {
                if (!this.state.myPendingInvestments.length) {
                    if (typeof this.state.amount !== 'string' || this.state.amount.trim() === '') {
                        error.amount.push('Please enter an amount');
                    } else if (parseFloat(this.state.amount) < parseFloat((this.context.settings && this.context.settings.investment && this.context.settings.investment.min_investment) || 50)) {
                        error.amount.push('Minimum investment is ₦' + parseInt(((this.context.settings && this.context.settings.investment && this.context.settings.investment.min_investment) || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
                    }

                    this.setState({error: error});

                    for(let i in error) {
                        if(error.hasOwnProperty(i)) {
                            if(error[i].length) {
                                return false;
                            }
                        }
                    }

                    this.setState({investing: true});
                    Api.fetch('investment.add', undefined, {
                        amount: this.state.amount,
                        roi: (this.context.settings && this.context.settings.investment && this.context.settings.investment.roi) || 0
                    }, {
                        accept: 'application/json'
                    }).then(({status, responseJSON}) => {
                        this.setState({investing: false});
                        if(status === 200) {
                            this.setState({amount: ''}, () => {
                                this.context.updateContext('paymentModalType', 'POOLFUNDING');
                                this.context.updateContext('paymentModalTypeId', responseJSON.data.id);
                                this.context.updateContext('paymentModalScheduleId', null);
                                this.context.updateContext('paymentModalTypeTitle', 'Pool Funding');
                                this.context.updateContext('paymentModalTitle', 'PropCrowdy Pool Funding');
                                this.context.updateContext('paymentModalAmount', responseJSON.data.amount);
                                this.context.updateContext('paymentModalMethods', ['wallet', 'paystack', 'rave','bank']);
                                $('#investment-modal').modal('hide');
                                $('#payment-modal').modal('show');
                            });
                        } else {
                            this.setState({message: responseJSON.message || ''});
                        }
                    }).catch((reason) => {
                        this.setState({investing: false});
                        let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                        if ([403, 422].indexOf(status) !== -1) {
                            this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
                        } else {
                            console.debug(reason);
                            this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
                        }
                    });
                } else {
                    UI.alert({
                        title: 'Transaction in progress',
                        content: 'You have a pool funding transaction in progress. Please wait for the payment to be confirmed.',
                    }, this.context);
                }
            } else {
                UI.alert({
                    title: 'Sorry',
                    content: 'Developers cannot make an investment. Only an investor can invest in opportunities.'
                }, this.context);
            }
        } else {
            UI.alert({
                title: 'Login First',
                content: 'You must be logged in to invest',
                buttons: [{
                    type: 'positive',
                    title: 'Login',
                    onClick: () => {
                        this.props.history.push({pathname: '/login', state: {message: 'Please login first', redirectPathname: '/opportunity/' + this.state.opportunity.slug}});
                    },
                }, {
                    type: 'neutral',
                    title: 'Register',
                    onClick: () => {
                        this.props.history.push({pathname: '/register', state: {message: 'Please register first', redirectPathname: '/opportunity/' + this.state.opportunity.slug}});
                    },
                }]
            }, this.context);
        }
    }

    render() {
        return (
            <div id="investment-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <form id="investment-modal-form" className="investment" onSubmit={(e) => {
                            e.preventDefault();
                            this.invest();
                        }}>
                            {this.state.investing && <Loading />}
                            <div className="message">{this.state.message}</div>
                            <div className="heading">Pool Funding</div>
                            <div className="min-investment">
                                <div className="title">Guarantee a minimum investment</div>
                                <div className="percent">{this.context.settings && this.context.settings.investment && this.context.settings.investment.roi}%</div>
                                <div className="time">{this.context.settings && this.context.settings.investment && this.context.settings.investment.tenor} Month{this.context.settings && this.context.settings.investment && this.context.settings.investment.tenor > 1 ? 's' : ''}</div>
                            </div>
                            <div className="form-groups">
                                <div className="form-group">
                                    <label htmlFor="investment-amount">Investment Amount</label>
                                    <input id="investment-amount" type="number" name="amount" min={(this.context.settings && this.context.settings.investment && this.context.settings.investment.min_investment) || 50} step=".01" value={this.state.amount} placeholder="Amount (₦)" className="form-control" onChange={(e) => {this.setState({amount: e.target.value})}} />
                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.amount.join('<br />')}} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Submit Details</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Investment);
