import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import $ from 'jquery';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';

export default class TransactionList extends React.Component {
    constructor(props) {
        super(props);
        TransactionList.contextType = Context;
        this.state = {
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Transaction Records');
        Document.updateDocumentHead({title: 'PropCrowdy - Wallet Transactions'});
        this.fetchList();
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(['investment.payment.successful', 'pool.investment.payment.successful'].indexOf(message.type) !== -1) {
                this.fetchList(true);
            }
        }
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }
            Api.fetch('wallet.transaction.list', undefined, {
                offset: update ? 0 : this.state.offset,
                limit: update ? Math.max(this.state.list.length, this.state.limit) : this.state.limit
            }).then(({status, responseJSON}) => {
                if(status === 200) {
                    this.setState({
                        list: [...(update ? [] : this.state.list), ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: (update ? 0 : this.state.offset) + (responseJSON.data || []).length
                    });
                }
                this.setState({fetching: false});
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    render() {
        return (
            <main id="main" className="wallet transactions">
                <div className="container">
                    <div className="box">
                        <div className="heading">Wallet Transactions</div>
                        <div className="sub-heading">Transaction Logs</div>
                        <div className="list">
                            <div className="transactions">
                                <div className="head">
                                    <div className="bullet" />
                                    <div className="reference">Reference</div>
                                    <div className="date">Transaction Date</div>
                                    <div className="amount">Amount</div>
                                    <div className="currency">Currency</div>
                                </div>
                                {this.state.list.map((transaction, index) => (
                                    <div key={index} className="transaction">
                                        <div className="bullet">
                                            <i className="icon las la-wallet" />
                                        </div>
                                        <div className="reference">#{transaction.reference_number}</div>
                                        <div className="date">{Time.timeToFriendly(transaction.created_at)}</div>
                                        <div className={'amount ' + (transaction.transaction_type || 'credit')}>
                                            {transaction.transaction_type === 'debit' ? '- ' : '+ '}
                                            {parseInt(transaction.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        </div>
                                        <div className="currency">{transaction.currency || 'NGN'}</div>
                                    </div>
                                ))}
                            </div>
                            {!this.state.fetching && !this.state.list.length && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="message">You have not made any transaction</span>
                                    <div className="links">
                                        <button className="link" onClick={(e) => {
                                            e.preventDefault();
                                            this.context.updateContext('paymentModalType', 'FUNDWALLET');
                                            this.context.updateContext('paymentModalTypeId', this.context.user.id);
                                            this.context.updateContext('paymentModalScheduleId', null);
                                            this.context.updateContext('paymentModalTypeTitle', 'Fund Wallet');
                                            this.context.updateContext('paymentModalTitle', 'Fund your PropCrowdy Wallet using either of the options below');
                                            this.context.updateContext('paymentModalAmount', null);
                                            this.context.updateContext('paymentModalData', this.context.user);
                                            this.context.updateContext('paymentModalMethods', ['paystack', 'rave']);
                                            $('#payment-modal').modal('show');
                                        }}>
                                            Fund Wallet
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                                {this.state.fetching && <Loading />}
                            </div>
                            {!this.state.fetching && this.state.list.length < this.state.total && (
                                <div className="list-load-more">
                                    <button onClick={() => {this.fetchList()}}>Load More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
