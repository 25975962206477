import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Resource from './../Templates/Layouts/Resource';
import {Link} from 'react-router-dom';
import Loading from './../../../App/Components/Templates/Loading';
import $ from 'jquery';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';

export default class VirtualTourPayment extends React.Component {
    constructor(props) {
        super(props);
        VirtualTourPayment.contextType = Context;
        this.state = {
            tour:  window.location.pathname.split('/')[4] === 'test' ? {id: 1, price: 20000, status: 0} : null
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Virtual Tour Payment'});
        this.fetchTour(!!this.state.tour);
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
        Hook.register('payment', this.onPayment.bind(this));
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
        Hook.unregister('payment', this.onPayment.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'tour.payment.successful' && message.data && parseInt(message.data.id) === this.state.tour.id) {
                this.fetchTour(true);
            }
        }
    }

    onPayment(result, type, typeId, data) {
        if(type === 'VIRTUALTOUR' && typeof data === 'object' && parseInt(data.id) === parseInt(this.state.tour.id)) {
            let tour = this.state.tour;
            tour['status'] = 2;
            this.setState({tour: tour}, () => {
                this.fetchTour(true);
            });
        }
    }

    fetchTour(update) {
        if(!update) {
            this.setState({fetching: true});
        }
        Api.fetch('tour.view', undefined, {token: window.location.pathname.split('/')[4]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({tour: responseJSON.data});
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    render() {
        return (
            <Resource heading="Virtual Tour Payment" title="Virtual Tour Payment">
                <main id="main" className="resource virtual-tour payment">
                    <div className="container">
                        {this.state.tour ? (
                            <div className="content">
                                {!this.state.tour.status ? (
                                    <>
                                        <span className="message">You have requested the quote for a virtual tour. Please use the button below to make the payment.</span>
                                        <span className="price">₦{parseInt((this.state.tour.price || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                        <button className="link" onClick={(e) => {
                                            e.preventDefault();
                                            this.context.updateContext('paymentModalType', 'VIRTUALTOUR');
                                            this.context.updateContext('paymentModalTypeId', this.state.tour.id);
                                            this.context.updateContext('paymentModalScheduleId', null);
                                            this.context.updateContext('paymentModalTypeTitle', 'Virtual Tour');
                                            this.context.updateContext('paymentModalTitle', 'Pay for your Virtual Tour using either of the options below');
                                            this.context.updateContext('paymentModalAmount', this.state.tour.price);
                                            this.context.updateContext('paymentModalData', this.state.tour);
                                            this.context.updateContext('paymentModalMethods', ['paystack', 'rave']);
                                            $('#payment-modal').modal('show');
                                        }}>
                                            Pay
                                        </button>
                                    </>
                                ) : null}
                                {this.state.tour.status === 1 ? (
                                    <>
                                        <i className="icon success las la-check-circle" />
                                        <span className="message">The payment for this tour has been confirmed.</span>
                                        <Link to={{pathname: '/resource/virtual-tour/' + this.state.tour.slug}} className="link">View Tour</Link>
                                    </>
                                ) : null}
                                {this.state.tour.status === 2 ? (
                                    <>
                                        <i className="icon info las la-info-circle" />
                                        <span className="message">The payment for this tour has not been confirmed. You will be notified once the payment is confirmed.</span>
                                    </>
                                ) : null}
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </main>
            </Resource>
        );
    }
}
