import React from 'react';
import {Context} from './../Context';
import {baseUrl} from './../../../../config';

export default class Document extends React.Component {
    constructor(props) {
        super(props);
        Document.contextType = Context;
    }

    static updateDocumentHead = (props) => {
        props = {
            ...{
                title: 'PropCrowdy',
                description: 'PropCrowdy is a crowd investment platform that empowers Nigerians to make smart investments',
                url: document.location.href,
                image: baseUrl + '/assets/images/logo-2.png'
            },
            ...(props || {})
        };

        Document.setDocumentTitle(props.title);
        Document.setDocumentDescription(props.description);
        Document.setDocumentURL(props.url);
        Document.setDocumentImage(props.image);
    }

    static setDocumentTitle = (title) => {
        document.title = title;

        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle) {
            ogTitle.setAttribute('content', title);
        }

        const ogHeadline = document.querySelector('meta[itemprop="og:headline"]');
        if (ogHeadline) {
            ogHeadline.setAttribute('content', title);
        }

        const ipHeadline = document.querySelector('meta[itemprop="headline"]');
        if (ipHeadline) {
            ipHeadline.setAttribute('content', title);
        }

        const twitterTitle = document.querySelector('meta[name="twitter:title"]');
        if (twitterTitle) {
            twitterTitle.setAttribute('content', title);
        }
    }

    static setDocumentDescription = (description) => {
        const _description = document.querySelector('meta[name="description"]');
        if (_description) {
            _description.setAttribute('content', description);
        }

        const ogDescription = document.querySelector('meta[property="og:description"]');
        if (ogDescription) {
            ogDescription.setAttribute('content', description);
        }
    }

    static setDocumentURL = (url) => {
        const ogURL = document.querySelector('meta[property="og:url"]');
        if (ogURL) {
            ogURL.setAttribute('content', url);
        }
    }

    static setDocumentImage = (imageUrl) => {
        const ogImage = document.querySelector('meta[property="og:image"]');
        if (ogImage) {
            ogImage.setAttribute('content', imageUrl);
        }

        const ipImage = document.querySelector('meta[itemprop="image"]');
        if (ipImage) {
            ipImage.setAttribute('content', imageUrl);
        }

        const twitterImage = document.querySelector('meta[name="twitter:image"]');
        if (twitterImage) {
            twitterImage.setAttribute('content', imageUrl);
        }

        const imageSRC = document.querySelector('link[rel="image_src"]');
        if (imageSRC) {
            imageSRC.setAttribute('content', imageUrl);
        }
    }
}
