import React from 'react';
import {Context} from './../Context';
import Hook from './Hook';

export default class Push extends React.Component {
    constructor(props) {
        super(props);
        Push.contextType = Context;
    }

    static onMessage = (message) => {
        Hook.fire('push.on.message', undefined, [message]);
    }
}
