import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import {Link} from 'react-router-dom';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';

export default class ArticleView extends React.Component {
    constructor(props) {
        super(props);
        ArticleView.contextType = Context;
        this.state = {
            article: this.props.location.state && this.props.location.state.article,
            previousArticle: this.props.location.state && this.props.location.state.previousArticle,
            nextArticle: this.props.location.state && this.props.location.state.nextArticle,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'default' : 'default');
        Document.updateDocumentHead({title: (this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'PropBuy' : 'PropCrowdy') + ' Blog - ' + (this.state.article ? this.state.article.title : 'Article')});
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params || prevProps.match.url !== this.props.match.url) {
            this.setState({
                article: this.props.location.state && this.props.location.state.article,
                previousArticle: this.props.location.state && this.props.location.state.previousArticle,
                nextArticle: this.props.location.state && this.props.location.state.nextArticle
            }, () => {
                this.init();
            });
        }
    }

    init() {
        if(this.state.article) {
            Document.updateDocumentHead({title: (this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'PropBuy' : 'PropCrowdy') + ' Blog - ' + this.state.article.title});
            this.fetchRelatedArticle();
        } else {
            this.fetchArticle();
        }
    }

    fetchArticle() {
        this.setState({fetching: true});
        Api.fetch('blog.article.view', undefined, {slug: window.location.pathname.split('/')[2]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({article: responseJSON.data}, () => {
                    Document.updateDocumentHead({title: (this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'PropBuy' : 'PropCrowdy') + ' Blog - ' + this.state.article.title});
                    this.fetchRelatedArticle();
                });
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    fetchRelatedArticle() {
        Api.fetch('blog.article.related', undefined, {id: this.state.article.id}).then(({status, responseJSON}) => {
            if(status === 200) {
                this.setState({nextArticle: responseJSON.data});
            }
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    share(type) {
        const url = window.location.href;
        const target = (this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'Propbuy' : 'PropCrowdy') + ' - Share Article';
        const features = 'width = 500, height = 500, scrollbars = yes';
        if (type === 'facebook') {
            window.open('https://www.facebook.com/sharer/sharer.php?display=popup&u=' + encodeURIComponent(url), target, features);
        }
        if (type === 'twitter') {
            window.open('https://twitter.com/share?text=' + this.state.article.title + '&url=' + encodeURIComponent(url) + '&href=' + encodeURIComponent(url), target, features);
        }
        if (type === 'linkedin') {
            window.open('https://www.linkedin.com/cws/share?url=' + encodeURIComponent(url), target, features);
        }
        if (type === 'pinterest') {
            window.open('https://pinterest.com/pin/create/button/?url=' + encodeURIComponent(url), target, features);
        }
        if (type === 'whatsapp') {
            window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(url), target, features);
        }
        if (type === 'telegram') {
            window.open('https://telegram.me/share/url?url=' + encodeURIComponent(url), target, features);
        }
        if (type === 'email') {
            window.open('mailto:?subject=' + this.state.article.title + '&body=' + encodeURIComponent(url), '_self', features);
        }
    }

    render() {
        let date = Time.timeToFriendly(this.state?.article?.created_at, 1);
        return (
            <main id="main" className="blog article">
                <div className="bread-crumb">
                    {this.state.article && (
                        <div className="container">
                            <Link to={{pathname: '/articles'}} className="link">Blog</Link>
                            <i className="icon arrow las la-angle-right" />
                            <Link to={{pathname: '/article/' + this.state.article.slug}} className="link">{this.state.article.title}</Link>
                        </div>
                    )}
                </div>
                {this.state.article ? (
                    <div className="container">
                        <div className="heading">
                            <div className="date">{date}</div>
                            <div className="title">{this.state.article.title}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="cover">
                                    <img src={this.state.article.cover_image ? this.state.article.cover_image.url : ''} alt={this.state.article.title} className="image" />
                                </div>
                                <div className="body">
                                    <div className="share">
                                        <div>Share to</div>
                                        <button className="lab la-facebook" onClick={() => this.share('facebook')} />
                                        <button className="lab la-twitter" onClick={() => this.share('twitter')} />
                                        <button className="lab la-linkedin" onClick={() => this.share('linkedin')} />
                                        {/*<button className="lab la-pinterest" onClick={() => this.share('pinterest')} />*/}
                                        <button className="lab la-whatsapp" onClick={() => this.share('whatsapp')} />
                                        <button className="lab la-telegram" onClick={() => this.share('telegram')} />
                                        <button className="lab la-email" onClick={() => this.share('email')} />
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: this.state.article.description}} />
                                </div>
                                <div className="author">
                                    <div className="title">Written By</div>
                                    <div className="user">
                                        <img src={this.state.article.written_by.photo ? this.state.article.written_by.photo.url : '/assets/images/vectors/avatar.svg'} alt={this.state.article.written_by.name} className="avatar" />
                                        <div className="details">
                                            <div className="name">{this.state.article.written_by.name}</div>
                                            <div className="role">{this.state.article.written_by.duty}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="related">
                                    <div className="title">Related Articles</div>
                                    <div className="articles">
                                        {this.state.previousArticle && (
                                            <Link to={{pathname: '/article/' + this.state.previousArticle.slug, state: {article: this.state.previousArticle, previousArticle: this.state.article}}} className="previous" style={{backgroundImage: 'url("' + (this.state.previousArticle.cover_image ? this.state.previousArticle.cover_image.url : '') + '")'}}>
                                                <span className="direction">Previous</span>
                                                <span className="title">{this.state.previousArticle.title}</span>
                                                <span className="role">{this.state.previousArticle.written_by.duty}</span>
                                            </Link>
                                        )}
                                        {this.state.nextArticle && (
                                            <Link to={{pathname: '/article/' + this.state.nextArticle.slug, state: {article: this.state.nextArticle, previousArticle: this.state.article}}} className="next" style={{backgroundImage: 'url("' + (this.state.nextArticle.cover_image ? this.state.nextArticle.cover_image.url : '') + '")'}}>
                                                <span className="direction">Next</span>
                                                <span className="title">{this.state.nextArticle.title}</span>
                                                <span className="role">{this.state.nextArticle.written_by.duty}</span>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="ad-placeholder">Advert Banner</div>
                                <div className="ad-placeholder">Advert Banner</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </main>
        );
    }
}
