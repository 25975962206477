import "./index.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const GetStartedModal = ({ showModal, toggleModal }) => {
  const history = useHistory();
  // const [showAmoutError, setShowAmountError] = useState(false);
  // const [showRoiError, setShowRoiError] = useState(false);
  // const [showTenorError, setShowTenorError] = useState(false);

  const contextData = JSON.parse(localStorage.getItem("context")) || {};
  const opportunity = contextData?.constants?.opportunity || [];

  const roiOptions = opportunity.roi || [];
  const tenorOptions = opportunity.tenor || [];

  const modalContainer = document.querySelector(".custom_modal");

  const handleWindowClick = (event) => {
    if (event.target === modalContainer) {
      toggleModal();
    }
  };

  const FormPages = ({ toggleModal }) => {
    // const [formPage, setFormPage] = useState(1);
    const [pageIndex, setPageIndex] = useState(0);
    const [amount, setAmount] = useState("");
    const [percentage, setPercentage] = useState("");
    const [tenor, setTenor] = useState("");

    const allData = {
      amount: amount,
      percentage: percentage,
      tenor: tenor,
    };

    const handleContinue = (e) => {
      e.preventDefault();
      if (pageIndex > 2) {
        toggleModal();
        // history.push("/discover");

        return;
      } else {
        if (pageIndex === 0 && amount !== "") {
          setPageIndex((prev) => prev + 1);
        }

        if (pageIndex === 1 && percentage !== "") {
          setPageIndex((prev) => prev + 1);
        }

        if (pageIndex === 2 && tenor !== "") {
          // setPageIndex((prev) => prev + 1);
          history.push("/discover");
        }
      }
    };

    const getStarted = (
      <div className="page_index">
        <div className="contents">
          <div className="titles">
            <p className="main_title">Discover Opportunities</p>
            <p className="sub_title">
              Let's get you started making good investments
            </p>
          </div>
          <p className="center_text">
            how much are you ready to start an investment with?
          </p>
          <label id="amount">Enter Amount</label>
          {/* <input /> */}
          <input
            type="number"
            placeholder="Enter Amount"
            name="amount"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            // onChange={handleAmountChange}
          />
          <p className="validation_warning_text">This field cannot be empty</p>
        </div>
        {/* <form onSubmit={handleGetStarted} className="modal_form"> */}

        <button
          onClick={handleContinue}
          className="custom_modal_continue_button"
        >
          Get Started
        </button>
        {/* </form> */}
      </div>
    );

    const chooseRoi = (
      <div className="page_index">
        <div className="contents">
          <div className="titles">
            <p className="main_title">Discover Opportunities</p>
            <p className="sub_title">You’re Almost there, just one more step</p>
          </div>
          <p className="center_text">Choose Your Expected ROI</p>
          <label id="percentage">Select Percentage</label>

          <select
            value={percentage}
            name="percentage"
            onChange={(e) => {
              setPercentage(e.target.value);
            }}
            className="select_box"
            style={{
              border: "none",
              borderBottom: " 1px solid rgba(0, 0, 0, 0.5)",
              color: "rgba(5, 5, 5, 0.5)",
            }}
          >
            <option value="" disabled>
              Select Percentage
            </option>
            {roiOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.display}
              </option>
            ))}
          </select>

          <p className="validation_warning_text">This field cannot be empty</p>
        </div>
        <button
          onClick={handleContinue}
          className="custom_modal_continue_button"
        >
          Proceed
        </button>
      </div>
    );

    const chooseTenor = (
      <div className="page_index">
        <div className="contents">
          <div className="titles">
            <p className="main_title">Discover Opportunities</p>
            <p className="sub_title">
              You’ve done well, complete to see opportunities
            </p>
          </div>
          <p className="center_text">Choose Your Investment Tenor</p>
          <label id="tenor">Select Tenor</label>
          {/* <input
            placeholder="Select Tenor"
            name="tenor"
            value={tenor}
            onChange={(e) => {
              setTenor(e.target.value);
            }}
          /> */}

          <select
            value={tenor}
            name="tenor"
            onChange={(e) => {
              setTenor(e.target.value);
            }}
            className="select_box"
            style={{
              border: "none",
              borderBottom: " 1px solid rgba(0, 0, 0, 0.5)",
              color: "rgba(5, 5, 5, 0.5)",
            }}
          >
            <option value="" disabled>
              Select Tenor
            </option>
            {tenorOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.display}
              </option>
            ))}
          </select>
          <p className="validation_warning_text">This field cannot be empty</p>
        </div>
        {/* <form onSubmit={handleGetStarted} className="modal_form"> */}

        <button
          onClick={handleContinue}
          className="custom_modal_continue_button"
        >
          Complete
        </button>
        {/* </form> */}
      </div>
    );

    const selectedPage = [getStarted, chooseRoi, chooseTenor];

    return <div>{selectedPage[pageIndex]}</div>;
  };

  return (
    <div
      onClick={(e) => handleWindowClick(e)}
      className={`custom_modal ${showModal ? "show_modal" : ""}`}
    >
      <div className="custom_modal_content">
        <FormPages toggleModal={toggleModal} />
      </div>
    </div>
  );
};

export default GetStartedModal;
