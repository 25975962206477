import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';

export default class InvestmentUpdateList extends React.Component {
    constructor(props) {
        super(props);
        InvestmentUpdateList.contextType = Context;
        this.state = {
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Updates from PropCrowdy');
        Document.updateDocumentHead({title: 'PropCrowdy - Funding Updates'});
        this.fetchList();
        InvestmentUpdateList.fetchUnreadList(this.context);
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'investment.update') {
                this.fetchList(true);
            }
        }
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchList() {
        if(!this.state.fetching) {
            this.setState({fetching: true});
            Api.fetch('opportunity.investment.update.list', undefined, {
                offset: this.state.offset,
                limit: this.state.limit
            }).then(({status, responseJSON}) => {
                if (status === 200) {
                    this.setState({
                        list: [...this.state.list, ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: this.state.offset + (responseJSON.data || []).length
                    });
                }
                (responseJSON.data || []).map((update) => {
                    if (!parseInt(update.read)) {
                        this.markUpdateRead(update.id)
                    }
                    return true;
                })
                this.setState({fetching: false});
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    static fetchUnreadList(context) {
        Api.fetch('opportunity.investment.update.list', undefined, {type: 'unread', unread: 1, read: 0, limit: 5}).then(({status, responseJSON}) => {
            if(status === 200) {
                context.updateContext('unreadFundingUpdates', responseJSON.data || []);
            }
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    static clearUnreadList(context) {
        context.updateContext('unreadFundingUpdates', []);
    }

    markUpdateRead(id) {
        Api.fetch('opportunity.investment.update.read', undefined, {customer_id: this.context.user.id, id: id, funding_update_id: id}).then(() => {
            InvestmentUpdateList.fetchUnreadList(this.context);
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    hideUpdate(index) {
        let list = this.state.list;
        Api.fetch('opportunity.investment.update.delete', undefined, {customer_id: this.context.user.id, id: this.state.list[index].id, funding_update_id: this.state.list[index].id}).then(() => {}).catch((reason) => {console.debug(reason)});
        list.splice(index, 1);
        this.setState({list: list, total: this.state.total - 1});
    }

    render() {
        return (
            <main id="main" className="opportunity admin fund-updates listing">
                <div className="container">
                    <div className="list">
                        {!this.state.fetching && !this.state.list.length && (
                            <div className="list-empty">
                                <i className="icon las la-info-circle" />
                                <span className="message">No update</span>
                            </div>
                        )}
                        <div className="items">
                            {this.state.list.map((update, index) => (
                                <div key={index} className="item update">
                                    <div className="overview">
                                        <div className="left">
                                            <img src="/assets/images/vectors/icons/cash.svg" alt="Update" className="icon" />
                                        </div>
                                        <div className="middle">
                                            <div className="top">
                                                <div className="title">{update.title}</div>
                                                <div className="time">{Time.timeToFriendly(update.created_at, 0)}</div>
                                            </div>
                                            <div className="middle">
                                                <div className="title">PropCrowdy Funding Update</div>
                                            </div>
                                            <div className="bottom">
                                                <button className="toggle-details">
                                                    <span className="title">Details</span>
                                                    <i className="icon las la-angle-down" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="top">
                                                <div className="title">Hide update from timeline</div>
                                            </div>
                                            <div className="middle">

                                            </div>
                                            <div className="bottom">
                                                <div className="actions">
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        UI.alert({
                                                            title: 'Confirmation',
                                                            content: 'Are you sure you want to hide this update from the timeline?',
                                                            buttons: [{
                                                                type: 'positive',
                                                                title: 'Yes',
                                                                onClick: () => {
                                                                    this.hideUpdate(index);
                                                                },
                                                            }, {
                                                                type: 'neutral',
                                                                title: 'No'
                                                            }]
                                                        }, this.context);
                                                    }} className="green hide-mobile">Hide</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details" dangerouslySetInnerHTML={{__html: update.description}} />
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        UI.alert({
                                            title: 'Confirmation',
                                            content: 'Are you sure you want to hide this update from the timeline?',
                                            buttons: [{
                                                type: 'positive',
                                                title: 'Yes',
                                                onClick: () => {
                                                    this.hideUpdate(index);
                                                },
                                            }, {
                                                type: 'neutral',
                                                title: 'No'
                                            }]
                                        }, this.context);
                                    }} className="close-button show-mobile">
                                        <i className="icon las la-times" />
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                            {this.state.fetching && <Loading />}
                        </div>
                        {!this.state.fetching && this.state.list.length < this.state.total && (
                            <div className="list-load-more">
                                <button onClick={() => {this.fetchList()}}>Load More</button>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}
