import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Document from './../../../App/Components/Utils/Document';
import Loading from './../../../App/Components/Templates/Loading';
import DeveloperListItem from './../../../Developer/Components/Templates/DeveloperListItem';
import ReactPaginate from 'react-paginate';
import Api from './../../../App/Components/Utils/Api';

export default class List extends React.Component {
    constructor(props) {
        super(props);
        List.contextType = Context;
        this.state = {
            sort: "0",
            type: null,
            location: null,
            term: null,
            page: Api.queryStringToObj(this.props.history.location.search).page || 1,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default.buy');
        Document.updateDocumentHead({title: 'PropBuy - Developers'});
        let param = Api.queryStringToObj(this.props.location.search);
        this.setState({sort: param.sort, type: param.type, location: param.location, term: param.term}, () => {
            this.fetchList();
        });
    }

    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }
            Api.fetch('developer.list', undefined, {
                fire_sale: this.state.fireSale,
                sort: this.state.sort,
                developer_type_id: this.state.type,
                location: this.state.location,
                search: this.state.term,
                offset: (this.state.page - 1) * this.state.limit,
                limit: this.state.limit
            }).then(({status, responseJSON}) => {
                this.setState({fetching: false});
                if(status === 200) {
                    this.setState({
                        list: responseJSON.data || [],
                        total: responseJSON.total
                    });
                }
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    render() {
        return (
            <main id="main" className="developer developers">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="sub-heading">Meet PROPBUY Property</div>
                                    <div className="heading">Developers</div>
                                </div>
                                <div className="col-lg-6">
                                    {/*<div className="filters">*/}
                                    {/*    <select name="sort" onChange={(e) => {*/}
                                    {/*        this.setState({page: 1, sort: e.target.value}, () => {*/}
                                    {/*            this.fetchList();*/}
                                    {/*        });*/}
                                    {/*    }} value={this.state.sort}>*/}
                                    {/*        <option value="0" disabled>Sort by Rating</option>*/}
                                    {/*        <option value="low-high">Lowest Rating</option>*/}
                                    {/*        <option value="high-low">Highest Rating</option>*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="list">
                                {this.state.fetching && <Loading />}
                                {!this.state.fetching && !this.state.list.length && (
                                    <div className="list-empty">
                                        <i className="icon las la-info-circle" />
                                        <span className="message">No result</span>
                                    </div>
                                )}
                                <div className="row">
                                    {this.state.list.map((developer, index) => (
                                        <div key={index} className="col-xl-3">
                                            <DeveloperListItem developer={developer} company={developer.customer_developer_company || {}} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="pagination-container">
                                {this.state.total ? (
                                    <div className="limits">
                                        {/*<label>Show:</label>*/}
                                        {/*<Link to={{pathname: '#'}} className={'limit' + (this.state.limit === 24 ? ' active' : '')} onClick={(e) => {*/}
                                        {/*    e.preventDefault();*/}
                                        {/*    this.setState({page: 1, limit: 24}, () => {*/}
                                        {/*        this.fetchList();*/}
                                        {/*    });*/}
                                        {/*}}>24</Link>*/}
                                        {/*<Link to={{pathname: '#'}} className={'limit' + (this.state.limit === 36 ? ' active' : '')} onClick={(e) => {*/}
                                        {/*    e.preventDefault();*/}
                                        {/*    this.setState({page: 1, limit: 36}, () => {*/}
                                        {/*        this.fetchList();*/}
                                        {/*    });*/}
                                        {/*}}>36</Link>*/}
                                        {/*<Link to={{pathname: '#'}} className={'limit' + (this.state.limit === 48 ? ' active' : '')} onClick={(e) => {*/}
                                        {/*    e.preventDefault();*/}
                                        {/*    this.setState({page: 1, limit: 48}, () => {*/}
                                        {/*        this.fetchList();*/}
                                        {/*    });*/}
                                        {/*}}>48</Link>*/}
                                    </div>
                                ) : null}
                                <ReactPaginate
                                    previousLabel={''}
                                    nextLabel={''}
                                    breakLabel={'...'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageCount={Math.ceil(this.state.total / this.state.limit)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={({selected}) => {
                                        this.setState({page: selected + 1}, () => {
                                            this.fetchList();
                                        });
                                    }}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    nextClassName={'page-item'}
                                    previousLinkClassName={'page-link icon las la-angle-left'}
                                    nextLinkClassName={'page-link icon las la-angle-right'}
                                    activeClassName={'active'}
                                    hrefBuilder={(page) => '#'}
                                    initialPage={this.state.page - 1}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ad-placeholder">Advert Banner</div>
                            <div className="ad-placeholder">Advert Banner</div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
