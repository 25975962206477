// import "./../../assets/style.css";
import "./../../assets/stylings/company_profile_styles.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import Loading from "./../../../App/Components/Templates/Loading";
import { Link } from "react-router-dom";
import _ from "lodash";
import Document from "./../../../App/Components/Utils/Document";
import Hook from "./../../../App/Components/Utils/Hook";
import Api from "./../../../App/Components/Utils/Api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import removeMemberIcon from "../../assets/images/remove_board_member_icon.svg";

export default class CompanyAdd extends React.Component {
  constructor(props) {
    super(props);
    CompanyAdd.contextType = Context;
    this.logoFileInput = null;
    this.buildingSurveyPlanFileInput = null;
    this.cacDocumentFileInput = null;
    this.authorizationLetterFileInput = null;
    this["board.0.photoFileInput"] = null;
    this.state = {
      name: "",
      auditedFinancialStatement: "",
      auditedFinancialStatementDisplayName: "",
      auditedFinancialStatementUploadProgress: 0,
      warningToInvestors: "",
      description: "",
      tellStory: "",
      email: "",
      logo: "",
      logoDisplayName: "",
      logoUploadProgress: 0,
      phoneNumber: "",
      address: "",
      stateId: "0",
      countryId: "0",
      cacDocument: "",
      cacDocumentDisplayName: "",
      cacDocumentUploadProgress: 0,
      authorizationLetter: "",
      authorizationLetterDisplayName: "",
      authorizationLetterUploadProgress: 0,
      facebookUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      board: [
        {
          name: "",
          location: "",
          nationality_id: "0",
          photo: "",
          nationality: "",
        },
      ],
      board_inputs: { name: "", location: "", nationality_id: "0", photo: "" },
      "board.0.photoDisplayName": "",
      "board.0.photoUploadProgress": 0,
      "board.0.photoUrl": "",
      message: "",
      step: "introduction",
      steps: ["introduction", "story", "contact", "board"],
      stateList: [],

      board_members: [],
      error: {
        name: [],
        audited_financial_statement: [],
        warning_to_investors: [],
        description: [],
        tell_story: [],
        email: [],
        logo: [],
        phone_number: [],
        address: [],
        state_id: [],
        country_id: [],
        cac_document: [],
        authorization_letter: [],
        facebook_url: [],
        twitter_url: [],
        linkedin_url: [],
        board: [],
        "boards.0.name": [],
        "boards.0.location": [],
        "boards.0.nationality_id": [],
        "boards.0.nationality": [],
        "boards.0.photo": [],
      },

      submitting: false,
    };

    this.prevStep = this.prevStep.bind(this);
    this.goBackStep = this.goBackStep.bind(this);
  }

  componentDidMount() {
    this.context.updateContext("layout", "defaultPictured");
    // this.context.updateContext("layout", "default.split");
    // this.context.updateContext("layout", "picturedLayout");
    Document.updateDocumentHead({ title: "PropCrow_divdy - Setup Company" });
    if (this.context.user) {
      if (this.context.user.customer_type === "developer") {
        if (this.context.user.customer_developer_company) {
          this.props.history.replace("/opportunity/create");
        } else {
          if (
            (
              (this.context.constants &&
                this.context.constants.locale &&
                this.context.constants.locale.countries) ||
              []
            ).length
          ) {
            this.setState(
              {
                countryId: ((this.context.constants &&
                  this.context.constants.locale &&
                  this.context.constants.locale.countries) ||
                  [])[0].id.toString(),
              },
              () => {
                this.fetchStates(
                  this.state.countryId,
                  ((this.context.constants &&
                    this.context.constants.locale &&
                    this.context.constants.locale.countries) ||
                    [])[0].country_states
                );
              }
            );
          }
        }
      } else {
        this.props.history.replace("/how");
      }
    } else {
      this.props.history &&
        this.props.history.replace({
          pathname: "/login",
          state: {
            message: "Please login first",
            redirectPathname: "/company/setup",
          },
        });
    }
  }

  fetchStates(countryId, preload) {
    this.setState({
      stateId: (preload || []).length ? preload[0].id.toString() : "0",
      stateList: preload || [],
    });
    Api.fetch("locale.state.list", undefined, { country_id: countryId })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.setState({ stateList: responseJSON.data || [] });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
  }

  goBackStep(step) {
    if (
      this.state.steps.indexOf(step) <=
      this.state.steps.indexOf(this.state.step)
    ) {
      this.setState({ ...this.state, step: step });
    } else {
      return;
    }

    // console.log(
    //   "within the goBackStep function  original_step after change:: ",
    //   this.state.step
    // );
  }

  goToStep(step) {
    if (
      this.state.steps.indexOf(step) <=
      this.state.steps.indexOf(this.state.step)
    ) {
      this.setState({ step: step });
    } else {
      this.validateSteps([this.state.step]).then((invalidSteps) => {
        if (invalidSteps.length) {
          this.setState({ step: invalidSteps[0] });
        } else {
          this.setState({ step: step });
        }
      });
    }
  }

  validateSteps(steps) {
    steps = steps || [];
    let invalidSteps = [];
    let addInvalidStep = (step) => {
      if (invalidSteps.indexOf(step) === -1) {
        invalidSteps.push(step);
      }
    };

    return new Promise((resolve, reject) => {
      let error = this.state.error;
      for (let i in error) {
        if (error.hasOwnProperty(i)) {
          error[i] = [];
        }
      }
      this.setState({ message: "" });
      steps.map((step) => {
        if (step === "introduction") {
          if (
            typeof this.state.name !== "string" ||
            this.state.name.trim() === ""
          ) {
            error.name.push("Please provide the company's name");
            addInvalidStep("introduction");
          }

          if (
            typeof this.state.auditedFinancialStatement !== "string" ||
            this.state.auditedFinancialStatement.trim() === ""
          ) {
            if (this.state.auditedFinancialStatementUploadProgress) {
              error.audited_financial_statement.push(
                "Please wait for the upload to finish"
              );
            } else {
              error.audited_financial_statement.push(
                "Please upload the company's audited financial statement"
              );
            }
            addInvalidStep("introduction");
          }

          if (
            typeof this.state.warningToInvestors !== "string" ||
            this.state.warningToInvestors.trim() === ""
          ) {
            error.warning_to_investors.push(
              "Please provide the warning to investors"
            );
            addInvalidStep("introduction");
          }

          if (
            typeof this.state.description !== "string" ||
            this.state.description.trim() === ""
          ) {
            error.description.push("Please provide the company's description");
            addInvalidStep("introduction");
          }
        }

        if (step === "story") {
          if (
            typeof this.state.tellStory !== "string" ||
            this.state.tellStory.trim() === ""
          ) {
            error.tell_story.push("Please tell your story");
            addInvalidStep("story");
          }

          if (
            typeof this.state.email !== "string" ||
            this.state.email.trim() === ""
          ) {
            error.email.push("Please provide the company's email address");
            addInvalidStep("story");
          } else if (
            !(
              typeof this.state.email === "string" &&
              this.state.email.match(
                /^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi
              )
            )
          ) {
            error.email.push("Please provide a valid email address");
            addInvalidStep("story");
          }

          if (
            typeof this.state.logo !== "string" ||
            this.state.logo.trim() === ""
          ) {
            if (this.state.logoUploadProgress) {
              error.logo.push("Please wait for the upload to finish");
            } else {
              error.logo.push("Please upload the company's logo");
            }
            addInvalidStep("story");
          }

          if (
            typeof this.state.phoneNumber !== "string" ||
            this.state.phoneNumber.trim() === ""
          ) {
            error.phone_number.push(
              "Please provide the company's phone number"
            );
            addInvalidStep("story");
          } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
            error.phone_number.push("Please provide a valid phone number");
            addInvalidStep("story");
          }
        }

        if (step === "contact") {
          if (
            typeof this.state.address !== "string" ||
            this.state.address.trim() === ""
          ) {
            error.address.push("Please provide the company's address");
            addInvalidStep("contact");
          }

          if (!parseInt(this.state.stateId)) {
            error.state_id.push("Please provide company's state");
            addInvalidStep("contact");
          }

          if (!parseInt(this.state.countryId)) {
            error.country_id.push("Please provide company's country");
            addInvalidStep("contact");
          }

          if (
            typeof this.state.cacDocument !== "string" ||
            this.state.cacDocument.trim() === ""
          ) {
            if (this.state.cacDocumentUploadProgress) {
              error.cac_document.push("Please wait for the upload to finish");
            } else {
              error.cac_document.push("Please upload the company CAC document");
            }
            addInvalidStep("contact");
          }

          if (
            typeof this.state.authorizationLetter !== "string" ||
            this.state.authorizationLetter.trim() === ""
          ) {
            if (this.state.authorizationLetterUploadProgress) {
              error.authorization_letter.push(
                "Please wait for the upload to finish"
              );
            } else {
              error.authorization_letter.push(
                "Please upload the company Authorization Letter"
              );
            }
            addInvalidStep("contact");
          }

          // if (
          //   typeof this.state.facebookUrl !== "string" ||
          //   this.state.facebookUrl.trim() === ""
          // ) {
          //   error.facebook_url.push("Please provide a company's Facebook URL");
          //   addInvalidStep("contactFace");
          // } else if (
          //   !this.state.facebookUrl.match(
          //     /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
          //   )
          // ) {
          //   error.facebook_url.push("Please use a valid URL");
          //   addInvalidStep("contactFacebook");
          // }

          if (
            typeof this.state.twitterUrl !== "string" ||
            this.state.twitterUrl.trim() === ""
          ) {
            error.twitter_url.push("Please provide a company's Twitter URL");
            addInvalidStep("contact");
          } else if (
            !this.state.twitterUrl.match(
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
            )
          ) {
            error.twitter_url.push("Please use a valid URL");
            addInvalidStep("contact");
          }

          if (
            typeof this.state.linkedinUrl !== "string" ||
            this.state.linkedinUrl.trim() === ""
          ) {
            error.linkedin_url.push("Please provide a company's Linkedin URL");
            addInvalidStep("contact");
          } else if (
            !this.state.linkedinUrl.match(
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
            )
          ) {
            error.linkedin_url.push("Please use a valid URL");
            addInvalidStep("contact");
          }
        }

        if (step === "board") {
          if (this.state.board.length < 3) {
            error.board.push("Please provide at least 3 board members");
            addInvalidStep("board");
          } else {
            this.state.board.map((member, index) => {
              if (typeof member.name !== "string" || !member.name) {
                error["boards." + index + ".name"].push("Please provide name");
                addInvalidStep("board");
              }

              if (typeof member.location !== "string" || !member.location) {
                error["boards." + index + ".location"].push(
                  "Please provide location"
                );
                addInvalidStep("board");
              }

              if (!parseInt(member.nationality_id)) {
                error["boards." + index + ".nationality_id"].push(
                  "Please provide nationality"
                );
                addInvalidStep("board");
              }

              if (typeof member.photo !== "string" || !member.photo) {
                error["boards." + index + ".photo"].push("Please upload photo");
                addInvalidStep("board");
              }

              return true;
            });
          }
        }

        return true;
      });

      this.setState({ error: error });

      resolve(invalidSteps);
    });
  }

  stepIndex(step) {
    let steps = this.state.steps;
    let index = -1;
    for (let i in steps) {
      if (steps.hasOwnProperty(i) && steps[i].id === step) {
        index = i;
        break;
      }
    }
    return index;
  }

  nextStep() {
    this.goToStep(
      this.state.steps[
        Math.min(
          this.state.steps.indexOf(this.state.step) + 1,
          this.state.steps.length - 1
        )
      ]
    );
  }
  prevStep() {
    if (this.state.steps.indexOf(this.state.step) - 1 >= 0) {
      this.goBackStep(
        this.state.steps[
          this.state.steps.indexOf(this.state.step) - 1
          // Math.min(
          //   this.state.steps.indexOf(this.state.step) - 1,
          //   this.state.steps.length + 1
          // )
        ]
      );
    } else {
      return;
    }
  }

  errorStep() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i) && error[i].length) {
        let steps = this.state.steps;
        for (let j in steps) {
          if (
            steps.hasOwnProperty(j) &&
            steps[j].fields.indexOf(i) !== -1 &&
            steps[j]
          ) {
            this.goToStep(steps[j].id);
            break;
          }
        }
        break;
      }
    }
  }

  // addBoardMember() {
  //   let board = this.state.board;
  //   let error = this.state.error;
  //   error["boards." + this.state.board.length + ".name"] = [];
  //   error["boards." + this.state.board.length + ".location"] = [];
  //   error["boards." + this.state.board.length + ".nationality_id"] = [];
  //   error["boards." + this.state.board.length + ".photo"] = [];
  //   board.push({ name: "", location: "", nationality_id: "0", photo: "" });
  //   this.setState({ board: board, error: error });
  // }

  addBoardMemberNew() {
    let board = this.state.board;
    let error = this.state.error;
    error["boards." + this.state.board.length + ".name"] = [];
    error["boards." + this.state.board.length + ".location"] = [];
    error["boards." + this.state.board.length + ".nationality_id"] = [];
    error["boards." + this.state.board.length + ".nationality"] = [];
    error["boards." + this.state.board.length + ".photo"] = [];
    board.push({ name: "", location: "", nationality_id: "0", photo: "" });
    this.setState({ board: board, error: error });
  }

  updateBoardMember(index, type, value) {
    let board = this.state.board;
    board[index][type] = value;
    this.setState({ board: board });
  }

  removeBoardMember(index) {
    let state = this.state;
    for (let i = index; i < this.state.board.length - 1; i++) {
      this["board." + i + ".photoFileInput"] =
        this["board." + (i + 1) + ".photoFileInput"];
      state["board." + i + ".photoDisplayName"] =
        this.state["board." + (i + 1) + ".photoDisplayName"];
      state["board." + i + ".photoUploadProgress"] =
        this.state["board." + (i + 1) + ".photoUploadProgress"];
      state["board." + i + ".photoUrl"] =
        this.state["board." + (i + 1) + ".photoUrl"];
      state.error["boards." + i + ".name"] =
        state.error["boards." + (i + 1) + ".name"];
      state.error["boards." + i + ".location"] =
        state.error["boards." + (i + 1) + ".location"];
      state.error["boards." + i + ".nationality_id"] =
        state.error["boards." + (i + 1) + ".nationality_id"];
      state.error["boards." + i + ".photo"] =
        state.error["boards." + (i + 1) + ".photo"];
    }
    delete this["board." + (this.state.board.length - 1) + ".photoFileInput"];
    delete state[
      "board." + (this.state.board.length - 1) + ".photoDisplayName"
    ];
    delete state[
      "board." + (this.state.board.length - 1) + ".photoUploadProgress"
    ];
    delete state["board." + (this.state.board.length - 1) + ".photoUrl"];
    delete state.error["boards." + (this.state.board.length - 1) + ".name"];
    delete state.error["boards." + (this.state.board.length - 1) + ".location"];
    delete state.error[
      "boards." + (this.state.board.length - 1) + ".nationality_id"
    ];
    delete state.error["boards." + (this.state.board.length - 1) + ".photo"];
    state.board.splice(index, 1);
    this.setState({ state });
  }

  uploadFile(fileHandle, key, callback) {
    let setKey = (value) => {
      let state = this.state;
      _.set(state, key, value);
      this.setState(state, () => {
        if (typeof callback === "function") {
          callback.apply();
        }
      });
    };
    let formData = new FormData();
    if (fileHandle.files.length) {
      this.setState(
        {
          [key + "UploadProgress"]: 0.1,
          [key + "DisplayName"]: "Uploading... (0%)",
          [key + "Url"]: URL.createObjectURL(fileHandle.files[0]),
          message: "",
        },
        () => {
          setKey("");
        }
      );
      fileHandle.disabled = true;
      formData.append("file", fileHandle.files[0]);
      Api.fetch("file.upload", undefined, formData, undefined, (progress) => {
        this.setState({
          [key + "UploadProgress"]: progress,
          [key + "DisplayName"]:
            "Uploading... (" + Math.ceil(progress * 100) + "%)",
        });
      })
        .then(({ status, responseJSON }) => {
          fileHandle.disabled = false;
          if (status === 200) {
            this.setState(
              {
                [key + "DisplayName"]: responseJSON.original_name,
                [key + "UploadProgress"]: 0,
              },
              () => {
                setKey(responseJSON.name, () => {
                  this.validateSteps([this.state.step])
                    .then(() => {})
                    .catch(() => {});
                });
              }
            );
          } else {
            this.setState({
              [key + "DisplayName"]: "",
              [key + "UploadProgress"]: 0,
              [key + "Url"]: "/assets/images/image-placeholder.png",
              message: responseJSON.message || "",
            });
          }
        })
        .catch((reason) => {
          this.setState({
            [key + "DisplayName"]: "",
            [key + "UploadProgress"]: 0,
            [key + "Url"]: "/assets/images/image-placeholder.png",
          });
          fileHandle.disabled = false;
          let { status, responseJSON } =
            typeof reason === "object" ? reason : {};
          if ([403, 422].indexOf(status) !== -1) {
            let error = {};
            if (responseJSON.error && responseJSON.error.file) {
              error[
                (key) =>
                  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
              ] = responseJSON.error["file"];
            }
            this.setState({
              error: { ...this.state.error, ...error },
              message: responseJSON.message || "",
            });
          } else {
            // console.debug(reason);
            this.setState({
              message:
                (responseJSON && responseJSON.message) ||
                "Error Occurred! Please check the internet and try again",
            });
          }
        });
    } else {
      this.setState(
        {
          [key + "DisplayName"]: "",
          [key + "Url"]: "/assets/images/image-placeholder.png",
        },
        () => {
          setKey("");
        }
      );
    }
  }

  submit() {
    this.setState({ message: "" });
    this.validateSteps([this.state.steps[this.state.steps.length - 1]]).then(
      (invalidSteps) => {
        if (invalidSteps.length) {
          this.setState({ step: invalidSteps[0] });
        } else {
          this.setState({ submitting: true });
          let data = {
            name: this.state.name,
            audited_financial_statement: this.state.auditedFinancialStatement,
            warning_to_investors: this.state.warningToInvestors,
            description: this.state.description,
            tell_story: this.state.tellStory,
            email: this.state.email,
            logo: this.state.logo,
            phone_number: this.state.phoneNumber,
            address: this.state.address,
            state_id: this.state.stateId,
            country_id: this.state.countryId,
            cac_document: this.state.cacDocument,
            authorization_letter: this.state.authorizationLetter,
            facebook_url: this.state.facebookUrl,
            twitter_url: this.state.twitterUrl,
            linkedin_url: this.state.linkedinUrl,
            boards: this.state.board,
          };
          Api.fetch("developer.company.add", undefined, data)
            .then(({ status, responseJSON }) => {
              this.setState({ submitting: false });
              if (status === 200) {
                let user = this.context.user;
                user["customer_developer_company"] = data;
                this.context.updateContext("user", user, () => {
                  Hook.fire("user.updated", undefined, [user, this.context]);
                  this.props.history.push({ pathname: "/opportunity/create" });
                });
              } else {
                this.setState({ message: responseJSON.message || "" });
              }
            })
            .catch((reason) => {
              this.setState({ submitting: false });
              let { status, responseJSON } =
                typeof reason === "object" ? reason : {};
              if ([403, 422].indexOf(status) !== -1) {
                this.setState({
                  error: { ...this.state.error, ...responseJSON.error },
                  message: responseJSON.message || "",
                });
              } else {
                // console.debug(reason);
                this.setState({
                  message:
                    (responseJSON && responseJSON.message) ||
                    "Error Occurred! Please check the internet and try again",
                });
              }
            });
        }
      }
    );
  }

  render() {
    return (
      <main id="main" className="company_add" style={{}}>
        {/* <div className="bread-crumb">
          <div className="container">
            <Link to={{ pathname: "/dashboard" }} className="link">
              Dashboard
            </Link>
            <i className="icon arrow_div las la-angle-right" />
            <Link to={{ pathname: "/company/setup" }} className="link">
              Company Profile
            </Link>
          </div>
        </div> */}
        <div className="">
          <div className="">
            {/* <div className="col-xl-6 split-left">
              <div className="content">
                <div className="heading">Company Profile</div>
                <div className="sub-heading">Provide Company Information</div>
              </div>
              <img
                src="/assets/images/checklist-man.png"
                alt="Checklist Man"
                className="image"
              />
            </div> */}
            <div className="form_container">
              <form
                // className="steps"
                className="company_profile_form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.submit();
                }}
              >
                {this.state.submitting && <Loading />}
                <div className="bullets">
                  {this.state.steps.map((step, index) => (
                    <span
                      key={index}
                      className={
                        "bullet" + (this.state.step === step ? " active" : "")
                      }
                    />
                  ))}
                </div>
                {/* <img
                  src="/assets/images/vectors/icons/offer.svg"
                  alt="Offer"
                  className="icon"
                /> */}
                <div className="message">{this.state.message}</div>
                <div className="step">
                  {this.state.steps.indexOf("introduction") <=
                    this.state.steps.indexOf(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("introduction");
                      }}
                    >
                      {this.state.steps.indexOf("board") !==
                      this.state.steps.indexOf(this.state.step) ? (
                        <p className="red_top_text">
                          setUp Your PropCrowdy Company Profile
                        </p>
                      ) : (
                        <p className="red_top_text">You’re almost done</p>
                      )}
                      {this.state.steps.indexOf("introduction") ===
                        this.state.steps.indexOf(this.state.step) && (
                        <h2 className="company_main_title">Company Intro</h2>
                      )}
                      {this.state.steps.indexOf("story") ===
                        this.state.steps.indexOf(this.state.step) && (
                        <h2 className="company_main_title">Tell Your Story</h2>
                      )}
                      {this.state.steps.indexOf("contact") ===
                        this.state.steps.indexOf(this.state.step) && (
                        <h2 className="company_main_title">Contact Details</h2>
                      )}
                      {this.state.steps.indexOf("board") ===
                        this.state.steps.indexOf(this.state.step) && (
                        <h2 className="company_main_title">
                          Lets Know
                          <br /> your board members
                        </h2>
                      )}
                      {/* <h2 className="company_main_title">Company Intro</h2> */}
                      {this.state.steps.indexOf("board") !==
                        this.state.steps.indexOf(this.state.step) && (
                        <p className="sub_texts">
                          You’re few steps away from getting funded on
                          PropCrowdy
                        </p>
                      )}
                    </div>
                  )}

                  {this.state.steps.indexOf("board") !==
                    this.state.steps.indexOf(this.state.step) && (
                    <div className="level_circles_container">
                      <div
                        className={`level_circle ${
                          this.state.steps.indexOf("introduction") <=
                            this.state.steps.indexOf(this.state.step) &&
                          "active_circle"
                        }`}
                      >
                        1
                      </div>
                      <div
                        className={`level_circle ${
                          this.state.steps.indexOf("story") <=
                            this.state.steps.indexOf(this.state.step) &&
                          "active_circle"
                        }`}
                      >
                        2
                      </div>
                      <div
                        className={`level_circle ${
                          this.state.steps.indexOf("contact") <=
                            this.state.steps.indexOf(this.state.step) &&
                          "active_circle"
                        }`}
                      >
                        3
                      </div>
                      <hr className="level_dashed_line" />
                    </div>
                  )}

                  {this.state.step === "introduction" && (
                    <div className="content">
                      <div className="row_div">
                        <div className="input_container">
                          <label htmlFor="company-description">
                            Company Description *
                          </label>
                          <textarea
                            id="company-description"
                            name="description"
                            value={this.state.description}
                            placeholder="Company Introduction"
                            className="generic_input"
                            style={{ height: "120px" }}
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.description.join("<br />"),
                            }}
                          />

                          <p className="input_explanation">
                            Write a summary about your company.
                          </p>
                        </div>
                      </div>
                      <div className="row_div">
                        <div className="input_container">
                          <label htmlFor="company-name">Name *</label>
                          <input
                            id="company-name"
                            type="text"
                            name="name"
                            value={this.state.name}
                            placeholder="Company Name"
                            className="generic_input"
                            onChange={(e) => {
                              this.setState({ name: e.target.value });
                            }}
                            required
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html: this.state.error.name.join("<br />"),
                            }}
                          />
                        </div>
                      </div>
                      <div className="row_div">
                        <div className="input_container has-ico">
                          <div style={{ position: "relative" }}>
                            <label htmlFor="company-audited-financial-statement">
                              Audited Financial Statement *
                            </label>
                            <input
                              id="company-audited-financial-statement"
                              type="text"
                              name="audited_financial_statement_display_name"
                              value={
                                this.state.auditedFinancialStatementDisplayName
                              }
                              placeholder="Audited Financial Statement"
                              className="generic_input"
                              disabled
                              onChange={(e) => {
                                this.setState({
                                  auditedFinancialStatementDisplayName:
                                    e.target.value,
                                });
                              }}
                            />
                            <img
                              src="/assets/images/vectors/icons/upload_button.svg"
                              alt="Upload"
                              className="upload_button_icon"
                              style={{
                                boxShadow:
                                  "0 -" +
                                  (this.state.auditedFinancialStatement
                                    ? 40
                                    : Math.ceil(
                                        this.state
                                          .auditedFinancialStatementUploadProgress *
                                          40
                                      )) +
                                  "px rgba(0, 0, 0, .5) inset",
                              }}
                              onClick={() => {
                                this.auditedFinancialStatementFileInput.click();
                              }}
                            />
                          </div>
                          <input
                            type="hidden"
                            name="audited_financial_statement"
                            value={this.state.auditedFinancialStatement}
                          />
                          <input
                            ref={(auditedFinancialStatementFileInput) => {
                              this.auditedFinancialStatementFileInput =
                                auditedFinancialStatementFileInput;
                            }}
                            type="file"
                            name="audited_financial_statement_file"
                            className="display-none"
                            onChange={(e) => {
                              this.uploadFile(
                                e.target,
                                "auditedFinancialStatement"
                              );
                            }}
                          />

                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.audited_financial_statement.join(
                                  "<br />"
                                ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="row_div">
                        <div className="input_container">
                          <label htmlFor="company-warning-to-investors">
                            Warning to Investors *
                          </label>
                          <textarea
                            id="company-warning-to-investors"
                            type="text"
                            name="warning_to_investors"
                            value={this.state.warningToInvestors}
                            placeholder="Warning to Investors"
                            className="generic_input"
                            style={{ height: "120px" }}
                            onChange={(e) => {
                              this.setState({
                                warningToInvestors: e.target.value,
                              });
                            }}
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.warning_to_investors.join(
                                  "<br />"
                                ),
                            }}
                          />
                          <p className="input_explanation">
                            What do you think investors should be worry about?
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="step">
                  {this.state.steps.indexOf("story") <=
                    this.state.steps.indexOf(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("story");
                      }}
                    >
                      {/* <span className="number">2</span>
                      <span className="title">Tell Your Story too</span> */}

                      {/* <p className="red_top_text">
                        setUp Your PropCrowdy Company Profile
                      </p>
                      <h2 className="company_main_title">Tell Your Story</h2>
                      <p className="sub_texts">
                        You’re few steps away from getting funded on PropCrowdy
                      </p> */}
                    </div>
                  )}
                  {this.state.step === "story" && (
                    <div className="content">
                      <div className="row_div">
                        <div className="input_container">
                          <label htmlFor="company-tell-story">
                            Your Story *
                          </label>
                          <textarea
                            id="company-tell-story"
                            name="tell_story"
                            value={this.state.tellStory}
                            placeholder="Company Introduction"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({ tellStory: e.target.value });
                            }}
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.tell_story.join("<br />"),
                            }}
                          />
                          <p className="input_explanation">
                            Why should investors invest?
                          </p>
                        </div>
                      </div>

                      <div className="input_container">
                        <label htmlFor="company-phone-number">
                          Phone Number *
                        </label>
                        <input
                          id="company-phone-number"
                          type="text"
                          name="phone-number"
                          placeholder="Phone Number"
                          className="form-control"
                          value={this.state.phoneNumber}
                          onChange={(e) => {
                            this.setState({ phoneNumber: e.target.value });
                          }}
                        />
                        <div
                          className="error"
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.error.phone_number.join("<br />"),
                          }}
                        />
                      </div>

                      <div className="input_container has-ico">
                        <label htmlFor="company-logo">
                          Upload Company Logo *
                        </label>
                        <input
                          id="company-logo"
                          type="text"
                          name="logo_display_name"
                          value={this.state.logoDisplayName}
                          placeholder="Company Logo"
                          className="generic_input"
                          disabled
                          onChange={(e) => {
                            this.setState({
                              logoDisplayName: e.target.value,
                            });
                          }}
                        />
                        <input
                          type="hidden"
                          name="logo"
                          value={this.state.logo}
                        />
                        <input
                          ref={(logoFileInput) => {
                            this.logoFileInput = logoFileInput;
                          }}
                          type="file"
                          name="logo_file"
                          className="display-none"
                          accept="image/x-png, image/jpeg"
                          onChange={(e) => {
                            this.uploadFile(e.target, "logo");
                          }}
                        />
                        <img
                          src="/assets/images/vectors/icons/upload_button.svg"
                          alt="Upload"
                          className="upload_button_icon"
                          style={{
                            boxShadow:
                              "0 -" +
                              (this.state.logo
                                ? 40
                                : Math.ceil(
                                    this.state.logoUploadProgress * 40
                                  )) +
                              "px rgba(0, 0, 0, .5) inset",
                          }}
                          onClick={() => {
                            this.logoFileInput.click();
                          }}
                        />
                        {/* <div className="info">Allowed Formats: JPG, PNG</div> */}
                        <div
                          className="error"
                          dangerouslySetInnerHTML={{
                            __html: this.state.error.logo.join("<br />"),
                          }}
                        />
                      </div>

                      <div className="input_container">
                        <label htmlFor="company-email">Email *</label>
                        <input
                          id="company-email"
                          type="text"
                          name="email"
                          value={this.state.email}
                          placeholder="Enter Official Email"
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          required
                        />
                        <div
                          className="error"
                          dangerouslySetInnerHTML={{
                            __html: this.state.error.email.join("<br />"),
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="step">
                  {this.state.steps.indexOf("contact") <=
                    this.state.steps.indexOf(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("contact");
                      }}
                    >
                      {/* <span className="number">3</span>
                      <span className="title">Contact Address</span> */}
                    </div>
                  )}
                  {this.state.step === "contact" && (
                    <div className="content">
                      <div className="row_div">
                        <div className="input_container">
                          <label htmlFor="company-address">Address *</label>
                          <textarea
                            id="company-address"
                            // type="text"
                            name="Company Address"
                            value={this.state.address}
                            placeholder="Address"
                            className="generic_input"
                            onChange={(e) => {
                              this.setState({ address: e.target.value });
                            }}
                            required
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html: this.state.error.address.join("<br />"),
                            }}
                          />
                        </div>

                        <div className="input_container double">
                          <div className="container_half">
                            <label htmlFor="company-state">State *</label>
                            <select
                              id="company-state-id"
                              name="state_id"
                              value={this.state.stateId}
                              placeholder="Select"
                              className="generic_input"
                              onChange={(e) => {
                                this.setState({ stateId: e.target.value });
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Select
                              </option>
                              {this.state.stateList.map((state, index) => (
                                <option key={index} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.state_id.join("<br />"),
                              }}
                            />
                          </div>

                          <div className="container_half">
                            <label htmlFor="company-country">Country *</label>
                            <select
                              id="company-country-id"
                              name="country_id"
                              value={this.state.countryId}
                              placeholder="Select"
                              className="generic_input"
                              onChange={(e) => {
                                let preload = [];
                                for (let i in (this.context.constants &&
                                  this.context.constants.locale &&
                                  this.context.constants.locale.countries) ||
                                  []) {
                                  if (
                                    (
                                      (this.context.constants &&
                                        this.context.constants.locale &&
                                        this.context.constants.locale
                                          .countries) ||
                                      []
                                    ).hasOwnProperty(i) &&
                                    parseInt(
                                      ((this.context.constants &&
                                        this.context.constants.locale &&
                                        this.context.constants.locale
                                          .countries) ||
                                        [])[i].id
                                    ) === parseInt(e.target.value)
                                  ) {
                                    preload =
                                      ((this.context.constants &&
                                        this.context.constants.locale &&
                                        this.context.constants.locale
                                          .countries) ||
                                        [])[i].country_states || [];
                                  }
                                }
                                this.setState(
                                  { countryId: e.target.value },
                                  () => {
                                    this.fetchStates(
                                      this.state.countryId,
                                      preload
                                    );
                                  }
                                );
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Select
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.locale &&
                                  this.context.constants.locale.countries) ||
                                []
                              ).map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.country_id.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row_div">
                        <div className="input_container has-ico">
                          <label htmlFor="company-cac-document">
                            CAC Document *
                          </label>
                          <input
                            id="company-cac-document"
                            type="text"
                            name="cac_document_display_name"
                            value={this.state.cacDocumentDisplayName}
                            placeholder="CAC Certificate"
                            className="generic_input"
                            disabled
                            onChange={(e) => {
                              this.setState({
                                cacDocumentDisplayName: e.target.value,
                              });
                            }}
                          />
                          <input
                            type="hidden"
                            name="cac_document"
                            value={this.state.cacDocument}
                          />
                          <input
                            ref={(cacDocumentFileInput) => {
                              this.cacDocumentFileInput = cacDocumentFileInput;
                            }}
                            type="file"
                            name="cac_document_file"
                            className="display-none"
                            onChange={(e) => {
                              this.uploadFile(e.target, "cacDocument");
                            }}
                          />
                          <img
                            src="/assets/images/vectors/icons/upload_button.svg"
                            alt="Upload"
                            className="upload_button_icon"
                            style={{
                              boxShadow:
                                "0 -" +
                                (this.state.cacDocument
                                  ? 40
                                  : Math.ceil(
                                      this.state.cacDocumentUploadProgress * 40
                                    )) +
                                "px rgba(0, 0, 0, .5) inset",
                            }}
                            onClick={() => {
                              this.cacDocumentFileInput.click();
                            }}
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.cac_document.join("<br />"),
                            }}
                          />
                        </div>
                        <div className="input_container has-ico">
                          <label htmlFor="company-authorization-letter">
                            Authorization Letter *
                          </label>
                          <input
                            id="company-authorization-letter"
                            type="text"
                            name="authorization_letter_display_name"
                            value={this.state.authorizationLetterDisplayName}
                            placeholder="Authorization Letter"
                            className="generic_input"
                            disabled
                            onChange={(e) => {
                              this.setState({
                                authorizationLetterDisplayName: e.target.value,
                              });
                            }}
                          />
                          <input
                            type="hidden"
                            name="authorization_letter"
                            value={this.state.authorizationLetter}
                          />
                          <input
                            ref={(authorizationLetterFileInput) => {
                              this.authorizationLetterFileInput =
                                authorizationLetterFileInput;
                            }}
                            type="file"
                            name="authorization_letter_file"
                            className="display-none"
                            onChange={(e) => {
                              this.uploadFile(e.target, "authorizationLetter");
                            }}
                          />
                          <img
                            src="/assets/images/vectors/icons/upload_button.svg"
                            alt="Upload"
                            className="upload_button_icon"
                            style={{
                              boxShadow:
                                "0 -" +
                                (this.state.authorizationLetter
                                  ? 40
                                  : Math.ceil(
                                      this.state
                                        .authorizationLetterUploadProgress * 40
                                    )) +
                                "px rgba(0, 0, 0, .5) inset",
                            }}
                            onClick={() => {
                              this.authorizationLetterFileInput.click();
                            }}
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.authorization_letter.join(
                                  "<br />"
                                ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="row_div">
                        {/* <div className="input_container">
                          <label htmlFor="company-facebook-url">
                            Facebook Url *
                          </label>
                          <input
                            id="company-facebook-url"
                            type="url"
                            name="facebook_url"
                            value={this.state.facebookUrl}
                            placeholder="Facebook Url"
                            className="generic_input"
                            onChange={(e) => {
                              this.setState({ facebookUrl: e.target.value });
                            }}
                            required
                          />
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.error.facebook_url.join("<br />"),
                            }}
                          />
                        </div> */}

                        <div className="input_container double">
                          <div className="container_half">
                            <label htmlFor="company-twitter-url">
                              Twitter Url *
                            </label>
                            <input
                              id="company-twitter-url"
                              type="url"
                              name="twitter_url"
                              value={this.state.twitterUrl}
                              placeholder="Twitter Url"
                              className="generic_input"
                              onChange={(e) => {
                                this.setState({ twitterUrl: e.target.value });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.twitter_url.join("<br />"),
                              }}
                            />
                          </div>
                          <div className="container_half">
                            <label htmlFor="company-linkedin-url">
                              Linkedin Url *
                            </label>
                            <input
                              id="company-linkedin-url"
                              type="url"
                              name="linkedin_url"
                              value={this.state.linkedinUrl}
                              placeholder="Linkedin Url"
                              className="generic_input"
                              onChange={(e) => {
                                this.setState({ linkedinUrl: e.target.value });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.linkedin_url.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="step">
                  {this.state.steps.indexOf("board") <=
                    this.state.steps.indexOf(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("board");
                      }}
                    >
                      {/* <span className="number">4</span>
                      <span className="title">Board Of Directors</span> */}
                      <div className="members_display_overall_container">
                        {this.state.board.map((member, index) => (
                          <div key={index}>
                            {member.name !== "" &&
                              member.location !== "" &&
                              member.nationality_id !== "" &&
                              member.photo !== "" && (
                                <div
                                  // key={index}
                                  className="board_members_list_display"
                                >
                                  <div
                                    className="member_display_image_container"
                                    // style={{ marginRight: "3.64%" }}
                                  >
                                    <img
                                      src={
                                        this.state[
                                          "board." + index + ".photoUrl"
                                        ]
                                      }
                                      alt=""
                                      className="member_display_image"
                                    />
                                  </div>
                                  <div className="board_member_info">
                                    <div className="texts_container">
                                      <p className="title">Name</p>
                                      <p className="title_value">
                                        {member.name}
                                      </p>
                                    </div>
                                    <hr className="info_divider" />
                                    <div
                                    // style={{
                                    //   borderLeft: "1px solid #000",
                                    //   borderRight: "1px solid #000",
                                    // }}
                                    >
                                      <p className="title">Location</p>
                                      <p className="title_value">
                                        {member.location}
                                      </p>
                                    </div>
                                    <hr className="info_divider" />
                                    <div>
                                      <p className="title">Nationality</p>
                                      <p className="title_value">
                                        {member.nationality}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    className="remove_member_icon_container"
                                    // style={{ marginLeft: "6.04%" }}
                                  >
                                    <img
                                      src={removeMemberIcon}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.removeBoardMember(index);
                                      }}
                                      className="remove_member_icon"
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {this.state.step === "board" && (
                    <div className="content" style={{ marginTop: "90px" }}>
                      <div className="row_div">
                        <div className="field">
                          <label htmlFor="company-board-member-name">
                            Board Of Directors Member *
                          </label>
                        </div>
                      </div>
                      {/* {this.state.board.map((member, index) => (
                        <div key={index} className="row_div no-label compact">
                          <div className="input_container double">
                            <div className="container_half">
                              <input
                                type="text"
                                name="boards[][name]"
                                value={this.state.board_inputs.name}
                                placeholder="Name"
                                className="generic_input"
                                onChange={(e) => {
                                  // this.updateBoardMember(
                                  //   index,
                                  //   "name",
                                  //   e.target.value
                                  // );
                                  this.setState({
                                    board_inputs: (prev) => ({
                                      ...prev,
                                      name: e.target.value,
                                    }),
                                  });
                                }}
                                required
                              />
                              <div
                                className="error"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.state.error[
                                      "boards." + index + ".name"
                                    ].join("<br />"),
                                }}
                              />
                            </div>
                            <div className="container_half geocomplete">
                              <label>Location</label>
                              <input
                                type="text"
                                name="boards[][location]"
                                value={this.state.board_inputs.location}
                                placeholder="Location"
                                className="generic_input"
                                onChange={(e) => {
                                  // this.updateBoardMember(
                                  //   index,
                                  //   "location",
                                  //   e.target.value
                                  // );
                                  this.setState({
                                    board_inputs: (prev) => ({
                                      ...prev,
                                      location: e.target.value,
                                    }),
                                  });
                                }}
                                required
                              />
                              <div
                                className="error"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.state.error[
                                      "boards." + index + ".location"
                                    ].join("<br />"),
                                }}
                              />
                            </div>
                          </div>
                          <div className="input_container">
                            <select
                              name="boards[][nationality_id]"
                              value={this.state.border_inputs.nationality_id}
                              placeholder="Nationality"
                              className="generic_input"
                              onChange={(e) => {
                                // this.updateBoardMember(
                                //   index,
                                //   "nationality_id",
                                //   e.target.value
                                // );
                                this.setState({
                                  board_inputs: (prev) => ({
                                    ...prev,
                                    nationality_id: e.target.value,
                                  }),
                                });
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Nationality
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.locale &&
                                  this.context.constants.locale.countries) ||
                                []
                              ).map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error[
                                    "boards." + index + ".nationality_id"
                                  ].join("<br />"),
                              }}
                            />
                          </div>
                          <div className="input_container has-ico">
                            <input
                              id="company-board-member-photo"
                              type="text"
                              name="boards[][photo_display_name]"
                              value={
                                this.state[
                                  "board." + index + ".photoDisplayName"
                                ]
                              }
                              placeholder="Photo"
                              className="generic_input"
                              disabled
                              onChange={(e) => {
                                this.setState({
                                  ["board." + index + ".photoDisplayName"]:
                                    e.target.value,
                                });
                              }}
                            />
                            <input
                              type="hidden"
                              name="boards[]photo"
                              value={member.photo}
                            />
                            <input
                              ref={(boardFileInput) => {
                                this["board." + index + ".photoFileInput"] =
                                  boardFileInput;
                              }}
                              type="file"
                              name="board[][photo_file]"
                              className="display-none"
                              onChange={(e) => {
                                this.uploadFile(
                                  e.target,
                                  "board." + index + ".photo"
                                );
                              }}
                            />
                            <img
                              src="/assets/images/vectors/icons/upload_button.svg"
                              alt="Upload"
                              className="upload_button_icon"
                              style={{
                                boxShadow:
                                  "0 -" +
                                  (this.state.board[index].photo
                                    ? 40
                                    : Math.ceil(
                                        this.state[
                                          "board." +
                                            index +
                                            ".photoUploadProgress"
                                        ] * 40
                                      )) +
                                  "px rgba(0, 0, 0, .5) inset",
                              }}
                              onClick={() => {
                                this[
                                  "board." + index + ".photoFileInput"
                                ].click();
                              }}
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error[
                                    "boards." + index + ".photo"
                                  ].join("<br />"),
                              }}
                            />
                          </div>
                          <div className="field no-label">
                            <button
                              id="company-board-member-remove"
                              type="button"
                              className="action"
                            >
                              <i
                                className="icon las la-minus-circle"
                                onClick={() => {
                                  this.removeBoardMember(index);
                                }}
                              />
                            </button>
                          </div>
                        </div>
                      ))} */}

                      {/* <p>=====================================</p> */}
                      {this.state.board.map((member, index) => (
                        <div key={index} className="row_div no-label compact">
                          <div className="input_container double">
                            <div className="container_half">
                              <input
                                type="text"
                                name="boards[][name]"
                                value={member.name}
                                placeholder="Name"
                                className="generic_input"
                                onChange={(e) => {
                                  this.updateBoardMember(
                                    index,
                                    "name",
                                    e.target.value
                                  );
                                }}
                                required
                              />
                              <div
                                className="error"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.state.error[
                                      "boards." + index + ".name"
                                    ].join("<br />"),
                                }}
                              />
                            </div>
                            <div className="container_half geocomplete">
                              {/* <GooglePlacesAutocomplete
                                apiKey={
                                  this.context.settings &&
                                  this.context.settings.google
                                    ? this.context.settings.google.api
                                    : "AIzaSyDneJtifU7zFOVA4Mq1LpzP09UCISiAsxs"
                                }
                                selectProps={{
                                  bounds: [
                                    { lat: 4.0690959, lng: 2.676932 },
                                    { lat: 13.885645, lng: 14.678014 },
                                  ],
                                  componentRestrictions: {
                                    country: ["ng"],
                                  },
                                  location: member.location,
                                  onChange: (result) => {
                                    this.updateBoardMember(
                                      index,
                                      "location",
                                      result.label
                                    );
                                  },
                                  styles: {
                                    input: (provided) => ({
                                      ...provided,
                                      color: "#000",
                                    }),
                                    option: (provided) => ({
                                      ...provided,
                                      color: "#000",
                                    }),
                                    singleValue: (provided) => ({
                                      ...provided,
                                      color: "#000",
                                    }),
                                  },
                                }}
                              /> */}
                              <label>Location</label>
                              <input
                                type="text"
                                name="boards[][location]"
                                value={member.location}
                                placeholder="Location"
                                className="generic_input"
                                onChange={(e) => {
                                  this.updateBoardMember(
                                    index,
                                    "location",
                                    e.target.value
                                  );
                                }}
                                required
                              />
                              <div
                                className="error"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.state.error[
                                      "boards." + index + ".location"
                                    ].join("<br />"),
                                }}
                              />
                            </div>
                          </div>
                          <div className="input_container">
                            <select
                              name="boards[][nationality_id]"
                              value={member.nationality_id}
                              placeholder="Nationality"
                              className="generic_input"
                              onChange={(e) => {
                                this.updateBoardMember(
                                  index,
                                  "nationality_id",
                                  e.target.value
                                );
                                this.updateBoardMember(
                                  index,
                                  "nationality",
                                  "Nigeria"
                                );
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Nationality
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.locale &&
                                  this.context.constants.locale.countries) ||
                                []
                              ).map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error[
                                    "boards." + index + ".nationality_id"
                                  ].join("<br />"),
                              }}
                            />
                          </div>
                          <div className="input_container has-ico">
                            <input
                              id="company-board-member-photo"
                              type="text"
                              name="boards[][photo_display_name]"
                              value={
                                this.state[
                                  "board." + index + ".photoDisplayName"
                                ]
                              }
                              placeholder="Photo"
                              className="generic_input"
                              disabled
                              onChange={(e) => {
                                this.setState({
                                  ["board." + index + ".photoDisplayName"]:
                                    e.target.value,
                                });
                              }}
                            />
                            <input
                              type="hidden"
                              name="boards[]photo"
                              value={member.photo}
                            />
                            <input
                              ref={(boardFileInput) => {
                                this["board." + index + ".photoFileInput"] =
                                  boardFileInput;
                              }}
                              type="file"
                              name="board[][photo_file]"
                              className="display-none"
                              onChange={(e) => {
                                this.uploadFile(
                                  e.target,
                                  "board." + index + ".photo"
                                );
                              }}
                            />
                            <img
                              src="/assets/images/vectors/icons/upload_button.svg"
                              alt="Upload"
                              className="upload_button_icon"
                              style={{
                                boxShadow:
                                  "0 -" +
                                  (this.state.board[index].photo
                                    ? 40
                                    : Math.ceil(
                                        this.state[
                                          "board." +
                                            index +
                                            ".photoUploadProgress"
                                        ] * 40
                                      )) +
                                  "px rgba(0, 0, 0, .5) inset",
                              }}
                              onClick={() => {
                                this[
                                  "board." + index + ".photoFileInput"
                                ].click();
                              }}
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error[
                                    "boards." + index + ".photo"
                                  ].join("<br />"),
                              }}
                            />
                          </div>
                          {/* <div className="field no-label">
                            <button
                              id="company-board-member-remove"
                              type="button"
                              className="action"
                            >
                              <i
                                className="icon las la-minus-circle"
                                onClick={() => {
                                  this.removeBoardMember(index);
                                }}
                              />
                            </button>
                          </div> */}
                        </div>
                      ))}
                      <div className="please_note_div">
                        <div className="please_note_black_texts">
                          <div className="info">
                            <p className="please_note_text">Please Note:</p>
                            <p className="note_content">
                              A minimum of 3 board members should be uploaded
                            </p>
                          </div>
                          <div
                            className="error"
                            dangerouslySetInnerHTML={{
                              __html: this.state.error.board.join("<br />"),
                            }}
                          />
                        </div>
                        <button
                          type="button"
                          className="add_board_member"
                          onClick={() => {
                            this.addBoardMemberNew();
                          }}
                        >
                          <div className="add_board_member_icon_container">
                            <i
                              className="icon las la-plus-circle"
                              style={{ color: "#fff", fontSize: "18px" }}
                              // color="#fff"
                            />
                          </div>
                          Add Board Member
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* ===== */}

                <div
                  className={`controls buttons_holder ${
                    this.state.step !== "board" && "last_part"
                  }`}
                >
                  <div>
                    {this.state.step !== "introduction" &&
                      this.state.step !== "board" && (
                        <button
                          className="generic_button"
                          style={{ marginRight: "18px" }}
                          onClick={(e) => {
                            // console.log("prev button clicked");
                            e.preventDefault();
                            this.prevStep();
                          }}
                        >
                          Back to Previous
                        </button>
                      )}
                  </div>
                  <div className="row_div">
                    {/* <div className="col-md-8" /> */}
                    {/* <div className="col-md-4"> */}
                    {this.state.steps.indexOf(this.state.step) <
                    this.state.steps.length - 1 ? (
                      <button
                        type="button"
                        className="generic_button nex"
                        onClick={(e) => {
                          e.preventDefault();
                          this.nextStep();
                        }}
                      >
                        Proceed to Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="generic_button submi"
                        onClick={() => {
                          this.submit();
                        }}
                        style={{ width: "100%", flex: "1" }}
                      >
                        Submit Funding Application
                      </button>
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
