import React from "react";
import PageRoutesBar from "../Accessories/PageRoutesBar";
import SpecificPropertyContainerLayout from "../Accessories/SpecificPropertyContainerLayout";

const PropertyPage = () => {
  return (
    <div>
      <PageRoutesBar />
      <SpecificPropertyContainerLayout />
    </div>
  );
};

export default PropertyPage;
