import React from 'react';
import {Context} from './../Context';

export default class Hook extends React.Component {
    constructor(props) {
        super(props);
        Hook.contextType = Context;
    }

    static hooks = [];

    static register = (name, callback, key) => {
        key = key || new Date().getTime() + '' + Math.random();
        if (typeof Hook.hooks[name] === 'undefined') {
            Hook.hooks[name] = {};
        }
        Hook.hooks[name][key] = callback;
        return key
    }

    static unregister = (name, key) => {
        if (typeof Hook.hooks[name] !== 'undefined') {
            if (typeof key === 'function') {
                for (let i in Hook.hooks[name]) {
                    if (Hook.hooks[name].hasOwnProperty(i) && Hook.hooks[name] === key) {
                        delete Hook.hooks[name];
                    }
                }
            } else if (Hook.hooks[name][key]) {
                delete Hook.hooks[name][key];
            }
        }
    }

    static fire = (name, result, args) => {
        if (typeof args === 'undefined' || (typeof args !== 'undefined' && !Array.isArray(args))) {
            args = [];
        }
        args.unshift(result);
        if (typeof Hook.hooks[name] !== 'undefined') {
            for (let i in Hook.hooks[name]) {
                if (Hook.hooks[name].hasOwnProperty(i)) {
                    args[0] = result;
                    result = Hook.hooks[name][i].apply(undefined, args);
                }
            }
        }
        return result;
    }
}
