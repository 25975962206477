import React from "react";
import { Link } from "react-router-dom";
import { Context } from "./../Context";
import ArticleListItem from "./../../../Blog/Components/Templates/ArticleListItem";
import OpportunityListItem from "./../../../Opportunity/Components/Templates/OpportunityListItem";
import $ from "jquery";
import Document from "./../Utils/Document";
import Api from "./../Utils/Api";
import UI from "./../Utils/UI";
import noticeBanner from "../../../../modules/App/assets/images/banner-notice.gif";

import Slider from "react-slick";

import "../../assets/styling/home_page_styling.css";
import InvestorDeveloperSmallCard from "../miscellaneous-components/InvestorDeveloperSmallCard";
import WhyChoosePropCrowdy from "../miscellaneous-components/WhyChoosePropCrowdy";
import InvestorDeveloperSmallCardDetails from "../miscellaneous-components/InvestorDeveloperSmallCard/InvestorDeveloperSmallCardDetails";
import cardsInvestors from "../../assets/images/cards_investors.png";
import dealroomFormPicture from "../../assets/images/dealroom_form_picture.png";
import independentInvestmentCommitteePicture from "../../assets/images/independent_investment_committee.png";
// import googlePlayButtons from "../../assets/images/vectors/google_play_buttons.svg";
import googlePlayButton from "../../assets/images/google_play_image.png";
import appStoreButton from "../../assets/images/app_store_image.png";
import womanSmilingAtPhone from "../../assets/images/woman_smiling_at_phone.png";
import twoPhones from "../../assets/images/two_phones.png";
import laptopShowingDashboard from "../../assets/images/laptop_showing_dashboard.png";
import numberOneDetails from "../../assets/images/vectors/number_one_details.svg";
import numberTwoDetails from "../../assets/images/vectors/number_two_details.svg";
import numberThreeDetails from "../../assets/images/vectors/number_three_details.svg";
import contactUsBanner from "../../assets/images/contact_us_banner.png";
import GetStartedModal from "../miscellaneous-components/GetStartedModal";
import ContactUsBanner from "../../../Opportunity/Components/Accessories/ContactUsBanner";
import Countdown from "react-countdown";

export default class PropCrowdy extends React.Component {
  constructor(props) {
    super(props);
    PropCrowdy.contextType = Context;
    this.state = {
      trendingOpportunities: [],
      recentArticles: [],
      fundedCompanies: [],
      roi: [],
      tenors: [],
      showModal: false,
      amount: "",
    };
  }

  componentDidMount() {
    this.context.updateContext("layout", "default");
    Document.updateDocumentHead({ title: "PropCrowdy - Home" });
    Api.fetch("opportunity.list", undefined, { trending: 1, limit: 12 })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.setState(
            { trendingOpportunities: responseJSON.data || [] },
            () => {
              UI.slick();
              UI.animationEntry();
            }
          );
        }
      })
      .catch((reason) => {
        console.debug(reason);
      });
    Api.fetch("blog.article.list", undefined, { limit: 3 })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.setState({ recentArticles: responseJSON.data || [] }, () => {
            UI.animationEntry();
          });
        }
      })
      .catch((reason) => {
        console.debug(reason);
      });
    Api.fetch("developer.company.funded.list", undefined, { limit: 7 })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.setState({ fundedCompanies: responseJSON.data || [] }, () => {
            UI.slick();
            UI.animationEntry();
          });
        }
      })
      .catch((reason) => {
        console.debug(reason);
      });
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (!completed){
      return (
          <div className="homepage-countdown">
            <div className="time-box">
              <div className="time first">
                <span>{days}</span>
                <div>Days</div>
              </div>
            </div>
            <div className="time-box">
              <div className="time">
                <span>{hours}</span>
                <div>Hours</div>
              </div>
            </div>
            <div className="time-box">
              <div className="time">
                <span>{minutes}</span>
                <div>Minutes</div>
              </div>
            </div>
            <div className="time-box">
              <div className="time">
                <span>{seconds}</span>
                <div>Seconds</div>
              </div>
            </div>
          </div>
      )
    }
  }

  render() {
    const toggleModal = () => {
      this.setState({
        ...this.state,
        showModal: !this.state.showModal,
      });
    };

    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <>
        <div
          style={{
            maxWidth: "1600px",

            margin: "auto",
          }}
          className="slider-section"
        >
          <div className="on-going-project">
            <a href="/opportunity/2-bedroom-serviced-apartments-bolaji-apartments-609a7034e87541">
              <img className="desktop-img" src={require("./../../assets/images/desktop.png")}/>
              <img className="mobile-img" src={require("./../../assets/images/mobile.png")}/>
            </a>
          </div>
          <Slider {...settings}>
            <div>
              <div className="home_page_first_section">
                <img
                  src={womanSmilingAtPhone}
                  alt=""
                  className="main_home_image"
                />
                <div className="texts_part">
                  <div className="first_section_headings">
                    <h1 className="first_section_title">
                      Real Estate Investment Opportunities For Property
                      Development
                    </h1>
                    <p className="first_section_subtitle">
                      PropCrowdy is a crowdfunding investment platform that
                      empowers Nigerians to make smart investments in real
                      estate
                    </p>
                  </div>

                  <div className="links link_top">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleModal}
                      className="get_started modal_trigger"
                    >
                      Get Started
                    </div>

                    <Link
                      to={{ pathname: "/about" }}
                      className="link learn_more"
                    >
                      <img
                        src={
                          require("../../assets/images/vectors/icons/playButtonDownloaded.svg")
                            .default
                        }
                        alt="play video"
                        className="play_button_icon"
                      />
                      {/* <p>See How It Works</p> */}
                      See How It Works
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home_page_first_section">
                <img src={twoPhones} alt="" className="main_home_image" />
                <div className="texts_part">
                  <div className="first_section_headings">
                    <h1 className="first_section_title">
                      For Investors looking to make ROI from Regulated
                      Investment Platforms Creating a carbon-free future for
                      everyone
                    </h1>
                    <p className="first_section_subtitle">
                      We shape the manner and ways real estate developers build,
                      by promoting green and carbon policies for every developer
                      funded on the portal.
                    </p>
                  </div>

                  <div className="links link_top">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleModal}
                      className="get_started modal_trigger"
                    >
                      Get Started
                    </div>
                    <Link
                      to={{ pathname: "/carbon" }}
                      className="link learn_more"
                    >
                      <img
                        src={
                          require("../../assets/images/vectors/icons/playButtonDownloaded.svg")
                            .default
                        }
                        alt="play video"
                        className="play_button_icon"
                      />
                      See How It Works
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home_page_first_section">
                <img
                  src={laptopShowingDashboard}
                  alt=""
                  className="main_home_image .laptop_to_side"
                />
                <div className="texts_part">
                  <div className="first_section_headings">
                    <h1 className="first_section_title">
                      Secured real estate investments you can trust
                    </h1>
                    <p className="first_section_subtitle">
                      Propcrowdy, we are solving the twin problem of social
                      impact and inclusiveness for those at the bottom of the
                      pyramid and creating wealth and economic development for
                      small and medium-sized real estate developers
                    </p>
                  </div>

                  <div className="links link_top">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleModal}
                      className="get_started modal_trigger"
                    >
                      Get Started
                    </div>
                    <Link
                      to={{ pathname: "/about" }}
                      className="link learn_more"
                    >
                      <img
                        src={
                          require("../../assets/images/vectors/icons/playButtonDownloaded.svg")
                            .default
                        }
                        alt="play video"
                        className="play_button_icon"
                      />
                      See How It Works
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <main id="main" className="home crowdy">
          <GetStartedModal
            showModal={this.state.showModal}
            // setShowModal= {this.setState()}
            toggleModal={toggleModal}
          />
          {this.context.settings && this.context.settings.investments && this.context.settings.investments.homepage_count_down?(
              <div className="investment-launch">
                <div className="heading">Countdown to <span>Investment</span> Launch</div>
                <Countdown
                    date={this.context.settings.investments.homepage_count_date}
                    renderer={this.renderer}
                />
                <div className="t"><span className="add-weight">Propcrowdy</span> brings you well vetted investment opportunities</div>
                <div className="t">Regulated by Security and Exchange Commission of Nigeria <span className="add-weight">(SEC)</span></div>
              </div>
          ):null}
          <div className="main_sections">
            <section className="home_page_second_section">
              <div className="texts_contents">
                <div className="second_section_texts">
                  <h3 className="second_section_title">
                    Join Real Estate Investors investing realtime
                  </h3>
                  <p className="second_section_description">
                    PropCrowdy is regulated by the Security and exchange
                    commsion, with FCMB as a costodian bank and a board of
                    trustees.
                  </p>
                </div>
                <div className="second_section_buttons">
                  {/* <button>Google Play</button>
              <button>App Store</button> */}
                  <a
                    href="https://play.google.com/store/apps/details?id=io.propcrowdy.app"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "3%" }}
                  >
                    <img src={googlePlayButton} alt="google play store link" />
                  </a>
                  <a
                    href="https://apps.apple.com/ae/app/propcrowdy/id1588891096"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStoreButton} alt="app store link" />
                  </a>
                </div>
              </div>
              <img src={cardsInvestors} alt="" />
            </section>

            <section className="home_page_third_section third_section">
              <div className="investor_section_upper_texts">
                <h4>As an Investor</h4>
                <p>
                  Get access to investment opportunities while getting expert
                  advice on your investment choices. Our investment
                  opportunities are regulated by appropriate authorities,
                  investors need not worry as their investment are safe and
                  secure.
                </p>
              </div>
              <div className="investor_images_section">
                <img
                  src={
                    require("../../assets/images/investors_listing_card_picture_edited.png")

                  }
                  alt="as an investor"
                  className="as-an-investor-main-image"
                />
                <div className="investor_images_grid">
                  {InvestorDeveloperSmallCardDetails[0].investor.map(
                    (details) => {
                      return (
                        <InvestorDeveloperSmallCard
                          key={details.id}
                          imageUrl={details.imageUrl}
                          title={details.title}
                          description={details.description}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </section>

            <section className="home_page_third_section fourth_section">
              <div className="investor_section_upper_texts">
                <h4> As a Fund Raiser</h4>
                <p>
                  As a fund raiser looking to crowd f und a building project you
                  can gain access to investors on PropCrowdy in few steps.
                  Create a company profile, complete our verification and review
                  processes. Only for Nigerian Registerd Companies.
                </p>
              </div>
              <div className="investor_images_section">
                <img
                  src={
                    require("../../assets/images/developer_offer_document.png")

                  }
                  alt="as an investor"
                  className="as-an-investor-main-image"
                />
                <div className="investor_images_grid">
                  {InvestorDeveloperSmallCardDetails[1].developer.map(
                    (details) => {
                      return (
                        <InvestorDeveloperSmallCard
                          key={details.id}
                          imageUrl={details.imageUrl}
                          title={details.title}
                          description={details.description}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </section>

            <section className="why_choose_propcrowdy_section">
              <h2 className="why_choose_propcrowdy_heading">
                Why Choose PropCrowdy
              </h2>
              <div className="why_choose_container part_a">
                <img src={dealroomFormPicture} alt="dealroom form" />
                <div className="why_choose_propcrowdy_texts">
                  <div className="main_text">
                    <h4 className="why_choose_propcrowdy_title">
                      Our Deal Room
                    </h4>
                    <div className="why_choose_propcrowdy_description">
                      <p>
                        Our deal room provides you all the documentary evidence
                        you need to know about an investment.
                      </p>
                      <p>
                        As part of our due-diligence verification we compile all
                        necessary documents associated with each opportunity on
                        our platform.
                      </p>
                    </div>
                  </div>
                  <a
                    style={{ textDecoration: "none", marginTop: "30px" }}
                    href="/deal-room"
                  >
                    <button className="main_button">Learn More</button>
                  </a>
                </div>
              </div>
              <div className="why_choose_container part_b">
                <img
                  src={independentInvestmentCommitteePicture}
                  alt="indepentdent investment committee"
                />
                <div className="why_choose_propcrowdy_texts">
                  <div className="main_text">
                    <h4 className="why_choose_propcrowdy_title">
                      Independent Investment Committee
                    </h4>
                    <div className="why_choose_propcrowdy_description">
                      <p>
                        In other to ensure that our investments are viable, we
                        have a team of independent investment advisors who are
                        responsible for appraising the property investment and
                        ensuring that offerings meet the minimum risk acceptance
                        criteria.
                      </p>
                    </div>
                  </div>
                  <Link to={{ pathname: "/register" }} className="link">
                    <button
                      style={{ marginTop: "30px" }}
                      className="main_button"
                    >
                      Get Started
                    </button>
                  </Link>
                </div>
              </div>
            </section>

            <section className="numbered_description_section">
              <img src={numberOneDetails} alt="" />
              <img src={numberTwoDetails} alt="" />
              <img src={numberThreeDetails} alt="" />
            </section>

            {this.state.recentArticles && this.state.recentArticles.length ? (
              <section id="blog-articles">
                <div className="container content animation-entry">
                  <div className="heading">Articles From Our Blog</div>
                  <div className="sub-heading">
                    Stay updated with PropCrowdy trends and market updates
                  </div>
                </div>
                <div className="container content articles">
                  {/* <div className="row">
                {this.state.recentArticles.map((article, index) => (
                  <div key={index} className="col-md-4 animation-entry">
                    <ArticleListItem
                      article={article}
                      previousArticle={this.state.recentArticles[index + 1]}
                    />
                  </div>
                ))}
              </div> */}

                  <div className="articles_list">
                    {this.state.recentArticles.map((article, index) => (
                      <div key={index} className="">
                        <ArticleListItem
                          article={article}
                          previousArticle={this.state.recentArticles[index + 1]}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ) : null}
            {this.state.fundedCompanies && this.state.fundedCompanies.length ? (
              <section id="funded-companies">
                <div className="container content animation-entry">
                  <div className="heading">Funded Companies</div>
                  <div className="sub-heading">
                    Our exclusive list of investment companies
                  </div>
                </div>
                <div className="container content companies animation-entry">
                  <div
                    className="slick-carousel"
                    data-slick='{"dots":true,"infinite":true,"slidesToShow":6,"slidesToScroll":1,"responsive":[{"breakpoint":1500,"settings":{"slidesToShow":5,"slidesToScroll":1}},{"breakpoint":1200,"settings":{"slidesToShow":3,"slidesToScroll":1}},{"breakpoint":992,"settings":{"slidesToShow":2,"slidesToScroll":1}},{"breakpoint":576,"settings":{"slidesToShow":1,"slidesToScroll":1}}]}'
                  >
                    {this.state.fundedCompanies.map((company, index) => (
                      <div key={index}>
                        <Link
                          to={{
                            pathname:
                              "/company/" +
                              (company.company ? company.company.slug : ""),
                            state: { company: company.company },
                          }}
                          style={{
                            backgroundImage:
                              'url("' +
                              (company.company && company.company.logo
                                ? company.company.logo.url
                                : "/assets/images/company-logo.png") +
                              '")',
                          }}
                          className="company icon"
                          title={company.company && company.company.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ) : null}

            <div>
              <ContactUsBanner />
            </div>
          </div>
        </main>
      </>
    );
  }
}
