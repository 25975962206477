import React from 'react';
import {Context} from './../../../App/Components/Context';
import Api from './../../../App/Components/Utils/Api';
import Hook from './../../../App/Components/Utils/Hook';
import User from './../Utils';

export default class Util extends React.Component {
    constructor(props) {
        super(props);
        Util.contextType = Context;
    }

    static syncUser = (user, context) => {
        return new Promise((resolve, reject) => {
            user = user || User.getUser();
            if (user && user.id && user.token) {
                context.updateContext('user', {...(context.user || {}), ...user}, () => {
                    localStorage.setItem('user', JSON.stringify(user));
                    Api.fetch('user.view').then(({status, responseJSON}) => {
                        user = User.getUser();
                        if (user && user.id && user.token && status === 200 && responseJSON.data) {
                            user = {...user, ...responseJSON.data};
                            context.updateContext('user', user, () => {
                                localStorage.setItem('user', JSON.stringify(user));
                                Api.fetch('property.user.purchase.list').then(({status, responseJSON}) => {
                                    user = User.getUser();
                                    if (user && user.id && user.token && status === 200) {
                                        user['property_purchases'] = responseJSON.data || [];
                                        context.updateContext('user', user, () => {
                                            localStorage.setItem('user', JSON.stringify(user));
                                            Api.fetch('payment.user.view').then(({status, responseJSON}) => {
                                                user = User.getUser();
                                                if (user && user.id && user.token && status === 200) {
                                                    user['payment_info'] = responseJSON.data;
                                                    context.updateContext('user', user, () => {
                                                        localStorage.setItem('user', JSON.stringify(user));
                                                    });
                                                    resolve(user);
                                                } else {
                                                    reject('Error Occurred');
                                                }
                                            }).catch((reason) => {
                                                console.debug(reason);
                                                reject('Error Occurred! Please check the internet and try again');
                                            });
                                        });
                                    } else {
                                        reject('Error Occurred');
                                    }
                                }).catch((reason) => {
                                    console.debug(reason);
                                    reject('Error Occurred! Please check the internet and try again');
                                });
                            });
                        } else {
                            reject('Error Occurred');
                        }
                    }).catch((reason) => {
                        console.debug(reason);
                        reject('Error Occurred! Please check the internet and try again');
                    });
                });
            } else {
                reject('Please login first');
            }
        });
    }

    static getUser = () => {
        let user = localStorage.getItem('user');
        try {
            user = JSON.parse(user);
        } catch (e) {
        }
        if (user && user.id && user.token) {
            return user;
        }
    }

    static login = (user, context) => {
        context.updateContext('user', user, () => {
            localStorage.setItem('user', JSON.stringify(user));
            Hook.fire('user.logged.in', undefined, [user, context]);
        });
    }

    static logout = (context) => {
        localStorage.removeItem('user');
        context.updateContext('user', undefined);
        Hook.fire('user.logged.out', undefined, [context]);
    }
}
