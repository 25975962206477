import React from "react";
import { Link } from "react-router-dom";
import { Context } from "./../Context";
import Document from "./../Utils/Document";

import "../../assets/styling/about_page_styles.css";
import mainPicture from "../../assets/images/about_page/about_prowdy_main_pic.png";
import totalInvestmentsPicture from "../../assets/images/about_page/total_investments_picture.png";
import investorsListingCardPicture from "../../assets/images/about_page/investors_listing_card_picture.png";
import socialImpact from "../../assets/images/about_page/social_impact.svg";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    About.contextType = Context;
    this.state = {};
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      window.location.pathname.split("/")[1] === "buy"
        ? "default.buy"
        : "default"
    );
    Document.updateDocumentHead({
      title:
        (window.location.pathname.split("/")[1] === "buy"
          ? "PropBuy"
          : "PropCrowdy") + " - About Us",
    });
  }

  render() {
    return (
      <main id="main" className="about_page">
        {/* <h1 className="about_page_title">About PropCrowdy</h1> */}
        <div className="about_page_body_container">
          <section className="about_section_one">
            <div className="texts_container">
              <h2 className="about_page_title">About PropCrowdy</h2>
              <h1 className="texts_title_big">
                Propcrowdy is Nigeria’s digital investment platform for the real
                estate sector
              </h1>
              <p className="texts_description">
                Propcrowdy is Nigeria’s digital investment platform for SME's
                and the real estate sector, empowering property developers by
                providing them channels of funding for their respective property
                developments.
              </p>
            </div>

            <img src={mainPicture} alt="cards" />
          </section>
          <section className="about_section_one social_impact">
            <div className="texts_container" style={{ maxWidth: "622px" }}>
              <h4 className="texts_title_big">
                Social impact and inclusiveness
              </h4>
              <p className="texts_description">
                It's important to understand that social impact and
                inclusiveness is valuable to us. By creating an environment that
                values a wide range of opinions, experiences, and perspectives,
                we gain insights to serve those at the bottom of the pyramid and
                creating wealth and economic development for small and
                medium-sized real estate developers and stakeholders.
              </p>
              <p>
                In addition, aligning operations with values commonly associated
                with social impact help draw talent and boost brand appeal to
                customers, investors, and staff.
              </p>
            </div>

            <img
              src={socialImpact}
              alt="cards"
              className="social_impact_image"
            />
          </section>

          <section className="about_section_two">
            <div className="texts_container">
              <h1 className="texts_title">
                PropCrowdy was incorporated in Nov. 2018 with company
                registration number <span>RC-1543260</span>
              </h1>
              <div className="texts_description_container">
                <p className="texts_description">
                  Propcrowdy is Nigeria’s digital investment platform for the
                  real estate sector, empowering property developers by
                  providing them channels of funding for their respective
                  project development. Propcrowdy provide a much unique
                  opportunity for individuals, property developers to a one of
                  its kind financial solution for property development.
                </p>
                <p className="texts_description">
                  It creates appreciable earning opportunities for individuals
                  and provides the much-needed ease in property development
                  fundraising. As Africans and Nigeria both within or in the
                  diaspora, You deserves a shot at reliable investment
                  opportunity, regardless of background or social status.
                  Understanding how to build sustainable wealth is key to
                  building a better economy and having a better life
                </p>
              </div>
            </div>
            <img src={totalInvestmentsPicture} alt="cards" />
          </section>
          <section className="about_section_three">
            <div className="texts_container">
              <div className="texts_description_container">
                <p className="texts_description">
                  Working for money is fine, but having your money work for you
                  is key. Propcrowdy bridge the gap in income, wealth and
                  infrastructural development. With propcrowdy you can start an
                  online investment in real estate. Propcrowdy help you invest
                  in a portfolio of real estate property developments around the
                  country.
                </p>
                <p className="texts_description">
                  Each property development on propcrowdy is an opportunity to
                  earn from an investment made in sponsoring that development.
                  Another unique feature of the propcrowdy platform is your
                  determine your minimum investment amount. But we don’t allow
                  you investing beyond a certain percentage of your income.
                </p>
              </div>
            </div>
            <img src={investorsListingCardPicture} alt="cards" />
          </section>
        </div>
      </main>
    );
  }
}
