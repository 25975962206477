import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Resource from './../Templates/Layouts/Resource';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';

export default class ArticleResearchPaperView extends React.Component {
    constructor(props) {
        super(props);
        ArticleResearchPaperView.contextType = Context;
        this.state = {
            item: this.props.location.state && this.props.location.state.item,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Articles and Research Paper - ' + (this.state.item ? this.state.item.title : '')});
        this.fetchItem();
    }

    fetchItem() {
        this.setState({fetching: true});
        Api.fetch('research.view', undefined, {slug: window.location.pathname.split('/')[3]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({item: responseJSON.data}, () => {
                    Document.updateDocumentHead({title: 'PropCrowdy Articles and Research Paper - ' + (this.state.item ? this.state.item.title : '')});
                    this.fetchRelatedArticle();
                });
            }
            this.setState({fetching: false});
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    render() {
        return (
            <Resource heading="Publications" title={this.state.item ? this.state.item.title : ''} subTitle="Articles and Research Papers">
                <main id="main" className="resource article-research-paper">
                    {this.state.item ? (
                        <div className="container">
                            <div className="content box">
                                <div className="cover">
                                    <img src={this.state.item.book_cover ? this.state.item.book_cover.url : '/assets/images/image-placeholder.png'} alt={this.state.item.title} className="image" />
                                </div>
                                <div className="top">
                                    <div className="left">Overview</div>
                                    <div className="right">Purchase Full Publication</div>
                                </div>
                                <div className="preview">
                                    <img src={this.state.item.book_overview ? this.state.item.book_overview.url : '/assets/images/image-placeholder.png'} alt={this.state.item.title} className="image" />
                                </div>
                                <div className="links">
                                    <a href={(this.state.item.purchase_url.match(/^https?:\/\//gi) ? '' : 'https://') + this.state.item.purchase_url} target="_blank" rel="noopener noreferrer" className="link">Continue</a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </main>
            </Resource>
        );
    }
}
