import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import _ from 'lodash';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';
import $ from "jquery";
import {Link} from "react-router-dom";

export default class SettlementList extends React.Component {
    constructor(props) {
        super(props);
        SettlementList.contextType = Context;
        this.state = {
            opportunity: this.props.location.state && this.props.location.state.opportunity,
            list: [],
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Settlement Records');
        Document.updateDocumentHead({title: 'PropCrowdy - Settlement List'});
        this.fetchList();
    }


    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }

            Api.fetch('referral.settlement.list', undefined, {
                offset: update ? 0 : this.state.offset,
                limit: update ? Math.max(this.state.list.length, this.state.limit) : this.state.limit
            }).then(({status, responseJSON}) => {
                if(status === 200) {
                    this.setState({
                        list: [...(update ? [] : this.state.list), ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: (update ? 0 : this.state.offset) + (responseJSON.data || []).length
                    });
                }
                this.setState({fetching: false});
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }
    render() {
        return (
            <main id="main" className="opportunity fund-history">
                <div className="container">
                    <div className="box">
                        <div className="heading">Referral Settlements</div>
                        <div className="sub-heading">
                            <Link to={{pathname: '/referrals'}} className="link">Referrals</Link>
                        </div>
                        <div className="list">
                            <div className="investments settlement">
                                <div className="head">
                                    <div className="opportunity name">Name</div>
                                    <div className="date amount">Amount</div>
                                    <div className="date status">Status</div>
                                    <div className="date paid">Paid</div>
                                    <div className="actions"></div>
                                </div>
                                {this.state.list.map((settlement, index) => (
                                    <div key={index} className="investment">
                                        <div className="opportunity">
                                            <div className="title"> <span className="label">Name: </span>{settlement.customer.last_name+' '+settlement.customer.first_name}</div>
                                        </div>
                                        <div className="date" style={{textTransform:'capitalize'}}> <span className="labelx">Amount: N{settlement.amount}</span></div>
                                        <div className="amount">
                                            <span className="label">Status: </span>
                                            {settlement.status == 1?'Active': ''}
                                            {settlement.status == 2?'Paid': ''}
                                            {settlement.status == 3?'Withdrawn to wallet': ''}
                                            {settlement.status == 4?'Cancel': ''}
                                        </div>
                                        <div className="amount">
                                            <span className="label">Paid: </span>
                                            {settlement.paid == 1?'Paid': 'Not Paid'}
                                        </div>
                                        <div className="actions">

                                        </div>
                                    </div>
                                ))}
                            </div>
                            {!this.state.fetching && !this.state.list.length && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="message">No result</span>
                                </div>
                            )}
                            <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                                {this.state.fetching && <Loading />}
                            </div>
                            {!this.state.fetching && this.state.list.length < this.state.total && (
                                <div className="list-load-more">
                                    <button onClick={() => {this.fetchList()}}>Load More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
