import React from "react";
import "../../assets/stylings/accessories_stylings/PageRoutesBar_styling.css";

const PageRoutesBar = () => {
  return (
    <div className="page_routes_bar_container">
      <div className="main_bar">
        Home {">"} Deal Room {">"} 2 Bedroom Serviced Apartments Bolaji
        Apartments
      </div>
    </div>
  );
};

export default PageRoutesBar;
