import React from 'react';

const context = {
    updateContext: (key, value, callback) => {
        let _context = localStorage.getItem('context');
        try {
            _context = JSON.parse(_context);
            if(!_context) {
                _context = {};
            }
        } catch (e) {
            _context = {};
        }
        context[key] = value;
        _context[key] = value;
        localStorage.setItem('context', JSON.stringify(_context));
        if(typeof callback === 'function') {
            callback.apply();
        }
    }
}
const Context = React.createContext(context);

export {Context, context}