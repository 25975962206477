import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Link } from "react-router-dom";
import Loading from "./../../../App/Components/Templates/Loading";
import Document from "./../../../App/Components/Utils/Document";
import Api from "./../../../App/Components/Utils/Api";
import UI from "./../../../App/Components/Utils/UI";
import User from "./../Utils";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    Login.contextType = Context;
    this.state = {
      redirectPathname:
        (this.props.location.state &&
          this.props.location.state.redirectPathname) ||
        "/dashboard",
      redirectSearch:
        (this.props.location.state &&
          this.props.location.state.redirectSearch) ||
        "",
      requested: false,
      showPassword: false,
      email: "",
      password: "",
      forgot_password: false,
      message:
        (this.props.location.state && this.props.location.state.message) || "",
      error: {
        email: [],
        password: [],
      },
      submitting: false,
    };
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      this.context.layout === "defaultPictured" ||
        this.context.layout === "defaultPictured"
        ? "defaultPictured"
        : "defaultPictured"
    );
    Document.updateDocumentHead({ title: "PropCrowdy - Login" });
    if (this.context.user) {
      this.props.history.replace(
        this.context.user.platform === "propbuy" ? "/buy" : "/"
      );
    } else if (
      this.state.redirectPathname === "/dashboard" &&
      (this.context.layout === "default.buy" ||
        this.context.layout === "default.buy.split")
    ) {
      this.setState({ redirectPathname: "/property/my-list" });
    }
  }

  facebookLogin(result) {
    if (result.status !== "unknown") {
      this.socialLogin({
        firstName: result.name.split(" ")[0],
        lastName: result.name.split(" ")[1],
        email: result.email ? result.email : result.id + "@facebook.com",
        username: result.username ? result.username : "facebook_" + result.id,
        image: result.picture.data ? result.picture.data.url : "",
        gender: result.gender,
        id: result.id,
        type: "facebook",
        token: result.accessToken,
      });
    }
  }

  googleLogin(response) {
    if (response.error !== "popup_closed_by_user") {
      this.socialLogin({
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email
          ? response.profileObj.email
          : response.profileObj.googleId + "@google.com",
        username: response.profileObj.username
          ? response.profileObj.username
          : "google_" + response.profileObj.googleId,
        image: response.profileObj.imageUrl,
        gender: response.profileObj.gender,
        id: response.profileObj.googleId,
        type: "google",
        token: response.accessToken,
      });
    }
  }

  socialLogin(user) {
    this.setState({ submitting: true, message: "" });
    Api.fetch("user.auth.social", undefined, {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      username: user.username,
      phone_number: user.phoneNumber,
      gender: user.gender,
      password: user.password,
      password_confirmation: user.passwordConfirmation,
      customer_type: user.customerType,
      image: user.image,
      id: user.id,
      type: user.type,
      token: user.token,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          let user = responseJSON.data.user;
          if (user.phone_number && user.customer_type) {
            user["token"] = responseJSON.data.token;
            user["platform"] =
              this.context.layout === "default.buy" ||
              this.context.layout === "default.buy.split"
                ? "propbuy"
                : "propcrowdy";
            User.login(user, this.context);
            this.props.history.push({
              pathname: this.state.redirectPathname,
              search: this.state.redirectSearch,
            });
          } else {
            this.context.updateContext("verificationEmail", user.email);
            this.context.updateContext("verificationIsSocial", true);
            this.props.history.push({
              pathname: "/verify",
              search: "?requested=1",
              state: {
                redirectPathname: this.state.redirectPathname,
                search: this.state.redirectSearch,
              },
            });
          }
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          let errors = [];
          let error = responseJSON.error || {};
          for (let i in error) {
            if (error.hasOwnProperty(i)) {
              errors = [...errors, ...error[i]];
            }
          }
          UI.alert(
            {
              type: "error",
              title: "Error Occurred",
              subTitle: "Could not authenticate with " + user.type,
              content: errors.join("<br />"),
            },
            this.context
          );
        } else {
          // console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  submit() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (
      typeof this.state.email !== "string" ||
      this.state.email.trim() === ""
    ) {
      error.email.push("Please provide the email");
    }

    if (
      typeof this.state.password !== "string" ||
      this.state.password.trim() === ""
    ) {
      error.password.push("Please provide the password token");
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }

    this.setState({ submitting: true });
    Api.fetch("user.auth.login", undefined, {
      email: this.state.email,
      password: this.state.password,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ submitting: false });
        if (status === 200 && responseJSON.status) {
          let user = responseJSON.data.user;
          user["token"] = responseJSON.data.token;
          user["platform"] =
            this.context.layout === "default.buy" ||
            this.context.layout === "default.buy.split"
              ? "propbuy"
              : "propcrowdy";
          User.login(user, this.context);
          this.props.history.push({
            pathname: this.state.redirectPathname,
            search: this.state.redirectSearch,
          });
        } else {
          this.setState({ message: responseJSON.message || "" });
        }
      })
      .catch((reason) => {
        this.setState({ submitting: false });
        let { status, responseJSON } = typeof reason === "object" ? reason : {};
        if ([403, 422].indexOf(status) !== -1) {
          if (responseJSON.need_verification) {
            this.context.updateContext(
              "verificationEmail",
              responseJSON.data.user.email
            );
            this.context.updateContext("verificationIsSocial", false);
            this.props.history.push({
              pathname: "/verify",
              search: "?requested=1",
              state: {
                redirectPathname: this.state.redirectPathname,
                search: this.state.redirectSearch,
              },
            });
          } else {
            this.setState({
              error: { ...error, ...responseJSON.error },
              message: responseJSON.message || "",
            });
          }
        } else {
          // console.debug(reason);
          this.setState({
            message:
              (responseJSON && responseJSON.message) ||
              "Error Occurred! Please check the internet and try again",
          });
        }
      });
  }

  render() {
    return (
      <>
        {/* <main id="main" className="auth login">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 split-left">
                <div className="content">
                  <div className="heading">Welcome Back</div>
                  <div className="sub-heading">Login to your Account</div>
                  <img
                    src="/assets/images/vectors/desktop.svg"
                    alt="Security"
                    className="image"
                  />
                </div>
              </div>
              <div className="col-xl-6 split-right">
                <form
                  className="auth login"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submit();
                  }}
                >
                  {this.state.submitting && <Loading />}
                  <img
                    src="/assets/images/vectors/icons/lock-2.svg"
                    alt="Key"
                    className="icon"
                  />
                  <div className="message">{this.state.message}</div>
                  <div className="row">
                    <div className="col-xl-12">
                      <label htmlFor="login-email" className="stacked">
                        Email Address
                      </label>
                      <input
                        id="login-email"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        autoComplete="username"
                      />
                      <img
                        src="/assets/images/icons/at.png"
                        alt="At"
                        className="icon"
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.email.join("<br />"),
                        }}
                      />
                    </div>
                    <div className="col-xl-12">
                      <label htmlFor="login-password" className="stacked">
                        Password
                      </label>
                      <input
                        id="login-password"
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                        autoComplete="current-password"
                      />
                      <img
                        src={
                          this.state.showPassword
                            ? "/assets/images/icons/eye-opened.png"
                            : "/assets/images/icons/eye-closed.png"
                        }
                        alt="Eye"
                        className="icon"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                      />
                      <div
                        className="error"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error.password.join("<br />"),
                        }}
                      />
                    </div>
                  </div>
                  <div className="submit">
                    <button type="submit">Access Account</button>
                  </div>
                </form>
                <div className="bottom">
                  <div className="social-logins">
                    <FacebookLogin
                      appId={
                        this.context.settings && this.context.settings.facebook
                          ? this.context.settings.facebook.app_id
                          : "1920986124792619"
                      }
                      fields="name,email,picture"
                      callback={(result) => {
                        this.facebookLogin(result);
                      }}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="facebook"
                        >
                          <img
                            src="/assets/images/icons/facebook-light.png"
                            alt="Facebook"
                            className="icon"
                          />
                          <span className="text">Sign up with Facebook</span>
                        </button>
                      )}
                    />
                    <GoogleLogin
                      clientId={
                        this.context.settings && this.context.settings.google
                          ? this.context.settings.google.client_id
                          : "685037057672-4n9bec8k6he23uacrcgeaf0eflhn9aj6.apps.googleusercontent.com"
                      }
                      onSuccess={(response) => {
                        this.googleLogin(response);
                      }}
                      onFailure={(e) => {
                        console.debug(e);
                      }}
                      cookiePolicy={"single_host_origin"}
                      render={(renderProps) => (
                        <button
                          onClick={() => {
                            renderProps.onClick();
                          }}
                          className="google"
                        >
                          <img
                            src="/assets/images/icons/google-light.png"
                            alt="Google"
                            className="icon"
                          />
                          <span className="text">Sign up with Google</span>
                        </button>
                      )}
                    />
                  </div>
                  <div className="info">
                    <Link
                      to={{
                        pathname: "/forgot",
                        state: {
                          redirectPathname: this.state.redirectPathname,
                          search: this.state.redirectSearch,
                        },
                      }}
                      className="link"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="info divider-top">
                    Don't have an account?{" "}
                    <Link
                      to={{
                        pathname: "/register",
                        state: {
                          redirectPathname: this.state.redirectPathname,
                          search: this.state.redirectSearch,
                        },
                      }}
                      className="link"
                    >
                      Create Account
                    </Link>
                  </div>
                  <div className="info">
                    By clicking on Access Account, you agree to our{" "}
                    <Link
                      to={{ pathname: "/terms" }}
                      target="_blank"
                      className="link"
                    >
                      terms and conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={{ pathname: "/privacy" }}
                      target="_blank"
                      className="link"
                    >
                      privacy policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main> */}

        <section className="register_page">
          <main className="register_main">
            <p className="red_top_text">We’re Glad to have you here!</p>
            <h2 className="main_title">Enter Your Account</h2>
            <p className="sub_title_text">
              Now You can invest with confidence, knowing that your investment
              is safe.
            </p>
            <form
              className="form_main"
              onSubmit={(e) => {
                e.preventDefault();
                this.submit();
              }}
            >
              {this.state.submitting && <Loading />}
              <div className="input_container">
                <label htmlFor="register-email">Email</label>
                <input
                  id="register-email"
                  type="email"
                  placeholder="Email"
                  className="generic_input"
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  autoComplete="email"
                />
                {/* <img
                  src="/assets/images/icons/at.png"
                  alt="At"
                  className="icon"
                /> */}
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.email.join("<br />"),
                  }}
                />
              </div>

              <div className="input_container">
                <div style={{ position: "relative" }}>
                  <label htmlFor="register-password">Password</label>
                  <input
                    id="register-password"
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="generic_input"
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    autoComplete="new-password"
                  />
                  <img
                    src={
                      this.state.showPassword
                        ? "/assets/images/icons/eye_opened_icon.svg"
                        : "/assets/images/icons/eye_closed_icon.svg"
                    }
                    alt="Eye"
                    className="icon"
                    onClick={() => {
                      this.setState({
                        showPassword: !this.state.showPassword,
                      });
                    }}
                  />
                </div>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.password.join("<br />"),
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "16px",
                  marginLeft: "5px",
                  marginBottom: "40px",
                }}
              >
                {/* <input
                  id="dev_checkbox"
                  type="checkbox"
                  checked={this.state.forgot_password === true}
                  onChange={() => {
                    this.setState((prev) => ({
                      ...prev,
                      forgot_password: !this.state.forgot_password,
                    }));
                  }}
                  // size={20}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  className=""
                /> */}
                <div className="message">{this.state.message}</div>
                <a
                  href="/forgot"
                  // htmlFor="dev_checkbox"
                  style={{
                    position: "static",
                    margin: "0px",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Forgot Password?
                </a>
              </div>

              <button
                className="generic_button"
                type="submit"
                style={{ width: "100%" }}
              >
                Login
              </button>
            </form>
            <div className="already_have_an_account">
              <p>Don't have an account?</p>{" "}
              <Link
                to={{
                  pathname: "/register",
                  state: {
                    redirectPathname: this.state.redirectPathname,
                    search: this.state.redirectSearch,
                  },
                }}
                className="goto_other_signing"
              >
                Create an account today
              </Link>
            </div>

            <div className="agree_to_our_terms">
              By clicking on Access Account,
              <br /> you agree to our{" "}
              <Link
                to={{ pathname: "/terms" }}
                target="_blank"
                className="link"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link
                to={{ pathname: "/privacy" }}
                target="_blank"
                className="link"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                privacy policy
              </Link>
            </div>
          </main>
        </section>
      </>
    );
  }
}
