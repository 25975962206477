import "../../assets/stylings/accessories_stylings/ContactUsBanner_styling.css";

import midBoxes from "../../assets/images/contactUsBanner/mid_distance_linked_boxes.svg";
import rightBoxes from "../../assets/images/contactUsBanner/extreme_right_linked_boxes.svg";

const ContactUsBanner = () => {
  return (
    <div className="contact_us_banner_container">
      <div className="texts">
        <h2 className="main_text">
          Get all the support you need, Contact our online agent{" "}
        </h2>
        <p className="sub_text">
          Get in touch or create account. We are 24/7 available
        </p>
      </div>
      
      <button><a href="mailto:info@propcrowdy.com">Contact Us</a></button>

      <img src={midBoxes} alt="" className="mid_box" />
      <img src={rightBoxes} alt="" className="right_box" />
    </div>
  );
};

export default ContactUsBanner; 
