import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import _ from 'lodash';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';
import $ from "jquery";
import {Link} from "react-router-dom";

export default class ReferralList extends React.Component {
    constructor(props) {
        super(props);
        ReferralList.contextType = Context;
        this.state = {
            opportunity: this.props.location.state && this.props.location.state.opportunity,
            list: [],
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Referral Records');
        Document.updateDocumentHead({title: 'PropCrowdy - Referral List'});
        this.fetchList();
    }


    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }

            Api.fetch('referral.list', undefined, {
                offset: update ? 0 : this.state.offset,
                limit: update ? Math.max(this.state.list.length, this.state.limit) : this.state.limit
            }).then(({status, responseJSON}) => {
                if(status === 200) {
                    this.setState({
                        list: [...(update ? [] : this.state.list), ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: (update ? 0 : this.state.offset) + (responseJSON.data || []).length
                    });
                }
                this.setState({fetching: false});
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }
    render() {
        return (
            <main id="main" className="opportunity fund-history">
                <div className="container">
                    <div className="box">
                        <div className="heading">Referrals</div>
                        <div className="sub-heading">
                            <Link to={{pathname: '/referral-settlements'}} className="link">view Referral Settlements</Link>
                        </div>
                        <div className="list">
                            <div className="investments">
                                <div className="head">
                                    <div className="opportunity">Name</div>
                                    <div className="date">Customer Type</div>
                                    <div className="amount">Date Registered</div>
                                    <div className="actions" />
                                </div>
                                {this.state.list.map((user, index) => (
                                    <div key={index} className="investment">
                                        <div className="opportunity">
                                            <div className="title"> <span className="label">Name: </span>{user.last_name+' '+user.first_name}</div>
                                        </div>
                                        <div className="date" style={{textTransform:'capitalize'}}><span className="labxel">type: {user.customer_type}</span></div>
                                        <div className="amount">
                                            <span className="label">Date: </span>
                                            {Time.timeToFriendly(user.created_at, 1)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {!this.state.fetching && !this.state.list.length && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="message">No result</span>
                                </div>
                            )}
                            <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                                {this.state.fetching && <Loading />}
                            </div>
                            {!this.state.fetching && this.state.list.length < this.state.total && (
                                <div className="list-load-more">
                                    <button onClick={() => {this.fetchList()}}>Load More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
