import "./../../../assets/style.css";
import React from "react";
import { Context } from "./../../../../App/Components/Context";
import Loading from "./../../../../App/Components/Templates/Loading";
import _ from "lodash";
import $ from "jquery";
import Api from "./../../../../App/Components/Utils/Api";
import UI from "./../../../../App/Components/Utils/UI";
import Hook from "./../../Utils/Hook";

export default class PaymentProof extends React.Component {
  constructor(props) {
    super(props);
    PaymentProof.contextType = Context;
    this.photoFileInput = null;
    this.state = {
      loading: false,
      name: "",
      amount: "",
      file: "",
      fileDisplayName: "",
      fileUploadProgress: 0,
      fileUrl: "",
      error: {
        name: [],
        amount: [],
        file: [],
        proof_of_payment: [],
      },
    };
  }

  closePaymentProofModal() {
    this.context.updateContext("paymentProofModalType", undefined);
    this.context.updateContext("paymentProofModalTypeId", undefined);
    this.context.updateContext("paymentProofModalScheduleId", null);
    this.context.updateContext("paymentProofModalTypeTitle", undefined);
    this.context.updateContext("paymentProofModalTitle", undefined);
    this.context.updateContext("paymentProofModalAmount", undefined);
    this.context.updateContext("paymentProofModalData", undefined);
    this.context.updateContext("paymentProofModalMethods", undefined);
    $("#payment-proof-modal").modal("hide");
  }

  uploadFile(fileHandle, key, callback, type) {
    type = type || "image";
    let setKey = (value) => {
      let state = this.state;
      _.set(state, key, value);
      this.setState(state, () => {
        if (typeof callback === "function") {
          callback.apply();
        }
      });
    };
    let formData = new FormData();
    if (fileHandle.files.length) {
      fileHandle.disabled = true;
      formData.append("file", fileHandle.files[0]);
      Api.fetch("file.upload", undefined, formData, undefined, (progress) => {
        this.setState({
          [key + "UploadProgress"]: progress,
          [key + "DisplayName"]:
            "Uploading... (" + Math.ceil(progress * 100) + "%)",
        });
      })
        .then(({ status, responseJSON }) => {
          fileHandle.disabled = false;
          if (status === 200) {
            this.setState(
              {
                [key + "DisplayName"]: responseJSON.original_name,
                [key + "UploadProgress"]: 0,
              },
              () => {
                setKey(responseJSON.name, () => {
                  this.validateSteps([this.state.step])
                    .then(() => {})
                    .catch(() => {});
                });
              }
            );
          } else {
            let error = {};
            if (responseJSON.error && responseJSON.error.file) {
              this.setState({ message: responseJSON.error.file.join(", ") });
              error[
                key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
              ] = responseJSON.error["file"];
            }
            this.setState({
              [key + "DisplayName"]: "",
              [key + "UploadProgress"]: 0,
              [key + "Url"]:
                type === "video" ? "" : "/assets/images/image-placeholder.png",
              message: responseJSON.message || "",
              error: { ...this.state.error, ...error },
            });
            for (let i in error) {
              if (error.hasOwnProperty(i)) {
                if (error[i].length) {
                  if (
                    [
                      "building_survey_plan",
                      "architectural_drawing",
                      "tax_record",
                      "certificate_of_occupancy",
                      "original_purchase_receipt",
                      "homeowners_insurance",
                      "transfer_of_ownership",
                    ].indexOf(i) !== -1
                  ) {
                    this.setState({ fileTab: "document" });
                  } else if (i === "video") {
                    this.setState({ fileTab: "video" });
                  } else if (i.split(".")[0] === "property_images") {
                    this.setState({ fileTab: "image" });
                  }
                  break;
                }
              }
            }
          }
        })
        .catch((reason) => {
          this.setState({
            [key + "DisplayName"]: "",
            [key + "UploadProgress"]: 0,
            [key + "Url"]:
              type === "video" ? "" : "/assets/images/image-placeholder.png",
          });
          fileHandle.disabled = false;
          let { status, responseJSON } =
            typeof reason === "object" ? reason : {};
          if ([403, 422].indexOf(status) !== -1) {
            let error = {};
            if (responseJSON.error && responseJSON.error.file) {
              this.setState({ message: responseJSON.error.file.join(", ") });
              error[
                key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
              ] = responseJSON.error["file"];
            }
            this.setState({
              error: { ...this.state.error, ...error },
              message: responseJSON.message || "",
            });
            for (let i in error) {
              if (error.hasOwnProperty(i)) {
                if (error[i].length) {
                  if (
                    [
                      "building_survey_plan",
                      "architectural_drawing",
                      "tax_record",
                      "certificate_of_occupancy",
                      "original_purchase_receipt",
                      "homeowners_insurance",
                      "transfer_of_ownership",
                    ].indexOf(i) !== -1
                  ) {
                    this.setState({ fileTab: "document" });
                  } else if (i === "video") {
                    this.setState({ fileTab: "video" });
                  } else if (i.split(".")[0] === "property_images") {
                    this.setState({ fileTab: "image" });
                  }
                  break;
                }
              }
            }
          } else {
            console.debug(reason);
            this.setState({
              message:
                (responseJSON && responseJSON.message) ||
                "Error Occurred! Please check the internet and try again",
            });
          }
        });
    } else {
      this.setState({ [key + "DisplayName"]: "", [key + "Url"]: "" }, () => {
        setKey("");
      });
    }
  }

  submit() {
    let type, typeId, data;
    try {
      type = this.context.paymentProofModalType;
      typeId = this.context.paymentProofModalTypeId;
      if (
        this.context.paymentProofModalData &&
        this.context.paymentProofModalData.constructor.name === "Array"
      ) {
        data = [...this.context.paymentProofModalData];
      } else if (
        this.context.paymentProofModalData &&
        this.context.paymentProofModalData.constructor.name === "Object"
      ) {
        data = { ...this.context.paymentProofModalData };
      } else {
        data = this.context.paymentProofModalData;
      }
    } catch (e) {
      // console.log(e);
    }

    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });

    if (
      !this.context.user &&
      (typeof this.state.name !== "string" || this.state.name.trim() === "")
    ) {
      error.name.push("Please provide your name");
    }

    if (!this.context.paymentProofModalAmount && !parseInt(this.state.amount)) {
      error.amount.push("Please enter the amount");
    }

    if (typeof this.state.file !== "string" || this.state.file.trim() === "") {
      error.file.push("Please upload the proof of payment");
    }

    this.setState({ error: error });

    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        if (error[i].length) {
          return false;
        }
      }
    }
    this.setState({ loading: true });
    Api.fetch("bank.pay", undefined, {
      name: this.context.user
        ? this.context.user.first_name + " " + this.context.user.last_name
        : this.state.name,
      amount: parseInt(
        this.context.paymentProofModalAmount || this.state.amount
      ),
      reference:
        "PC-" +
        this.context.paymentProofModalType +
        "-" +
        this.context.paymentProofModalTypeId +
        "-" +
        (this.context.user ? this.context.user.id : 0) +
        "-" +
        new Date().getTime(),
      type: this.context.paymentProofModalType,
      type_id: this.context.paymentProofModalTypeId,
      schedule_id: this.context.paymentProofModalScheduleId,
      user_id: this.context.user ? this.context.user.id : 0,
      customer_id: this.context.user ? this.context.user.id : 0,
      proof_of_payment: this.state.file,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ loading: false });
        if (status === 200) {
          if (responseJSON.status) {
            Api.fetch("payment.callback.bank", undefined, {
              reference:
                "PC-" +
                this.context.paymentProofModalType +
                "-" +
                this.context.paymentProofModalTypeId +
                "-" +
                (this.context.user ? this.context.user.id : 0) +
                "-" +
                new Date().getTime(),
              type: this.context.paymentProofModalType,
              type_id: this.context.paymentProofModalTypeId,
              schedule_id: this.context.paymentProofModalScheduleId,
              user_id: this.context.user ? this.context.user.id : 0,
              proof_of_payment: this.state.file,
            })
              .then(() => {
                Hook.fire("payment", undefined, [type, typeId, data]);
              })
              .catch((reason) => {
                Hook.fire("payment", undefined, [type, typeId, data]);
                console.debug(reason);
              });
            this.closePaymentProofModal();
            UI.alert(
              {
                title: "Proof of Payment sent",
                content:
                  "Your proof of payment has been sent successfully. You will be notified when the review is complete.",
              },
              this.context
            );
          } else {
            UI.alert(
              {
                type: "error",
                title: "Payment upload not successful",
                content: responseJSON.message,
              },
              this.context
            );
          }
        }
      })
      .catch((reason) => {
        this.setState({ loading: false });
        let { responseJSON } = typeof reason === "object" ? reason : {};
        UI.alert(
          {
            type: "error",
            title: "Error Occurred",
            content:
              (responseJSON && responseJSON.message) ||
              "Payment upload not successful! Please check the internet and try again",
          },
          this.context
        );
        console.debug(reason);
      });
  }

  render() {
    return (
      <div
        id="payment-proof-modal"
        className="modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="payment-modal-title"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            {this.state.loading && <Loading />}
            <button
              type="button"
              className="close-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="heading">
              {this.context.paymentProofModalTypeTitle}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="info">
                  <div className="title">WIRE PAYMENT</div>
                  <div className="description">Bank Details</div>
                  <div className="details">
                    <div className="detail">
                      <div className="title">Bank Name</div>
                      <div className="value">
                        {this.context.settings &&
                          this.context.settings.bank_details &&
                          this.context.settings.bank_details.bank_name}
                      </div>
                    </div>
                    <div className="detail">
                      <div className="title">Bank Account Name</div>
                      <div className="value">
                        {this.context.settings &&
                          this.context.settings.bank_details &&
                          this.context.settings.bank_details.bank_account_name}
                      </div>
                    </div>
                    <div className="detail">
                      <div className="title">Bank Account Number</div>
                      <div className="value">
                        {this.context.settings &&
                          this.context.settings.bank_details &&
                          this.context.settings.bank_details
                            .bank_account_number}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submit();
                  }}
                >
                  <div className="title">Upload Proof Of Payment</div>
                  <div className="name">
                    <input
                      type="text"
                      name="name"
                      value={
                        this.context.user
                          ? this.context.user.first_name +
                            " " +
                            this.context.user.last_name
                          : ""
                      }
                      disabled={!!this.context.user}
                      placeholder="Name of Depositor"
                      className="form-control input"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      required
                    />
                    <div
                      className="error"
                      dangerouslySetInnerHTML={{
                        __html: this.state.error.name.join("<br />"),
                      }}
                    />
                  </div>
                  <div className="amount">
                    <input
                      type="number"
                      name="amount"
                      value={
                        this.context.paymentProofModalAmount ||
                        this.state.amount
                      }
                      disabled={
                        !!parseInt(this.context.paymentProofModalAmount)
                      }
                      min="10"
                      step=".01"
                      placeholder="Amount Deposited"
                      className="form-control input"
                      onChange={(e) => {
                        this.setState({ amount: e.target.value });
                      }}
                      required
                    />
                    <i className="icon las la-naira primary" />
                    <div
                      className="error"
                      dangerouslySetInnerHTML={{
                        __html: this.state.error.amount.join("<br />"),
                      }}
                    />
                  </div>
                  <div className="file">
                    <input
                      id="file"
                      type="text"
                      name="file_display_name"
                      value={this.state.fileDisplayName}
                      placeholder="Upload Proof Of Payment"
                      className="form-control"
                      disabled
                      onChange={(e) => {
                        this.setState({ fileDisplayName: e.target.value });
                      }}
                    />
                    <input type="hidden" name="file" value={this.state.file} />
                    <input
                      ref={(fileFileInput) => {
                        this.fileFileInput = fileFileInput;
                      }}
                      type="file"
                      name="file_file"
                      className="display-none"
                      onChange={(e) => {
                        this.uploadFile(e.target, "file");
                      }}
                    />
                    <button
                      type="button"
                      className="upload-button upload"
                      style={{
                        boxShadow:
                          (this.state.file
                            ? this.state.isSmallScreen
                              ? 110
                              : 200
                            : Math.ceil(
                                this.state.fileUploadProgress *
                                  (this.state.isSmallScreen ? 110 : 200)
                              )) + "px 0 rgba(0, 0, 0, .5) inset",
                      }}
                      onClick={() => {
                        this.fileFileInput.click();
                      }}
                    >
                      Choose File
                    </button>
                    <div
                      className="error"
                      dangerouslySetInnerHTML={{
                        __html: this.state.error.file.join("<br />"),
                      }}
                    />
                  </div>
                  <div className="message">{this.state.message}</div>
                  <div className="submit">
                    <input type="submit" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
