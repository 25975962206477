import { useState, useRef } from "react";
import "../../assets/stylings/accessories_stylings/SpecificPropertyContainerLayout_styling.css";
import { Rating } from "react-simple-star-rating";

import avatarIcon from "../../assets/images/avatar_icon.svg";
import propertyImage from "../../../User/assets/images/property_image.png";

import rectangles from "../../assets/images/minor_card_rectangles.svg";
import prevArrowCircled from "../../assets/images/prev_arrow.svg";
import nextArrowCircled from "../../assets/images/next_arrow.svg";

import { ProgressBar } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ContactUsBanner from "./ContactUsBanner";
import ProgressBarCustom from "./ProgressBarCustom";

const SpecificPropertyContainerLayout = () => {
  const [amountRaised, setAmountRaised] = useState(1132550);

  const [amountGoal, setAmountGoal] = useState(100000000);

  const [progress, setProgress] = useState((amountRaised / amountGoal) * 100);

  const [oneStarRating, setOneStarRating] = useState(1);
  const [twoStarsRating, setTwoStarsRating] = useState(2);
  const [threeStarsRating, setThreeStarsRating] = useState(4);
  const [fourStarsRating, setFourStarsRating] = useState(14);
  const [fiveStarsRating, setFiveStarsRating] = useState(79);
  const [totalRaters, setTotalRaters] = useState(
    oneStarRating +
      twoStarsRating +
      threeStarsRating +
      fourStarsRating +
      fiveStarsRating
  );
  let oneStarPercentage = (oneStarRating / totalRaters) * 100;
  let twoStarsPercentage = (twoStarsRating / totalRaters) * 100;
  let threeStarsPercentage = (threeStarsRating / totalRaters) * 100;
  let fourStarsPercentage = (fourStarsRating / totalRaters) * 100;
  let fiveStarsPercentage = (fiveStarsRating / totalRaters) * 100;

  const [displayPage, setDisplayPage] = useState(0);

  // Catch Rating value
  // const handleRating = (rate) => {
  //   setRating(rate);
  // };

  // const handleReset = () => {
  // Set the initial value
  // setRating(0);
  // };

  // Optinal callback functions
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value, index) => console.log(value, index);

  // console.log(progress);

  const gotoNext = () => {
    sliderRef?.current?.slickNext();
  };
  const gotoPrev = () => {
    sliderRef?.current?.slickPrev();
  };

  const PrevArrow = () => {
    return (
      <div
        onClick={gotoPrev}
        className="arrow_container prev"
        style={{ display: "block" }}
      >
        <img src={prevArrowCircled} alt="previous arrow" />
      </div>
    );
  };
  const NextArrow = () => {
    return (
      <div
        onClick={gotoNext}
        className="arrow_container next"
        style={{ display: "block" }}
      >
        <img src={nextArrowCircled} alt="previous arrow" />
      </div>
    );
  };

  const sliderRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoPlay: true,
    autoPlaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    appendDots: (dots) => (
      <div
        style={{
          // backgroundColor: "#ddd",
          // borderRadius: "10px",
          padding: "0px",
          marginTop: "20px",
          height: "111px",
          bottom: "-127px",
        }}
      >
        <ul className="dot_images_container">{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="small_property_image_container">
        <img
          src={propertyImage}
          alt="property"
          className="small_property_image"
        />
      </div>
    ),
  };

  // const [activate, setActivate] = useState(false);

  return (
    <div className="specific_property_main_container_layout">
      <section className="main_first_section">
        <div className="left_part">
          <div className="texts">
            <h5 className="intro_title">Hazrat Concept</h5>
            <h2 className="main_title">2 Bedroom Serviced Apartments</h2>
          </div>
          <div className="others">
            <div className="images_slides">
              <div className="big_image_container">
                <Slider ref={sliderRef} {...settings}>
                  {/* <div style={{ maxWidth: "828px", height: "398px" }}> */}
                  <img
                    src={propertyImage}
                    alt="property"
                    className="big_property_image"
                  />
                  <img
                    src={propertyImage}
                    alt="property"
                    className="big_property_image"
                  />
                  <img
                    src={propertyImage}
                    alt="property"
                    className="big_property_image"
                  />
                  <img
                    src={propertyImage}
                    alt="property"
                    className="big_property_image"
                  />
                  <img
                    src={propertyImage}
                    alt="property"
                    className="big_property_image"
                  />
                  <img
                    src={propertyImage}
                    alt="property"
                    className="big_property_image"
                  />
                  {/* </div> */}
                </Slider>
              </div>
              {/* <div className="small_images_listing">
                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                  return (
                    <img
                      key={index}
                      src={propertyImage}
                      alt="property"
                      className="small_property_image"
                    />
                  );
                })}
              </div> */}
            </div>
            {/* <div className="minor_cards_container">
              <div style={{ background: "#E4FAFF" }} className="minor_card">
                <div className="card_texts">
                  <p className="main_text">Property Developer</p>
                  <p className="sub_text">Information</p>
                </div>
                <button className="card_button">Visit Developer Page</button>

                <img src={rectangles} alt="" className="rectangles" />
              </div>
              <div style={{ background: "#DEE1E2" }} className="minor_card">
                <div className="card_texts">
                  <p className="main_text">Enter Deal Room</p>
                  <p className="sub_text">See All Supporting Documents</p>
                </div>
                <Link to={"/deal-room"}>
                  <button className="card_button">Proceed To Deal Room</button>
                </Link>

                <img src={rectangles} alt="" className="rectangles" />
              </div>
            </div> */}
          </div>
        </div>
        <div className="right_part">
          <div className="info_card_display">
            <div className="top_title">Open</div>
            <div className="info_card_content">
              <div className="raised_amount_display">
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "4%",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "ApercuProMediumNormal",
                      fontSize: "36px",
                      lineHeight: "56px",
                      letterSpacing: "-0.03em",
                    }}
                  >
                    ₦{amountRaised?.toString()}
                  </div>
                  <p
                    style={{
                      color: "#ff0000",
                      fontSize: "13px",
                      lineHeight: "16.16px",
                      marginBottom: "10px",
                    }}
                  >
                    Raised
                  </p>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    borderRadius: "4px",
                    backgroundColor: "#00D7A3",
                    padding: "2px",
                    margin: "12px 0px",
                  }}
                >
                  <ProgressBar
                    percent={progress}
                    filledBackground="#fff"
                    height={4}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3%",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: "#ff0000",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      lineHeight: "17.4px",
                      maxWidth: "50px",
                    }}
                  >
                    Goal
                  </p>
                  <div
                    style={{
                      fontFamily: "ApercuProMediumNormal",
                    }}
                  >
                    ₦{amountGoal?.toString()}
                  </div>
                </div>
              </div>
              <ul className="list_of_sponsored">
                {[1, 2, 3].map((_, index) => {
                  return (
                    <li key={index} className="sponsored">
                      <div className="main_part">
                        <img src={avatarIcon} alt="avatar icon" />
                        <div className="texts_part">
                          <p
                            style={{
                              fontFamily: "ApercuProBoldNormal",
                              fontSize: "15px",
                              lineHeight: "20px",
                            }}
                          >
                            Sponsored
                          </p>
                          <p>₦10,000</p>
                        </div>
                      </div>
                      <p
                        style={{
                          fontFamily: "ApercuProMediumNormal",
                          fontSize: "9px",
                          lineHeight: "11px",
                          color: "rgba(0, 0, 0, 0.58",
                        }}
                      >
                        9 months ago
                      </p>
                    </li>
                  );
                })}
              </ul>
              <button
                style={{
                  fontFamily: "ApercuProMediumNormal",
                  color: "#ff0000",
                  fontSize: "13px",
                  lineHeight: "16.16px",
                  padding: "23.5px 0px",
                  background: "none",
                }}
              >
                see more
              </button>
              <div className="buttons">
                <button className="button1">Invest Now</button>
                <button className="button2">Share Project</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SpecificPropertyContainerLayout;
