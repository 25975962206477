import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Context } from "./../../Context";
import Loading from "./../Loading";
import Api from "./../../Utils/Api";
import UI from "./../../Utils/UI";

import whatsappIcon from "../../../assets/images/vectors/icons/whatsapp_icon.svg";
import secIcon from "../../../assets/images/vectors/icons/sec_icon.svg";

export default class Default extends React.Component {
  constructor(props) {
    super(props);
    Default.contextType = Context;
    this.subscribeForm = null;
    this.state = {
      isMobile: window.innerWidth <= 992,
      searchActive: false,
      searchResultActive: false,
      searchTerm: "",
      searching: false,
      searchResultList: [],
      subscribeEmail: "",
      subscribing: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  onResize() {
    this.setState({ isMobile: window.innerWidth <= 992 });
  }

  search() {
    if (this.state.searchTerm.length && !this.state.searching) {
      let term = this.state.searchTerm;
      this.setState({ searching: true });
      let id =
        !this.props.htmlClasses || this.props.htmlClasses.indexOf("buy") === -1
          ? "opportunity.list"
          : "property.list";
      Api.fetch(id, undefined, { search: this.state.searchTerm, limit: 5 })
        .then(({ status, responseJSON }) => {
          this.setState({ searching: false }, () => {
            if (
              this.state.searchTerm.length &&
              this.state.searchTerm !== term
            ) {
              this.search();
            }
          });
          if (status === 200) {
            this.setState({ searchResultList: responseJSON.data || [] });
          }
        })
        .catch((reason) => {
          this.setState({ searching: false }, () => {
            if (
              this.state.searchTerm.length &&
              this.state.searchTerm !== term
            ) {
              this.search();
            }
          });
          console.debug(reason);
        });
    }
  }

  subscribe() {
    this.setState({ subscribing: true });
    if (
      typeof this.state.subscribeEmail !== "string" ||
      !this.state.subscribeEmail.length
    ) {
      this.setState({ subscribing: false });
      return UI.alert(
        {
          type: "error",
          title: "No Email",
          content: "Please provide an email",
        },
        this.context
      );
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.subscribeEmail
      )
    ) {
      this.setState({ subscribing: false });
      return UI.alert(
        {
          type: "error",
          title: "Invalid Email",
          content: "Please provide a valid email",
        },
        this.context
      );
    }
    Api.fetch("email.subscribe", undefined, {
      email: this.state.subscribeEmail,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ subscribing: false });
        if (status === 200) {
          this.setState({ subscribeEmail: "" });
          UI.alert(
            {
              title: "Congratulations",
              content:
                "Your email address has been added to our email subscription list.",
            },
            this.context
          );
        }
      })
      .catch((reason) => {
        this.setState({ subscribing: false });
        console.debug(reason);
      });
  }

  header() {
    return (
      <header id="header">
        <div id="header-content">
          <nav className="navbar navbar-expand-lg navbar-light">
            {!this.props.htmlClasses ||
            this.props.htmlClasses.indexOf("buy") === -1 ? (
              <NavLink id="header-logo" to="/" className="navbar-brand">
                <img
                  src="/assets/images/vectors/logo.svg"
                  alt="PropCrowdy Logo"
                />
              </NavLink>
            ) : (
              <NavLink
                id="header-logo"
                to="/buy"
                className="navbar-brand primary"
              >
                <img src="/assets/images/logo-3.png" alt="PropBuy Logo" />
              </NavLink>
            )}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#header-navbar-nav-container"
              aria-controls="header-navbar-nav-container"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              id="header-navbar-nav-container"
              className="collapse navbar-collapse"
            >
              <form
                id="header-search"
                action="/discover"
                method="get"
                className={
                  "form-inline my-2 my-lg-0" +
                  (this.state.searchActive ? " active" : "")
                }
                autoComplete="off"
              >
                <input
                  type="search"
                  name="term"
                  placeholder={
                    !this.props.htmlClasses ||
                    this.props.htmlClasses.indexOf("buy") === -1
                      ? this.state.isMobile
                        ? "Search opportunities"
                        : "Enter a keyword to search opportunities"
                      : this.state.isMobile
                      ? "Search properties"
                      : "Enter a keyword to search properties"
                  }
                  className="form-control"
                  aria-label="Search"
                  onChange={(e) => {
                    this.setState({ searchTerm: e.target.value }, () => {
                      this.search();
                    });
                  }}
                  onFocus={(e) => {
                    this.setState({ searchResultActive: true });
                  }}
                  onBlur={(e) => {
                    setTimeout(() => {
                      this.setState({ searchResultActive: false });
                    }, 500);
                  }}
                  autoComplete="off"
                />
                <button
                  className="icon las la-search search-button"
                  type="submit"
                />
                <button
                  className="icon las la-times close-button"
                  onClick={() => {
                    this.setState({ searchActive: false });
                  }}
                  type="button"
                />
              </form>
              <ul
                className={
                  "navbar-nav m-auto" + (this.state.searchActive ? " fade" : "")
                }
              >
                {!this.props.htmlClasses ||
                this.props.htmlClasses.indexOf("buy") === -1 ? (
                  <>
                    <li className="nav-item">
                      <NavLink to={{ pathname: "/home" }} className="nav-link">
                        <span className="title">Home</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/discover" }}
                        className="nav-link"
                      >
                        <span className="title">Discover</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={{ pathname: "/how" }} className="nav-link">
                        <span className="title">How it Works</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={{ pathname: "/about" }} className="nav-link">
                        <span className="title">About Us</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/carbon" }}
                        className="nav-link"
                      >
                        <span className="title">Go green</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/articles" }}
                        className="nav-link"
                      >
                        <span className="title">Blog</span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <NavLink to={{ pathname: "/buy" }} className="nav-link">
                        <span className="title">Home</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/properties" }}
                        className="nav-link"
                      >
                        <span className="title">Properties</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/developers" }}
                        className="nav-link"
                      >
                        <span className="title">Developers</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/articles" }}
                        className="nav-link"
                      >
                        <span className="title">Blog</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/home" }}
                        className="nav-link primary"
                      >
                        <span className="title">PropCrowdy</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={{ pathname: "/properties/fire" }}
                        className="nav-link"
                      >
                        <img
                          src="/assets/images/vectors/icons/fire.svg"
                          className="icon"
                          alt="Fire"
                        />
                        <span className="title normal">Fire Sales</span>
                      </NavLink>
                    </li>
                  </>
                )}
                <li className="nav-item hide-mobile">
                  <button
                    className="nav-link"
                    onClick={() => {
                      this.setState({ searchActive: true });
                    }}
                  >
                    <img
                      src="/assets/images/vectors/icons/search-1.svg"
                      className="icon search"
                      alt="Search"
                    />
                  </button>
                </li>
                {!this.context.user ? (
                  <>
                    <li className="nav-item auth">
                      <NavLink
                        to={{ pathname: "/login" }}
                        className="nav-link white"
                      >
                        <span className="title">Login</span>
                      </NavLink>
                    </li>
                    <li className="nav-item auth">
                      <NavLink
                        to={{ pathname: "/register" }}
                        className="nav-link black"
                      >
                        <span className="title">Register</span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <NavLink
                      to={{
                        pathname:
                          this.context.layout === "default.buy" ||
                          this.context.layout === "default.buy.split"
                            ? this.context.user.customer_type === "developer"
                              ? "/property/my-list"
                              : "/property/purchases"
                            : "/dashboard",
                      }}
                      className="nav-link white"
                    >
                      <span className="title">Dashboard</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </nav>
          {this.state.searchTerm && (
            <div
              id="header-search-result"
              className={
                "result" + (this.state.searchResultActive ? " active" : "")
              }
            >
              {!this.props.htmlClasses ||
              this.props.htmlClasses.indexOf("buy") === -1 ? (
                <div className="media-list">
                  {this.state.searchResultList.map((opportunity, index) => (
                    <Link
                      key={index}
                      to={{
                        pathname: "/opportunity/" + opportunity.slug,
                        state: { opportunity: opportunity },
                      }}
                      className="item"
                    >
                      <img
                        className="thumb"
                        src={
                          opportunity.media && opportunity.media[0]
                            ? opportunity.media[0].url
                            : "/assets/images/image-placeholder.png"
                        }
                        alt={opportunity.title}
                      />
                      <span className="details">
                        <span className="title">{opportunity.title}</span>
                        <strong className="goal text-primary">
                          Goal: ₦
                          {parseInt(opportunity.amount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </strong>
                        <span className="min-investment text-primary">
                          Minimum Investment: ₦
                          {opportunity.min_investment
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                        <span className="returns">
                          Returns: {opportunity.roi_percentage}% in{" "}
                          {opportunity.tenor} Months
                        </span>
                      </span>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="media-list">
                  {this.state.searchResultList.map((property, index) => (
                    <Link
                      key={index}
                      to={{
                        pathname: "/property/" + property.slug,
                        state: { property: property },
                      }}
                      className="item"
                    >
                      <img
                        className="thumb"
                        src={
                          property.media && property.media[0]
                            ? property.media[0].url
                            : "/assets/images/image-placeholder.png"
                        }
                        alt={property.title}
                      />
                      <span className="details">
                        <span className="title">{property.title}</span>
                        <strong className="price text-primary">
                          Price: ₦
                          {parseInt(property.price)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </strong>
                      </span>
                    </Link>
                  ))}
                </div>
              )}
              {this.state.searching && (
                <div
                  className={
                    "ajax-loading-container" +
                    (this.state.searching ? " active" : "")
                  }
                >
                  {this.state.searching && <Loading />}
                </div>
              )}
              {!this.state.searching && !this.state.searchResultList.length ? (
                <div className="list-empty">
                  <i className="icon las la-info-circle" />
                  <span className="message">No result</span>
                </div>
              ) : (
                <Link
                  to={{
                    pathname:
                      !this.props.htmlClasses ||
                      this.props.htmlClasses.indexOf("buy") === -1
                        ? "/discover"
                        : "/properties",
                    search: "?term=" + this.state.searchTerm,
                  }}
                  className="more"
                >
                  See More
                </Link>
              )}
            </div>
          )}
        </div>
      </header>
    );
  }

  footer() {
    return (
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              {!this.props.htmlClasses ||
              this.props.htmlClasses.indexOf("buy") === -1 ? (
                <img
                  src="/assets/images/vectors/logo-1.svg"
                  alt="PropCrowdy Logo"
                  className="site-logo"
                />
              ) : (
                <img
                  src="/assets/images/logo-4.png"
                  alt="PropCrowdy Logo"
                  className="site-logo"
                />
              )}
              {!this.props.htmlClasses ||
              this.props.htmlClasses.indexOf("buy") === -1 ? (
                <p>
                  PropCrowdy is a crowd investment platform that empowers
                  Nigerians to make smart investments
                </p>
              ) : (
                <p>
                  PropBuy is Nigeria’s Digital Properties Platform for the real
                  estate sector, created to provide affordable housing options
                  to Nigerians and Africans. With PropBuy, you get access to
                  quality and affordable housing that has been vetted by our
                  team.
                </p>
              )}
              <div className="social-links">
                <a
                  href={
                    !this.props.htmlClasses ||
                    this.props.htmlClasses.indexOf("buy") === -1
                      ? "https://www.facebook.com/Propcrowdy-100332148515906"
                      : "https://www.instagram.com/propbuynig"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <i className="icon lab la-facebook-f" />
                </a>
                <a
                  href={
                    !this.props.htmlClasses ||
                    this.props.htmlClasses.indexOf("buy") === -1
                      ? "https://www.twitter.com/propcrowdy1"
                      : "https://www.twitter.com/propbuy1"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <i className="icon lab la-twitter" />
                </a>
                <a
                  href={
                    !this.props.htmlClasses ||
                    this.props.htmlClasses.indexOf("buy") === -1
                      ? "https://www.instagram.com/propcrowdy"
                      : "https://www.instagram.com/propbuynig"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <i className="icon lab la-instagram" />
                </a>
                <a
                  href={
                    !this.props.htmlClasses ||
                    this.props.htmlClasses.indexOf("buy") === -1
                      ? "https://www.linkedin.com/company/propcrowdy"
                      : "https://www.linkedin.com/company/propbuy"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <i className="icon lab la-linkedin-in" />
                </a>
                <a
                  href={
                    !this.props.htmlClasses ||
                    this.props.htmlClasses.indexOf("buy") === -1
                      ? "https://youtube.com/channel/UCNAv100ASAzZidgjrmabO1A/channels"
                      : "https://www.youtube.com/channel/UCZPsK_cnN0PqtYyAls6M__g"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <i className="icon lab la-youtube"></i>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=+2348107321018&text=hi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <i className="icon lab la-whatsapp"></i>
                </a>
              </div>
              <div className="social-links-whatsapp sticky">
                <a
                  href="https://api.whatsapp.com/send?phone=+2348107321018&text=hi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link whatsapp"
                >
                  <img src={whatsappIcon} alt="whatsapp" />
                </a>
              </div>
              <div className="social-links-whatsapp sticky sec">
                {/* <a
                  href="https://api.whatsapp.com/send?phone=+2348107321018&text=hi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link whatsapp"
                > */}
                <img src={secIcon} alt="whatsapp" />
                {/* </a> */}
              </div>
            </div>
            <div className="col-md-2 about">
              <h6>About Us</h6>
              <ul>
                <li>
                  <NavLink
                    to={{
                      pathname:
                        (this.props.htmlClasses &&
                        this.props.htmlClasses.indexOf("buy") !== -1
                          ? "/buy"
                          : "") + "/knowledge",
                    }}
                  >
                    Knowledge Base
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={{
                      pathname:
                        (this.props.htmlClasses &&
                        this.props.htmlClasses.indexOf("buy") !== -1
                          ? "/buy"
                          : "") + "/faq",
                    }}
                  >
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={{
                      pathname:
                        (this.props.htmlClasses &&
                        this.props.htmlClasses.indexOf("buy") !== -1
                          ? "/buy"
                          : "") + "/about",
                    }}
                  >
                    About Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-2 quick-nav">
              <h6>Quick Nav</h6>
              <ul>
                <li>
                  <NavLink
                    to={{
                      pathname:
                        (this.props.htmlClasses &&
                        this.props.htmlClasses.indexOf("buy") !== -1
                          ? "/buy"
                          : "") + "/terms",
                    }}
                  >
                    Terms and Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={{
                      pathname:
                        (this.props.htmlClasses &&
                        this.props.htmlClasses.indexOf("buy") !== -1
                          ? "/buy"
                          : "") + "/privacy",
                    }}
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={{
                      pathname:
                        (this.props.htmlClasses &&
                        this.props.htmlClasses.indexOf("buy") !== -1
                          ? "/buy"
                          : "") + "/fees",
                    }}
                  >
                    Fees and Charges
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-4 contact">
              <h6>Contact Us</h6>
              <ul>
                <li>
                  <img
                    src="/assets/images/vectors/icons/location-on.svg"
                    alt="Location"
                    className="icon"
                  />
                  <span className="title">
                    21 Adeyemo Alakija Street, Victoria Island, Lagos, Nigeria
                  </span>
                </li>
                <li>
                  <img
                    src="/assets/images/vectors/icons/ios-call.svg"
                    alt="Phone"
                    className="icon"
                  />
                  <span className="title">+2348107321018</span>
                </li>
                <li>
                  <img
                    src="/assets/images/vectors/icons/email.svg"
                    alt="mail"
                    className="icon"
                  />
                  <span className="title">info@propcrowdy.com</span>
                </li>
              </ul>
              <form
                ref={(subscribeForm) => {
                  this.subscribeForm = subscribeForm;
                }}
                className={
                  "subscribe-form email-subscription-form" +
                  (this.state.subscribing ? " submitting" : "")
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  this.subscribe();
                }}
              >
                <input
                  type="email"
                  name="email"
                  value={this.state.subscribeEmail}
                  placeholder="example@example.com"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ subscribeEmail: e.target.value });
                  }}
                  required
                />
                <input type="submit" value="Join" />
              </form>
            </div>
          </div>
          <div className="row copyright">
            <div className="col-md-6">® Propcrowdy 2020</div>
            <div className="col-md-6" />
          </div>
        </div>
      </footer>
    );
  }

  render() {
    return (
      <div
        id="layout"
        className={
          "default" +
          (this.props.htmlClasses ? " " + this.props.htmlClasses.join(" ") : "")
        }
      >
        {this.header()}
        {this.props.children}
        {this.footer()}
      </div>
    );
  }
}
