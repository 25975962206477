import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import { Link } from "react-router-dom";
import Loading from "./../../../App/Components/Templates/Loading";
import $ from "jquery";
import AppDocument from "./../../../App/Components/Utils/Document";
import Hook from "./../../../App/Components/Utils/Hook";
import Api from "./../../../App/Components/Utils/Api";

import UI from "./../../../App/Components/Utils/UI";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

export default class DealRoom extends React.Component {
  constructor(props) {
    super(props);
    DealRoom.contextType = Context;
    this.state = {
      isMobile: window.innerWidth <= 992,
      opportunity:
        this.props.location.state && this.props.location.state.opportunity,
      showInvestAmountForm: false,
      showShareButton: false,
      investAmount: "",
      investments: [],
      myInvestments: [],
      myPendingInvestments: [],
      myAbortedInvestments: [],
      numPages: null,
      pageNumber: 1,
      error: {
        amount: [],
      },
      showDocument: false,
    };
  }

  componentDidMount() {
    this.context.updateContext("layout", "default");
    AppDocument.updateDocumentHead({
      title:
        "PropCrowdy" +
        (this.state.opportunity
          ? " Opportunity - " + this.state.opportunity.title
          : " - Opportunity"),
    });
    window.addEventListener("click", this.onClick.bind(this));
    window.addEventListener("resize", this.onResize.bind(this));
    this.init();
    Hook.register("push.on.message", this.pushOnMessage.bind(this));
    Hook.register("payment", this.onPayment.bind(this));
  }

  componentDidUpdate(prevProps, x) {
    if (
      prevProps.match.params !== this.props.match.params ||
      prevProps.match.url !== this.props.match.url
    ) {
      this.setState(
        {
          //                opportunity: this.props.location.state && this.props.location.state.opportunity,
        },
        () => {
          //                this.init();
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClick.bind(this));
    window.removeEventListener("resize", this.onResize.bind(this));
    Hook.unregister("push.on.message", this.pushOnMessage.bind(this));
    Hook.unregister("payment", this.onPayment.bind(this));
  }

  init() {
    if (this.state.opportunity) {
      AppDocument.updateDocumentHead({
        title: "PropCrowdy Opportunity - " + this.state.opportunity.title,
      });
    }
    this.fetchOpportunity();
  }

  onClick(e) {
    if (e && e.target) {
      const invest = $(e.target).closest("#main.opportunity .invest");
      if (!invest.length && !this.state.submitting) {
        this.setState({ showInvestAmountForm: false });
      }

      const share = $(e.target).closest("#main.opportunity .share");
      if (!share.length) {
        this.setState({ showShareButtons: false });
      }
    }
  }

  onResize() {
    this.setState({ isMobile: window.innerWidth <= 992 });
  }

  pushOnMessage(result, message) {
    if (typeof message == "object") {
      if (
        message.type === "investment.payment.successful" &&
        message.data &&
        parseInt(message.data.id) === this.state.opportunity.id
      ) {
        this.fetchOpportunity(true);
      }
    }
  }

  onPayment(result, type, typeId, data) {
    if (
      type === "INVESTMENT" &&
      typeof data === "object" &&
      parseInt(data.id) === parseInt(this.state.opportunity.id)
    ) {
      this.fetchOpportunity(true);
    }
  }

  fetchOpportunity(update) {
    if (!update) {
      this.setState({ fetching: true });
    }
    Api.fetch("opportunity.view", undefined, {
      slug: window.location.pathname.split("/")[2],
    })
      .then(({ status, responseJSON }) => {
        this.setState({ fetching: false });
        if (status === 200) {
          this.setState({ opportunity: responseJSON.data }, () => {
            AppDocument.updateDocumentHead({
              title: "PropCrowdy Opportunity - " + this.state.opportunity.title,
            });
            this.listInvestments();
          });
        }
      })
      .catch((reason) => {
        this.setState({ fetching: false });
        // console.debug(reason);
      });
  }

  listInvestments() {
    let investments = [];
    let myInvestments = [];
    let myPendingInvestments = [];
    let myAbortedInvestments = [];
    if (this.state.opportunity) {
      (this.state.opportunity.crowdy_investments || [])
        .reverse()
        .map((investment, index) => {
          if (parseInt(investment.paid) === 1) {
            investments.push(investment);
          }
          if (
            this.context.user &&
            this.context.user.id === investment.customer_id
          ) {
            myInvestments.push(investment);
            if (parseInt(investment.paid) === 2) {
              myPendingInvestments.push(investment);
            } else if (parseInt(investment.paid) === 0) {
              myAbortedInvestments.push(investment);
            }
          }
          return true;
        });
    }
    this.setState({
      investments: investments,
      myInvestments: myInvestments,
      myPendingInvestments: myPendingInvestments,
      myAbortedInvestments: myAbortedInvestments,
    });
  }

  viewImage(src, title) {
    this.context.updateContext("imageViewerModalSrc", src);
    this.context.updateContext("imageViewerModalTitle", title);
    $("#image-viewer-modal").modal("show");
  }

  viewVideo(src, title, mimeType) {
    this.context.updateContext("videoViewerModalSrc", src);
    this.context.updateContext("videoViewerModalTitle", title);
    this.context.updateContext("videoViewerModalDescription", "");
    this.context.updateContext(
      "videoViewerModalMimeType",
      mimeType || "video/mp4"
    );
    $("#video-viewer-modal").modal("show");
  }

  invest() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i)) {
        error[i] = [];
      }
    }
    this.setState({ message: "" });
    if (this.context.user) {
      if (this.context.user.customer_type !== "developer") {
        if (!this.state.myPendingInvestments.length) {
          if (!this.state.myAbortedInvestments.length) {
            if (this.state.showInvestAmountForm) {
              if (
                typeof this.state.investAmount !== "string" ||
                this.state.investAmount.trim() === ""
              ) {
                error.amount.push("Please enter an amount");
              } else if (
                parseFloat(this.state.investAmount) <
                parseFloat(this.state.opportunity.min_investment)
              ) {
                error.amount.push(
                  "Minimum investment is ₦" +
                    parseInt(this.state.opportunity.min_investment || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else if (
                parseFloat(this.state.investAmount) >
                parseFloat(this.state.opportunity.max_investment)
              ) {
                error.amount.push(
                  "Maximum investment is ₦" +
                    parseInt(this.state.opportunity.max_investment || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }

              this.setState({ error: error });

              for (let i in error) {
                if (error.hasOwnProperty(i)) {
                  if (error[i].length) {
                    return false;
                  }
                }
              }

              this.setState({ submitting: true });
              Api.fetch("opportunity.investment.add", undefined, {
                crowdy_id: this.state.opportunity.id,
                amount_invested: this.state.investAmount,
                customer_id: this.context.user.id,
              })
                .then(({ status, responseJSON }) => {
                  this.setState({ submitting: false });
                  if (status === 200 && responseJSON.data) {
                    this.fetchOpportunity();
                    this.setState({ showInvestAmountForm: false }, () => {
                      this.context.updateContext(
                        "paymentModalType",
                        "INVESTMENT"
                      );
                      this.context.updateContext(
                        "paymentModalTypeId",
                        responseJSON.data.id
                      );
                      this.context.updateContext(
                        "paymentModalScheduleId",
                        null
                      );
                      this.context.updateContext(
                        "paymentModalTypeTitle",
                        "Opportunity Investment"
                      );
                      this.context.updateContext(
                        "paymentModalTitle",
                        this.state.opportunity.title
                      );
                      this.context.updateContext(
                        "paymentModalAmount",
                        responseJSON.data.amount_invested
                      );
                      this.context.updateContext(
                        "paymentModalData",
                        this.state.opportunity
                      );
                      this.context.updateContext("paymentModalMethods", [
                        "wallet",
                        "paystack",
                        "rave",
                        "bank",
                      ]);
                      $("#payment-modal").modal("show");
                    });
                  } else {
                    let error = this.state.error;
                    error["amount"] = [responseJSON.message];
                    this.setState({ error: error });
                  }
                })
                .catch((reason) => {
                  this.setState({ submitting: false });
                  let { status, responseJSON } =
                    typeof reason === "object" ? reason : {};
                  if ([403, 422].indexOf(status) !== -1) {
                    this.setState({
                      error: { ...error, ...responseJSON.error },
                      message: responseJSON.message || "",
                    });
                  } else {
                    // console.debug(reason);
                    this.setState({
                      message:
                        (responseJSON && responseJSON.message) ||
                        "Error Occurred! Please check the internet and try again",
                    });
                  }
                });
            } else {
              this.setState({ showInvestAmountForm: true });
            }
          } else {
            UI.alert(
              {
                title: "Investment in Progress",
                content:
                  "You have initiated an investment but you have not made the payment.",
                buttons: [
                  {
                    type: "positive",
                    title: "Pay Now",
                    onClick: () => {
                      this.setState({ showInvestAmountForm: false }, () => {
                        this.context.updateContext(
                          "paymentModalType",
                          "INVESTMENT"
                        );
                        this.context.updateContext(
                          "paymentModalTypeId",
                          this.state.myAbortedInvestments[0].id
                        );
                        this.context.updateContext(
                          "paymentModalScheduleId",
                          null
                        );
                        this.context.updateContext(
                          "paymentModalTypeTitle",
                          "Opportunity Investment"
                        );
                        this.context.updateContext(
                          "paymentModalTitle",
                          this.state.opportunity.title
                        );
                        this.context.updateContext(
                          "paymentModalAmount",
                          this.state.myAbortedInvestments[0].amount_invested
                        );
                        this.context.updateContext(
                          "paymentModalData",
                          this.state.opportunity
                        );
                        this.context.updateContext("paymentModalMethods", [
                          "wallet",
                          "paystack",
                          "rave",
                          "bank",
                        ]);
                        $("#payment-modal").modal("show");
                      });
                    },
                  },
                  {
                    type: "negative",
                    title: "Cancel Investment",
                    onClick: () => {
                      let myAbortedInvestments =
                        this.state.myAbortedInvestments || [];
                      if (myAbortedInvestments.length) {
                        let opportunity = this.state.opportunity;
                        let investmentIndex = undefined;
                        Api.fetch("opportunity.investment.delete", {
                          id: myAbortedInvestments[0].id,
                        })
                          .then(({ status, responseJSON }) => {})
                          .catch((reason) => {
                            // console.debug(reason);
                          });
                        (opportunity.crowdy_investments || []).map(
                          (investment, index) => {
                            if (
                              investment.id ===
                              this.state.myAbortedInvestments[0].id
                            ) {
                              investmentIndex = index;
                            }
                            return true;
                          }
                        );
                        myAbortedInvestments.splice(0, 1);
                        if (typeof investmentIndex !== "undefined") {
                          (opportunity.crowdy_investments || []).splice(
                            investmentIndex,
                            1
                          );
                        }
                        this.setState({
                          opportunity: opportunity,
                          myAbortedInvestments: myAbortedInvestments,
                        });
                      }
                    },
                  },
                ],
              },
              this.context
            );
          }
        } else {
          UI.alert(
            {
              title: "Transaction in progress",
              content:
                "You have an investment transaction in progress. Please wait for the payment to be confirmed.",
            },
            this.context
          );
        }
      } else {
        UI.alert(
          {
            title: "Sorry",
            content:
              "Developers cannot make an investment. Only an investor can invest.",
          },
          this.context
        );
      }
    } else {
      UI.alert(
        {
          title: "Login First",
          content: "You must be logged in to invest",
          buttons: [
            {
              type: "positive",
              title: "Login",
              onClick: () => {
                this.props.history.push({
                  pathname: "/login",
                  state: {
                    message: "Please login first",
                    redirectPathname:
                      "/opportunity/" + this.state.opportunity.slug,
                  },
                });
              },
            },
            {
              type: "neutral",
              title: "Register",
              onClick: () => {
                this.props.history.push({
                  pathname: "/register",
                  state: {
                    message: "Please register first",
                    redirectPathname:
                      "/opportunity/" + this.state.opportunity.slug,
                  },
                });
              },
            },
          ],
        },
        this.context
      );
    }
  }

  share(type) {
    const url = window.location.href;
    const target = "PropCrowdy - Share Opportunity";
    const features = "width = 500, height = 500, scrollbars = yes";
    if (type === "facebook") {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?display=popup&u=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "twitter") {
      window.open(
        "https://twitter.com/share?text=" +
          this.state.opportunity.title +
          "&url=" +
          encodeURIComponent(url) +
          "&href=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "linkedin") {
      window.open(
        "https://www.linkedin.com/cws/share?url=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "pinterest") {
      window.open(
        "https://pinterest.com/pin/create/button/?url=" +
          encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "whatsapp") {
      window.open(
        "https://api.whatsapp.com/send?text=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "telegram") {
      window.open(
        "https://telegram.me/share/url?url=" + encodeURIComponent(url),
        target,
        features
      );
    }
    if (type === "email") {
      window.open(
        "mailto:?subject=" +
          this.state.opportunity.title +
          "&body=" +
          encodeURIComponent(url),
        "_self",
        features
      );
    }
  }

  fund() {
    let percent =
      (parseFloat(this.state.opportunity.amount_raised || 0) /
        parseFloat(this.state.opportunity.amount)) *
      100;
    return (
      <div className="fund deal">
        <div className="heading">{this.state.opportunity.title}</div>
        <div className="location">{this.state.opportunity.location}</div>
        <div className="profile-info">Project Information</div>
        <div className="row">
          <div className="col-md-6">
            <div className="title">Country</div>
            <div className="sub-title">
              {this.state.opportunity.country.name}
            </div>
          </div>
          <div className="col-md-6">
            <div className="title">State</div>
            <div className="sub-title">{this.state.opportunity.state.name}</div>
          </div>
          <div className="col-md-6">
            <div className="title">ROI</div>
            <div className="sub-title">
              {this.state.opportunity.roi_percentage}%
            </div>
          </div>
          <div className="col-md-6">
            <div className="title">Tenure</div>
            <div className="sub-title">
              {this.state.opportunity.tenor} Months
            </div>
          </div>
          <div className="col-md-6">
            <div className="title">Amount</div>
            <div className="sub-title">
              ₦
              {parseInt(this.state.opportunity.amount || 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
          <div className="col-md-6">
            <div className="title">Amount Raised</div>
            <div className="sub-title">
              {" "}
              ₦
              {parseInt(this.state.opportunity.amount_raised || 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            </div>
          </div>
        </div>
        {this.state.opportunity.company ? (
          <div className="developer">
            <button
              onClick={() => {
                this.setState({ showDocument: !this.state.showDocument });
              }}
              className="link"
            >
              View Property Documents
            </button>

            <button
              style={{ marginTop: "15px", width: "234px" }}
              className="link"
              onClick={(e) => {
                e.preventDefault();
                this.context.updateContext(
                  "contactModalType",
                  "PROPERTYPAYMENT"
                );
                this.context.updateContext(
                  "contactModalData",
                  this.state.opportunity
                );
                $("#contact-modal").modal("show");
              }}
            >
              Contact PropCrowdy
            </button>
          </div>
        ) : null}
        <div className="info" />
        <div className="invest">
          {!this.state.myPendingInvestments.length &&
            this.state.showInvestAmountForm &&
            !this.state.fetching && (
              <form
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.invest();
                }}
              >
                {this.state.submitting && <Loading />}
                <div className="message">{this.state.message}</div>
                <input
                  type="number"
                  name="amount"
                  value={this.state.investAmount}
                  placeholder="Enter Investment Amount (₦)"
                  className="form-control"
                  onChange={(e) =>
                    this.setState({ investAmount: e.target.value })
                  }
                  required
                />
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.error.amount.join("<br />"),
                  }}
                />
              </form>
            )}
          {parseInt(this.state.opportunity.amount_raised || 0) <=
            parseInt(this.state.opportunity.amount || 0) &&
          (!this.context.user ||
            (this.context.user &&
              this.context.user.customer_type !== "developer")) ? (
            <button
              onClick={() => {
                this.invest();
              }}
              title={
                this.state.myPendingInvestments.length
                  ? "You have an investment transaction in progress. Please wait for the payment to be confirmed."
                  : this.state.myAbortedInvestments.length
                  ? "You have initiated an investment."
                  : "Invest"
              }
              disabled={
                this.state.opportunity.investment_status === 2 ||
                this.state.opportunity.investment_status === 3 ||
                this.state.opportunity.investment_status === 4
                  ? true
                  : false
              }
              className={
                this.state.myPendingInvestments.length ||
                this.state.myAbortedInvestments.length ||
                this.state.fetching ||
                this.state.opportunity.investment_status === 2 ||
                this.state.opportunity.investment_status === 3 ||
                this.state.opportunity.investment_status === 4
                  ? "disabled"
                  : ""
              }
            >
              Invest Now
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  pdfLoaded({ numPages: nextNumPages }) {
    this.setState({ numPages: nextNumPages });
  }
  render() {
    return (
      <main id="main" className="opportunity view">
        <div className="bread-crumb">
          {this.state.opportunity && (
            <div className="container">
              <span className="deal-room">Deal Room</span>
              <Link to={{ pathname: "/home" }} className="link">
                Home
              </Link>
              <i className="icon arrow las la-angle-right" />
              <Link
                to={{ pathname: "/opportunity/" + this.state.opportunity.slug }}
                className="link"
              >
                {this.state.opportunity.title}
              </Link>
            </div>
          )}
        </div>
        {this.state.opportunity ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="image-carousel-container">
                  <div className="preview">
                    <div
                      id="opportunity-image-carousel"
                      className="carousel slide image-carousel"
                      data-interval="0"
                    >
                      <div className="carousel-inner">
                        {this.state.opportunity.property_images.map(
                          (image, index) => (
                            <div
                              key={index}
                              className={
                                "carousel-item" + (index ? "" : " active")
                              }
                            >
                              <img
                                className="d-block w-100 image"
                                src={image.url}
                                alt={this.state.opportunity.title}
                                data-large-img-url={image.url}
                                onClick={() => {
                                  this.viewImage(image.url);
                                }}
                              />
                            </div>
                          )
                        )}
                        {this.state.opportunity.video ? (
                          <div className="carousel-item">
                            <img
                              className="d-block w-100 image"
                              src={
                                this.state.opportunity.video.preview ||
                                "/assets/images/video-placeholder.jpg"
                              }
                              alt={this.state.opportunity.title}
                              data-large-img-url={
                                this.state.opportunity.video.preview ||
                                "/assets/images/video-placeholder.jpg"
                              }
                              onClick={() => {
                                this.viewVideo(
                                  this.state.opportunity.video.url,
                                  "",
                                  this.state.property.video.mime_type
                                );
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="controls">
                    <button className="left-arrow arrow">
                      <i className="icon las la-angle-left" />
                    </button>
                    <div className="thumbs">
                      {this.state.opportunity.property_images.map(
                        (image, index) => (
                          <img
                            key={index}
                            src={image.url}
                            alt={this.state.opportunity.title}
                            data-slide-to={index}
                            className={"thumb" + (index ? "" : " active")}
                          />
                        )
                      )}
                      {this.state.opportunity.video ? (
                        <img
                          src={
                            this.state.opportunity.thumbnail ||
                            "/assets/images/video-placeholder.jpg"
                          }
                          alt={this.state.opportunity.title}
                          data-slide-to={
                            this.state.opportunity.property_images.length
                          }
                          className="thumb"
                        />
                      ) : null}
                    </div>
                    <button className="right-arrow arrow">
                      <i className="icon las la-angle-right" />
                    </button>
                  </div>
                </div>
                <div className="heading">
                  <img
                    src={
                      (this.state.opportunity.company &&
                        this.state.opportunity.company.logo &&
                        this.state.opportunity.company.logo.url) ||
                      "/assets/images/company-logo.png"
                    }
                    alt={
                      this.state.opportunity.company &&
                      this.state.opportunity.company.name
                    }
                    className="logo"
                  />
                  <div className="property">
                    <div className="deal-room-title">Company Information</div>
                    <div className="deal-room-sub-title">
                      {this.state.opportunity.company.name}
                    </div>
                  </div>
                </div>
                {this.state.isMobile && this.fund()}
                {this.state.showDocument ? (
                  <div style={{ minHeight: "500px", width: "100%" }}>
                    <iframe
                      style={{ minHeight: "500px", width: "100%" }}
                      src={
                        "https://docs.google.com/viewerng/viewer?url=" +
                        this.state.opportunity.deal_document.url +
                        "&embedded=true"
                      }
                    ></iframe>
                  </div>
                ) : null}
              </div>
              <div className="col-lg-5">
                {!this.state.isMobile && this.fund()}
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </main>
    );
  }
}
