import "../../assets/stylings/accessories_stylings/OpportunitySecondSection_styling.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import React, { useState, useRef, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import Ratings from "react-ratings-declarative";
import ProgressBarCustom from "./ProgressBarCustom";

import verifiedMark from "../../assets/images/verified_mark.svg";
import unverifiedMark from "../../assets/images/unverified_mark.svg";
import avatarIcon from "../../assets/images/avatar_icon.svg";

import reviewerDp from "../../assets/images/reviewer_dp.png";
import directorImage from "../../assets/images/director_image.png";
import textContainer from "../../assets/images/comment_textbox_with_pointer_edited.png";
import { Link, useHistory } from "react-router-dom";
import Api from "../../../App/Components/Utils/Api";
import UI from "../../../App/Components/Utils/UI";
import { Context } from "../../../App/Components/Context";
import $ from "jquery";

const OpportunitySecondSection = ({ opportunity, contexting }) => {
  const [oneStarRating, setOneStarRating] = useState(0);
  const [twoStarsRating, setTwoStarsRating] = useState(0);
  const [threeStarsRating, setThreeStarsRating] = useState(0);
  const [fourStarsRating, setFourStarsRating] = useState(0);
  const [fiveStarsRating, setFiveStarsRating] = useState(0);
  const [seeAllReviews, setSeeAllReviews] = useState(false);
  const [crowdyInvestments, setCrowdyInvestments] = useState([]);

  //LOGICS STATES
  const [reviewsRating, setReviewsRating] = useState([]);
  const [companyRating, setCompanyRating] = useState([]);

  const history = useHistory();

  useEffect(() => {
    // console.log(
    //   "this is the opportunity inside OpportunitySecondSection Component: ",
    //   opportunity
    // );

    Api.fetch("developer.company.review.list", undefined, {
      limit: `${seeAllReviews ? 1000 : 20}`,
      offset: 0,
      slug: opportunity?.company?.slug,
    })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          setReviewsRating(responseJSON?.data?.reviews);
          setCompanyRating(responseJSON?.data?.company);

          setOneStarRating(responseJSON?.data?.company?.rating_stats?.one);
          setTwoStarsRating(responseJSON?.data?.company?.rating_stats?.two);
          setThreeStarsRating(responseJSON?.data?.company?.rating_stats?.three);
          setFourStarsRating(responseJSON?.data?.company?.rating_stats?.four);
          setFiveStarsRating(responseJSON?.data?.company?.rating_stats?.five);
        }
      })
      .catch((reason) => {});
  }, [opportunity?.company?.slug, seeAllReviews]);

  const ratersSummation =
    oneStarRating +
    twoStarsRating +
    threeStarsRating +
    fourStarsRating +
    fiveStarsRating;

  const totalRaters = ratersSummation > 0 ? ratersSummation : 1;

  let oneStarPercentage = (oneStarRating / totalRaters) * 100;
  let twoStarsPercentage = (twoStarsRating / totalRaters) * 100;
  let threeStarsPercentage = (threeStarsRating / totalRaters) * 100;
  let fourStarsPercentage = (fourStarsRating / totalRaters) * 100;
  let fiveStarsPercentage = (fiveStarsRating / totalRaters) * 100;

  // const totalReview =  ;

  const [displayPage, setDisplayPage] = useState(0);
  const sampleNum = [1, 2, 3, 4, 5];

  const aboutPageContent = [
    { param: "House Type:", value: opportunity?.house_type },
    {
      param: "Cost of development:",
      value: `₦ ${opportunity?.cost_of_development.toString().replace(/\\B(?=(\\d{3})+(?!\\d))/g, ",")}`,
    },
    { param: "Developers Equity:", value: `${opportunity?.developer_equity}%` },
    { param: "Funds required:", value: `₦ ${opportunity?.funds_required.toString().replace(/\\B(?=(\\d{3})+(?!\\d))/g, ",")}` },
    {
      param: "Sales price per apartment:",
      value: opportunity?.sales_price_per_apartment,
    },
    { param: "Investors Equity:", value: "29%" },
    { param: "Title:", value: opportunity?.title },
    {
      param: "Approvals:",
      value: opportunity?.approvals,
    },
    {
      param: "Construction:",
      value: opportunity?.constructions,
    },
    { param: "Minimum Investment:", value: `₦ ${parseFloat(opportunity?.min_investment).toString().replace(/\\B(?=(\\d{3})+(?!\\d))/g, ",")}` },
    { param: "Custodian Bank:", value: "FCMB" },
    { param: "Legal Advisory:", value: "Perchstone & Graeys" },
  ];
  const data = [
    {
      name: "Page A",
      "Investment risk": 5000,
      amt: 2400,
    },
    {
      name: "Page B",
      "Investment risk": 3000,
      amt: 2210,
    },
    {
      name: "Page C",
      "Investment risk": 1700,
      amt: 2290,
    },
    {
      name: "Page D",
      "Investment risk": 2780,
      amt: 2000,
    },
    {
      name: "Page E",
      "Investment risk": 1890,
      amt: 2181,
    },
    {
      name: "Page F",
      "Investment risk": 2390,
      amt: 2500,
    },
    {
      name: "Page G",
      "Investment risk": 2190,
      amt: 2100,
    },
  ];
  const company = [
    {
      name: "Page A",
      "Company's reputation": 3000,
      amt: 2400,
    },
    {
      name: "Page B",
      "Company's reputation": 3000,
      amt: 2210,
    },
    {
      name: "Page C",
      "Company's reputation": 4300,
      amt: 2290,
    },
    {
      name: "Page D",
      "Company's reputation": 2780,
      amt: 2000,
    },
    {
      name: "Page E",
      "Company's reputation": 1890,
      amt: 2181,
    },
    {
      name: "Page F",
      "Company's reputation": 2390,
      amt: 2500,
    },
    {
      name: "Page G",
      "Company's reputation": 3990,
      amt: 2100,
    },
  ];
  const reliability = [
    {
      name: "Page A",
      "Investment Reliability": 3000,
      amt: 2400,
    },
    {
      name: "Page B",
      "Investment Reliability": 3000,
      amt: 2210,
    },
    {
      name: "Page C",
      "Investment Reliability": 4300,
      amt: 2290,
    },
    {
      name: "Page D",
      "Investment Reliability": 2780,
      amt: 2000,
    },
    {
      name: "Page E",
      "Investment Reliability": 1890,
      amt: 2181,
    },
    {
      name: "Page F",
      "Investment Reliability": 1390,
      amt: 2500,
    },
    {
      name: "Page G",
      "Investment Reliability": 4490,
      amt: 2100,
    },
  ];
  const aboutProject = (
    <div style={{ marginBottom: "198px" }} className="about_the_project">
      <div className="texts_container">
        <div className="texts">
          <div className="description">Wait Period Before ROI</div>
          <div className="description_value">12 Months</div>
        </div>
        <div className="texts">
          <div className="description">ROI Percentage</div>
          <div className="description_value">20%</div>
        </div>
      </div>
      <div className="main_content_container">
        {aboutPageContent.map((content, index) => {
          return (
            <div key={index} className="content">
              <div className="param">{content.param}</div>
              <div className="value">{content.value}</div>
            </div>
          );
        })}
        {/* <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: opportunity.description,
          }}
        /> */}
      </div>
    </div>
  );
  const aboutCompany = (
    <div
      style={{ marginBottom: "198px" }}
      id="about_the_company"
      className="about_the_company"
    >
      <div className="cover">
        <div className="heading">
          <p className="main_title">Description of Business</p>
          <hr />
        </div>

        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: opportunity.description_of_investment_business,
          }}
        />
      </div>
      <div className="cover">
        <div className="heading">
          <p className="main_title">About The Company</p>
          <hr />
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: opportunity.company.description,
          }}
        />
      </div>
      <div className="cover">
        <div className="heading">
          <p className="main_title">Board Of Directors</p>
          <hr />
        </div>
        <div className="directors_list">
          {opportunity.company.company_board_of_directors.map(
            (director, index) => {
              const directorMedia = director.media[0].url;

              return (
                <div key={index} className="director">
                  <div className="director_image">
                    <img
                      src={directorMedia}
                      alt="director"
                      style={{ borderRadius: "50%" }}
                      className="directorImage"
                    />
                  </div>
                  <div className="director_details">
                    <div className="name">
                      <p className="director_name">{director.name}</p>
                    </div>
                    <div className="position">
                      <p>{director.funtion}</p>
                      <p>{director.location}, Nigeria</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
  const investmentRisk = (
    <div
      style={{
        marginBottom: "300px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="investment_risk"
    >
      <ResponsiveContainer width={390} height={300}>
        <LineChart
          width={390}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <XAxis dataKey="name" />
          <YAxis /> */}
          <Tooltip />
          <Legend />
          <ReferenceLine y={4000} label="high" stroke="red" />
          <ReferenceLine y={2000} label="low" stroke="red" />
          {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" /> */}
          <Line type="monotone" dataKey="Investment risk" stroke="red" />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width={390} height={300}>
        <LineChart
          width={390}
          height={300}
          data={company}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <XAxis dataKey="name" />
          <YAxis /> */}
          <Tooltip />
          <Legend />
          <ReferenceLine y={4000} label="high" stroke="red" />
          <ReferenceLine y={2000} label="low" stroke="red" />
          {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" /> */}
          <Line type="monotone" dataKey="Company's reputation" stroke="brown" />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width={390} height={300}>
        <LineChart
          width={390}
          height={300}
          data={reliability}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <XAxis dataKey="name" />
          <YAxis /> */}
          <Tooltip />
          <Legend />
          <ReferenceLine y={4000} label="high" stroke="red" />
          <ReferenceLine y={2000} label="low" stroke="red" />
          {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" /> */}
          <Line
            type="monotone"
            dataKey="Investment Reliability"
            stroke="purple"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
  const projectDisclaimer = (
    <div style={{ marginBottom: "145px" }} className="project_disclaimer">
      <div
        className="paragraph"
        dangerouslySetInnerHTML={{
          __html: opportunity.company.description,
        }}
      />
      <div className="other">
        <p className="title">Liquidity of the securities</p>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: opportunity.liquidity_of_security,
          }}
        />
      </div>
      <div className="other">
        <p className="title">Risk of Investment non-performance</p>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: opportunity.risk_of_investment_non_performance,
          }}
        />
      </div>
    </div>
  );

  const reviews = reviewsRating.length ? (
    <div style={{ marginBottom: "205px" }} className="reviews">
      <div style={{ flex: 1 }}>
        {reviewsRating.map((review, key) => {
          return (
            <div key={key} className="comments_list_container">
              <div className="comment">
                <div
                  style={{
                    // position: "relative",
                    maxWidth: "665px",
                  }}
                >
                  {/* <img
                    src={textContainer}
                    alt="container"
                    className="text_container_box"
                  /> */}
                  <div className="text_box">
                    <p className="comment_content_text">{review.content},</p>
                  </div>
                </div>

                <div className="main_part">
                  <img
                    src={review.customer?.photo?.url || avatarIcon}
                    alt=""
                    className="reviewer_dp"
                  />
                  <div className="description">
                    <p className="name">
                      {review.customer.first_name} {review.customer.last_name}
                    </p>
                    <div className="extra_details">
                      <div className="yellow_stars">
                        <Rating
                          initialValue={Number(review?.ratings)}
                          fillColor="#FFB800"
                          size={16.6}
                          readonly={true}
                        />
                      </div>
                      {review.customer.verified === "1" ? (
                        <div className="verified">
                          <img src={verifiedMark} alt="verified" />
                          <p className="verified_text">Verified user</p>
                        </div>
                      ) : (
                        <div className="verified">
                          <img src={unverifiedMark} alt="verified" />
                          <p
                            className="verified_text"
                            style={{ color: "#ff0000" }}
                          >
                            Unverified user
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="reviews_overview">
        <div className="first_part">
          <div className="main_texts_container">
            <p className="main_text">Reviews</p>
            <p className="sub_text">Satisfied customers</p>
          </div>
          <div className="overall_star_rating">
            <div className="main_part">
              {/* <Rating
                iconsCount={companyRating?.rating}
                emptyColor={"#ff0000"}
                size={38}
                readonly={true}
              /> */}
              <Ratings
                rating={companyRating?.rating}
                widgetDimensions="30px"
                widgetSpacings="0px"
              >
                <Ratings.Widget widgetRatedColor="#ff0000" />
                <Ratings.Widget widgetRatedColor="#ff0000" />
                <Ratings.Widget widgetRatedColor="#ff0000" />
                <Ratings.Widget widgetRatedColor="#ff0000" />
                <Ratings.Widget widgetRatedColor="#ff0000" />
              </Ratings>
              {/* <p>{companyRating?.rating}</p> */}
              <p>{companyRating?.rating}</p>
            </div>
            <p className="sub_text">Overall rating</p>
          </div>
        </div>
        <div className="second_part">
          <div className="listing_container">
            <div className="stars">
              <Rating
                // onClick={handleRating}
                fillColor={"#ff0000"}
                size={13}
                readonly={true}
                initialValue={5}
                /* Available Props */
              />
            </div>
            <div className="quantity_bar">
              <ProgressBarCustom
                color={"#ff0000"}
                backgroundColor="#ffffff"
                progress={fiveStarsPercentage}
                height={19}
                borderRadius={0}
                parentStyles={{
                  border: "1px solid #ff0000",
                  flex: "grow",
                }}
              />
              {/* </div> */}
            </div>
            <div className="reviewers_number">
              {companyRating?.rating_stats?.five}
            </div>
          </div>
          <div className="listing_container">
            <div className="stars">
              <Rating
                // onClick={handleRating}
                fillColor={"#ff0000"}
                size={13}
                readonly={true}
                initialValue={4}
                /* Available Props */
              />
            </div>
            <div className="quantity_bar">
              <ProgressBarCustom
                color={"#ff0000"}
                backgroundColor="#ffffff"
                progress={fourStarsPercentage}
                height={19}
                borderRadius={0}
                parentStyles={{
                  border: "1px solid #ff0000",
                  flex: "grow",
                }}
              />
              {/* </div> */}
            </div>
            <div className="reviewers_number">
              {companyRating?.rating_stats?.four}
            </div>
          </div>
          <div className="listing_container">
            <div className="stars">
              <Rating
                // onClick={handleRating}
                fillColor={"#ff0000"}
                size={13}
                readonly={true}
                initialValue={3}
                /* Available Props */
              />
            </div>
            <div className="quantity_bar">
              <ProgressBarCustom
                color={"#ff0000"}
                backgroundColor="#ffffff"
                progress={threeStarsPercentage}
                height={19}
                borderRadius={0}
                parentStyles={{
                  border: "1px solid #ff0000",
                  flex: "grow",
                }}
              />
              {/* </div> */}
            </div>
            <div className="reviewers_number">
              {companyRating?.rating_stats?.three}
            </div>
          </div>
          <div className="listing_container">
            <div className="stars">
              <Rating
                // onClick={handleRating}
                fillColor={"#ff0000"}
                size={13}
                readonly={true}
                initialValue={2}
                /* Available Props */
              />
            </div>
            <div className="quantity_bar">
              <ProgressBarCustom
                color={"#ff0000"}
                backgroundColor="#ffffff"
                progress={twoStarsPercentage}
                height={19}
                borderRadius={0}
                parentStyles={{
                  border: "1px solid #ff0000",
                  flex: "grow",
                }}
              />
              {/* </div> */}
            </div>
            <div className="reviewers_number">
              {companyRating?.rating_stats?.two}
            </div>
          </div>
          <div className="listing_container">
            <div className="stars">
              <Rating
                // onClick={handleRating}
                fillColor={"#ff0000"}
                size={13}
                readonly={true}
                initialValue={1}
                /* Available Props */
              />
            </div>
            <div className="quantity_bar">
              <ProgressBarCustom
                color={"#ff0000"}
                backgroundColor="#ffffff"
                progress={oneStarPercentage}
                height={19}
                borderRadius={0}
                parentStyles={{
                  border: "1px solid #ff0000",
                  flex: "grow",
                }}
              />
              {/* </div> */}
            </div>
            <div className="reviewers_number">
              {companyRating?.rating_stats?.one}
            </div>
          </div>
        </div>
        <div className="third_part">
          <button
            className="view_all_reviews"
            onClick={() => setSeeAllReviews(true)}
          >
            {/* View all {reviewsRating.length} reviews */}
            See all reviews
          </button>

          <button
            className="write_a_review"
            onClick={(e) => {
              // if (this.context.user) {
              if (contexting.user) {
                // this.context.updateContext(
                contexting.updateContext(
                  "reviewAddCompany",
                  // this.state.company,
                  opportunity.company,
                  () => {
                    $("#review-modal").modal("show");
                  }
                );
              } else {
                UI.alert(
                  {
                    title: "Login First",
                    content: "You must be logged in to add review",
                    buttons: [
                      {
                        type: "positive",
                        title: "Login",
                        onClick: () => {
                          // this.props.history.push({
                          history.push({
                            pathname: "/login",
                            state: {
                              message: "Please login first",
                              redirectPathname: "/home",
                            },
                          });
                        },
                      },
                      {
                        type: "neutral",
                        title: "Register",
                        onClick: () => {
                          // this.props.history.push({
                          history.push({
                            pathname: "/register",
                            state: {
                              message: "Please register first",
                              redirectPathname: "/home",
                            },
                          });
                        },
                      },
                    ],
                  },
                  // this.context
                  contexting
                );
              }
            }}
          >
            Write a review
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="no-reviews-box">No Reviews Yet</div>
  );

  // const emptyReview = (
  //   <div>
  //     <p>{reviewsRating?.message}</p>
  //   </div>
  // );

  const contentArray = [
    aboutProject,
    aboutCompany,
    investmentRisk,
    projectDisclaimer,
    reviews,
  ];

  const [headings, setHeadings] = useState([
    {
      id: "1",
      title: "About The Project",
      sub_title: "Project Description",
      activate: true,
    },
    {
      id: "2",
      title: "About The Company",
      sub_title: "Learn About the Raiser",
      activate: false,
    },
    {
      id: "3",
      title: "Investment Risk",
      sub_title: "See Risk Levels",
      activate: false,
    },
    {
      id: "4",
      title: "Project Disclaimer",
      sub_title: "Be Aware",
      activate: false,
    },
    {
      id: "5",
      title: "Reviews",
      sub_title: "See what clients are saying",
      activate: false,
    },
  ]);

  const handleHeadingClick = (id) => {
    headings.map((heading) => {
      if (id === heading.id) {
        heading.activate = true;
        setDisplayPage(id - 1);
      } else {
        heading.activate = false;
      }
      return headings;
    });
  };

  return (
    <section className="main_second_section">
      <div id="info" className="headings_to_display_contents">
        {headings.map((heading, id) => {
          return (
            <button
              key={heading.id}
              id={`heading_${heading.id}`}
              onClick={() => handleHeadingClick(heading.id)}
              className={`heading ${heading.activate && "active"}`}
            >
              <p className="main_heading active">{heading.title}</p>
              <p className="sub_heading active">{heading.sub_title}</p>
            </button>
          );
        })}
      </div>
      <hr className="horizontal_line" />
      <div className="contents_to_display">{contentArray[displayPage]}</div>
    </section>
  );
};

export default OpportunitySecondSection;
