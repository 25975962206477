import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from './../Context';
import Loading from './../Templates/Loading';
import Document from './../Utils/Document';
import Api from './../Utils/Api';


export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        Faq.contextType = Context;
        this.state = {
            faqs: [],
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', window.location.pathname.split('/')[1] === 'buy' ? 'default.buy' : 'default');
        Document.updateDocumentHead({title: (window.location.pathname.split('/')[1] === 'buy' ? 'PropBuy' : 'PropCrowdy') + ' - FAQs'});
        this.setState({fetching: true});
        Api.fetch('faq.list', undefined, {
            platform: window.location.pathname.split('/')[1] === 'buy' ? 'propbuy' : 'propcrowdy'
        }).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({faqs: responseJSON.data || []});
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    render() {
        return (
            <main id="main" className="p-0">
                <section id="top-cover">
                    <div className="container">
                        <div className="title">Frequently Asked Questions</div>
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</div>
                        ) : (
                            <div className="description">Check out our frequently asked questions </div>
                        )}
                        <div className="links">
                            <Link to={{pathname: (window.location.pathname.split('/')[1] === 'buy' ? '/buy' : '') + '/knowledge'}} className="link">View Knowledge Base</Link>
                        </div>
                    </div>
                </section>
                <section id="faq">
                    <div className="container">
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="heading">PropBuy Frequently Asked Questions</div>
                        ) : (
                            <div className="heading">PropCrowdy Frequently Asked Questions</div>
                        )}
                        <div className="content">
                            {this.state.fetching && <Loading />}
                            {this.state.faqs.map((faq, index) => (
                                <div key={index} className={'question' + (!index ? ' open' : '')}>
                                    <div className="title">
                                        {faq.question}
                                        <button />
                                    </div>
                                    <div className="answer" dangerouslySetInnerHTML={{__html: faq.answer}} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
