import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from './../Context';
import Document from './../Utils/Document';

export default class Terms extends React.Component {
    constructor(props) {
        Terms.contextType = Context;
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.context.updateContext('layout', window.location.pathname.split('/')[1] === 'buy' ? 'default.buy' : 'default');
        Document.updateDocumentHead({title: (window.location.pathname.split('/')[1] === 'buy' ? 'PropBuy' : 'PropCrowdy') + ' - Terms and Conditions'});
    }

    render() {
        return (
            <main id="main" className="p-0">
                <section id="top-cover">
                    <div className="container">
                        <div className="title">Terms and Conditions</div>
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="description">Our Terms & Conditions guide our business relationship and transactions with you.</div>
                        ) : (
                            <div className="description">Our Terms & Conditions guide our business relationship and transactions with you.</div>
                        )}
                        <div className="links">
                            <Link to={{pathname: (window.location.pathname.split('/')[1] === 'buy' ? '/buy' : '') + '/knowledge'}} className="link">View Knowledge Base</Link>
                        </div>
                    </div>
                </section>
                <section id="terms-and-condition">
                    <div className="container">
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="heading">PropBuy Terms and Conditions</div>
                        ) : (
                            <div className="heading">PropCrowdy Terms and Conditions</div>
                        )}
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitr Lorem ipsum dolor sit amet, consectetur adipiscing elitr sit amet, consectetur adipiscing elitr</p>
                            </div>
                        ) : (
                            <div className="content">
                                <p>Legal Disclaimer</p>
                                <p><strong>TERMS AND CONDITIONS</strong></p>
                                <p>Propcrowdy and its members, officers, directors, owners, employees, agents and representatives, provides this portal (the “Portal”) for informational and transactional purposes only.  Use of and access to the Site and the information, materials, services, and other content available on or through the Portal (“Content”) are subject to these terms of use and all applicable laws.</p>
                                <p><strong><br /> NO INVESTMENT ADVICE</strong></p>
                                <p>The Content is for informational purposes only and you should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on our Portal constitutes an inducement, recommendation, and endorsement by Propcrowdy or any third party service provider to buy or sell any securities or other financial instruments in this or in in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction.</p>
                                <p>All Content on this site is information of a general nature and does not address the circumstances of any particular individual or entity. Nothing in the Portal constitutes professional and/or financial advice, nor does any information on the Portal constitute a comprehensive or complete statement of the matters discussed or the law relating thereto. Propcrowdy is not a fiduciary by virtue of any person’s use of or access to the Portal or Content. You alone assume the sole responsibility of evaluating the merits and risks associated with the use of any information or other Content on the Portal before making any decisions based on such information or other Content. In exchange for using the Site, you agree not to hold Propcrowdy, its affiliates or any third party service provider liable for any possible claim for damages arising from any decision you make based on information or other Content made available to you through the Portal.</p>
                                <p><strong> </strong></p>
                                <p><strong>INVESTMENT RISKS</strong></p>
                                <p>There are risks associated with investing in securities. Investing in stocks, bonds, exchange traded funds, mutual funds, and money market funds involves risk of loss. Some high risk investments may use leverage, which will accentuate gains & losses.  A security’s or a firm’s past investment performance is not a conclusive guarantee or predictor of future investment performance.</p>
                                <p> </p>
                                <p>Any investment(s) made in the business hosted on this platform carries a high risk and the projections are highly speculative. There is a significant chance that you may lose your money either in part or in whole on any such investments and you are advised to assess your financial position as to whether you can afford to bear such risk without suffering undue financial/economic hardship should you proceed to make an investment on this platform.</p>
                                <p> </p>
                                <p>You should note that an investment in an Issuer is subject to market conditions and there can be no assurance that any appreciation in value will occur. The value of investments and the income from them can fluctuate and may fall and there is no certainty that an investor will get back any of his investment.</p>
                                <p>Also worthy if note, is the fact that this being an online platform, you may be liable to lose you’re your money either in part or in whole if this online platform fails and becomes insolvent.</p>
                                <p> </p>
                                <p><strong><br /> PORTAL AND CONTENT NOT WARRANTED</strong></p>
                                <p>THE PORTAL AND CONTENT, ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND.  YOU BEAR ALL RISKS ASSOCIATED WITH THE USE OF THE SITE AND CONTENT, INCLUDING BUT WITHOUT LIMITATION, TO ANY RELIANCE ON THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY CONTENT AVAILABLE ON THE SITE.  PROPCROWDY AND ITS EMPLOYEES, OFFICERS, DIRECTORS, , PARTNERS, AGENTS, REPRESENTATIVES, SUPPLIERS AND SERVICE PROVIDERS, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT WITHOUT LIMITATION, ALL WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, COMPLETENESS, USEFULNESS, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR USE, AND WARRANTIES THAT MAY ARISE FROM DEALING/PERFORMANCE OR USAGE OF TRADE.</p>
                                <p><strong><br /> LIMITATION OF LIABILITY AND DISPUTE RESOLUTION</strong></p>
                                <p>YOUR INITIAL REMEDY FOR DISSATISFACTION WITH THE PORTAL AND CONTENT IS TO STOP USING THE PORTAL AND CONTENT.  PROPCROWDY IS NOT LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES, UNDER ANY THEORY OF LIABILITY, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, USE, DATA, OR LOSS OF OTHER INTANGIBLES.  IN PARTICULAR, AND WITHOUT LIMITATION, PROPCROWDY WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE PORTAL OR CONTENT.</p>
                                <p>While we try to maintain the integrity and security of the Portal and the servers from which the Portal is operated, we do not guarantee that the Portal or Content is or remains secure, complete or correct, or that access to the Portal or Content will be uninterrupted or error free.  Additionally, third parties may make unauthorized alterations to the Portal or Content.  If you become aware of any unauthorized third party alteration to the Portal or Content, contact us with a description of the material(s) at issue and the URL.</p>
                                <p><strong> </strong></p>
                                <p><strong>DISPUTE RESOLUTION</strong></p>
                                <p>You agree that where a material dispute arises between you and Propcrowdy arising from the use of the Portal and/or investment in the securities offered by Propcrowdy, you shall first attempt to resolve the dispute by preparing and serving on the Company or its legal representatives, a memorandum or statement setting out your position on the issue concerning which there is a grievance and your reason for such grievance. Such issue shall first be attempted to be resolved by negotiation. However, where that fails, the legal representatives shall take such issues to mediation for resolution.</p>
                                <p>Where the issue remains unresolved for sixty (60) days after notice of dispute has been served from one party to the other, the dispute shall be referred to Arbitration in accordance with the Arbitration and Conciliation Act of Nigeria.</p>
                                <p>Whenever a dispute arises between you and Propcrowdy requiring arbitration, you shall jointly appoint an Arbitrator to preside over the dispute. Such appointment is proposed to be unanimous, but, where unanimity cannot be achieved within seven (7) days after the arbitration has been demanded, an independent Arbitrator shall be appointed by the Chief Judge of the Federal High Court of Nigeria at the request of the parties.</p>
                                <p>The venue for the arbitration shall be Nigeria and the award of the Arbitrator (including the decisions regarding payment of the Arbitrator’s fee and other expenses) shall be final and binding on the parties, subject to available rights of appeal preserved under the Arbitration and Conciliation Act of Nigeria and/or any other applicable law(s).</p>
                                <p> </p>
                                <p><strong>NOTICES, COMMUNICATIONS, AND ELECTRONIC SIGNATURES</strong></p>
                                <p>You agree to be bound by any affirmation, assent or agreement that you transmit on or through the Portal or any other aspect of Propcrowdy’s services that you access by computer or other electronic device, including internet, telephonic and wireless devices, including but not limited to any consent you give to receive communications from us solely through electronic transmission. You agree that, when in the future you click on a “Submit” or “I agree” or other similarly worded “button” or entry field with your mouse, keystroke or other device, your agreement or consent will be legally binding and enforceable and the legal equivalent of your handwritten signature.</p>
                                <p><strong> </strong></p>
                                <p><strong>LIMITED RIGHT OF USE/OWNERSHIP OF CONTENT</strong></p>
                                <p>You are permitted to use the Portal and Content for your personal information and transactions with Propcrowdy only.  The Portal and Content are and shall remain the property of Propcrowdy and is protected by copyright, trademark, patent, and/or other intellectual property, proprietary, work product rights and laws.  You may use the Portal and Content for your personal, noncommercial use, provided that you keep intact all copyright, trademark, patent and other proprietary notices. </p>
                                <p>Except as expressly authorized in advance by Propcrowdy in writing, you agree not to reproduce, modify or create derivative works based on, rent, lease, loan, sell, distribute, publish, publicly perform or display, reverse engineer, de-compile or dissemble, all or any part of the Portal or Content.</p>
                                <p>Trade names, trademarks and service marks of Propcrowdy include, without limitation, Propcrowdy and any associated logos.  All trademarks and service marks on the Portal not owned by Propcrowdy are the property of their respective owners.  Nothing contained on the Portal should be construed as granting, by implication, estoppel or otherwise, any license or right to use any of Propcrowdy’s trade names, trademarks or service marks without our express prior written consent.</p>
                                <p><strong> </strong></p>
                                <p><strong>TERMINATION</strong></p>
                                <p>Propcrowdy, in its sole discretion, may terminate your access to or use of the Portal and Content, at any time when there is reason to believe that you are in breach of the terms of use.  Your access to or use of the Portal and Content may be terminated without notice.  Propcrowdy shall not be liable to you or any third party for any termination of your access to the Portal or Content, or to any such information or files.</p>
                                <p><strong> </strong></p>
                                <p><strong>RULES OF CONDUCT</strong></p>
                                <p>Your use of the Portal and Content is conditioned on your compliance with the rules of conduct set forth here.  You will not:</p>
                                <ul>
                                    <li>Use the Portal or Content for any fraudulent or unlawful purpose.</li>
                                    <li>Interfere with or disrupt the operation of the Portal or Content or the servers or networks used to make the Portal and Content available; or violate any requirements, procedures, policies or regulations of such networks.</li>
                                    <li>Restrict or inhibit any other person from using the Portal or Content (including without limitation by hacking or defacing any portion of the Portal or Content).</li>
                                    <li>Use the Portal or Content to advertise or offer to sell or buy any securities or services without Propcrowdy’s express prior written consent.</li>
                                    <li>Reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Portal or Content.</li>
                                    <li>Modify, adapt, reverse engineer, de-compile/disassemble any part of the Portal or Content.</li>
                                    <li>Remove any copyright, trademark or other proprietary rights notice from the Portal or materials originating from the Portal or Content.</li>
                                    <li>Frame or mirror any part of the Portal or Content without Propcrowdy’s express prior written consent.</li>
                                    <li>Create a database by systematically downloading and storing Content.</li>
                                    <li>Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or in any way gather Content or reproduce or circumvent the navigational structure or presentation of the Portal without the Company’s express prior written consent.</li>
                                </ul>
                                <p> </p>
                                <p><strong>INDEMNIFICATION</strong></p>
                                <p>By accessing and using the Portal and Content, you agree to indemnify, defend and hold harmless Propcrowdy (specifically including its officers, directors, owners, partners, employees, agents, information providers, licensors and licensees) (collectively, the “Indemnified Parties”) from and against any and all claims, losses, costs and expenses (including attorneys’ fees) arising out of or relating to:</p>
                                <p> (a) Any breach (or claim, that if true, would be a breach) by you of these Terms; and</p>
                                <p> (b) Your use of or activities in connection with the Portal.</p>
                                <p> </p>
                                <p> We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you. You shall not enter into any settlement agreement which affects the rights of any of the Indemnified Parties or requires the taking of any action by any of them, without our prior written approval.</p>
                                <p><strong> </strong></p>
                                <p><strong>JURISDICTIONAL CONTEXT</strong></p>
                                <p>The Portal is controlled and operated by Propcrowdy from Nigeria, and is not intended to subject Propcrowdy to the laws or jurisdiction of any country or territory other than that of Nigeria.  Propcrowdy does not represent or warrant that the Portal or any part thereof is appropriate or available for use in any particular jurisdiction other than Nigeria, and only in those jurisdictions where Propcrowdy is registered or licensed or exempt from registration or licensing under applicable state or federal law.  In choosing to access the portal, you do so on your own initiative and at your own risk, and you are responsible for complying with all laws, rules and regulations.  We may limit the Portal’s availability to any person, geographic area or jurisdiction.</p>
                                <p> </p>
                                <p><strong>MODIFICATIONS</strong></p>
                                <p>Propcrowdy may amend the terms of use at any time in its discretion, by posting revisions on the Portal.</p>
                            </div>
                        )}
                    </div>
                </section>
            </main>
        );
    }
}
