import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Loading from './../../../App/Components/Templates/Loading';
import $ from 'jquery';
import _ from 'lodash';
import Util from './../../../App/Components/Utils';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';

export default class ConversationList extends React.Component {
    constructor(props) {
        super(props);
        ConversationList.contextType = Context;
        this.state = {
            isZoomScreen: window.innerWidth > 1200 && window.innerWidth <= 1366,
            isMobile: window.innerWidth <= 992,
            contentActive: false,
            headerHeight: 0,
            term: '',
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false,
            listIndex: 0,
            imageGrid: {},
            error: {
                message: []
            }
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'user.buy' ? 'user.buy' : 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Direct messages from ' + (this.context.layout === 'user.buy' ? 'PropBuy' : 'PropCrowdy'));
        Document.updateDocumentHead({title: (this.context.layout === 'user.buy' ? 'PropBuy' : 'PropCrowdy') + ' - Messages'});
        this.fetchConversations();
        ConversationList.fetchUnreadConversation(this.context);
        window.addEventListener('resize', this.onResize.bind(this));
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize.bind(this));
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    onResize() {
        this.setState({isMobile: window.innerWidth <= 992, isZoomScreen: window.innerWidth > 1200 && window.innerWidth <= 1366});
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'new.message') {
                let list = this.state.list;
                for (let i in list) {
                    if(list.hasOwnProperty(i) && list[i].id === parseInt(message.data.id)) {
                        this.fetchMessages(list[i].id, true);
                        break;
                    }
                }
            }
        }
    }

    resetList(callback) {
        this.setState({messageIndex: 0, list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchConversations() {
        if(!this.state.fetching) {
            this.setState({fetching: true});
            let term = this.state.term;
            Api.fetch('conversation.list', undefined, {
                customer_id: this.context.user.id,
                search: term,
                offset: this.state.offset,
                limit: this.state.limit
            }).then(({status, responseJSON}) => {
                if (status === 200) {
                    this.setState({
                        list: [...this.state.list, ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: this.state.offset + (responseJSON.data || []).length
                    }, () => {
                        let list = this.state.list;
                        if(list.length) {
                            if(!list[0].read) {
                                this.markConversationRead(list[0].id);
                            }
                        }
                        for (let i in list) {
                            if (list.hasOwnProperty(i)) {
                                list[i]['reply'] = list[i].reply ? list[i].reply : {
                                    message: '',
                                    attachments: [],
                                    error: {message: []},
                                    responseMessage: '',
                                    submitting: false
                                };
                                this.fetchMessages(list[i].id);
                            }
                        }
                        this.setState({list: list});
                    });
                }
                this.setState({fetching: false}, () => {
                    if(this.state.term !== term) {
                        this.setState({messageIndex: 0, list: [], offset: 0}, () => {
                            this.fetchConversations();
                        });
                    }
                });
            }).catch((reason) => {
                this.setState({fetching: false}, () => {
                    if(this.state.term !== term) {
                        this.setState({messageIndex: 0, list: [], offset: 0}, () => {
                            this.fetchConversations();
                        });
                    }
                });
                console.debug(reason);
            });
        }
    }

    static fetchUnreadConversation(context) {
        Api.fetch('conversation.list', undefined, {customer_id: context.user.id, type: 'unread', unread: 1, read: 0}).then(({status, responseJSON}) => {
            if (status === 200) {
                context.updateContext('unreadConversations', responseJSON.data || []);
            }
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    static clearUnreadConversation(context) {
        context.updateContext('unreadConversations', []);
    }

    markConversationRead(id) {
        Api.fetch('conversation.read', undefined, {
            customer_id: this.context.user.id,
            id: id,
            message_id: id,
            conversation_id: id
        }).then(() => {
            ConversationList.fetchUnreadConversation(this.context);
        }).catch((reason) => {
            console.debug(reason);
        });
    }

    fetchMessages(id, update) {
        let list = this.state.list;
        let conversation;
        for(let i in list) {
            if(list.hasOwnProperty(i) && list[i].id === id) {
                conversation = list[i];
                break;
            }
        }

        conversation['messages'] = conversation.messages || [];
        conversation['total'] = conversation.total || 0;
        conversation['offset'] = conversation.offset || 0;
        conversation['limit'] = conversation.limit || 5;
         if(!update) {
             conversation['fetching'] = true;
        }
        this.setState({list: list});

        let initialScrollTop = 0;
        let initialScrollHeight = 0;
        if(this.messageContent) {
            initialScrollTop = this.messageContent.scrollTop;
            initialScrollHeight = this.messageContent.scrollHeight;
        }
        Api.fetch('conversation.message.list', undefined, {
            customer_id: this.context.user.id,
            message_id: id,
            offset: update ? 0 : conversation.offset,
            limit: update ? Math.max(conversation.messages.length, conversation.limit) : conversation.limit
        }).then(({status, responseJSON}) => {
            if(status === 200) {
                conversation['messages'] = [...(update ? [] : conversation.messages), ...(responseJSON.data || [])];
                conversation['total'] = responseJSON.total || conversation.total;
                conversation['offset'] = (update ? 0 : conversation.offset) + (responseJSON.data || []).length;
                this.setState({list: list}, () => {
                    if(this.messageContent && !this.messageContent.scrollTop && !update) {
                        if(conversation.messages.length <= conversation.limit) {
                            this.messageContent.scrollTop = this.messageContent.scrollHeight;
                        } else {
                            this.messageContent.scrollTop = this.messageContent.scrollHeight - initialScrollHeight - initialScrollTop;
                        }
                    }
                    this.setImageGrid();
                });
            }
            conversation['fetching'] = false;
            this.setState({list: list});
        }).catch((reason) => {
            conversation['fetching'] = false;
            this.setState({list: list});
            console.debug(reason);
        });
    }

    setImageGrid() {
        if(this.state.list[this.state.listIndex].messages) {
            this.state.list[this.state.listIndex].messages.map(async (message, index) => {
                if(!this.state.imageGrid[message.message_id]) {
                    let wideImages = [];
                    let otherImages = [];
                    let fetchImages = (url) => {
                        return new Promise(async (resolve, reject) => {
                            try {
                                let image = new Image();
                                image.onload = () => {
                                    if(image.width / image.height < 1.3) {
                                        otherImages.push(url);
                                    } else {
                                        wideImages.push(url);
                                    }
                                    resolve();
                                }
                                image.src = url;
                            } catch(e) {
                                console.debug(e)
                                reject();
                            }
                        });
                    }
                    let promises = [];
                    message.media.map((file) => {
                        if(file.mime_type.match(/^image\//gi)) {
                            promises.push(fetchImages(file.url));
                        }
                        return true;
                    });
                    await Promise.all(promises);
                    let imageGrid = this.state.imageGrid;
                    imageGrid[message.message_id] = _.chunk(wideImages, 2).concat(otherImages);
                    this.setState({imageGrid: imageGrid});
                }
               return true;
            });
        }
    }

    viewImage(src, title) {
        this.context.updateContext('imageViewerModalSrc', src);
        this.context.updateContext('imageViewerModalTitle', title);
        $('#image-viewer-modal').modal('show');
    }

    addAttachments(fileHandle) {
        for(let i in fileHandle.files) {
            if(fileHandle.files.hasOwnProperty(i)) {
                let list = this.state.list;
                let reply = list[this.state.listIndex].reply;
                let attachments = reply.attachments;
                let attachmentsLength = attachments.push({progress: 0, display: '', url: '', file: ''});
                let index = attachmentsLength - 1;
                let formData = new FormData();
                attachments[index]['progress'] = 0.1;
                attachments[index]['display'] = 'Uploading... (0%)';
                attachments[index]['url'] = URL.createObjectURL(fileHandle.files[0]);
                attachments[index]['file'] = '';
                reply.error['files.' + index] = [];
                this.setState({list: list}, () => {
                    formData.append('file', fileHandle.files[i]);
                    Api.fetch('file.upload', undefined, formData, undefined, (progress) => {
                        attachments[index]['progress'] = progress;
                        attachments[index]['display'] = 'Uploading... (' + Math.ceil(progress * 100) + '%)';
                        this.setState({list: list});
                    }).then(({status, responseJSON}) => {
                        list = this.state.list;
                        attachments[index]['progress'] = 0;
                        if(status === 200) {
                            attachments[index]['display'] = responseJSON.original_name;
                            attachments[index]['file'] = responseJSON.name;
                            this.setState({list: list});
                        } else {
                            attachments[index]['display'] = '';
                            reply['responseMessage'] = responseJSON.message || '';
                            this.setState({list: list});
                        }
                    }).catch((reason) => {
                        attachments[index]['progress'] = 0;
                        attachments[index]['display'] = '';
                        let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                        if ([403, 422].indexOf(status) !== -1) {
                            if(responseJSON.error && responseJSON.error.file) {
                                reply.error['files.' + index] = responseJSON.error['file'];
                            }
                            reply['responseMessage'] = responseJSON.message || '';
                            this.setState({list: list});
                        } else {
                            console.debug(reason);
                            reply['responseMessage'] = (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again';
                            this.setState({list: list});
                        }
                    });
                });
            }
        }
    }

    removeAttachments(indices) {
        let list = this.state.list;
        let reply = list[this.state.listIndex].reply;
        let attachments = reply.attachments;
        let error = reply.error;
        let remove = (index) => {
            return new Promise((resolve, reject) => {
                for(let i = index; i < attachments.length - 1; i++) {
                    attachments[i]['progress'] = attachments[i + 1].progress;
                    attachments[i]['display'] = attachments[i + 1].display;
                    attachments[i]['url'] = attachments[i + 1].url;
                    attachments[i]['file'] = attachments[i + 1].file;
                    error['files.' + i] = error['files.' + (i + 1)];
                }
                delete error['files.' + (attachments.length - 1)];
                attachments.splice(attachments.length - 1, 1);
                this.setState({list: list}, () => {
                    resolve();
                });
            });
        };
        indices.map(async (index) => {
            return await remove(index);
        });
    }

    delete() {
        let list = this.state.list;
        Api.fetch('conversation.delete', undefined, {customer_id: this.context.user.id, conversation_id: this.state.list[this.state.listIndex].id, message_id: this.state.list[this.state.listIndex].messages ? this.state.list[this.state.listIndex].messages[0].message_id : 0}).then(() => {}).catch((reason) => {console.debug(reason)});
        list.splice(this.state.listIndex, 1);
        this.setState({list: list, listIndex: Math.max(0, this.state.listIndex - 1), total: this.state.total - 1});
    }

    reply() {
        let list = this.state.list;
        let conversation = list[this.state.listIndex];
        let reply = conversation.reply;
        let error = reply.error;

        reply['responseMessage'] = '';

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }

        if (typeof reply.message !== 'string' || reply.message.trim() === '') {
            error.message.push('Please type your message');
        } else {
            for(let i in reply.attachments) {
                if(reply.attachments.hasOwnProperty(i)) {

                }
            }
        }

        this.setState({list: list});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        if(this.replyMessageInput) {
            this.replyMessageInput.blur()
        }

        reply['submitting'] = true;
        this.setState({list: list});

        let data = {customer_id: this.context.user.id, message_id: conversation.id, message: reply.message, files: []}
        reply.attachments.map(attachment => data.files.push(attachment.file));
        Api.fetch('conversation.message.add', undefined, data).then(({status, responseJSON}) => {
            reply['submitting'] = false;
            if(status === 200) {
                reply['message'] = '';
                reply['attachments'] = [];
                conversation.messages.unshift(responseJSON.data);
                if(this.messageContent) {
                    this.messageContent.scrollTop = this.messageContent.scrollHeight;
                    setTimeout(() => {
                        this.messageContent.scrollTop = this.messageContent.scrollHeight;
                    }, 100);
                }
                this.fetchMessages(conversation.id, true);
            } else {
                reply['responseMessage'] = responseJSON.message || '';
            }

            this.setState({list: list}, () => {
                if(this.replyMessageInput) {
                    this.replyMessageInput.focus();
                }
            });
        }).catch((reason) => {
            reply['submitting'] = false;
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                reply['error'] = responseJSON.error;
            } else {
                console.debug(reason);
                reply['responseMessage'] =  (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again';
            }

            if(this.replyMessageInput) {
                this.replyMessageInput.focus();
            }
            this.setState({list: list});
        });
    }

    render() {
        return (
            <main id="main" className="message conversations" style={{height: 'calc(' + (this.state.isZoomScreen ? 125 : 100) + 'vh - ' + (this.context.adminHeaderHeight || 0) + 'px'}}>
                <div className="container">
                    <div className="list-container">
                        <div className={'list' + (!this.state.contentActive ? ' active' : '')}>
                            <form className="search" onSubmit={(e) => {e.preventDefault()}}>
                                <input type="search" placeholder="Search" onChange={(e) => {
                                    this.setState({term: e.target.value});
                                    this.resetList(() => {
                                        this.fetchConversations();
                                    });
                                }} />
                                <img src="/assets/images/vectors/icons/search-1.svg" className="icon" alt="Search" />
                            </form>
                            {!this.state.list.length && !this.state.fetching && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="info">You have no active conversation</span>
                                </div>
                            )}
                            {this.state.list.map((conversation, index) => (
                                <div key={index} className={'conversation-container' + (this.state.listIndex === index ? ' active' : '')} onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({listIndex: index, contentActive: true}, () => {
                                        if(!conversation.read) {
                                            this.markConversationRead(conversation.id);
                                        }
                                        if(this.messageContent) {
                                            this.messageContent.scrollTop = this.messageContent.scrollHeight;
                                        }
                                        this.fetchMessages(conversation.id, true);
                                    });
                                }}>
                                    <div className="conversation">
                                        <img className="thumb" alt={conversation.sender.name} src={conversation.sender.photo ? conversation.sender.photo.url : '/assets/images/vectors/avatar.svg'} />
                                        <div className="details">
                                            <div className="subject">{conversation.subject}</div>
                                            <div className="sender-role">{conversation.sender.duty}</div>
                                            <div className="content">{conversation.messages && conversation.messages.length ? Util.decodeHTMLEntities(conversation.messages[0].message.replace(/<\/?.*?>/gi, ' ')).substring(0, 80) : ''}</div>
                                            {conversation.messages && !!conversation.messages.length && (
                                                <>
                                                    {conversation.messages[0].media.length ? (
                                                        <div className="attachment">
                                                            <img src="/assets/images/vectors/icons/attachment.svg" alt="Attachment" className="icon" />
                                                            {conversation.messages[0].media.length} Attachments
                                                        </div>
                                                    ) : null}
                                                    <div className="time">{Time.timeToFriendly(conversation.messages[0].created_at, 0)}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                                {this.state.fetching && <Loading />}
                            </div>
                            {!this.state.fetching && this.state.list.length < this.state.total && (
                                <div className="list-load-more">
                                    <button onClick={() => {this.fetchConversations()}}>Load More</button>
                                </div>
                            )}
                        </div>
                        <div ref={(messageContent) => {this.messageContent = messageContent}} className={'messages' + (this.state.contentActive ? ' active' : '')} onScroll={(e) => {
                            if(e.target.scrollTop === 0) {
                                if(this.state.list[this.state.listIndex] && !this.state.list[this.state.listIndex].fetching && this.state.list[this.state.listIndex].messages && this.state.list[this.state.listIndex].messages.length < this.state.list[this.state.listIndex].total) {
                                    this.fetchMessages(this.state.list[this.state.listIndex].id);
                                }
                            }
                        }}>
                            {!this.state.list.length && !this.state.fetching && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="info">You have no message</span>
                                </div>
                            )}
                            {this.state.list.length > 0 && (
                                <>
                                    <div className="actions">
                                        <button className="reply-all" onClick={() => {
                                            if(this.replyMessageInput) {
                                                this.replyMessageInput.focus()
                                                this.messageContent.scrollTop = this.messageContent.scrollHeight
                                            }
                                        }} />
                                        <button className="delete" onClick={(e) => {
                                            e.preventDefault();
                                            UI.alert({
                                                title: 'Confirmation',
                                                content: 'Are you sure you want to delete this conversation?',
                                                buttons: [{
                                                    type: 'positive',
                                                    title: 'Yes',
                                                    onClick: () => {
                                                        this.delete();
                                                    },
                                                }, {
                                                    type: 'neutral',
                                                    title: 'No'
                                                }]
                                            }, this.context);
                                        }} />
                                        <button className="close" onClick={() => {this.setState({contentActive: false})}}>
                                            <i className="icon las la-angle-left" />
                                            Back
                                        </button>
                                    </div>
                                    <div className="list">
                                        <div className="height-fill-available" />
                                        {this.state.list[this.state.listIndex].messages && this.state.list[this.state.listIndex].messages.map((message, index) => (
                                            <div key={index} className="message-container">
                                                <div className="message">
                                                    <img className="thumb" alt={message.customer_is_sender ? (this.context.user.first_name) : this.state.list[this.state.listIndex].sender.name} src={(message.customer_is_sender ? this.context.user.photo : this.state.list[this.state.listIndex].sender.photo) ? (message.customer_is_sender ? this.context.user.photo.url : this.state.list[this.state.listIndex].sender.photo.url) : '/assets/images/vectors/avatar.svg'} />
                                                    <div className="details">
                                                        {/*{!index && (<div className="subject">{this.state.list[this.state.listIndex].subject}</div>)}*/}
                                                        <div className="name">{message.customer_is_sender ? (this.context.user.first_name) : this.state.list[this.state.listIndex].sender.name}</div>
                                                        <div className="sender-role">{message.customer_is_sender ? (((this.context.constants && this.context.constants.user && this.context.constants.user.customer_type) || []).reduce((types, type) => {types[type.value] = type.display; return types}, {})[this.context.user.customer_type] || String(this.context.user.customer_type).replace(/[^A-Za-z0-9]/gi, ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')) : this.state.list[this.state.listIndex].sender.duty}</div>
                                                        <div className="email">{message.customer_is_sender ? (this.context.user.email) : this.state.list[this.state.listIndex].sender.email}</div>
                                                        <div className="time">{Time.timeToFriendly(message.created_at, 0)}</div>
                                                        <div className="body" dangerouslySetInnerHTML={{__html: message.message}} />
                                                        {message.media && message.media.length ? (
                                                            <div className="attachments">
                                                                <div className="heading">
                                                                    <img src="/assets/images/vectors/icons/attachment.svg" alt="Attachments" className="icon" />
                                                                    {message.media.length} Attachments
                                                                </div>
                                                                <div className="grid files">
                                                                    {this.state.imageGrid[message.message_id] && this.state.imageGrid[message.message_id].map((url, index) => (
                                                                        <div key={index} className="cell">
                                                                            {url.constructor.name === 'Array' ? url.map((url, index) => (
                                                                                <img key={index} src={url} alt="Attachment" className="file half" onClick={() => {this.viewImage(url)}} />
                                                                            )) : (
                                                                                <img key={index} src={url} alt="Attachment" className="file" onClick={() => {this.viewImage(url)}} />
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="download">
                                                                    <button onClick={() => {window.open(Api.baseUrl + 'download-conversation-attachment?conversation_id=' + this.state.list[this.state.listIndex].id + '&message_id=' + message.id)}}>
                                                                        <i className="icon las la-download" />
                                                                        Download
                                                                    </button>
                                                                    {Util.humanFileSize(message.file_size)} total
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className={'ajax-loading-container' + (this.state.list[this.state.listIndex].fetching ? ' active' : '')}>
                                            {this.state.list[this.state.listIndex].fetching && <Loading />}
                                        </div>
                                    </div>
                                    {this.state.list[this.state.listIndex].reply && (
                                        <form className={'reply' + (this.state.list[this.state.listIndex].reply.submitting ? ' submitting' : '')} onSubmit={(e) => {
                                            e.preventDefault();
                                            this.reply();
                                        }}>
                                            <div className="field">
                                                <input ref={(replyMessageInput) => {this.replyMessageInput = replyMessageInput}} name="message" type="text" value={this.state.list[this.state.listIndex].reply.message} placeholder="Type Message..." className="message" onChange={(e) => {
                                                    let list = this.state.list;
                                                    let reply = list[this.state.listIndex].reply;
                                                    reply['message'] = e.target.value;
                                                    this.setState({list: list});
                                                }} />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.list[this.state.listIndex].reply.error.message.join('<br />')}} />
                                            </div>
                                            <input type="button" className="attach" onClick={() => {this.attachmentFileInput.click()}} />
                                            <input ref={(attachmentFileInput) => {this.attachmentFileInput = attachmentFileInput}} type="file" name="files[]" className="display-none" onChange={(e) => {
                                                this.addAttachments(e.target)
                                            }} multiple />
                                            <div className="files">
                                                {this.state.list[this.state.listIndex].reply.attachments.map((attachment, index) => (
                                                    <div key={index} className="file-container">
                                                        <div key={index} className="file">
                                                            {attachment.file || this.state.list[this.state.listIndex].reply.error['files.' + index].length ? (
                                                                <button type="button" className="close-button las la-times" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.removeAttachments([index])
                                                                }} />
                                                            ) : (
                                                                <div className="progress" style={{width:  Math.ceil(attachment.progress * 100)+ '%'}} />
                                                            )}
                                                            <div className="display">
                                                                {attachment.display}
                                                            </div>
                                                        </div>
                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.list[this.state.listIndex].reply.error['files.' + index].join('<br />')}} />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="response-message">{this.state.list[this.state.listIndex].reply.responseMessage}</div>
                                            <input type="submit" value="" className="submit" />
                                        </form>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
