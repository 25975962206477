import "./../../../assets/style.css";
import React from "react";
import { Context } from "./../../../../App/Components/Context";

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    Alert.contextType = Context;
    this.state = {};
  }

  render() {
    return (
      <div
        id="alert-modal"
        className="modal"
        tabIndex="-1"
        role="dialog"
        // style={{ zindex: "200px" }}
      >
        <div className="modal-dialog" role="document">
          <div
            style={{ marginTop: "150px" }}
            className={"modal-content alert-box " + this.context.AlertModalType}
          >
            <button
              type="button"
              className="close-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="heading">{this.context.AlertModalTitle}</p>
            <div
              className="sub-heading"
              dangerouslySetInnerHTML={{
                __html: this.context.AlertModalSubTitle,
              }}
            />
            <p className="content">{this.context.AlertModalContent}</p>
            <div className="buttons">
              {(this.context.AlertModalButtons || [{ type: "neutral" }]).map(
                (button, index) => (
                  <div key={index}>
                    {button.type === "positive" && (
                      <button
                        key={index}
                        type="button"
                        className={
                          "btn btn-" +
                          (button.theme || "primary") +
                          " confirm-button"
                        }
                        data-dismiss="modal"
                        onClick={() => {
                          if (typeof button.onClick === "function") {
                            button.onClick();

                            //   this.context.AlertModalContent;

                            // AlertModalButtonsRoute;
                          }
                        }}
                      >
                        {button.title || "OK"}
                      </button>
                    )}
                    {button.type === "negative" && (
                      <button
                        key={index}
                        type="button"
                        className={
                          "btn btn-" +
                          (button.theme || "danger") +
                          " close-button"
                        }
                        data-dismiss="modal"
                        onClick={() => {
                          if (typeof button.onClick === "function") {
                            button.onClick();
                          }
                        }}
                      >
                        {button.title || "Cancel"}
                      </button>
                    )}
                    {button.type === "neutral" && (
                      <button
                        key={index}
                        type="button"
                        className={
                          "btn btn-" +
                          (button.theme || "secondary") +
                          " confirm-button"
                        }
                        data-dismiss="modal"
                        onClick={() => {
                          if (typeof button.onClick === "function") {
                            button.onClick();
                          }
                        }}
                      >
                        {button.title || "OK"}
                      </button>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
