import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Resource from './../Templates/Layouts/Resource';
import Loading from './../../../App/Components/Templates/Loading';
import $ from 'jquery';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';

export default class EmailPhoneMarketingPayment extends React.Component {
    constructor(props) {
        super(props);
        EmailPhoneMarketingPayment.contextType = Context;
        this.state = {
            marketing: window.location.pathname.split('/')[4] === 'test' ? {id: 1, price: 20000, status: 0} : null
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'default');
        Document.updateDocumentHead({title: 'PropCrowdy Real Estate Email & Phone Database Payment'});
        this.fetchMarketing(!!this.state.marketing);
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
        Hook.register('payment', this.onPayment.bind(this));
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
        Hook.unregister('payment', this.onPayment.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(message.type === 'marketing.payment.successful' && message.data && parseInt(message.data.id) === this.state.marketing.id) {
                this.fetchMarketing(true);
            }
        }
    }

    onPayment(result, type, typeId, data) {
        if(type === 'EMAILPHONEMARKETING' && typeof data === 'object' && parseInt(data.id) === parseInt(this.state.marketing.id)) {
            let marketing = this.state.marketing;
            marketing['status'] = 2;
            this.setState({marketing: marketing}, () => {
                this.fetchMarketing(true);
            });
        }
    }

    fetchMarketing(update) {
        if(!update) {
            this.setState({fetching: true});
        }
        Api.fetch('marketing.view', undefined, {token: window.location.pathname.split('/')[4]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({marketing: responseJSON.data});
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    render() {
        return (
            <Resource heading="Email Phone Database" title="Real Estate Email & Phone Database Payment">
                <main id="main" className="resource email-phone-marketing payment">
                    <div className="container">
                        {this.state.marketing ? (
                            <div className="content">
                                {!this.state.marketing.status ? (
                                    <>
                                        <span className="message">You have requested the quote for a email and phone marketing. Please use the button below to make the payment.</span>
                                        <span className="price">₦{parseInt((this.state.marketing.price || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                        <button className="link" onClick={(e) => {
                                            e.preventDefault();
                                            this.context.updateContext('paymentModalType', 'EMAILPHONEMARKETING');
                                            this.context.updateContext('paymentModalTypeId', this.state.marketing.id);
                                            this.context.updateContext('paymentModalTypeTitle', 'Email & Phone Marketing');
                                            this.context.updateContext('paymentModalScheduleId', null);
                                            this.context.updateContext('paymentModalTitle', 'Pay for your Email and Phone Marketing using either of the options below');
                                            this.context.updateContext('paymentModalAmount', this.state.marketing.price);
                                            this.context.updateContext('paymentModalData', this.state.marketing);
                                            this.context.updateContext('paymentModalMethods', ['paystack', 'rave', 'bank']);
                                            $('#payment-modal').modal('show');
                                        }}>
                                            Pay
                                        </button>
                                    </>
                                ) : null}
                                {this.state.marketing.status === 1 ? (
                                    <>
                                        <i className="icon success las la-check-circle" />
                                        <span className="message">The payment for this marketing has been confirmed.</span>
                                    </>
                                ) : null}
                                {this.state.marketing.status === 2 ? (
                                    <>
                                        <i className="icon info las la-info-circle" />
                                        <span className="message">The payment for this marketing has not been confirmed. You will be notified once the payment is confirmed.</span>
                                    </>
                                ) : null}
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </main>
            </Resource>
        );
    }
}
