import "./../../../assets/style.css";
import React from "react";
import { Context } from "./../../../../App/Components/Context";
import { Link } from "react-router-dom";
import Cookie from "./../../Utils/Cookie";
import $ from "jquery";

export default class RiskDisclosure extends React.Component {
  constructor(props) {
    super(props);
    RiskDisclosure.contextType = Context;
    this.state = {};
  }

  render() {
    return (
      <div
        id="risk-disclosure-modal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content alert-box">
            <button
              type="button"
              className="close-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="heading">RISK DISCLOSURE STATEMENT</p>
            <div className="content">
              <p>
                This statement serves as a disclosure to the investor that there
                are risks involved in placing an investment through an online
                portal and this portal operated by Propcrowdy is not an
                exception. Should you choose to place an investment through this
                platform, you will have done this of your own volition and
                without relying on any information on this platform, other than
                the information availed by the Issuer...{" "}
                <Link
                  to={{ pathname: "/risk" }}
                  className="more-link"
                  onClick={() => {
                    $("#risk-disclosure-modal").modal("hide");
                    Cookie.setCookie("has_acknowledged_risk", 1);
                  }}
                >
                  Learn More
                </Link>
                .
              </p>
            </div>
            <div className="buttons">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  Cookie.setCookie("has_acknowledged_risk", 1);
                }}
              >
                I AGREE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
