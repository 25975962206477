import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Loading from "./../../../App/Components/Templates/Loading";
import _ from "lodash";
import Document from "./../../../App/Components/Utils/Document";
import Api from "./../../../App/Components/Utils/Api";
import UI from "./../../../App/Components/Utils/UI";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export default class Add extends React.Component {
  constructor(props) {
    super(props);
    Add.contextType = Context;
    this.state = {
      title: "",
      location: "",
      stateId: "0",
      countryId: "0",
      investorsWarning: "",
      waitPeriodBeforeROI: "0",
      liquidityOfSecurity: "",
      riskOfInvestmentNonPerformance: "0",
      roiPercentage: "0",
      canInvestmentBeCancelled: "",
      startDate: "",
      closingDate: "",
      minInvestment: "",
      maxInvestment: "",
      amount: "",
      descriptionOfFinancialCondition: "",
      propertyImages: ["", "", "", "", "", ""],
      "propertyImages.0DisplayName": "",
      "propertyImages.0UploadProgress": 0,
      "propertyImages.0Url": "/assets/images/image-placeholder.png",
      "propertyImages.1DisplayName": "",
      "propertyImages.1UploadProgress": 0,
      "propertyImages.1Url": "/assets/images/image-placeholder.png",
      "propertyImages.2DisplayName": "",
      "propertyImages.2UploadProgress": 0,
      "propertyImages.2Url": "/assets/images/image-placeholder.png",
      "propertyImages.3DisplayName": "",
      "propertyImages.3UploadProgress": 0,
      "propertyImages.3Url": "/assets/images/image-placeholder.png",
      "propertyImages.4DisplayName": "",
      "propertyImages.4UploadProgress": 0,
      "propertyImages.4Url": "/assets/images/image-placeholder.png",
      "propertyImages.5DisplayName": "",
      "propertyImages.5UploadProgress": 0,
      "propertyImages.5Url": "/assets/images/image-placeholder.png",
      confirmation: false,
      message: "",
      step: "index",
      steps: [
        {
          id: "index",
          fields: [
            "title",
            "location",
            "state_id",
            "country_id",
            "investors_warning",
            "tenor",
            "liquidity_of_security",
            "risk_of_investment_non_performance",
            "roi_percentage",
          ],
        },
        {
          id: "description",
          fields: [
            "can_investment_be_cancelled",
            "start_date",
            "closing_date",
            "min_investment",
            "max_investment",
            "amount",
            "description_of_financial_condition",
          ],
        },
        {
          id: "images",
          fields: [
            "property_images",
            "property_images.0",
            "property_images.1",
            "property_images.2",
            "property_images.3",
            "property_images.4",
            "property_images.5",
          ],
        },
      ],
      stateList: [],
      error: {
        title: [],
        location: [],
        state_id: [],
        country_id: [],
        investors_warning: [],
        tenor: [],
        liquidity_of_security: [],
        risk_of_investment_non_performance: [],
        roi_percentage: [],
        can_investment_be_cancelled: [],
        start_date: [],
        closing_date: [],
        min_investment: [],
        max_investment: [],
        amount: [],
        description_of_financial_condition: [],
        property_images: [],
        "property_images.0": [],
        "property_images.1": [],
        "property_images.2": [],
        "property_images.3": [],
        "property_images.4": [],
        "property_images.5": [],
        confirmation: [],
      },
      submitting: false,
    };
  }

  componentDidMount() {
    this.context.updateContext("layout", "default.split");
    Document.updateDocumentHead({ title: "PropCrowdy - Create Opportunity" });
    if (this.context.user) {
      if (this.context.user.customer_type === "developer") {
        if (this.context.user.customer_developer_company) {
          if (
            (
              (this.context.constants &&
                this.context.constants.locale &&
                this.context.constants.locale.countries) ||
              []
            ).length
          ) {
            this.setState(
              {
                countryId: ((this.context.constants &&
                  this.context.constants.locale &&
                  this.context.constants.locale.countries) ||
                  [])[0].id.toString(),
              },
              () => {
                this.fetchStates(
                  this.state.countryId,
                  ((this.context.constants &&
                    this.context.constants.locale &&
                    this.context.constants.locale.countries) ||
                    [])[0].country_states
                );
              }
            );
          }
        } else {
          this.props.history.replace("/company/setup");
        }
      } else {
        this.props.history.replace("/how");
      }
    } else {
      this.props.history &&
        this.props.history.replace({
          pathname: "/login",
          state: {
            message: "Please login first",
            redirectPathname: "/opportunity/create",
          },
        });
    }
  }

  fetchStates(countryId, preload) {
    this.setState({
      stateId: (preload || []).length ? preload[0].id.toString() : "0",
      stateList: preload || [],
    });
    Api.fetch("locale.state.list", undefined, { country_id: countryId })
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.setState({ stateList: responseJSON.data || [] });
        }
      })
      .catch((reason) => {
        console.debug(reason);
      });
  }

  goToStep(step) {
    if (this.stepIndex(step) <= this.stepIndex(this.state.step)) {
      this.setState({ step: step });
    } else {
      this.validateSteps([this.state.step]).then((invalidSteps) => {
        if (invalidSteps.length) {
          this.setState({ step: invalidSteps[0] });
        } else {
          this.setState({ step: step });
        }
      });
    }
  }

  validateSteps(steps) {
    steps = steps || [];
    let invalidSteps = [];
    let addInvalidStep = (step) => {
      if (invalidSteps.indexOf(step) === -1) {
        invalidSteps.push(step);
      }
    };

    return new Promise((resolve, reject) => {
      let error = this.state.error;
      for (let i in error) {
        if (error.hasOwnProperty(i)) {
          error[i] = [];
        }
      }

      steps.map((step) => {
        if (step === "index") {
          if (
            typeof this.state.title !== "string" ||
            this.state.title.trim() === ""
          ) {
            error.title.push("Please provide the offer's title");
            addInvalidStep("index");
          }

          if (
            typeof this.state.location !== "string" ||
            this.state.location.trim() === ""
          ) {
            error.location.push("Please provide the offer's location");
            addInvalidStep("index");
          }

          if (!parseInt(this.state.stateId)) {
            error.state_id.push("Please provide opportunity's state");
            addInvalidStep("index");
          }

          if (!parseInt(this.state.countryId)) {
            error.country_id.push("Please provide opportunity's country");
            addInvalidStep("index");
          }

          if (!parseInt(this.state.roiPercentage)) {
            error.roi_percentage.push("Please provide the ROI percentage");
            addInvalidStep("index");
          }

          if (!parseInt(this.state.waitPeriodBeforeROI)) {
            error.tenor.push("Please provide the wait period before roi");
            addInvalidStep("index");
          }

          if (
            typeof this.state.liquidityOfSecurity !== "string" ||
            this.state.liquidityOfSecurity.trim() === ""
          ) {
            error.liquidity_of_security.push(
              "Please provide the liquidity of security"
            );
            addInvalidStep("index");
          }

          if (
            typeof this.state.riskOfInvestmentNonPerformance !== "string" ||
            this.state.riskOfInvestmentNonPerformance.trim() === ""
          ) {
            error.risk_of_investment_non_performance.push(
              "Please provide the risk of investment (non-performance)"
            );
            addInvalidStep("index");
          }

          if (
            typeof this.state.investorsWarning !== "string" ||
            this.state.investorsWarning.trim() === ""
          ) {
            error.investors_warning.push("Please provide the investor warning");
            addInvalidStep("index");
          }
        }

        if (step === "description") {
          if (
            typeof this.state.canInvestmentBeCancelled !== "string" ||
            this.state.canInvestmentBeCancelled.trim() === ""
          ) {
            error.can_investment_be_cancelled.push(
              "Please specify if investment can be cancelled"
            );
            addInvalidStep("description");
          }

          if (!parseInt(this.state.startDate)) {
            error.start_date.push("Please provide the start date");
            addInvalidStep("description");
          }

          if (!parseInt(this.state.closingDate)) {
            error.closing_date.push("Please provide the closing date");
            addInvalidStep("description");
          }

          if (!parseFloat(this.state.minInvestment)) {
            error.min_investment.push("Please provide the minimum investment");
            addInvalidStep("description");
          } else if (parseFloat(this.state.minInvestment) < 50) {
            error.min_investment.push("The minimum investment is too small");
            addInvalidStep("description");
          }

          if (!parseFloat(this.state.maxInvestment)) {
            error.max_investment.push("Please provide the maximum investment");
            addInvalidStep("description");
          }

          if (
            parseFloat(this.state.minInvestment) >
            parseFloat(this.state.maxInvestment)
          ) {
            error.min_investment.push(
              "Minimum investment cannot be greater than maximum investment"
            );
            addInvalidStep("description");
          }

          if (
            parseFloat(this.state.maxInvestment) > parseFloat(this.state.amount)
          ) {
            error.max_investment.push(
              "Maximum investment cannot be greater than the goal"
            );
            addInvalidStep("description");
          }

          if (!parseFloat(this.state.amount)) {
            error.amount.push("Please provide the investment goal");
            addInvalidStep("description");
          }

          if (
            typeof this.state.descriptionOfFinancialCondition !== "string" ||
            this.state.descriptionOfFinancialCondition.trim() === ""
          ) {
            error.description_of_financial_condition.push(
              "Please specify if investment can be cancelled"
            );
            addInvalidStep("description");
          }
        }

        if (step === "images") {
          if (this.state.propertyImages.length < 3) {
            error.property_images.push("Please provide at least 6 images");
            addInvalidStep("images");
          } else {
            this.state.propertyImages.map((image, index) => {
              if (typeof image !== "string" || !image) {
                if (this.state["propertyImages." + index + "UploadProgress"]) {
                  error["property_images." + index].push("Please wait...");
                } else {
                  error["property_images." + index].push(
                    "Please provide photo"
                  );
                }
                addInvalidStep("images");
              }
              return true;
            });
          }

          if (!this.state.confirmation) {
            error.confirmation.push(
              "Please confirm that all information provided are truthful and correct"
            );
            addInvalidStep("images");
          }
        }

        return true;
      });

      this.setState({ error: error }, () => {
        resolve(invalidSteps);
      });
    });
  }

  stepIndex(step) {
    let steps = this.state.steps;
    let index = -1;
    for (let i in steps) {
      if (steps.hasOwnProperty(i) && steps[i].id === step) {
        index = i;
        break;
      }
    }
    return index;
  }

  nextStep() {
    this.goToStep(
      this.state.steps[
        Math.min(
          this.stepIndex(this.state.step) + 1,
          this.state.steps.length - 1
        )
      ].id
    );
  }

  errorStep() {
    let error = this.state.error;
    for (let i in error) {
      if (error.hasOwnProperty(i) && error[i].length) {
        let steps = this.state.steps;
        for (let j in steps) {
          if (
            steps.hasOwnProperty(j) &&
            steps[j].fields.indexOf(i) !== -1 &&
            steps[j]
          ) {
            this.goToStep(steps[j].id);
            break;
          }
        }
        break;
      }
    }
  }

  uploadFile(fileHandle, key, callback) {
    let setKey = (value) => {
      let state = this.state;
      _.set(state, key, value);
      this.setState(state, () => {
        if (typeof callback === "function") {
          callback.apply();
        }
      });
    };
    let formData = new FormData();
    if (fileHandle.files.length) {
      this.setState(
        {
          [key + "UploadProgress"]: 0.1,
          [key + "DisplayName"]: "Uploading... (0%)",
          [key + "Url"]: URL.createObjectURL(fileHandle.files[0]),
          message: "",
        },
        () => {
          setKey("");
        }
      );
      fileHandle.disabled = true;
      formData.append("file", fileHandle.files[0]);
      Api.fetch("file.upload", undefined, formData, undefined, (progress) => {
        this.setState({
          [key + "UploadProgress"]: progress,
          [key + "DisplayName"]:
            "Uploading... (" + Math.ceil(progress * 100) + "%)",
        });
      })
        .then(({ status, responseJSON }) => {
          fileHandle.disabled = false;
          if (status === 200) {
            this.setState(
              {
                [key + "DisplayName"]: responseJSON.original_name,
                [key + "UploadProgress"]: 0,
              },
              () => {
                setKey(responseJSON.name, () => {
                  this.validateSteps([this.state.step])
                    .then(() => {})
                    .catch(() => {});
                });
              }
            );
          } else {
            this.setState({
              [key + "DisplayName"]: "",
              [key + "UploadProgress"]: 0,
              [key + "Url"]: "/assets/images/image-placeholder.png",
              message: responseJSON.message || "",
            });
          }
        })
        .catch((reason) => {
          this.setState({
            [key + "DisplayName"]: "",
            [key + "UploadProgress"]: 0,
            [key + "Url"]: "/assets/images/image-placeholder.png",
          });
          fileHandle.disabled = false;
          let { status, responseJSON } =
            typeof reason === "object" ? reason : {};
          if ([403, 422].indexOf(status) !== -1) {
            let error = {};
            if (responseJSON.error && responseJSON.error.file) {
              error[
                (key) =>
                  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
              ] = responseJSON.error["file"];
            }
            this.setState({
              error: { ...this.state.error, ...error },
              message: responseJSON.message || "",
            });
          } else {
            console.debug(reason);
            this.setState({
              message:
                (responseJSON && responseJSON.message) ||
                "Error Occurred! Please check the internet and try again",
            });
          }
        });
    } else {
      this.setState(
        {
          [key + "DisplayName"]: "",
          [key + "Url"]: "/assets/images/image-placeholder.png",
        },
        () => {
          setKey("");
        }
      );
    }
  }

  submit() {
    this.setState({ message: "" });
    this.validateSteps([this.state.steps[this.state.steps.length - 1].id]).then(
      (invalidSteps) => {
        if (invalidSteps.length) {
          this.setState({ step: invalidSteps[0] });
        } else {
          this.setState({ submitting: true });
          Api.fetch("opportunity.add", undefined, {
            title: this.state.title,
            location: this.state.location,
            state_id: this.state.stateId,
            country_id: this.state.countryId,
            investors_warning: this.state.investorsWarning,
            tenor: this.state.waitPeriodBeforeROI,
            liquidity_of_security: this.state.liquidityOfSecurity,
            risk_of_investment_non_performance:
              this.state.riskOfInvestmentNonPerformance,
            roi_percentage: this.state.roiPercentage,
            can_investment_be_cancelled: this.state.canInvestmentBeCancelled,
            start_date:
              String(new Date(this.state.startDate).getFullYear()).padStart(
                4,
                "0"
              ) +
              "-" +
              String(new Date(this.state.startDate).getMonth() + 1).padStart(
                2,
                "0"
              ) +
              "-" +
              String(new Date(this.state.startDate).getDate()).padStart(2, "0"),
            closing_date:
              String(new Date(this.state.closingDate).getFullYear()).padStart(
                2,
                "0"
              ) +
              "-" +
              String(new Date(this.state.closingDate).getMonth() + 1).padStart(
                2,
                "0"
              ) +
              "-" +
              String(new Date(this.state.closingDate).getDate()).padStart(
                2,
                "0"
              ),
            min_investment: this.state.minInvestment,
            max_investment: this.state.maxInvestment,
            amount: this.state.amount,
            description_of_financial_condition:
              this.state.descriptionOfFinancialCondition,
            description: this.state.descriptionOfFinancialCondition,
            property_images: this.state.propertyImages,
            confirmation: this.state.confirmation ? 1 : 0,
          })
            .then(({ status, responseJSON }) => {
              this.setState({ submitting: false });
              if (status === 201) {
                this.setState({
                  title: "",
                  location: "",
                  stateId: "0",
                  countryId: "0",
                  investorsWarning: "",
                  waitPeriodBeforeROI: "0",
                  liquidityOfSecurity: "",
                  roiPercentage: "0",
                  canInvestmentBeCancelled: "",
                  startDate: "",
                  closingDate: "",
                  minInvestment: "",
                  maxInvestment: "",
                  amount: "",
                  descriptionOfFinancialCondition: "",
                  propertyImages: ["", "", "", "", "", ""],
                  "propertyImages.0DisplayName": "",
                  "propertyImages.0UploadProgress": 0,
                  "propertyImages.0Url": "/assets/images/image-placeholder.png",
                  "propertyImages.1DisplayName": "",
                  "propertyImages.1UploadProgress": 0,
                  "propertyImages.1Url": "/assets/images/image-placeholder.png",
                  "propertyImages.2DisplayName": "",
                  "propertyImages.2UploadProgress": 0,
                  "propertyImages.2Url": "/assets/images/image-placeholder.png",
                  "propertyImages.3DisplayName": "",
                  "propertyImages.3UploadProgress": 0,
                  "propertyImages.3Url": "/assets/images/image-placeholder.png",
                  "propertyImages.4DisplayName": "",
                  "propertyImages.4UploadProgress": 0,
                  "propertyImages.4Url": "/assets/images/image-placeholder.png",
                  "propertyImages.5DisplayName": "",
                  "propertyImages.5UploadProgress": 0,
                  "propertyImages.5Url": "/assets/images/image-placeholder.png",
                });
                UI.alert(
                  {
                    title: "Success",
                    content: responseJSON.message,
                    buttons: [
                      {
                        type: "positive",
                        title: "OK",
                        onClick: () => {
                          this.props.history.push({ pathname: "/discover" });
                        },
                      },
                    ],
                  },
                  this.context
                );
              } else {
                this.setState({ message: responseJSON.message || "" });
              }
            })
            .catch((reason) => {
              this.setState({ submitting: false });
              let { status, responseJSON } =
                typeof reason === "object" ? reason : {};
              if ([403, 422].indexOf(status) !== -1) {
                this.setState(
                  {
                    error: { ...this.state.error, ...responseJSON.error },
                    message: responseJSON.message || "",
                  },
                  () => {
                    this.errorStep();
                  }
                );
              } else {
                console.debug(reason);
                this.setState({
                  message:
                    (responseJSON && responseJSON.message) ||
                    "Error Occurred! Please check the internet and try again",
                });
              }
            });
        }
      }
    );
  }

  render() {
    return (
      <main id="main" className="opportunity add">
        <div className="bread-crumb">
          <div className="container">
            <Link to={{ pathname: "/dashboard" }} className="link">
              Dashboard
            </Link>
            <i className="icon arrow las la-angle-right" />
            <Link to={{ pathname: "/opportunity/create" }} className="link">
              Offer Document
            </Link>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 split-left">
              <div className="content">
                <div className="heading">Setup Offer Document</div>
                <div className="sub-heading">Provide Company Information</div>
              </div>
              <img
                src="/assets/images/checklist-man.png"
                alt="Checklist Man"
                className="image"
              />
            </div>
            <div className="col-xl-6 split-right">
              <form
                className="steps"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.submit();
                }}
              >
                {this.state.submitting && <Loading />}
                <div className="bullets">
                  {this.state.steps.map((step, index) => (
                    <span
                      key={index}
                      className={
                        "bullet" +
                        (this.state.step === step.id ? " active" : "")
                      }
                    />
                  ))}
                </div>
                <img
                  src="/assets/images/vectors/icons/offer.svg"
                  alt="Offer"
                  className="icon"
                />
                <div className="message">{this.state.message}</div>
                <div className="step">
                  {this.stepIndex("index") <=
                    this.stepIndex(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("index");
                      }}
                    >
                      <span className="number">1</span>
                      <span className="title">Setup Offer Document</span>
                    </div>
                  )}
                  {this.state.step === "index" && (
                    <div className="content">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-title">Title *</label>
                            <input
                              id="opportunity-title"
                              type="text"
                              name="title"
                              value={this.state.title}
                              placeholder="Enter Title"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ title: e.target.value });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html: this.state.error.title.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field geocomplete">
                            <label htmlFor="opportunity-location">
                              Location *
                            </label>
                            <GooglePlacesAutocomplete
                              apiKey={
                                this.context.settings &&
                                this.context.settings.google
                                  ? this.context.settings.google.api
                                  : "AIzaSyDneJtifU7zFOVA4Mq1LpzP09UCISiAsxs"
                              }
                              selectProps={{
                                bounds: [
                                  { lat: 4.0690959, lng: 2.676932 },
                                  { lat: 13.885645, lng: 14.678014 },
                                ],
                                componentRestrictions: {
                                  country: ["ng"],
                                },
                                location: this.state.location,
                                onChange: (result) => {
                                  this.setState({ location: result.label });
                                },
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    color: "#000",
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    color: "#000",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: "#000",
                                  }),
                                },
                              }}
                            />
                            <input
                              id="opportunity-location"
                              type="text"
                              name="location"
                              value={this.state.location}
                              placeholder="Location"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ location: e.target.value });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.location.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-state">State *</label>
                            <select
                              id="opportunity-state-id"
                              name="state_id"
                              value={this.state.stateId}
                              placeholder="State"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ stateId: e.target.value });
                              }}
                              required
                            >
                              <option value="0" disabled>
                                State
                              </option>
                              {this.state.stateList.map((state, index) => (
                                <option key={index} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.state_id.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="company-country">Country *</label>
                            <select
                              id="company-country-id"
                              name="country_id"
                              value={this.state.countryId}
                              placeholder="Country"
                              className="form-control"
                              onChange={(e) => {
                                let preload = [];
                                for (let i in (this.context.constants &&
                                  this.context.constants.locale &&
                                  this.context.constants.locale.countries) ||
                                  []) {
                                  if (
                                    (
                                      (this.context.constants &&
                                        this.context.constants.locale &&
                                        this.context.constants.locale
                                          .countries) ||
                                      []
                                    ).hasOwnProperty(i) &&
                                    parseInt(
                                      ((this.context.constants &&
                                        this.context.constants.locale &&
                                        this.context.constants.locale
                                          .countries) ||
                                        [])[i].id
                                    ) === parseInt(e.target.value)
                                  ) {
                                    preload =
                                      ((this.context.constants &&
                                        this.context.constants.locale &&
                                        this.context.constants.locale
                                          .countries) ||
                                        [])[i].country_states || [];
                                  }
                                }
                                this.setState(
                                  { countryId: e.target.value },
                                  () => {
                                    this.fetchStates(
                                      this.state.countryId,
                                      preload
                                    );
                                  }
                                );
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Country
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.locale &&
                                  this.context.constants.locale.countries) ||
                                []
                              ).map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.country_id.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="company-roi-percentage">
                              ROI Percentage (%) *
                            </label>
                            <select
                              id="company-roi-percentage"
                              name="roi_percentage"
                              value={this.state.roiPercentage}
                              placeholder="Return of investment percentage"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  roiPercentage: e.target.value,
                                });
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Return of investment percentage
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.opportunity &&
                                  this.context.constants.opportunity.roi) ||
                                []
                              ).map((roi, index) => (
                                <option key={index} value={roi.value}>
                                  {roi.display}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.roi_percentage.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="company-tenor">
                              Wait Period Before ROI *
                            </label>
                            <select
                              id="company-tenor"
                              name="tenor"
                              value={this.state.waitPeriodBeforeROI}
                              placeholder="Select Duration"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  waitPeriodBeforeROI: e.target.value,
                                });
                              }}
                              required
                            >
                              <option value="0" disabled>
                                Select Duration
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.opportunity &&
                                  this.context.constants.opportunity.tenor) ||
                                []
                              ).map((tenor, index) => (
                                <option key={index} value={tenor.value}>
                                  {tenor.display}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html: this.state.error.tenor.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="field">
                            <label htmlFor="opportunity-liquidity-of-security">
                              Liquidity Of The Security *
                            </label>
                            <textarea
                              id="opportunity-liquidity-of-security"
                              name="liquidity_of_security"
                              value={this.state.liquidityOfSecurity}
                              placeholder="Liquidity Of The Security"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  liquidityOfSecurity: e.target.value,
                                });
                              }}
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.liquidity_of_security.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="field">
                            <label htmlFor="opportunity-investors-warning">
                              Investor Warning *
                            </label>
                            <textarea
                              id="opportunity-investors-warning"
                              name="investors_warning"
                              value={this.state.investorsWarning}
                              placeholder="Investor Warning"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  investorsWarning: e.target.value,
                                });
                              }}
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.investors_warning.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="step">
                  {this.stepIndex("description") <=
                    this.stepIndex(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("description");
                      }}
                    >
                      <span className="number">2</span>
                      <span className="title">
                        Description Of Investment Business
                      </span>
                    </div>
                  )}
                  {this.state.step === "description" && (
                    <div className="content">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="company-country">
                              Can Investment Be Cancelled *
                            </label>
                            <select
                              id="company-can-investment-be-cancelled"
                              name="can_investment_be_cancelled"
                              value={this.state.canInvestmentBeCancelled}
                              placeholder="Country"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  canInvestmentBeCancelled: e.target.value,
                                });
                              }}
                              required
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {(
                                (this.context.constants &&
                                  this.context.constants.opportunity &&
                                  this.context.constants.opportunity
                                    .can_investment_be_cancelled) ||
                                []
                              ).map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.display}
                                </option>
                              ))}
                            </select>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.can_investment_be_cancelled.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-start-date">
                              Start Date *
                            </label>
                            <DatePicker
                              id="opportunity-start-date"
                              name="start_date"
                              minDate={new Date()}
                              selected={this.state.startDate}
                              onChange={(date) => {
                                this.setState({
                                  startDate: new Date(date).getTime(),
                                });
                              }}
                              dateFormat="Y-M-d"
                              placeholderText="Pick Start Date"
                              className="date-picker"
                              autoComplete="off"
                              required
                            />
                            <img
                              src="/assets/images/icons/event.png"
                              alt="Event"
                              className="icon"
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.start_date.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-closing-date">
                              Closing Date *
                            </label>
                            <DatePicker
                              id="opportunity-closing-date"
                              name="closing_date"
                              minDate={new Date()}
                              maxDate={
                                new Date(
                                  new Date(
                                    this.state.startDate || new Date().getTime()
                                  ).getTime() +
                                    86400000 * 60
                                )
                              }
                              selected={this.state.closingDate}
                              onChange={(date) => {
                                this.setState({
                                  closingDate: new Date(date).getTime(),
                                });
                              }}
                              dateFormat="Y-M-d"
                              placeholderText="Pick Closing Date"
                              className="date-picker"
                              autoComplete="off"
                              required
                            />
                            <img
                              src="/assets/images/icons/event.png"
                              alt="Event"
                              className="icon"
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.closing_date.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-min-investment">
                              Min investment *
                            </label>
                            <input
                              id="opportunity-min-investment"
                              type="number"
                              name="min_investment"
                              value={this.state.minInvestment}
                              min="0"
                              step=".01"
                              placeholder="Minimum investment (₦)"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  minInvestment: e.target.value,
                                });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.min_investment.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-max-investment">
                              Max investment *
                            </label>
                            <input
                              id="opportunity-max-investment"
                              type="number"
                              name="max_investment"
                              value={this.state.maxInvestment}
                              min="0"
                              step=".01"
                              placeholder="Maximum investment (₦)"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  maxInvestment: e.target.value,
                                });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.max_investment.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field">
                            <label htmlFor="opportunity-amount">
                              Amount to be Raised *
                            </label>
                            <input
                              id="opportunity-amount"
                              type="number"
                              name="amount"
                              value={this.state.amount}
                              placeholder="Amount (₦)"
                              min="0"
                              step=".01"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ amount: e.target.value });
                              }}
                              required
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html: this.state.error.amount.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field">
                            <label htmlFor="opportunity-description">
                              Description of the financial conditions *
                            </label>
                            <textarea
                              id="opportunity-description-of-financial-condition"
                              name="description_of_financial_condition"
                              value={this.state.descriptionOfFinancialCondition}
                              placeholder="Description of the financial conditions"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  descriptionOfFinancialCondition:
                                    e.target.value,
                                });
                              }}
                            />
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.description_of_financial_condition.join(
                                    "<br />"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="step">
                  {this.stepIndex("images") <=
                    this.stepIndex(this.state.step) && (
                    <div
                      className="heading"
                      onClick={() => {
                        this.goToStep("images");
                      }}
                    >
                      <span className="number">3</span>
                      <span className="title">Upload Property Images</span>
                    </div>
                  )}
                  {this.state.step === "images" && (
                    <div className="content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="images">
                            {this.state.propertyImages.map((image, index) => (
                              <div key={index} className="field">
                                <label
                                  htmlFor={
                                    "opportunity-property-image-" + index
                                  }
                                >
                                  {
                                    this.state[
                                      "propertyImages." + index + "DisplayName"
                                    ]
                                  }
                                </label>
                                <input
                                  type="hidden"
                                  name="property_images[]"
                                  value={this.state["propertyImages." + index]}
                                />
                                <input
                                  ref={(propertyImagesFileInput) => {
                                    this[
                                      "propertyImages." + index + "FileInput"
                                    ] = propertyImagesFileInput;
                                  }}
                                  type="file"
                                  name="property_images_file[]"
                                  className="display-none"
                                  accept="image/x-png, image/jpeg"
                                  onChange={(e) => {
                                    this.uploadFile(
                                      e.target,
                                      "propertyImages." + index
                                    );
                                  }}
                                />
                                <div
                                  className={
                                    "image" +
                                    (this.state[
                                      "propertyImages." +
                                        index +
                                        "UploadProgress"
                                    ]
                                      ? " uploading"
                                      : "")
                                  }
                                  style={{
                                    backgroundImage:
                                      'url("' +
                                      this.state[
                                        "propertyImages." + index + "Url"
                                      ] +
                                      '")',
                                  }}
                                  onClick={() => {
                                    this[
                                      "propertyImages." + index + "FileInput"
                                    ].click();
                                  }}
                                >
                                  <div
                                    className="progress"
                                    style={{
                                      height:
                                        "calc(100% - " +
                                        (this.state.propertyImages[index]
                                          ? 100
                                          : Math.ceil(
                                              this.state[
                                                "propertyImages." +
                                                  index +
                                                  "UploadProgress"
                                              ] * 100
                                            )) +
                                        "%)",
                                    }}
                                  />
                                </div>
                                <div
                                  className="error"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      this.state.error[
                                        "property_images." + index
                                      ].join("<br />"),
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field">
                            <div className="checkbox">
                              <input
                                ref={(confirmation) =>
                                  (this.confirmation = confirmation)
                                }
                                id="account-confirmation"
                                type="checkbox"
                                name="confirmation"
                                value="1"
                                checked={this.state.confirmation}
                                onChange={(e) => {
                                  this.setState({
                                    confirmation: !this.state.confirmation,
                                  });
                                }}
                                required
                              />
                              <label htmlFor="account-confirmation">
                                I confirm that all information provided are
                                truthful and correct
                              </label>
                            </div>
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.error.confirmation.join("<br />"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="controls">
                  <div className="row">
                    <div className="col-md-8" />
                    <div className="col-md-4">
                      {this.stepIndex(this.state.step) <
                      this.state.steps.length - 1 ? (
                        <button
                          type="button"
                          className="next"
                          onClick={() => {
                            this.nextStep();
                          }}
                        >
                          Continue
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="submit"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          Complete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
