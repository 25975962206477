import React from "react";
import "./index.css";

const InvestorDeveloperSmallCard = ({ imageUrl, title, description }) => {
  return (
    <div className="investor-developer-small-card-container">
      <img
        // src={require(`${imageUrl}`).default}
        src={
          // require(`../../../assets/images/vectors/icons/${imageUrl}.svg`)
          //   .default
          require("../../../assets/images/vectors/icons/" + imageUrl + ".svg")

        }
        alt="icon"
        className="investor-developer-card-icon"
      />
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
};

export default InvestorDeveloperSmallCard;

// "../../../assets/images/vectors/icons/user-avatar-in-circle.svg";
