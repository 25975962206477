import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import $ from "jquery";

export default class VideoViewer extends React.Component {
    constructor(props) {
        super(props);
        VideoViewer.contextType = Context;
        this.state = {

        }
    }

    youtubeIdFromLink(url) {
        url = url || '';
        let match = url.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/)
        return match ? match[1] : '';
    }

    render() {
        return (
            <div id="video-viewer-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" aria-label="Close" onClick={() => {
                            $('#video-viewer-modal video').each((index, video) => video.pause());
                            this.context.updateContext('videoViewerModalSrc', '');
                            $('#video-viewer-modal').modal('hide');
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {this.context.videoViewerModalType === 'youtube' ? (
                            <iframe title="YouTube Player" width="560" height="315" src={'https://www.youtube.com/embed/' + this.youtubeIdFromLink(this.context.videoViewerModalSrc) + '?rel=0'} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        ) : (
                            <video controls width="560" height="315" className="video">
                                <source src={this.context.videoViewerModalSrc} type={this.context.videoViewerModalSrcMimeType || 'video/mp4'} />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        )}
                        <div className="title">{this.context.videoViewerModalTitle}</div>
                        <div className="description">{this.context.videoViewerModalDescription}</div>
                    </div>
                </div>
            </div>
        );
    }
}
