import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "./../assets/style.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "slick-carousel/slick/slick.min.js";
import React from "react";
import Navigation from "./Navigation";
import { Context, context } from "./Context";
import Cookie from "./Utils/Cookie";
import Hook from "./Utils/Hook";
import Api from "./Utils/Api";
import Push from "./Utils/Push";
import UI from "./Utils/UI";
import User from "./../../User/Components/Utils";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    App.contextType = Context;
    this.serviceWorkerRegistration = null;
    this.updateContext = (key, value, callback) => {
      let _context = localStorage.getItem("context");
      try {
        _context = JSON.parse(_context);
        if (!_context) {
          _context = {};
        }
      } catch (e) {
        _context = {};
      }
      this.setState(
        (state) => ({ [key]: (state[key] = value) }),
        () => {
          if (typeof callback === "function") {
            callback.apply();
          }
          if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
              registration.active.postMessage({
                type: "context",
                data: JSON.parse(JSON.stringify(this.state)),
              });
            });
          }
        }
      );
      _context[key] = value;
      localStorage.setItem("context", JSON.stringify(_context));
    };
    let __context = localStorage.getItem("context");
    try {
      __context = JSON.parse(__context);
    } catch (e) {
      __context = {};
    }
    this.state = {
      ...__context,
      ...context,
      updateContext: this.updateContext,
    };
  }

  componentDidMount() {
    Hook.register("api.mock.response", (result, id, params, data) => {});
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('p');
    this.state.updateContext('partner', myParam)
    this.fetchSettings();
    $(() => {
      this.documentReady();
    });
  }

  fetchSettings() {
    let settings;
    Api.fetch("setting.list")
      .then(({ status, responseJSON }) => {
        if (status === 200 && responseJSON.data) {
          settings = responseJSON.data;
          Api.fetch("investment.setting.list")
            .then(({ status, responseJSON }) => {
              if (status === 200 && responseJSON.data) {
                settings["investment"] = responseJSON.data;
                this.state.updateContext("settings", settings, () => {
                  this.settingsReady();
                });
              }
            })
            .catch((reason) => {
              if (settings) {
                this.state.updateContext("settings", settings, () => {
                  this.settingsReady();
                });
              }
              // console.debug(reason);
            });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
  }

  fetchConstants() {
    Api.fetch("user.constant.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{ user: responseJSON.data || {} },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("opportunity.constant.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{ opportunity: responseJSON.data || {} },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("opportunity.investment.constant.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{ opportunityInvestment: responseJSON.data || {} },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("property.constant.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              property: {
                ...((this.state.constants && this.state.constants.property) ||
                  {}),
                ...(responseJSON.data || {}),
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("property.category.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              property: {
                ...((this.state.constants && this.state.constants.property) ||
                  {}),
                ...{ categories: responseJSON.data || {} },
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("property.feature.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              property: {
                ...((this.state.constants && this.state.constants.property) ||
                  {}),
                ...{ features: responseJSON.data || {} },
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("locale.country.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              locale: {
                ...((this.state.constants && this.state.constants.locale) ||
                  {}),
                ...{ countries: responseJSON.data || {} },
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("payment.bank.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              payment: {
                ...((this.state.constants && this.state.constants.payment) ||
                  {}),
                ...{ banks: responseJSON.data || {} },
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("support.constant.list")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              support: {
                ...((this.state.constants && this.state.constants.support) ||
                  {}),
                ...{ ticket: responseJSON.data || {} },
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
    Api.fetch("support.portal.admin")
      .then(({ status, responseJSON }) => {
        if (status === 200) {
          this.state.updateContext("constants", {
            ...(this.state.constants || {}),
            ...{
              support: {
                ...((this.state.constants && this.state.constants.support) ||
                  {}),
                ...{ portalAdmin: responseJSON || {} },
              },
            },
          });
        }
      })
      .catch((reason) => {
        // console.debug(reason);
      });
  }

  documentReady() {
    Hook.register("route.changed", (result, newLocation, oldLocation) => {
      $(".tooltip.show").remove();
      window.scrollTo(0, 0);
      setTimeout(() => {
        UI.slick();
        UI.animationEntry();
      });
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        navigator.serviceWorker.addEventListener("message", (event) => {
          // console.log(event.data)
          let message =
            event.data && event.data.data && event.data.data.message;
          try {
            message = JSON.parse(message);
          } catch (e) {}
          if (message) {
            // console.log(message);
            Push.onMessage(message);
          }
        });
      });
    }

    Hook.register("user.logged.in", (result, user, context) => {
      User.syncUser(user, context).then(() => {});
    });

    Hook.register("user.updated", (result, user, context) => {
      User.syncUser(user, context).then(() => {});
    });

    if (typeof Notification !== "undefined") {
      if (Notification.permission === "default") {
        try {
          Notification.requestPermission().then((permission) => {
            if (!("permission" in Notification)) {
              Notification.permission = permission;
            }
          });
        } catch (e) {
          Notification.requestPermission((permission) => {
            if (!("permission" in Notification)) {
              Notification.permission = permission;
            }
          });
        }
      }
    }

    let hasAcknowledgedRisk = Cookie.getCookie("has_acknowledged_risk");
    if (!hasAcknowledgedRisk) {
      $("#risk-disclosure-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    }
    $(document).on("focus", "#investment-modal-form input", (e) => {
      $(e.target).closest(".form-group").addClass("float");
    });

    $(document).on("shown.bs.modal", "#investment-modal", function () {
      $("#investment-modal-form input").each((index, input) => {
        $(input).trigger("focus");
      });
    });

    $(document).on("focus", "#investment-modal-form input", (e) => {
      $(e.target).closest(".form-group").addClass("float");
    });

    $(document).on("blur", "#investment-modal-form input", (e) => {
      if (!$(e.target).val().length) {
        $(e.target).closest(".form-group").removeClass("float");
      }
    });

    $(document).on("click", ".navbar-nav .nav-link, .navbar-brand", (e) => {
      $(e.target).closest(".navbar").find(".navbar-collapse").collapse("hide");
    });

    $(document).on("click", ".active-switch > *", (e) => {
      $(e.target).addClass("active").siblings().removeClass("active");
    });

    $(document).on("click", ".active-switcher", (e) => {
      e.preventDefault();
      let button = $(e.target).closest(".active-switcher");
      $($(button).data("selector"))
        .addClass("active")
        .siblings()
        .removeClass("active");
    });

    $(document).on("click", ".print-page-button", (e) => {
      window.print();
    });

    $(document).on("click", ".toggle-class", (e) => {
      e.preventDefault();
      let button = $(e.target).closest(".toggle-class");
      $($(button).data("selector")).toggleClass($(button).data("class"));
    });

    $(document).on("click", "#faq .question button", (e) => {
      e.preventDefault();
      $(e.target).closest(".question").toggleClass("open");
    });

    $(document).on("click", ".nav-container .nav-toggler", (e) => {
      e.preventDefault();
      $(e.target).closest(".nav-container").toggleClass("open");
    });

    $(document).on("click", ".nav-container .nav-link", (e) => {
      $(e.target).closest(".nav-container").removeClass("open");
    });

    $(document).on("click", ".pills-container .pills-toggler", (e) => {
      $(e.target).closest(".pills-container").toggleClass("open");
    });

    $(document).on("click", ".pills-container .pill", (e) => {
      $(e.target).closest(".pills-container").removeClass("open");
    });

    $(document).on("click", ".admin-nav-toggler", (e) => {
      e.preventDefault();
      $(e.target).closest("#layout.user").toggleClass("nav-opened");
    });

    $(document).on("click", "#aside .nav-link", (e) => {
      $(e.target).closest("#layout.user").removeClass("nav-opened");
    });

    $(document).on(
      "click",
      ".image-carousel-container .controls .thumbs .thumb",
      (e) => {
        $(e.target)
          .closest(".image-carousel-container")
          .find(".image-carousel")
          .carousel($(e.target).data("slide-to"));
      }
    );

    $(document).on("slide.bs.carousel", ".image-carousel", (e) => {
      $(e.target)
        .closest(".image-carousel-container")
        .find(".controls .thumbs .thumb")
        .removeClass("active");
      $(e.target)
        .closest(".image-carousel-container")
        .find('.controls .thumbs .thumb[data-slide-to="' + e.to + '"]')
        .addClass("active");
    });

    $(document).on(
      "click",
      ".image-carousel-container .controls .left-arrow",
      (e) => {
        const thumbs = $(e.target)
          .closest(".image-carousel-container")
          .find(".controls .thumbs")
          .get(0);
        thumbs.scroll({
          top: thumbs.scrollTop,
          left: thumbs.scrollLeft - 100,
          behavior: "smooth",
        });
      }
    );

    $(document).on(
      "click",
      ".image-carousel-container .controls .right-arrow",
      (e) => {
        const thumbs = $(e.target)
          .closest(".image-carousel-container")
          .find(".controls .thumbs")
          .get(0);
        thumbs.scroll({
          top: thumbs.scrollTop,
          left: thumbs.scrollLeft + 100,
          behavior: "smooth",
        });
      }
    );

    $(document).on("mouseover", ".image-carousel-container .controls", (e) => {
      const controls = $(e.target).closest(".controls").get(0);
      if (
        controls.querySelector("img") &&
        (controls.querySelector("img:first-child").getBoundingClientRect()
          .left < controls.getBoundingClientRect().left ||
          controls.querySelector("img:last-child").getBoundingClientRect()
            .right > controls.getBoundingClientRect().right)
      ) {
        $(controls).addClass("show-arrows");
      }
    });

    $(document).on("mouseout", ".image-carousel-container .controls", (e) => {
      $(e.target).closest(".controls").removeClass("show-arrows");
    });

    $(document).on(
      "click",
      "#main.opportunity.listing .list .item .toggle-details",
      (e) => {
        $(e.target).closest(".item").toggleClass("show-details");
      }
    );

    $(window).on("scroll resize", UI.animationEntry);

    $('[data-toggle="tooltip"]').tooltip();

    UI.slick();
    UI.animationEntry();
  }

  settingsReady() {
    this.fetchConstants();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update().then(() => {
          registration.active.postMessage({
            type: "context",
            data: JSON.parse(JSON.stringify(this.state)),
          });
        });
      });
    }
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        <Navigation />
      </Context.Provider>
    );
  }
}

export { Context };
