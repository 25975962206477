import React from "react";

const ProgressBarCustom = ({
  color,
  backgroundColor,
  progress,
  height,
  borderRadius,
  parentStyles,
  childStyles,
}) => {
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    ...parentStyles,
  };

  const Childdiv = {
    height: "100%",
    width: "100%",
    maxWidth: `${progress}%`,
    backgroundColor: color,
    borderRadius: borderRadius,
    textAlign: "right",
    ...childStyles,
  };

  //  const progresstext = {
  //  padding: 10,
  //  color: "black",
  //  fontWeight: 900,
  //  };
  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        {/* <span style={progresstext}>{`${progress}%`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBarCustom;
