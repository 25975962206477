import React from 'react';
import MortgageCalculator from './Components/Pages/MortgageCalculator';

export default class Resource extends React.Component {
    render() {
        return (
            <MortgageCalculator />
        );
    }
}
