import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import Document from './../../../App/Components/Utils/Document';
import Api from './../../../App/Components/Utils/Api';

export default class VirtualTourView extends React.Component {
    constructor(props) {
        super(props);
        VirtualTourView.contextType = Context;
        this.state = {
            roomsOpened: true,
            activeRoomIndex: 0,
            activeImageIndex: 0,
            tour: null
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'blank');
        Document.updateDocumentHead({title: 'PropCrowdy Virtual Tour'});
        window.frames['virtual-tour-iframe'].document.body.style.margin = '0';
    }

    componentWillUnmount() {

    }

    fetchTour(update) {
        if(!update) {
            this.setState({fetching: true});
        }

        Api.fetch('tour.view', undefined, {token: window.location.pathname.split('/')[4]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({id: responseJSON.data});
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    render() {
        return (
            <main id="main" className="resource virtual-tour view">
                {this.state.tour ? (
                    <>
                        <ul className={'rooms' + (this.state.roomsOpened ? ' opened' : '')}>
                            {this.state.tour.rooms.map((room, roomIndex) => (
                                <li key={roomIndex} className={'room' + (this.state.activeRoomIndex === roomIndex ? ' active' : '')}>
                                    <span className="bullet">{roomIndex + 1}.</span>
                                    <button className="name" onClick={() => {this.setState({activeRoomIndex: roomIndex, activeImageIndex: 0})}}>{room.name}</button>
                                    <ul className="images">
                                        {room.images.map((image, imageIndex) => (
                                            <li key={imageIndex} className="image">
                                                <span className="bullet">&bull;</span>
                                                <button onClick={() => {this.setState({activeImageIndex: imageIndex})}}>View {imageIndex + 1}</button>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                            <li className="toggle">
                                <button onClick={() => {this.setState({roomsOpened: !this.state.roomsOpened})}}>
                                    <i className="icon las la-angle-up" />
                                </button>
                            </li>
                        </ul>
                        <iframe key={this.state.activeRoomIndex + '.' + this.state.activeImageIndex} id="virtual-tour-iframe" name="virtual-tour-iframe" title="Virtual Tour" src={'/assets/pannellum/pannellum.htm#panorama=' + this.state.tour.rooms[this.state.activeRoomIndex].images[this.state.activeImageIndex].url} onLoad={(e) => {
                            e.target.contentWindow.document.querySelector('.pnlm-load-button').click();
                        }} />
                    </>
                ) : null}
            </main>
        );
    }
}
