import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import {Link} from 'react-router-dom'
import FinancialInfo from './../../../Investor/Components/Templates/FinancialInfo';
import AgencyEdit from './../../../Developer/Components/Templates/AgencyEdit';
import _ from 'lodash';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';

export default class Account extends React.Component {
    constructor(props) {
        super(props);
        Account.contextType = Context;
        this.minAge = 18;
        this.yearRange = 100;
        this.photoFileInput = null;
        this.proofOfAnnualIncomeFileInput = null;
        this.state = {
            isSmallScreen: window.innerWidth <= 768,
            tab: 'profile',
            showOldPassword: false,
            showPassword: false,
            showPasswordConfirm: false,
            photo: '',
            photoDisplayName: '',
            photoUploadProgress: 0,
            photoUrl: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            gender: '',
            birthDay: '',
            birthMonth: '0',
            birthYear: '0',
            address: '',
            oldPassword: '',
            password: '',
            passwordConfirmation: '',
            message: '',
            error: {
                photo: [],
                first_name: [],
                last_name: [],
                email: [],
                phone_number: [],
                gender: [],
                birth_day: [],
                birth_month: [],
                birth_year: [],
                date_of_birth: [],
                address: [],
                old_password: [],
                password: [],
                password_confirmation: [],
            },
            submitting: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'user.buy' ? 'user.buy' : 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Update Profile');
        Document.updateDocumentHead({title: 'PropCrowdy - Account'});
        let dateOfBirth = (this.context.user.date_of_birth || '').split('-');
        this.setState({
            tab: ['agency', 'payment', 'security'].indexOf(window.location.pathname.split('/')[2]) !== -1 ? window.location.pathname.split('/')[2] : 'profile',
            firstName: this.context.user.first_name || '',
            lastName: this.context.user.last_name || '',
            email: this.context.user.email || '',
            phoneNumber: this.context.user.phone_number || '',
            gender: this.context.user.gender || '',
            birthDay: dateOfBirth[2] || '',
            birthMonth: dateOfBirth[1] || '0',
            birthYear: dateOfBirth[0] || '0',
            address: this.context.user.address || ''
        });
        window.addEventListener('resize', this.onResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        this.setState({isSmallScreen: window.innerWidth <= 768});
    }

    changeTab(tab) {
        if(!this.state.submitting) {
            let error = this.state.error;
            for(let i in error) {
                if(error.hasOwnProperty(i)) {
                    error[i] = [];
                }
            }
            this.setState({message: '', error: error, tab: tab});
        }
    }

    uploadFile(fileHandle, key, callback) {
        let setKey = (value) => {
            let state = this.state;
            _.set(state, key, value);
            this.setState(state, () => {
                if(typeof callback === 'function') {
                    callback.apply();
                }
            });
        }
        let formData = new FormData();
        if (fileHandle.files.length) {
            this.setState({[key + 'UploadProgress']: 0.1, [key + 'DisplayName']: 'Uploading... (0%)', [key + 'Url']: URL.createObjectURL(fileHandle.files[0]), message: ''}, () => {
                setKey('');
            });
            fileHandle.disabled = true;
            formData.append('file', fileHandle.files[0]);
            Api.fetch('file.upload', undefined, formData, undefined, (progress) => {
                this.setState({[key + 'UploadProgress']: progress, [key + 'DisplayName']: 'Uploading... (' + Math.ceil(progress * 100) + '%)'});
            }).then(({status, responseJSON}) => {
                fileHandle.disabled = false;
                if(status === 200) {
                    this.setState({[key + 'DisplayName']: responseJSON.original_name, [key + 'UploadProgress']: 0}, () => {
                        setKey(responseJSON.name, () => {
                            this.validateSteps([this.state.step]).then(() => {}).catch(() => {});
                        });
                    });
                } else {
                    this.setState({[key + 'DisplayName']: '', [key + 'UploadProgress']: 0, [key + 'Url']: '/assets/images/image-placeholder.png', message: responseJSON.message || ''});
                }
            }).catch((reason) => {
                this.setState({[key + 'DisplayName']: '', [key + 'UploadProgress']: 0, [key + 'Url']: '/assets/images/image-placeholder.png'});
                fileHandle.disabled = false;
                let {status, responseJSON} = typeof reason === 'object' ? reason : {};
                if ([403, 422].indexOf(status) !== -1) {
                    let error = {};
                    if(responseJSON.error && responseJSON.error.file) {
                        error[key => key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)] = responseJSON.error['file'];
                    }
                    this.setState({error: {...this.state.error, ...error}, message: responseJSON.message || ''});
                } else {
                    console.debug(reason);
                    this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
                }
            });
        } else {
            this.setState({[key + 'DisplayName']: '', [key + 'Url']: '/assets/images/image-placeholder.png'}, () => {
                setKey('');
            });
        }
    }

    changePassword() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if (typeof this.state.oldPassword !== 'string' || this.state.oldPassword.trim() === '') {
            error.old_password.push('Please provide the current password');
        }

        if (typeof this.state.password !== 'string' || this.state.password.trim() === '') {
            error.password.push('Please provide a password');
        } else if (this.state.password.trim().length < 10) {
            error.password.push('Password must contain at least 10 characters');
        } else if (!this.state.password.match(/[a-z]/) || !this.state.password.match(/[A-Z]/) || !this.state.password.match(/[0-9]/) || !this.state.password.match(/[^a-zA-Z0-9\s]/)) {
            error.password.push('Password must contain at least a uppercase and lowercase alphabet, a number, and a symbol');
        }

        if (this.state.password !== this.state.passwordConfirmation) {
            error.password_confirmation.push('Password confirmation does not match');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        let data = {
            old_password: this.state.oldPassword,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation
        }
        Api.fetch('user.password.change', undefined, data).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                UI.alert({title: 'Success', content: 'Password changed successfully'}, this.context);
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...this.state.error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    updateProfile() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if(this.state.photoUploadProgress) {
            error.photo.push('Please wait for the upload to finish');
        }

        if (typeof this.state.firstName !== 'string' || this.state.firstName.trim() === '') {
            error.first_name.push('Please provide your first name');
        } else if (!this.state.firstName.trim().match(/^[a-zA-Z-]{2,}$/gi)) {
            error.first_name.push('Please provide a valid first name');
        }

        if (typeof this.state.lastName !== 'string' || this.state.lastName.trim() === '') {
            error.last_name.push('Please provide your last name');
        } else if (!this.state.lastName.trim().match(/^[a-zA-Z-]{2,}$/gi)) {
            error.last_name.push('Please provide a valid last name');
        }

        if (typeof this.state.email !== 'string' || this.state.email.trim() === '') {
            error.email.push('Please provide your email address');
        } else if (!(typeof this.state.email === 'string' && this.state.email.match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi))) {
            error.email.push('Please provide a valid email');
        }

        if (typeof this.state.phoneNumber !== 'string' || this.state.phoneNumber.trim() === '') {
            error.phone_number.push('Please provide a phone number');
        } else if (!this.state.phoneNumber.match(/^\+?[0-9\s]{8,15}/gi)) {
            error.phone_number.push('Please provide a valid phone number');
        }

        if (typeof this.state.birthDay !== 'string' || this.state.birthDay.trim() === '') {
            error.birth_day.push('Please provide a birth day');
        } else if (!this.state.birthDay.match(/^[0-9]+/gi) || parseInt(this.state.birthDay) < 1 || parseInt(this.state.birthDay) > 31) {
            error.birth_day.push('Please provide a valid birth day');
        }

        if (typeof this.state.birthMonth !== 'string' || this.state.birthMonth.trim() === '') {
            error.birth_month.push('Please provide a birth month');
        } else if (!this.state.birthMonth.match(/^[0-9]+/gi) || parseInt(this.state.birthMonth) < 1 || parseInt(this.state.birthMonth) > 12) {
            error.birth_month.push('Please provide a valid birth month');
        }

        if (typeof this.state.address !== 'string' || this.state.address.trim() === '') {
            error.address.push('Please provide your address');
        }

        if (typeof this.state.birthYear !== 'string' || this.state.birthYear.trim() === '') {
            error.birth_year.push('Please provide a birth year');
        } else if (!this.state.birthYear.match(/^[0-9]+/gi) || parseInt(this.state.birthYear) < new Date().getFullYear() - this.minAge - this.yearRange || parseInt(this.state.birthYear) > new Date().getFullYear() - this.minAge) {
            error.birth_year.push('Please provide a valid birth year');
        }

        if (typeof this.state.gender !== 'string' || this.state.gender.trim() === '') {
            error.gender.push('Please select a gender');
        } else {
            let genders = [];
            ((this.context.constants && this.context.constants.user && this.context.constants.user.gender) || []).map(type => genders.push(type.value));
            if (genders.indexOf(this.state.gender) === -1) {
                error.gender.push('Please provide a valid gender');
            }
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        let data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone_number: this.state.phoneNumber,
            gender: this.state.gender,
            date_of_birth: this.state.birthYear.padStart(4, '0') + '-' + this.state.birthMonth.padStart(2, '0') + '-' + this.state.birthDay.padStart(2, '0'),
            address: this.state.address || ''
        }
        if(this.state.photo) {
            data['photo'] = this.state.photo;
        }
        Api.fetch('user.edit', undefined, data).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                let user = this.context.user;
                this.setState({photo: '', photoDisplayName: '', photoUploadProgress: 0, photoUrl: ''});
                this.context.updateContext('user', user, () => {
                    Hook.fire('user.updated', undefined, [user, this.context]);
                });
                UI.alert({title: 'Success', content: 'Profile updated successfully'}, this.context);
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...this.state.error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <main id="main" className="account user">
                <div className="container">
                    <div className="box">
                        <div className="heading">
                            <div className="title">Setting</div>
                            <div className="tabs">
                                <Link to={{pathname: '/account/profile'}} className={'tab' + (this.state.tab === 'profile' ? ' active' : '')} onClick={() => {this.changeTab('profile')}}>Profile</Link>
                                <Link to={{pathname: '/account/security'}} className={'tab' + (this.state.tab === 'security' ? ' active' : '')} onClick={() => {this.changeTab('security')}}>Security</Link>
                                {this.context.user.customer_type === 'developer' ? (
                                    <Link to={{pathname: '/account/agency'}} className={'tab' + (this.state.tab === 'agency' ? ' active' : '')} onClick={() => {this.changeTab('agency')}}>Agency Details</Link>
                                ) : (
                                    <Link to={{pathname: '/account/payment'}} className={'tab' + (this.state.tab === 'payment' ? ' active' : '')} onClick={() => {this.changeTab('payment')}}>Financial Information</Link>
                                )}
                            </div>
                        </div>
                        {this.state.tab === 'profile' && (
                            <div className="content">
                                <form className="user account" onSubmit={(e) => {e.preventDefault(); this.updateProfile()}}>
                                    {this.state.submitting && <Loading />}
                                    <div className="message">{this.state.message}</div>
                                    <div className="sub-heading first">General Settings</div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-photo">Profile Picture</label>
                                                <div className="info">Update Picture</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">Profile Photo Recommended Size: 400x400 pixels</div>
                                                <input id="account-photo" type="text" name="photo_display_name" value={this.state.photoDisplayName} placeholder="Profile Photo" className="form-control" disabled onChange={(e) => {this.setState({photoDisplayName: e.target.value})}} />
                                                <input type="hidden" name="photo" value={this.state.photo} />
                                                <input ref={(photoFileInput) => {this.photoFileInput = photoFileInput}} type="file" name="photo_file" className="display-none" accept="image/x-png, image/jpeg" onChange={(e) => {this.uploadFile(e.target, 'photo')}} />
                                                <button type="button" className="upload-button upload" style={{boxShadow: (this.state.photo ? (this.state.isSmallScreen ? 110 : 200) : Math.ceil(this.state.photoUploadProgress * (this.state.isSmallScreen ? 110 : 200))) + 'px 0 rgba(0, 0, 0, .5) inset'}} onClick={() => {this.photoFileInput.click()}}>Choose File</button>
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.photo.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label>Edit Full Name</label>
                                                <div className="info">This is the name that appears on every transaction</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="info">First Name*</div>
                                                        <input id="account-first-name" type="text" name="first_name" value={this.state.firstName} placeholder="First Name" className="form-control" onChange={(e) => {this.setState({firstName: e.target.value})}} required />
                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.first_name.join('<br />')}} />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="info">Last Name*</div>
                                                        <input id="account-last-name" type="text" name="last_name" value={this.state.lastName} placeholder="Last Name" className="form-control" onChange={(e) => {this.setState({lastName: e.target.value})}} required />
                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.last_name.join('<br />')}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-email">Email Address</label>
                                                <div className="info">This is the mail we send transaction notifications and other updates</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">Email*</div>
                                                <input id="account-email" type="email" name="email" value={this.state.email} placeholder="Email Address" className="form-control" onChange={(e) => {this.setState({email: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-phone-number">Phone Number</label>
                                                <div className="info">This is the number we send SMS verification messages</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">Phone Number*</div>
                                                <input id="account-phone-number" type="text" name="phone_number" value={this.state.phoneNumber} pattern="^\+?[0-9\s]{8,15}" placeholder="Phone Number" className="form-control" onChange={(e) => {this.setState({phoneNumber: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.phone_number.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label>Gender</label>
                                                <div className="info">What is your gender?</div>
                                            </div>
                                            <div className="col-xl-6">
                                                {((this.context.constants && this.context.constants.user && this.context.constants.user.gender) || []).map((gender, index) => (
                                                    <div key={index} className="radio">
                                                        <input id={'account-gender-' + gender.value} type="radio" name="gender" value={gender.value} checked={this.state.gender === gender.value} onChange={(e) => {this.setState({gender: e.target.value})}} required />
                                                        <label style={{position:'relative !important'}} htmlFor={'account-gender-' + gender.value}>{gender.display}</label>
                                                    </div>
                                                ))}
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.gender.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label>Date of Birth</label>
                                                <div className="info">For birthday emails</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="info">Day*</div>
                                                        <input id="account-birth-day" type="text" name="birth_day" value={this.state.birthDay} pattern="[0-9]{1,2}" placeholder="Day" className="form-control" onChange={(e) => {this.setState({birthDay: e.target.value})}} required />
                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.birth_day.join('<br />')}} />
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="info">Month*</div>
                                                        <select id="account-birth-month" name="birth_month" value={this.state.birthMonth} placeholder="Month" className="form-control" onChange={(e) => {this.setState({birthMonth: e.target.value})}} required>
                                                            <option value="0" disabled>Month</option>
                                                            {_.range(12).map((value, index) => (
                                                                <option key={index} value={(value + 1).toString()}>{Time.months[value]}</option>
                                                            ))}
                                                        </select>
                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.birth_month.join('<br />')}} />
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="info">Year*</div>
                                                        <select id="account-birth-year" name="birth_year" value={this.state.birthYear} placeholder="Year" className="form-control" onChange={(e) => {this.setState({birthYear: e.target.value})}} required>
                                                            <option value="0" disabled>Year</option>
                                                            {_.range(this.yearRange).map((value, index) => (
                                                                <option key={index} value={(new Date().getFullYear() - this.minAge - value).toString()}>{new Date().getFullYear() - 18 - value}</option>
                                                            ))}
                                                        </select>
                                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.birth_year.join('<br />')}} />
                                                    </div>
                                                </div>
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.date_of_birth.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-address">Address</label>
                                                <div className="info">For generating invoice</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">Address*</div>
                                                <input id="account-address" type="text" name="address" value={this.state.address} placeholder="Address" className="form-control" onChange={(e) => {this.setState({address: e.target.value})}} required />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.address.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit">
                                        <div className="row">
                                            <div className="col-xl-6" />
                                            <div className="col-xl-6">
                                                <input type="submit" value="Update" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {this.state.tab === 'security' && (
                            <div className="content">
                                <form className="user account" onSubmit={(e) => {e.preventDefault(); this.changePassword()}}>
                                    {this.state.submitting && <Loading />}
                                    <div className="message">{this.state.message}</div>
                                    <div className="sub-heading first">Change Password</div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-old-password">Current Password</label>
                                                <div className="info">Enter current password</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">Current Password*</div>
                                                <input id="account-old-password" type={this.state.showOldPassword ? 'text' : 'password'} name="old_password" value={this.state.oldPassword} placeholder="Current Password" className="form-control" onChange={(e) => {this.setState({oldPassword: e.target.value})}} required />
                                                <img src={this.state.showOldPassword ? '/assets/images/icons/eye-opened.png' : '/assets/images/icons/eye-closed.png'} alt="Eye" className="icon" onClick={() => {this.setState({showOldPassword: !this.state.showOldPassword})}} />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.old_password.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-password">New Password</label>
                                                <div className="info">Enter new password</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">New Password*</div>
                                                <input id="account-password" type={this.state.showPassword ? 'text' : 'password'} name="password" value={this.state.password} placeholder="New Password" onChange={(e) => {this.setState({password: e.target.value})}} required />
                                                <img src={this.state.showPassword ? '/assets/images/icons/eye-opened.png' : '/assets/images/icons/eye-closed.png'} alt="Eye" className="icon" onClick={() => {this.setState({showPassword: !this.state.showPassword})}} />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.password.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <label htmlFor="account-password-confirmation">New Password Confirmation</label>
                                                <div className="info">Re-enter new password</div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="info">New Password Confirmation*</div>
                                                <input id="account-password-confirmation" type={this.state.showPasswordConfirm ? 'text' : 'password'} name="password_confirmation" value={this.state.passwordConfirmation} placeholder="New Password Confirmation" onChange={(e) => {this.setState({passwordConfirmation: e.target.value})}} required />
                                                <img src={this.state.showPasswordConfirm ? '/assets/images/icons/eye-opened.png' : '/assets/images/icons/eye-closed.png'} alt="Eye" className="icon" onClick={() => {this.setState({showPasswordConfirm: !this.state.showPasswordConfirm})}} />
                                                <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.password_confirmation.join('<br />')}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit">
                                        <div className="row">
                                            <div className="col-xl-6" />
                                            <div className="col-xl-6">
                                                <input type="submit" value="Change" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {this.state.tab === 'payment' && (
                            <div className="content">
                                <FinancialInfo />
                            </div>
                        )}
                        {this.state.tab === 'agency' && (
                            <div className="content">
                                <AgencyEdit />
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}
