import React, { useEffect, useState } from "react";
import join from "../../../../../src/Cards.svg";
import badge from "../../../../../src/badge.svg";
import Api from "../Utils/Api";
import axios from "axios";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

const notifysuccess = (msg) => toast(msg);
const notifyfail = (msg) => toast(msg);

const Waitlist = (prop) => {
  const [customerTypes, setCustomerTypes] = useState([]);

  useEffect(() => {
    // prop.context = ''

    const context = JSON.parse(localStorage.getItem("context"));
    if (context) {
      const customerTypesArray = context.constants.user.customer_type;

      setCustomerTypes(customerTypesArray);
    }
  }, []);

  const [state, setState] = useState({
    first_name: "",
    email: "",
    last_name: "",
    phone_number: "",
    // checkbox: false,
    customer_type: "developer",
  });
  const [errors, setErrors] = useState({
    first_name: [],
    email: [],
    last_name: [],
    phone_number: [],
  });
  const onChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCustomerTypeChange = (e) => {
    setState((prev) => ({
      ...prev,
      customer_type: e.target.value,
    }));
  };

  return (
    <div>
      {/* <ToastContainer /> */}
      <Toaster />
      <div
        style={{
          // minHeight: '100vh',
          width: "100vw",
        }}
        className="waitlist-container"
      >
        <div className="waitlist-left">
          <div>
            <img alt="" src={join} />
          </div>
        </div>
        <div className="waitlist-right">
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "22px",
                textTransform: "capitalize",

                color: "#FF0000",
              }}
            >
              PropCrowdy is licensed and regulated By SEC{" "}
            </p>
            <img alt="" src={badge} style={{ height: "60px", width: "60px" }} />
          </div>
          <div>
            <p
              style={{
                fontFamily: "Tiempos Headline",
                fontStyle: "normal",
                fontWeight: "900",
                fontSize: "50px",
                lineHeight: "64px",

                color: "#000000",
              }}
            >
              Join Investors Wait List
            </p>
            <p>
              Now You can invest with confidence, knowing that your investment
              is safe.
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <form
              style={{ maxWidth: "585px", width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData();
                formData.append("first_name", state.first_name);
                formData.append("last_name", state.last_name);
                formData.append("email", state.email);
                formData.append("phone_number", state.phone_number);
                // formData.append(
                //   "customer_type",
                //   state.checkbox ? "developer" : "retail_investor"
                // );
                formData.append("customer_type", state.customer_type);
                axios
                  .post(
                    "https://office.propcrowdy.com/api/v1/wait-list",
                    formData
                  )
                  .then((res) => {
                    setState({
                      first_name: "",
                      email: "",
                      last_name: "",
                      phone_number: "",
                      customer_type: "developer",
                    });
                    notifysuccess(res.data.message);
                  })
                  .catch((err) => {
                    notifyfail(
                      err.response.data?.errors?.email ||
                        err.response.data.errors?.last_name ||
                        err.response.data.errors?.phone_number ||
                        err.response.data.errors?.first_name ||
                        err.response.data?.message
                    );
                    setErrors((prev) => ({
                      ...prev,
                      first_name: err.response.data?.errors?.first_name,
                      email: err.response.data?.errors?.email,
                      last_name: err.response.data?.errors?.last_name,
                      phone_number: err.response.data?.errors?.phone_number,
                    }));
                  });
                // Api.fetch("waitlist", undefined, state).then(
                //   ({ status, responsJSON }) => {
                //     if (status === 200) {
                //       setState({});
                //       // toast.success(
                //       //   "You have successfully joined the waitlist"
                //       // );
                //     } else {
                //       // toast.error("Unable to join waitlist, please try again");
                //     }
                //   }
                // );
              }}
            >
              <div className="waitlist-holder">
                {/* <div style={{ width: "100%" }}> */}
                <input
                  placeholder="First Name"
                  name="first_name"
                  value={state.first_name}
                  onChange={onChange}
                  className="waitlist-input"
                />
                {/* </div> */}
                {/* <div style={{ width: "100%" }}> */}
                <input
                  name="last_name"
                  placeholder="Last Name"
                  onChange={onChange}
                  value={state.last_name}
                  className="waitlist-input pt"
                />
                {/* </div> */}
              </div>
              <div>
                <input
                  name="phone_number"
                  value={state.phone_number}
                  onChange={onChange}
                  placeholder="Phone Number"
                  style={{ width: "100%", marginTop: "16px" }}
                />
                <input
                  name="email"
                  onChange={onChange}
                  value={state.email}
                  placeholder="Email Address"
                  style={{ width: "100%", marginTop: "16px" }}
                />
              </div>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <input
                  id="dev_checkbox"
                  type="checkbox"
                  checked={state.checkbox}
                  onChange={handleCheckbox}
                  // size={20}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  className="waitlist_checkbox"
                />
                <label
                  htmlFor="dev_checkbox"
                  style={{
                    position: "static",
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#ff0000",
                  }}
                >
                  Send me developer request
                </label>
              </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "16px",
                  fontWeight: "700",
                }}
              >
                {customerTypes.map((customerType, index) => {
                  return (
                    <div key={index}>
                      <label
                        style={{
                          position: "static",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="customer_type"
                          value={customerType.value}
                          style={{ marginRight: "5px" }}
                          onChange={handleCustomerTypeChange}
                          checked
                        />
                        {customerType.display}
                      </label>
                    </div>
                  );
                })}
                {/* <label
                  style={{
                    position: "static",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <input
                    type="radio"
                    name="customer_type"
                    value="developer"
                    style={{ marginRight: "5px" }}
                    onChange={handleCustomerTypeChange}
                    checked
                  />
                  Developer
                </label> */}

                {/* <label
                  style={{
                    position: "static",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "15px",
                  }}
                >
                  <input
                    type="radio"
                    name="customer_type"
                    value="retail_investor"
                    style={{ marginRight: "5px" }}
                    onChange={handleCustomerTypeChange}
                  />
                  Retail Investor
                </label> */}
              </div>

              <div>
                <button
                  style={{
                    marginTop: "62px",
                    width: "286px",
                    height: "60px",
                    background: "#FF0000",
                    color: "white",
                    boxShadow:
                      "0px 67px 80px rgba(55, 52, 169, 0.07), 0px 43.4259px 46.8519px rgba(55, 52, 169, 0.0531481), 0px 25.8074px 25.4815px rgba(55, 52, 169, 0.0425185), 0px 13.4px 13px rgba(55, 52, 169, 0.035), 0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815), 0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519)",
                    borderRadius: "47px",
                  }}
                >
                  Send me Investment Oppotunitues
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
