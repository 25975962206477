import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import Loading from './../../../../App/Components/Templates/Loading';
import Api from './../../../../App/Components/Utils/Api';
import UI from './../../../../App/Components/Utils/UI';
import {baseUrl} from './../../../../../config';
import $ from "jquery";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        Contact.contextType = Context;
        this.state = {
            email: '',
            subject: '',
            body: '',
            submitting: false,
            message: '',
            error: {
                email: [],
                subject: [],
                body: []
            }
        }
    }

    close() {
        this.context.updateContext('contactModalType', undefined);
        this.context.updateContext('contactModalData', undefined);
        $('#contact-modal').modal('hide');
    }

    submit() {
        let error = this.state.error;
        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});
        if(!this.context.user) {
            if (typeof this.state.email !== 'string' || this.state.email.trim() === '') {
                error.email.push('Please provide your email address');
            } else if (!(typeof this.state.email === 'string' && this.state.email.match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}/gi))) {
                error.email.push('Please provide a valid email');
            }
        }

        if (!this.context.contactModalType && (typeof this.state.subject !== 'string' || this.state.subject.trim() === '')) {
            error.subject.push('Please provide subject for the message');
        }

        if (typeof this.state.body !== 'string' || this.state.body.trim() === '') {
            error.body.push('Please provide the message body');
        }

        this.setState({error: error});

        for(let i in error) {
            if(error.hasOwnProperty(i)) {
                if(error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        Api.fetch('contact', undefined, {
            email: this.context.user.email || this.state.email,
            subject: this.context.contactModalType && this.context.contactModalType === 'PROPERTYPAYMENT' ? 'Payment for Property: ' + this.context.contactModalData.title : this.state.subject,
            body: this.state.body + (this.context.contactModalType === 'PROPERTYPAYMENT' && this.context.contactModalData ? '\n\nUser ID: ' + this.context.user.id + '\nUser Name: ' + this.context.user.first_name + ' ' + this.context.user.last_name + '\nUser Email: ' + this.context.user.email + '\nProperty Link: ' + baseUrl + '/property/' + this.context.contactModalData.slug : '')
        }).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if(status === 200) {
                this.setState({amount: ''}, () => {
                    this.close();
                    UI.alert({
                        title: 'Message sent',
                        content: 'Your message has been delivered successfully',
                    }, this.context);
                });
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <div id="contact-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <form id="contact-modal-form" className="investment" onSubmit={(e) => {
                            e.preventDefault();
                            this.submit();
                        }}>
                            {this.state.submitting && <Loading />}
                            <div className="heading">
                                {!this.context.contactModalType ? 'Contact' : ''}
                                {this.context.contactModalType === 'PROPERTYPAYMENT' ? 'Contact for More Information' : ''}
                            </div>
                            <div className="sub-heading">
                                {this.context.contactModalType === 'PROPERTYPAYMENT' && this.context.contactModalData ? this.context.contactModalData.title : ''}
                            </div>
                            <div className="form-groups">
                                {!this.context.user ? (
                                    <div className="form-group">
                                        <input id="contact-email" type="email" name="email" placeholder="Email Address" onChange={(e) => {this.setState({email: e.target.value})}} />
                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.email.join('<br />')}} />
                                    </div>
                                ) : null}
                                {!this.context.contactModalType ? (
                                    <div className="form-group">
                                        <input id="contact-subject" type="subject" name="text" placeholder="Subject" onChange={(e) => {this.setState({subject: e.target.value})}} />
                                        <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.subject.join('<br />')}} />
                                    </div>
                                ) : null}
                                <div className="form-group">
                                    <textarea id="contact-body" name="body" placeholder="Message Body" onChange={(e) => {this.setState({body: e.target.value})}} />
                                    <div className="error" dangerouslySetInnerHTML={{__html: this.state.error.body.join('<br />')}} />
                                </div>
                            </div>
                            <div className="message">{this.state.message}</div>
                            <button type="submit" className="btn btn-primary btn-block">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
