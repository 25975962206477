import "./../../assets/style.css";
import React from "react";
import { Context } from "./../../../App/Components/Context";
import ArticleListItem from "./../Templates/ArticleListItem";
import Loading from "./../../../App/Components/Templates/Loading";
import Document from "./../../../App/Components/Utils/Document";
import Api from "./../../../App/Components/Utils/Api";

export default class ArticleList extends React.Component {
  constructor(props) {
    super(props);
    ArticleList.contextType = Context;
    this.state = {
      term: null,
      offset:
        Api.queryStringToObj(this.props.history.location.search).offset || 0,
      limit:
        Api.queryStringToObj(this.props.history.location.search).limit || 24,
      list: [],
      total: 0,
      fetching: false,
    };
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      this.context.layout === "default.buy" ||
        this.context.layout === "default.buy.split"
        ? "default"
        : "default"
    );
    Document.updateDocumentHead({ title: "PropCrowdy - Articles" });
    let param = Api.queryStringToObj(this.props.location.search);
    this.setState({ term: param.term }, () => {
      this.fetchList();
    });
  }

  resetList(callback) {
    this.setState({ list: [], offset: 0 }, () => {
      if (typeof callback === "function") {
        callback.apply();
      }
    });
  }

  fetchList() {
    if (!this.state.fetching) {
      this.setState({ fetching: true });
      Api.fetch("blog.article.list", undefined, {
        search: this.state.term,
        offset: this.state.offset,
        limit: this.state.limit,
      })
        .then(({ status, responseJSON }) => {
          if (status === 200) {
            this.setState({
              list: [...this.state.list, ...(responseJSON.data || [])],
              total: responseJSON.total,
              offset: this.state.offset + (responseJSON.data || []).length,
            });
          }
          this.setState({ fetching: false });
        })
        .catch((reason) => {
          this.setState({ fetching: false });
          console.debug(reason);
        });
    }
  }

  render() {
    return (
      <main id="main" className="blog articles">
        <div className="container">
          <div className="list">
            {!this.state.fetching && !this.state.list.length && (
              <div className="list-empty">
                <i className="icon las la-info-circle" />
                <span className="message">No result</span>
              </div>
            )}
            <div className="row">
              {this.state.list.map((article, index) => (
                <div key={index} className="col-lg-4">
                  <ArticleListItem
                    article={article}
                    previousArticle={this.state.list[index + 1]}
                  />
                </div>
              ))}
            </div>
            <div
              className={
                "ajax-loading-container" +
                (this.state.fetching ? " active" : "")
              }
            >
              {this.state.fetching && <Loading />}
            </div>
            {!this.state.fetching &&
              this.state.list.length < this.state.total && (
                <div className="list-load-more">
                  <button
                    onClick={() => {
                      this.fetchList();
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
          </div>
        </div>
      </main>
    );
  }
}
