import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import _ from 'lodash';
import Loading from './../../../App/Components/Templates/Loading';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';
import $ from "jquery";
import {Link} from "react-router-dom";

export default class InvestmentList extends React.Component {
    constructor(props) {
        super(props);
        InvestmentList.contextType = Context;
        this.state = {
            opportunity: this.props.location.state && this.props.location.state.opportunity,
            list: [],
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'Transaction Records');
        Document.updateDocumentHead({title: 'PropCrowdy - Funding History'});
        if(parseInt(window.location.pathname.split('/')[3])) {
            this.fetchOpportunity();
        }
        this.fetchList();
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
        Hook.register('payment', this.onPayment.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.match.params, this.props.match.params) || prevProps.match.url !== this.props.match.url) {
            this.resetList(() => {
                this.setState({
                    opportunity: this.props.location.state && this.props.location.state.opportunity,
                    list: [],
                    offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
                    limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
                    total: 0,
                }, () => {
                    this.fetchList();
                });
            })
        }
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
        Hook.unregister('payment', this.onPayment.bind(this));
    }

    onPayment(result, type, typeId, data) {
        if(['INVESTMENT', 'POOLFUNDING'].indexOf(type) !== -1) {
            this.fetchList(true);
        }
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(['investment.payment.successful', 'pool.investment.payment.successful'].indexOf(message.type) !== -1) {
                this.fetchList(true);
            }
        }
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchOpportunity() {
        this.setState({fetching: true});
        Api.fetch('opportunity.view', undefined, {slug: window.location.pathname.split('/')[4]}).then(({status, responseJSON}) => {
            this.setState({fetching: false});
            if(status === 200) {
                this.setState({opportunity: responseJSON.data}, () => {
                    Document.updateDocumentHead({title: 'PropCrowdy Funding History - ' + this.state.opportunity.title});
                });
            }
        }).catch((reason) => {
            this.setState({fetching: false});
            console.debug(reason);
        });
    }

    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }
            let id, opportunityId;
            if(parseInt(window.location.pathname.split('/')[3])) {
                id = 'investment.list.opportunity';
                opportunityId = window.location.pathname.split('/')[3];
            } else if (window.location.pathname.split('/')[3] === 'pool') {
                id = 'investment.list.pool';
            } else {
                id = 'opportunity.investment.list';
            }
            Api.fetch(id, undefined, {
                opportunity_id: opportunityId,
                offset: update ? 0 : this.state.offset,
                limit: update ? Math.max(this.state.list.length, this.state.limit) : this.state.limit
            }).then(({status, responseJSON}) => {
                if(status === 200) {
                    this.setState({
                        list: [...(update ? [] : this.state.list), ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: (update ? 0 : this.state.offset) + (responseJSON.data || []).length
                    });
                }
                this.setState({fetching: false});
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    cancel(index) {
        let list = this.state.list;
        Api.fetch('opportunity.investment.cancel', undefined, {
            type: this.state.list[index].type || 'investment',
            pool_funding_id: this.state.list[index].id,
            investment_id: this.state.list[index].id,
            customer_id: this.context.user.id
        }).then(() => {}).catch((reason) => {
            console.debug(reason)
        });
        list.splice(index, 1);
        this.setState({list: list, total: this.state.total - 1});
    }

    render() {
        return (
            <main id="main" className="opportunity fund-history">
                <div className="container">
                    <div className="box">
                        {parseInt(window.location.pathname.split('/')[3]) ? (
                            this.context.user.customer_type === 'developer' ? (
                                <>
                                    <div className="heading">{this.state.opportunity ? this.state.opportunity.title : null}</div>
                                    <div className="sub-heading">A list of all investment made for this offer</div>
                                </>
                            ) : (
                                <>
                                    <div className="heading">{this.state.opportunity ? this.state.opportunity.title : null}</div>
                                    <div className="sub-heading">A list of all your investments on this offer</div>
                                </>
                            )
                        ) : (
                            window.location.pathname.split('/')[3] === 'pool' ? (
                                <>
                                    <div className="heading">Pool Funding History</div>
                                    <div className="sub-heading">Transaction Logs</div>
                                </>
                            ) : (
                                <>
                                    <div className="heading">Funding History</div>
                                    <div className="sub-heading">Transaction Logs</div>
                                </>
                            )
                        )}
                        <div className="list">
                            <div className="investments">
                                <div className="head">
                                    {parseInt(window.location.pathname.split('/')[3]) || window.location.pathname.split('/')[3] === 'pool' ? (
                                        <div className="opportunity">Investor</div>
                                    ) : (
                                        <div className="opportunity">Opportunity/Location</div>
                                    )}
                                    <div className="date">Investment Date</div>
                                    <div className="amount">Amount</div>
                                    <div className="actions" />
                                </div>
                                {this.state.list.map((investment, index) => (
                                    <div key={index} className="investment">
                                        <div className="opportunity">
                                            {parseInt(window.location.pathname.split('/')[3]) ? (
                                                this.context.user.customer_type === 'developer' ? (
                                                    investment.customer ? investment.customer.first_name + ' ' + investment.customer.last_name : ''
                                                ) : (
                                                    this.context.user.first_name + ' ' + this.context.user.last_name
                                                )
                                            ) : (
                                                window.location.pathname.split('/')[3] === 'pool' ? (
                                                    this.context.user.first_name + ' ' + this.context.user.last_name
                                                ) : (
                                                    <>
                                                        <div className="title"><Link to={{pathname: '/opportunity/' + investment.crowdy.slug, state: {opportunity: investment.crowdy}}}>{investment.crowdy ? investment.crowdy.title : ''}</Link></div>
                                                        <div className="location">{investment.crowdy ? investment.crowdy.location : ''}</div>
                                                    </>
                                                )
                                            )}
                                        </div>
                                        <div className="date"><span className="label">Date: </span>{Time.timeToFriendly(investment.created_at, 1)}</div>
                                        <div className="amount">
                                            <span className="label">Amount: </span>
                                            ₦{parseInt(investment.amount_invested || investment.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            {/*<img src="/assets/images/vectors/icons/inflow.svg" alt="Credit" className="icon" />*/}
                                        </div>
                                        <div className="actions">
                                            {parseInt(investment.paid) === 3 ? (
                                                <>
                                                    {window.location.pathname.split('/')[3] === 'pool' ? (
                                                        <button onClick={(e) => {
                                                            e.preventDefault();
                                                            this.context.updateContext('paymentModalType', 'POOLFUNDING');
                                                            this.context.updateContext('paymentModalTypeId', investment.id);
                                                            this.context.updateContext('paymentModalScheduleId', null);
                                                            this.context.updateContext('paymentModalTypeTitle', 'Pool Funding');
                                                            this.context.updateContext('paymentModalTitle', 'PropCrowdy Pool Funding');
                                                            this.context.updateContext('paymentModalAmount', investment.amount);
                                                            this.context.updateContext('paymentModalMethods', ['wallet', 'paystack', 'rave','bank']);
                                                            $('#payment-modal').modal('show');
                                                        }} className="action">Pay</button>
                                                    ) : (
                                                        <>
                                                            {this.context.user.customer_type === 'developer' ? (
                                                                <button disabled className="action pending">Payment Pending</button>
                                                            ) : (
                                                                <>
                                                                    {this.state.opportunity || investment.crowdy ? (
                                                                        <button onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.context.updateContext('paymentModalType', 'INVESTMENT');
                                                                            this.context.updateContext('paymentModalTypeId', investment.id);
                                                                            this.context.updateContext('paymentModalScheduleId', null);
                                                                            this.context.updateContext('paymentModalTypeTitle', 'Opportunity Investment');
                                                                            this.context.updateContext('paymentModalTitle', (this.state.opportunity || investment.crowdy).title);
                                                                            this.context.updateContext('paymentModalAmount', investment.amount_invested);
                                                                            this.context.updateContext('paymentModalData', this.state.opportunity || investment.crowdy);
                                                                            this.context.updateContext('paymentModalMethods', ['wallet', 'paystack', 'rave','bank']);
                                                                            $('#payment-modal').modal('show');
                                                                        }} className="action pay">Pay</button>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : null}
                                            {/*{parseInt(investment.paid) === 1 ? (<button disabled className="action paid">Paid</button>) : null}*/}
                                            {/*{parseInt(investment.paid) === 2 ? (<button disabled className="action processing">Processing</button>) : null}*/}
                                            {/*{parseInt(investment.paid) === 3 ? (<button disabled className="action cancelled">Pending</button>) : null}*/}
                                            {/*{parseInt(investment.paid) === 0 ? (<button disabled className="action cancelled">Cancelled</button>) : null}*/}
                                            {parseInt(investment.paid) === 3 || investment.can_cancel ? (
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    UI.alert({
                                                        title: 'Confirmation',
                                                        content: 'Are you sure you want to cancel this investment?',
                                                        buttons: [{
                                                            type: 'positive',
                                                            title: 'Yes',
                                                            onClick: () => {
                                                                this.cancel(index);
                                                            },
                                                        }, {
                                                            type: 'neutral',
                                                            title: 'No'
                                                        }]
                                                    }, this.context);
                                                }} className="action cancel">Cancel</button>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {!this.state.fetching && !this.state.list.length && (
                                <div className="list-empty">
                                    <i className="icon las la-info-circle" />
                                    <span className="message">No result</span>
                                </div>
                            )}
                            <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                                {this.state.fetching && <Loading />}
                            </div>
                            {!this.state.fetching && this.state.list.length < this.state.total && (
                                <div className="list-load-more">
                                    <button onClick={() => {this.fetchList()}}>Load More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
