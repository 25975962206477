import React from 'react';
import {Context} from './../Context';
import {Link} from 'react-router-dom';

export default class Error extends React.Component {
    constructor(props) {
        super(props);
        Error.contextType = Context;
        this.state = {
            isMobile: window.innerWidth <= 992,
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? 'default.buy' : 'default');
        Document.updateDocumentHead({title: 'PropCrowdy - 404 Not Found'});
        window.addEventListener('resize', this.onResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        this.setState({isMobile: window.innerWidth <= 992});
    }

    render() {
        return (
            <main id="main" className="not-found">
                <div className="container">
                    {this.state.isMobile ? (
                        <img src="/assets/images/vectors/not-found.svg" alt="Not Found" className="image" />
                    ) : (
                        <img src="/assets/images/vectors/404-error.svg" alt="404 Error Found" className="image" />
                    )}
                    <div className="message">It looks like you lost your way</div>
                    <Link to={{pathname: this.context.layout === 'default.buy' || this.context.layout === 'default.buy.split' ? '/buy' : '/'}} className="link">Go Back Home</Link>
                </div>
            </main>
        );
    }
}
