import React from "react";
import { Context } from "./../Context";
import { Link } from "react-router-dom";
import Document from "./../Utils/Document";
import UI from "./../Utils/UI";

import "../../assets/styling/how_page_styling.css";

import numberLabelling from "../../assets/images/vectors/numbers_labelling.svg";

export default class How extends React.Component {
  constructor(props) {
    super(props);
    How.contextType = Context;
    this.state = {
      tab: "develop",
    };
    How.contextType = Context;
  }

  componentDidMount() {
    this.context.updateContext(
      "layout",
      this.context.layout === "default.buy" ||
        this.context.layout === "default.buy.split"
        ? "default.buy"
        : "default"
    );
    Document.updateDocumentHead({ title: "PropCrowdy - How it Works" });
    if (window.location.pathname.split("/")[2] === "invest") {
      this.setState({ tab: "invest" });
    }
  }

  render() {
    return (
      <main id="main" className="how_page">
        <section className="section_container first">
          <div className="main_part">
            <div className="section_headings">
              <h3 className="title">For Fund Raisers</h3>
              <p className="subtitle">
                Raise funds using PropCrowdy in few and easy steps
              </p>
            </div>
            <div className="section_procedures">
              <div className="numbers_labelling">
                <div className="number">1</div>
                <div className="vertical_line one"></div>
                <div className="number">2</div>
                <div className="vertical_line two"></div>
                <div className="number">3</div>
              </div>
              <img src={numberLabelling} alt="numbers" />

              <div className="section_descriptions_container">
                <div className="descriptions_column">
                  <h5 className="description_column_title">
                    Start your Campaign
                  </h5>
                  <ul className="description_column_list">
                    <li>Create an account</li>
                    <li>Setup offer document</li>
                    <li>Set your Fundraiser goal</li>
                    <li>Tell your story</li>
                    <li>Add a picture or video</li>
                  </ul>
                </div>
                <div className="descriptions_column">
                  <h5 className="description_column_title">
                    Share with Friends
                  </h5>
                  <ul className="description_column_list">
                    <li>Send emails</li>
                    <li>Send text messages</li>
                    <li>Share on social media</li>
                    <li>Invite friends</li>
                    <li>Generate promotional banners</li>
                  </ul>
                </div>
                <div className="descriptions_column">
                  <h5 className="description_column_title">Manage Funds</h5>
                  <ul className="description_column_list">
                    <li>Accept Investments</li>
                    <li>Thank Investors</li>
                    <li>Withdraw funds</li>
                    <li>Wallet setup</li>
                    <li>Close rounds</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Link to={{ pathname: "/opportunity/create" }} className="link">
            <button>Get Project Sponsor</button>
          </Link>
        </section>
        <section className="section_container second">
          <div className="main_part">
            <div className="section_headings">
              <h3 className="title">For Investors</h3>
              <p className="subtitle">
                Grow Wealth with PropCrowdy Opportunities
              </p>
            </div>
            <div className="section_procedures">
              <div className="numbers_labelling">
                <div className="number">1</div>
                <div className="vertical_line_investor one"></div>
                <div className="number">2</div>
                <div className="vertical_line_investor two"></div>
                <div className="number">3</div>
              </div>
              <img src={numberLabelling} alt="numbers" />

              <div className="section_descriptions_container">
                <div className="descriptions_column">
                  <h5 className="description_column_title">Register Account</h5>
                  <ul className="description_column_list">
                    <li>Set Investment Profile</li>
                  </ul>
                </div>
                <div className="descriptions_column">
                  <h5 className="description_column_title">Explore Projects</h5>
                  <ul className="description_column_list">
                    <li>Discover Opportunities</li>
                  </ul>
                </div>
                <div className="descriptions_column">
                  <h5 className="description_column_title">Sponsor Projects</h5>
                  <ul className="description_column_list">
                    <li>Make an Investment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <Link to={{ pathname: "/discover" }} className="link"> */}
          <Link to={{ pathname: "/discover" }} className="link">
            <button>Invest and Earn</button>
          </Link>
        </section>
      </main>
    );
  }
}
