import "../../assets/stylings/DealRoomId_styling.css";
import PageRoutesBar from "../Accessories/PageRoutesBar";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../App/Components/Context";

import propertyImage from "../../../User/assets/images/property_image.png";
import documentPdf from "../../assets/images/document_pdf.svg";

const DealRoomId = () => {
  // DealRoomId.contextType = Context;

  const [projectDocuments, setProjectDocuments] = useState([]);

  const [details, setDetails] = useState({});
  const context = useContext(Context);
  const opportunity = context;
  // console.log(
  //   "This is inside the deal-room/id page, the context: ",
  //   opportunity
  // );

  useEffect(() => {
    const more_details = JSON.parse(localStorage.getItem("more_infos"));
    if (more_details) {
      setDetails(more_details);
    }
  }, []);

  const projectImages = details?.projectPictures || [];
  const projectMainImage = projectImages[0]?.url;

  // console.log("this is the image source: ", projectMainImage);

  // console.log("this is the details docs: ", details.projectDocuments);
  return (
    <>
      <PageRoutesBar />
      <div className="dealroomId_container">
        <div className="dealroomId_upper_part">
          <div className="upper_part_texts">
            <h5 className="intro_title">{details.companyName}</h5>
            <h2 className="main_title">{details.projectTitle}</h2>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: details.companyDescription,
              }}
            />
          </div>
          <img src={projectMainImage} alt="property" />{" "}
        </div>
        <div className="dealroomId_lower_part">
          <h4 className="lower_part_title">Documents</h4>
          <ul className="document_listing_container">
            {details?.projectDocuments?.map((document, index) => {
              return (
                <a
                  href={document.url}
                  download
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  key={index}
                  className="document"
                >
                  <img src={documentPdf} alt="document" />
                  <p style={{textTransform:'uppercase'}} className="document_name">{document.collection_name.replaceAll('_', " ")}</p>
                </a>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DealRoomId;
