import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from './../Context';
import Document from './../Utils/Document';

export default class Fees extends React.Component {
    constructor(props) {
        super(props);
        Fees.contextType = Context;
        this.state = {

        };
    }

    componentDidMount() {
        this.context.updateContext('layout', window.location.pathname.split('/')[1] === 'buy' ? 'default.buy' : 'default');
        Document.updateDocumentHead({title: (window.location.pathname.split('/')[1] === 'buy' ? 'PropBuy' : 'PropCrowdy') + ' - Fees and Charges'});
    }

    render() {
        return (
            <main id="main" className="p-0">
                <section id="top-cover">
                    <div className="container">
                        <div className="title">Fees and Charges</div>
                        {window.location.pathname.split('/')[1] === 'buy' ? (
                            <div className="description">
                                These Transactions are free
                                <ul>
                                    <li>Depositing money into you PropBuy wallet</li>
                                    <li>Setting up a developer account</li>
                                    <li>Failed transactions are refundable without extra charges</li>
                                </ul>
                            </div>
                        ) : (
                            <div className="description">
                                These Transactions are free
                                <ul>
                                    <li>Depositing money into you PropCrowdy wallet</li>
                                    <li>Setting up a developer account</li>
                                    <li>Failed transactions are refundable without extra charges</li>
                                </ul>
                            </div>
                        )}
                        <div className="links">
                            <Link to={{pathname: '/register'}} className="link">Register A Developer Account</Link>
                        </div>
                    </div>
                </section>
                <section id="fees-and-charges">
                    <div className="container">
                        {/*<div className="locale row">*/}
                        {/*    <label className="col-md-4">Select your country and local currency</label>*/}
                        {/*    <div className="col-md-8 row">*/}
                        {/*        <div className="col-md-6">*/}
                        {/*            <select name="country" className="form-control">*/}
                        {/*                <option value="us">United States</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-md-6">*/}
                        {/*            <select name="currency" className="form-control">*/}
                        {/*                <option value="usd">USD (US Dollar)</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row pill-tabs">
                            <div className="col-md-4 pills-container">
                                <button className="pills-toggler"><i className="icon las la-align-justify" /></button>
                                <div className="pills active-switch">
                                    <div className="pill local-payment-method active active-switcher" data-selector=".tab.local-payment-method">
                                        <div className="title">Local payment method</div>
                                        <div className="description">Local Bank transfers, e-wallets, debit and credit card, USSD</div>
                                    </div>
                                    <div className="pill global-payment-method active-switcher" data-selector=".tab.global-payment-method">
                                        <div className="title">Global payment method</div>
                                        <div className="description">International payment through Mastercard, VISA, American Express</div>
                                    </div>
                                    {/*<div className="pill pool-funding active-switcher" data-selector=".tab.pool-funding">*/}
                                    {/*    <div className="title">Pool Funding</div>*/}
                                    {/*    <div className="description">Group funding</div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-md-8 tabs-container">
                                <div className="tab local-payment-method active">
                                    {/*<div className="col-md-6">*/}
                                        <div className="title">Local payment method</div>
                                        <div className="content">
                                            <p>Applicable fees are charged by the payment service providers</p>
                                        </div>
                                    {/*</div>*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*    <div className="fee">Fee: 1.00 %</div>*/}
                                    {/*    <div className="logos">*/}
                                    {/*        <img src="/assets/images/paystack.png" alt="Paystack" className="logo" />*/}
                                    {/*        <img src="/assets/images/access.png" alt="Access" className="logo" />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="tab global-payment-method">
                                    {/*<div className="col-md-6">*/}
                                        <div className="title">Global payment method</div>
                                        <div className="content">
                                            <p>Applicable fees are charged by the payment service providers</p>
                                        </div>
                                    {/*</div>*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*    <div className="fee">Fee: 1.00 %</div>*/}
                                    {/*    <div className="logos">*/}
                                    {/*        <img src="/assets/images/paystack.png" alt="Paystack" className="logo" />*/}
                                    {/*        <img src="/assets/images/access.png" alt="Access" className="logo" />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {/*<div className="row tab pool-funding">*/}
                                {/*    <div className="col-md-6">*/}
                                {/*        <div className="title">Pool Funding</div>*/}
                                {/*        <div className="content">*/}
                                {/*            <p>Group funding</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-6">*/}
                                {/*        <div className="fee">Fee: 1.00 %</div>*/}
                                {/*        <div className="logos">*/}
                                {/*            <img src="/assets/images/paystack.png" alt="Paystack" className="logo" />*/}
                                {/*            <img src="/assets/images/access.png" alt="Access" className="logo" />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
