import "./../../assets/style.css";
import "../../assets/stylings/dashboard_investor_stylings.css";
import React from "react";
import { Link } from "react-router-dom";
import { Context } from "./../../../App/Components/Context";
import Loading from "./../../../App/Components/Templates/Loading";
import Api from "./../../../App/Components/Utils/Api";
import $ from "jquery";

import walletIcon from "../../assets/images/wallet_icon.svg";
import circledArrowDown from "../../assets/images/circled_arrow_down.svg";
import announcementSpeaker from "../../assets/images/announcement_speaker.svg";

import propertyImage from "../../assets/images/property_image.png";

export default class DashboardInvestor extends React.Component {
  constructor(props) {
    super(props);
    DashboardInvestor.contextType = Context;
    this.state = {
      ongoingOpportunityList: [],
      totalOngoingOpportunityList: 0,
      fetchingOngoingOpportunityList: false,
      recommendedOpportunityList: [],
      totalRecommendedOpportunityList: 0,
      fetchingRecommendedOpportunityList: false,
      showROI: false,
      copied: false,
    };
  }

  componentDidMount() {
    this.fetchOngoingOpportunities();
    this.fetchRecommendedOpportunities();
  }

  fetchOngoingOpportunities() {
    this.setState({ fetchingOngoingOpportunityList: true });
    Api.fetch("opportunity.list", undefined, { type: "ongoing", limit: 3 })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingOngoingOpportunityList: false });
        if (status === 200) {
          this.setState({
            ongoingOpportunityList: responseJSON.data || [],
            totalOngoingOpportunityList: responseJSON.total,
          });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingOngoingOpportunityList: false });
        console.debug(reason);
      });
  }

  fetchRecommendedOpportunities() {
    this.setState({ fetchingRecommendedOpportunityList: true });
    Api.fetch("opportunity.list", undefined, { type: "recommended", limit: 3 })
      .then(({ status, responseJSON }) => {
        this.setState({ fetchingRecommendedOpportunityList: false });
        if (status === 200) {
          this.setState({
            recommendedOpportunityList: responseJSON.data || [],
            totalRecommendedOpportunityList: responseJSON.total,
          });
        }
      })
      .catch((reason) => {
        this.setState({ fetchingRecommendedOpportunityList: false });
        console.debug(reason);
      });
  }

  render() {
    return (
      <main id="main" className="dashboard">
        <div className="dashboard_container">
          {this.state.announcement && (
            <div className="announcement">
              <img
                src="/assets/images/vectors/icons/announcement.svg"
                alt="Announcement"
                className="icon"
              />
              <div className="body">
                <div className="title">{this.state.announcement.title}:</div>
                <div className="content">
                  {this.state.announcement.content}
                  {this.state.announcement.link && (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={this.state.announcement.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link las la-link"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="stats">
            <div className="cards_section">
              <div className="info_cards">
                <div className="dashboard-card wallet">
                  <div className="top_texts">
                    <div className="title">
                      My Wallet Balance{" "}
                      <img src={circledArrowDown} alt="arrow down" />
                    </div>
                    <div className="amount">
                      ₦
                      {parseInt(this.context.user.wallet_balance || 0)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="buttons">
                    <button
                      className="button button_fund_wallet"
                      onClick={(e) => {
                        e.preventDefault();
                        this.context.updateContext(
                          "paymentModalType",
                          "FUNDWALLET"
                        );
                        this.context.updateContext(
                          "paymentModalTypeId",
                          this.context.user.id
                        );
                        this.context.updateContext(
                          "paymentModalScheduleId",
                          null
                        );
                        this.context.updateContext(
                          "paymentModalTypeTitle",
                          "Fund Wallet"
                        );
                        this.context.updateContext(
                          "paymentModalTitle",
                          "Fund your PropCrowdy Wallet using either of the options below"
                        );
                        this.context.updateContext("paymentModalAmount", null);
                        this.context.updateContext(
                          "paymentModalData",
                          this.context.user
                        );
                        this.context.updateContext("paymentModalMethods", [
                          "paystack",
                          "rave",
                        ]);
                        $("#payment-modal").modal("show");
                      }}
                    >
                      <img src={walletIcon} alt="fund wallet" />
                      Fund Wallet
                    </button>
                    <button className="button button_withdraw" disabled>
                      Withdraw
                      {/*                                            <i className="icon las la-file-export" />*/}
                    </button>
                  </div>
                </div>

                <div className="dashboard-card investment">
                  <div className="top_texts">
                    <div className="title">
                      Total amount Invested{" "}
                      <img src={circledArrowDown} alt="arrow down" />
                    </div>
                    <div className="amount">
                      ₦
                      {parseInt(
                        this.context.user.investment_stat
                          ? this.context.user.investment_stat
                              .total_amount_invested
                          : 0
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div onClick={() => {
                    this.setState({showROI:!this.state.showROI})
                  }} className="check_roi_button">Check Expected ROI</div>
                  {this.state.showROI && <div className="expected_roi">
                    ₦ {(parseFloat(this.context.user.investment_stat.totalExpectedInvestmentReturn) - parseFloat(this.context.user.investment_stat.total_amount_invested))}
                  </div>}
                </div>

                {/* my addition  is here */}

                <div className="dashboard-card pool-funding">
                  <div className="top_texts">
                    <div className="title">Pool funding Balance</div>
                    <div className="amount">
                      ₦
                      {parseInt(
                        this.context.user.pool_funding_stat
                          ? this.context.user.pool_funding_stat
                              .ongoingExpectedPoolReturn
                          : 0
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>

                  <button className="fund_button">
                    <img src={walletIcon} alt="fund from wallet" />
                    <div className="wrap check">Fund from wallet</div>
                  </button>
                </div>

                {/* and it ends here */}
              </div>
              <div className="copy">
                <div className="text_part">
                  <div className="title"> REFER AND EARN</div>
                  <p className="sub_title">Get bonus on every referral</p>
                </div>
                {/* <div className="code"></div> */}
                <div style={{cursor:'pointer'}} className="referral_link" onClick={()=>{
                    this.setState({copied:true})
                    setTimeout( ()  => {
                        this.setState({copied:false})
                    }, 3000)
                    navigator.clipboard.writeText(`https://propcrowdy.com/register?ref=${this.context.user.customer_number}`);
                }}> Copy Referral Link</div>
                <img
                  src={announcementSpeaker}
                  alt="announcement"
                  // style={{
                  //   width: "72px",
                  //   height: "94px",
                  //   position: "absolute",
                  //   right: "0",
                  //   bottom: "0",
                  // }}
                  className="copy_announcement"
                />
              </div>
                {this.state.copied && <span style={{color:'green'}}>Copied!</span>}
            </div>
          </div>

          <div className="opportunities-container recommended_opportunities">
            <div className="heading">
              <div className="title">Recommended opportunities</div>
            </div>
            <div className="opportunities_container">
              {this.state.fetchingRecommendedOpportunityList && <Loading />}
              <div className="opportunities_listing">
                {this.state.recommendedOpportunityList.map(
                  (opportunity, index) => (
                    <div key={index} className="opportunity_individual">
                      {opportunity.title === "-" && <Loading />}

                      <div className="property_image">
                        <img src={propertyImage} alt="property" />
                      </div>
                      <div className="texts_part">
                        <div className="property_title">
                          {opportunity.title}
                        </div>

                        <div className="property_subtexts">
                          <div className="property_description">
                            <p className="roi_value">ROI {opportunity.roi_percentage}%</p>
                            <p className="min_value">₦ {opportunity.min_investment
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Min</p>
                          </div>
                          <Link
                            to={{
                              pathname: "/opportunity/" + opportunity.slug,
                              state: { opportunity: opportunity },
                            }}
                            className="link"
                          >
                            View details
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="opportunities-container">
            <div className="heading">
              <div className="title">My On-going Investments</div>
              <Link to={{ pathname: "/opportunity/list" }} className="link">
                View More
              </Link>
            </div>
            <div className="opportunities">
              {this.state.fetchingOngoingOpportunityList && <Loading />}
              <div className="row">
                {this.state.ongoingOpportunityList.map((opportunity, index) => (
                  <div key={index} className="col-md-4">
                    <div className="opportunity investment">
                      {opportunity.title === "-" && <Loading />}
                      <div className="location">
                        <i className="icon las la-map-marker" />
                        {opportunity.location}
                      </div>
                      <div className="title">{opportunity.title}</div>
                      <div className="type">Offer</div>
                      <div className="status">
                        <i className="icon las la-check" />
                        ONGOING
                      </div>
                      {this.context && this.context.settings && this.context.settings.investments && this.context.settings.investments.show_goal?(
                      <div className="amount">
                        <span className="label">Invested Amount</span>
                        <span className="value">
                          ₦{parseInt(opportunity.amount_raised || 0)}
                        </span>
                      </div>
                          ):null}
                      <Link
                        to={{
                          pathname: "/opportunity/" + opportunity.slug,
                          state: { opportunity: opportunity },
                        }}
                        className="link"
                      >
                        View details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
