import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Context } from "../../Context";
import Loading from "../Loading";
import Api from "../../Utils/Api";
import UI from "../../Utils/UI";
import "./layout.css";

// import whatsappIcon from "../../../assets/images/vectors/icons/whatsapp_icon.svg";
// import logoClear from "../../../assets/images/vectors/logo_clear.svg";

import devImage from "../../../assets/images/dev_picture_building.png";

export default class DefaultPictured extends React.Component {
  constructor(props) {
    super(props);
    DefaultPictured.contextType = Context;
    this.subscribeForm = null;
    this.state = {
      isMobile: window.innerWidth <= 992,
      searchActive: false,
      searchResultActive: false,
      searchTerm: "",
      searching: false,
      searchResultList: [],
      subscribeEmail: "",
      subscribing: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  onResize() {
    this.setState({ isMobile: window.innerWidth <= 992 });
  }

  search() {
    if (this.state.searchTerm.length && !this.state.searching) {
      let term = this.state.searchTerm;
      this.setState({ searching: true });
      let id =
        !this.props.htmlClasses || this.props.htmlClasses.indexOf("buy") === -1
          ? "opportunity.list"
          : "property.list";
      Api.fetch(id, undefined, { search: this.state.searchTerm, limit: 5 })
        .then(({ status, responseJSON }) => {
          this.setState({ searching: false }, () => {
            if (
              this.state.searchTerm.length &&
              this.state.searchTerm !== term
            ) {
              this.search();
            }
          });
          if (status === 200) {
            this.setState({ searchResultList: responseJSON.data || [] });
          }
        })
        .catch((reason) => {
          this.setState({ searching: false }, () => {
            if (
              this.state.searchTerm.length &&
              this.state.searchTerm !== term
            ) {
              this.search();
            }
          });
          console.debug(reason);
        });
    }
  }

  subscribe() {
    this.setState({ subscribing: true });
    if (
      typeof this.state.subscribeEmail !== "string" ||
      !this.state.subscribeEmail.length
    ) {
      this.setState({ subscribing: false });
      return UI.alert(
        {
          type: "error",
          title: "No Email",
          content: "Please provide an email",
        },
        this.context
      );
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.subscribeEmail
      )
    ) {
      this.setState({ subscribing: false });
      return UI.alert(
        {
          type: "error",
          title: "Invalid Email",
          content: "Please provide a valid email",
        },
        this.context
      );
    }
    Api.fetch("email.subscribe", undefined, {
      email: this.state.subscribeEmail,
    })
      .then(({ status, responseJSON }) => {
        this.setState({ subscribing: false });
        if (status === 200) {
          this.setState({ subscribeEmail: "" });
          UI.alert(
            {
              title: "Congratulations",
              content:
                "Your email address has been added to our email subscription list.",
            },
            this.context
          );
        }
      })
      .catch((reason) => {
        this.setState({ subscribing: false });
        console.debug(reason);
      });
  }

  header() {
    return (
      <header id="heade">
        <nav
          className="navbar navbar-expand-lg navbar-light"
          style={{ padding: "0px" }}
        >
          {!this.props.htmlClasses ||
          this.props.htmlClasses.indexOf("buy") === -1 ? (
            <NavLink id="header-logo" to="/" className="navbar-brand">
              <img
                src="/assets/images/vectors/logo.svg"
                alt="PropCrowdy Logo"
              />
            </NavLink>
          ) : (
            <NavLink
              id="header-logo"
              to="/buy"
              className="navbar-brand primary"
            >
              <img src="/assets/images/logo-3.png" alt="PropBuy Logo" />
            </NavLink>
          )}
        </nav>
        {/* <nav>
          <NavLink id="header-logo" to="/" className="navbar-brand">
            <img src={logoClear} alt="PropCrowdy Logo" />
          </NavLink>
        </nav> */}
      </header>
    );
  }

  // header() {
  //   return (
  //     <header>
  //       <div>
  //         <img src={logoClear} alt="PropCrowdy Logo" className="header_image" />
  //       </div>
  //     </header>
  //   );
  // }

  render() {
    return (
      <div
        id=""
        className={
          "register-container" +
          (this.props.htmlClasses ? " " + this.props.htmlClasses.join(" ") : "")
        }
      >
        <div className="register-left">
          <div className="register-header">{this.header()}</div>
          <div className="spacer" />
          <div className="register-holder">{this.props.children}</div>
        </div>
        <div className="register-right">
          <img src={devImage} alt="" className="" />
          {/* <img
            src={"../../../assets/images/dev_picture_building.png"}
            alt=""
            className=""
          /> */}
        </div>
        {/* </div> */}
      </div>
    );
  }
}
