import './../../../assets/style.css';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {Context} from './../../../../App/Components/Context';

export default class Resource extends React.Component {
    constructor(props) {
        super(props);
        Resource.contextType = Context;
        this.state = {

        };
    }

    render() {
        return (
            <div id="resource-layout" className={!window.location.pathname.split('/')[2] ? 'index' : ''}>
                <div className="bread-crumb">
                    <div className="container">
                        <span className="link">Resources</span>
                        {this.props.subTitle ? (
                            <>
                                <i className="icon arrow las la-angle-right" />
                                <span className="link">{this.props.subTitle}</span>
                            </>
                        ) : null}
                        <i className="icon arrow las la-angle-right" />
                        <span className="link">{this.props.title}</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="heading">{this.props.heading || 'Resources'}</div>
                            {/*<div className="sub-heading">{this.props.subHeading || 'Resources and Tools'}</div>*/}
                            <div className="nav">
                                <div className={'nav-item' + (window.location.pathname.split('/')[2] === 'mortgage-calculator' || !window.location.pathname.split('/')[2] ? ' active' : '')}>
                                    <NavLink to={{pathname: '/resource/mortgage-calculator'}} className="nav-link">
                                        <div className="title">Mortgage Calculator</div>
                                        <div className="description">Use our mortgage calculator for accurate mortgage rates and summaries</div>
                                    </NavLink>
                                </div>
                                <div className={'nav-item' + (window.location.pathname.split('/')[2] === 'virtual-tour' ? ' active' : '')}>
                                    <NavLink to={{pathname: '/resource/virtual-tour/create'}} className={'nav-link' + (!window.location.pathname.split('/')[2] ? ' active' : '')}>
                                        <div className="title">Virtual Tour Creator</div>
                                        <div className="description">Create virtual tour using this amazing tool and integrate on your website</div>
                                    </NavLink>
                                </div>
                                <div className={'nav-item' + (window.location.pathname.split('/')[2] === 'articles-research-papers' || window.location.pathname.split('/')[2] === 'article-research-paper' ? ' active' : '')}>
                                    <NavLink to={{pathname: '/resource/articles-research-papers'}} className="nav-link">
                                        <div className="title">Articles and Research Papers</div>
                                        <div className="description">Remain knowledgeable, explore a wide collection of real estate research materials</div>
                                    </NavLink>
                                </div>
{/*                                <div className={'nav-item' + (window.location.pathname.split('/')[2] === 'email-phone-marketing' ? ' active' : '')}>
                                    <NavLink to={{pathname: '/resource/email-phone-marketing'}} className="nav-link">
                                        <div className="title">Real Estate Email & Phone Database</div>
                                        <div className="description">Explore and start your marketing with our real estate players phone and email database</div>
                                    </NavLink>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-lg-8 content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
