import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import Loading from './../../../../App/Components/Templates/Loading';
import UI from './../../../../App/Components/Utils/UI';
import Time from './../../../../App/Components/Utils/Time';
import $ from 'jquery';

export default class PaymentSchedules extends React.Component {
    constructor(props) {
        super(props);
        PaymentSchedules.contextType = Context;
        this.state = {

        }
    }

    close() {
        this.context.updateContext('paymentScheduleModalType', undefined);
        this.context.updateContext('paymentScheduleModalTitle', undefined);
        this.context.updateContext('paymentScheduleModalDescription', undefined);
        this.context.updateContext('paymentScheduleModalList', undefined);
        $('#payment-schedule-modal').modal('hide');
    }

    pay(schedule) {
        schedule = {...schedule}
        if (schedule.status === 'payment_pending') {
            let typeId = schedule.deal_id;
            let scheduleId = schedule.id;
            let title = this.context.paymentScheduleModalTitle;
            let amount = schedule.amount;
            this.context.updateContext('paymentModalType', 'PROPBUY');
            this.context.updateContext('paymentModalTypeId', typeId);
            this.context.updateContext('paymentModalScheduleId', scheduleId);
            this.context.updateContext('paymentModalTypeTitle', 'Property Purchase');
            this.context.updateContext('paymentModalTitle', title);
            this.context.updateContext('paymentModalAmount', amount);
            this.context.updateContext('paymentModalData', schedule);
            this.context.updateContext('paymentModalMethods', ['wallet', 'paystack', 'rave','bank']);
            this.close();
            $('#payment-modal').modal('show');
        } else if(schedule.status === 'processing_payment') {
            this.close();
            UI.alert({
                title: 'Transaction in progress',
                content: 'Your payment transaction is in progress. Please wait for the payment to be confirmed.',
            }, this.context);
        }
    }

    render() {
        return (
            <div id="payment-schedule-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div id="payment-schedule-list" onSubmit={(e) => {
                            e.preventDefault();
                            this.submit();
                        }}>
                            {this.state.submitting && <Loading />}
                            <div className="heading">Payment Schedule</div>
                            <div className="sub-heading">Your Payment schedule for {this.context.paymentScheduleModalTitle}</div>
                            <div className="list">
                                <div className="head">
                                    <div className="amount">Amount</div>
                                    <div className="status">Status</div>
                                    <div className="date">Due Date</div>
                                    <div className="actions">Actions</div>
                                </div>
                                {(this.context.paymentScheduleModalList || []).map((schedule, index) => (
                                    <div key={index} className={'schedule ' + schedule.status + (Time.strToTime(schedule.due_date) <= new Date().getTime() ? ' due' : '')}>
                                        <div className="amount">₦{parseInt((schedule.amount || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                                        <div className="status">{((this.context.constants && this.context.constants.property && this.context.constants.property.status) || []).reduce((statuses, status) => {statuses[status.value] = status.display; return statuses}, {})[schedule.status]}</div>
                                        <div className="date">{Time.timeToFriendly(schedule.due_date)}</div>
                                        <div className="actions">
                                            <button disabled={schedule.status === 'paid' || schedule.status === 'processing_payment' || Time.strToTime(schedule.due_date) > new Date().getTime()} className="action" onClick={() => {this.pay(schedule)}}>
                                                {schedule.status === 'payment_pending' ? (Time.strToTime(schedule.due_date) > new Date().getTime() ? 'Not Due' : 'Pay Now') : ''}
                                                {schedule.status === 'processing_payment' ? 'Processing' : ''}
                                                {schedule.status === 'paid' ? 'Paid' : ''}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
