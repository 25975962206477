import React from "react";
import { withRouter } from "react-router-dom";
import Blank from "./Layouts/Blank";
import Default from "./Layouts/Default";
import User from "./../../../User/Components/Templates/Layouts/User";
import Modals from "./Modals";
import { Context } from "./../Context";
import Error from "./Error";
import OpportunityInvestmentUpdateList from "./../../../Investor/Components/Pages/InvestmentUpdateList";
import MessageConversationList from "./../../../Message/Components/Pages/ConversationList";
import NotificationList from "./../../../Notification/Components/Pages/List";
import SupportTicketList from "./../../../Support/Components/Pages/TicketList";
import Announcement from "./../../../Announcement/Components/Templates/View";
import Hook from "./../../../App/Components/Utils/Hook";
import UI from "./../../../App/Components/Utils/UI";
import UserUtil from "./../../../User/Components/Utils";
import DefaultPictured from "./Layouts/DefaultPictured";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    Layout.contextType = Context;
    this.state = {
      navigationHistory: [],
      navigationDirection: "forward",
      error: false,
      children: this.props.children,
    };
  }

  componentDidMount() {
    Hook.register("push.on.message", this.pushOnMessage.bind(this));
    Hook.register("user.logged.in", this.userLoggedIn.bind(this));
    Hook.register("user.logged.out", this.userLoggedOut.bind(this));
  }

  componentWillUnmount() {
    Hook.unregister("push.on.message", this.pushOnMessage.bind(this));
    Hook.unregister("user.logged.in", this.userLoggedIn.bind(this));
    Hook.unregister("user.logged.out", this.userLoggedOut.bind(this));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState({ children: this.props.children });
      // this.context.updateContext('layout', 'default');
      let key = window.history.state ? window.history.state.key : null;
      if (key) {
        this.setState(
          {
            navigationDirection:
              this.state.navigationHistory.indexOf(key) !== -1
                ? "backward"
                : "forward",
          },
          () => {
            let history = this.state.navigationHistory;
            if (this.state.navigationDirection === "backward") {
              history.splice(history.indexOf(key));
            } else {
              history.push(key);
              history = history.splice(-50);
            }
            this.setState({ navigationHistory: history });
          }
        );
      }
      Hook.fire("route.changed", undefined, [
        this.props.location,
        prevProps.location,
      ]);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ children: [Error] });
  }

  pushOnMessage(result, message) {
    if (typeof message == "object") {
      if (message.type === "investment.payment.successful") {
        OpportunityInvestmentUpdateList.fetchUnreadList(this.context);
        UserUtil.syncUser(this.context.user, this.context)
          .then(() => {})
          .catch(() => {});
      }

      if (message.type === "pool.investment.payment.successful") {
        OpportunityInvestmentUpdateList.fetchUnreadList(this.context);
        UserUtil.syncUser(this.context.user, this.context)
          .then(() => {})
          .catch(() => {});
      }

      if (message.type === "investment.update") {
        OpportunityInvestmentUpdateList.fetchUnreadList(this.context);
        UserUtil.syncUser(this.context.user, this.context)
          .then(() => {})
          .catch(() => {});
      }

      if (message.type === "new.message") {
        MessageConversationList.fetchUnreadConversation(this.context);
        if (
          this.context.user &&
          window.location.pathname.split("/")[1] !== "messages"
        ) {
          UI.alert(
            {
              title: "New Message",
              content: "You have a new Message",
              buttons: [
                {
                  type: "positive",
                  title: "See Conversations",
                  onClick: () => {
                    this.props.history.push({ pathname: "/messages" });
                  },
                },
              ],
            },
            this.context
          );
        }
      }

      if (message.type === "new.notification") {
        NotificationList.fetchUnreadList(this.context);
        if (
          this.context.user &&
          window.location.pathname.split("/")[1] !== "notifications"
        ) {
          UI.alert(
            {
              title: "New Notification",
              content: "You have a new notification",
              buttons: [
                {
                  type: "positive",
                  title: "See Notifications",
                  onClick: () => {
                    this.props.history.push({ pathname: "/notifications" });
                  },
                },
              ],
            },
            this.context
          );
        }
      }
    }
  }

  userLoggedIn(result, user, context) {
    MessageConversationList.fetchUnreadConversation(context);
    OpportunityInvestmentUpdateList.fetchUnreadList(context);
    Announcement.fetchAnnouncement(context);
    NotificationList.fetchUnreadList(context);
    SupportTicketList.fetchUnreadList(context);
  }

  userLoggedOut(result, context) {
    MessageConversationList.clearUnreadConversation(context);
    OpportunityInvestmentUpdateList.clearUnreadList(context);
    Announcement.clearAnnouncement(context);
    NotificationList.clearUnreadList(context);
    SupportTicketList.clearUnreadList(context);
  }

  render() {
    return (
      <>
        {this.context.layout === "blank" ? (
          <Blank htmlClasses={[this.state.navigationDirection + "-navigation"]}>
            {this.state.children}
          </Blank>
        ) : null}

        {this.context.layout === "defaultPictured" ? (
          <DefaultPictured
            htmlClasses={[this.state.navigationDirection + "-navigation"]}
          >
            {this.state.children}
          </DefaultPictured>
        ) : null}
        {this.context.layout === "default" || !this.context.layout ? (
          <Default
            htmlClasses={[this.state.navigationDirection + "-navigation"]}
          >
            {this.state.children}
          </Default>
        ) : null}
        {this.context.layout === "default.split" ? (
          <Default
            htmlClasses={[
              this.state.navigationDirection + "-navigation",
              "split",
            ]}
          >
            {this.state.children}
          </Default>
        ) : null}
        {this.context.layout === "default.buy" ? (
          <Default
            htmlClasses={[
              this.state.navigationDirection + "-navigation",
              "buy",
            ]}
          >
            {this.state.children}
          </Default>
        ) : null}
        {this.context.layout === "default.buy.split" ? (
          <Default
            htmlClasses={[
              this.state.navigationDirection + "-navigation",
              "buy",
              "split",
            ]}
          >
            {this.state.children}
          </Default>
        ) : null}

        {this.context.layout === "user" ? (
          <User htmlClasses={[this.state.navigationDirection + "-navigation"]}>
            {this.state.children}
          </User>
        ) : null}
        {this.context.layout === "user.buy" ? (
          <User
            htmlClasses={[
              this.state.navigationDirection + "-navigation",
              "buy",
            ]}
          >
            {this.state.children}
          </User>
        ) : null}
        <Modals />
      </>
    );
  }
}

export default withRouter(Layout);
