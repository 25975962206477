const InvestorDeveloperSmallCardDetails = [
  {
    id: 1,
    investor: [
      {
        id: 1,
        // imageUrl:
        // "../../../assets/images/vectors/icons/user-avatar-in-circle.svg",
        imageUrl: "user_avatar_in_circle",
        title: "Set Up Your Account",
        description:
          "Easily sign up with your social media account or create an account with your email",
      },
      {
        id: 2,
        imageUrl: "search_icon_in_circle",
        title: "Search Opportunities",
        description:
          "Select a project opportunity and invest, get monthly updates on your dashboard.",
      },
      {
        id: 3,
        imageUrl: "cash_in_circle_icon",
        title: "Fund A Project",
        description:
          "Your return on Investment is deposited into your account at the end of the cycle.",
      },
      {
        id: 4,
        imageUrl: "stock_going_up_icon",
        title: "Get Returns",
        description:
          "Follow up with trends on your dashboard and monitor your investments.",
      },
    ],
  },
  {
    id: 2,
    developer: [
      {
        id: 1,
        imageUrl: "user_avatar_in_circle",
        title: "Set Up Your Account",
        description:
          "Easily sign up with your social media account or create an account with your email",
      },
      {
        id: 2,
        imageUrl: "search_icon_in_circle",
        title: "Tell Your Story",
        description:
          "Select a project opportunity and sponsor, get monthly updates on your dashboard.",
      },
      {
        id: 3,
        imageUrl: "cash_in_circle_icon",
        title: "Get Funded",
        description:
          "Your return after sponsorship (RAS) are deposited into your account at the end of the cycle.",
      },
      {
        id: 4,
        imageUrl: "stock_going_up_icon",
        title: "Stay Updated",
        description:
          "Follow up with trends on your dashboard and monitor your investments.",
      },
    ],
  },
];

export default InvestorDeveloperSmallCardDetails;
