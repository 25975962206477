import './../../assets/style.css';
import React from 'react';
import {Context} from './../../../App/Components/Context';
import {Link, withRouter} from 'react-router-dom';
import Loading from './../../../App/Components/Templates/Loading';
import _ from 'lodash';
import Document from './../../../App/Components/Utils/Document';
import Hook from './../../../App/Components/Utils/Hook';
import Api from './../../../App/Components/Utils/Api';
import Time from './../../../App/Components/Utils/Time';
import UI from './../../../App/Components/Utils/UI';

class List2 extends React.Component {
    constructor(props) {
        super(props);
        List2.contextType = Context;
        this.state = {
            type: window.location.pathname.split('/')[1] === 'my-opportunities' ? 'my_ongoing' : '0',
            funded: this.props.funded ? '1' : '0',
            amount: null,
            return: null,
            tenor: null,
            term: null,
            offset: Api.queryStringToObj(this.props.history.location.search).offset || 0,
            limit: Api.queryStringToObj(this.props.history.location.search).limit || 24,
            list: [],
            total: 0,
            fetching: false
        };
    }

    componentDidMount() {
        this.context.updateContext('layout', 'user');
        this.context.updateContext('pageShowWelcome', false);
        this.context.updateContext('pageSubHeading', 'List of your PropCrowdy opportunities');
        Document.updateDocumentHead({title: 'PropCrowdy - Opportunities'});
        let param = Api.queryStringToObj(this.props.location.search);
        this.setState({
            amount: param.amount,
            return: param.return,
            tenor: param.tenor,
            term: param.term
        }, () => {
            this.fetchList();
        });
        Hook.register('push.on.message', this.pushOnMessage.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.match.params, this.props.match.params) || prevProps.match.url !== this.props.match.url) {
            this.resetList(() => {
                let param = Api.queryStringToObj(this.props.location.search);
                this.setState({
                    type: window.location.pathname.split('/')[1] === 'my-opportunities' ? 'my_ongoing' : '0',
                    funded: this.props.funded ? '1' : '0',
                    amount: param.amount,
                    return: param.return,
                    tenor: param.tenor,
                    term: param.term,
                    list: []
                }, () => {
                    this.fetchList();
                });
            })
        }
    }

    componentWillUnmount() {
        Hook.unregister('push.on.message', this.pushOnMessage.bind(this));
    }

    pushOnMessage(result, message) {
        if(typeof message == 'object') {
            if(['investment.payment.successful', 'pool.investment.payment.successful'].indexOf(message.type) !== -1) {
                this.fetchList(true);
            }
        }
    }

    resetList(callback) {
        this.setState({list: [], offset: 0}, () => {
            if(typeof callback === 'function') {
                callback.apply();
            }
        });
    }

    fetchList(update) {
        if(!this.state.fetching) {
            if(!update) {
                this.setState({fetching: true});
            }
            let id ;
            if(parseInt(this.state.funded)) {
                id = 'opportunity.list';
            } else if(this.context.user.customer_type === 'developer') {
                id = 'developer.opportunity.list';
            } else {
                id = 'investor.opportunity.list';
            }
            Api.fetch(id, undefined, {
                type: this.state.type,
                funded: this.state.funded,
                amount: this.state.amount,
                return: this.state.return,
                tenor: this.state.tenor,
                search: this.state.term,
                offset: update ? 0 : this.state.offset,
                limit: update ? Math.max(this.state.list.length, this.state.limit) : this.state.limit
            }).then(({status, responseJSON}) => {
                this.setState({fetching: false});
                if(status === 200) {
                    this.setState({
                        list: [...(update ? [] : this.state.list), ...(responseJSON.data || [])],
                        total: responseJSON.total,
                        offset: (update ? 0 : this.state.offset) + (responseJSON.data || []).length
                    });
                }
            }).catch((reason) => {
                this.setState({fetching: false});
                console.debug(reason);
            });
        }
    }

    close(index) {
        let list = this.state.list;
        Api.fetch('opportunity.close', undefined, {opportunity_id: this.state.list[index].id}).then(() => {}).catch((reason) => {console.debug(reason)});
        list[index]['investment_status'] = 2;
        this.setState({list: list});
    }

    render() {
        return (
            <main id="main" className="opportunity admin opportunities listing">
                <div className="container">
                    <div className="list">
                        {!this.state.fetching && !this.state.list.length && (
                            <div className="list-empty">
                                <i className="icon las la-info-circle" />
                                <span className="message">{this.context.user.customer_type === 'developer' ? 'You have not set up any offer' : 'You have not invested in any opportunity'}</span>
                                <div className="links">
                                    {this.context.user.customer_type === 'developer' ? (
                                        <Link to={{pathname: '/opportunity/create'}} className="link">Set up an offer</Link>
                                    ) : (
                                        <Link to={{pathname: '/discover'}} className="link">Discover Opportunities</Link>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="items">
                            {this.state.list.map((opportunity, index) => (
                                <div key={index} className="item opportunity">
                                    <div className="overview">
                                        <Link to={{pathname: '/opportunity/' + opportunity.slug, state: {opportunity: opportunity}}} className="left">
                                            <img src="/assets/images/vectors/icons/opportunity-1.svg" alt="Opportunity" className="icon" />
                                        </Link>
                                        <div className="middle">
                                            <div className="top">
                                                <Link to={{pathname: '/opportunity/' + opportunity.slug, state: {opportunity: opportunity}}} className="title text-uppercase">{opportunity.title}</Link>
                                                <div className={'verification-status' + (parseInt(opportunity.verified) ? ' yes' : ' no')}>{parseInt(opportunity.verified) ? 'Verified' : 'Unverified'}</div>
                                                {opportunity.crowdy_investments ? (
                                                    <div className="sponsors">{opportunity.crowdy_investments.length} Sponsors</div>
                                                ) : null}
                                                <div className="time">{Time.timeToFriendly(opportunity.created_at, 0)}</div>
                                            </div>
                                            <div className="middle">
                                                <div className="location">
                                                    <i className="icon las la-map-marker" />
                                                    {opportunity.location}
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="type">OPPORTUNITY</div>
                                                <div className="status has-icon">
                                                    <i className="icon las la-check" />
                                                    {((this.context.constants && this.context.constants.opportunity && this.context.constants.opportunity.status) || []).reduce((statuses, status) => {statuses[status.value] = status.display; return statuses}, {})[opportunity.status]}
                                                </div>
                                                {new Date(opportunity.closing_date).getTime() > new Date().getTime() && parseInt(opportunity.investment_status) === 1 ? (
                                                    <div className="fund-status opened">Opened Funding Rounds</div>
                                                ) : (parseInt(opportunity.investment_status) === 2 ? (
                                                        <div className="fund-status closed">Closed Funding Rounds</div>
                                                    ) : (
                                                        <div className="fund-status failed">Awaiting Payment Verification</div>
                                                    )
                                                )}
                                                <button className="toggle-details">
                                                    <span className="title">Details</span>
                                                    <i className="icon las la-angle-down" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="top">
                                                <div className="title">
                                                    Funding Goal
                                                    <img src="/assets/images/vectors/icons/saved.svg" alt="Funding Goal" className="icon" />
                                                </div>
                                            </div>
                                            <div className="middle">
                                                <div className="amount">
                                                    <span className="currency">NGN.</span>
                                                    <span className="value">{parseInt(opportunity.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                {parseInt(this.state.funded) ? (
                                                    <div className="fund-status actions">
                                                        {new Date(opportunity.closing_date).getTime() > new Date().getTime() && parseInt(opportunity.investment_status) === 1 ? (
                                                            <div className="opened white fade">Opened</div>
                                                        ) : (
                                                            <div className="closed green">{parseInt(opportunity.investment_status) === 2 ? 'Closed' : 'Pending'}</div>
                                                        )}
                                                        {this.context.user.customer_type !== 'developer' && (new Date(opportunity.closing_date).getTime() > new Date().getTime() ? (
                                                            <Link to={{pathname: '/opportunity/' + opportunity.slug, state: {opportunity: opportunity}}} className="opened white">
                                                                <span>Fund</span>
                                                                <i className="icon las la-arrow-right" />
                                                            </Link>
                                                        ) : null)}
                                                    </div>
                                                ) : (
                                                    <div className="fund-status actions">
                                                        {this.context.user.customer_type === 'developer' ? (
                                                            new Date(opportunity.closing_date).getTime() > new Date().getTime() && parseInt(opportunity.investment_status) === 1 ? (
                                                                <button className="opened white" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    UI.alert({
                                                                        title: 'Confirmation',
                                                                        content: 'Are you sure you want to close this funding round?',
                                                                        buttons: [{
                                                                            type: 'positive',
                                                                            title: 'Yes',
                                                                            onClick: () => {
                                                                                this.close(index);
                                                                            },
                                                                        }, {
                                                                            type: 'neutral',
                                                                            title: 'No'
                                                                        }]
                                                                    }, this.context);
                                                                }}>
                                                                    <span>Close</span>
                                                                    <i className="icon las la-arrow-right" />
                                                                </button>
                                                            ) : (
                                                                <div className="closed green">
                                                                    <span>{parseInt(opportunity.investment_status) === 2 ? 'Closed' : 'Pending'}</span>
                                                                </div>
                                                            )
                                                        ) : (
                                                            new Date(opportunity.closing_date).getTime() > new Date().getTime() && parseInt(opportunity.investment_status) === 1 ? (
                                                                <div className="opened white fade">Opened</div>
                                                            ) : (
                                                                <div className="closed green">{parseInt(opportunity.investment_status) === 2 ? 'Closed' : 'Pending'}</div>
                                                            )
                                                        )}
                                                        <Link to={{pathname: '/fund/history/' + opportunity.id + '/' + opportunity.slug, state: {opportunity: opportunity}}} className="opened white">
                                                            <span>Investments</span>
                                                            <i className="icon las la-arrow-right" />
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details" dangerouslySetInnerHTML={{__html: opportunity.description}} />
                                </div>
                            ))}
                        </div>
                        <div className={'ajax-loading-container' + (this.state.fetching ? ' active' : '')}>
                            {this.state.fetching && <Loading />}
                        </div>
                        {!this.state.fetching && this.state.list.length < this.state.total && (
                            <div className="list-load-more">
                                <button onClick={() => {this.fetchList()}}>Load More</button>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}

export default withRouter(List2);